/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {Avatar, Stack, Typography} from '@mui/material';
import {
    useGetApiBandaSystemEmployeesAccountsDirectoryStatuses,
    useGetApiBandaSystemEmployeesAccountsShow
} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import dayjs from 'dayjs';
import {useQsParams} from 'utils/hooks/useQsParams';
import {scrollBodyToTop, stringAvatar} from 'utils/functions';
import {FormTextField} from 'components/form/FormTextField';
import {FormDatePicker} from 'components/form/FormDatePicker';
import {FormSelect} from 'components/form/FormSelect';
import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {PencilIcon, UserIcon} from 'assets/icons';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'utils/constants';
import {ACCOUNT_TAB_ENUM} from 'utils/constants/menu';
import {ChipStatus} from 'components/accessory/ChipStatus';

export const fetchUsers = (params: {[p: string]: any}) => ({
    page: params.page || 1,
    sort: params.sort,
    per_page: params.per_page || '25',
    'filter[full_name]': params.full_name || '',
    'filter[email]': params.email || '',
    'filter[source_id]': params.source_id || '',
    'filter[date_of_birth]': dayjs(params.date_of_birth).isValid() ? params.date_of_birth : '' || '',
    'filter[search_hiring_date]': dayjs(params.hiring_date).isValid() ? params.hiring_date : '' || '',
    'filter[search_department]': params.department || '',
    'filter[search_position]': params.position || '',
    is_active: params.is_active ? Boolean(+params.is_active) : undefined,
    //@ts-ignore
    'status[]': params.status?.length ? params.status?.map((e: any) => e.value) : undefined
});

export default function UsersTable({form}: {form: any}) {
    const {reset} = form;
    const [params, , setParamsSmart] = useQsParams();
    const navigate = useNavigate();

    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            last_name: params.last_name,
            email: params.email,
            source_id: params.source_id,
            date_of_birth: params.date_of_birth,
            hiring_date: params.hiring_date,
            department: params.department,
            position: params.position,
            is_active: params.is_active,
            status: params.status
        });
    }, []);
    const {data: users, isLoading, refetch} = useGetApiBandaSystemEmployeesAccountsShow(fetchUsers(params));
    const {data: statuses} = useGetApiBandaSystemEmployeesAccountsDirectoryStatuses();
    return (
        <TableLayout
            //@ts-ignore
            columns={USERS_STRUCTURE(statuses?.data, navigate)}
            rows={users?.data || []}
            isLoading={isLoading}
            filterRow
            pagination={users?.pagination}
            negativeMargin
            minWidth={1500}
        />
    );
}

const ACTIVE_OPTIONS = [
    {
        id: 1,
        name: 'Работает',
        value: '1'
    },
    {
        id: 2,
        name: 'Уволен',
        value: '0'
    }
];

const USERS_STRUCTURE = (statuses: any, navigate: any) => [
    {
        title: <SortButton title={'Сотрудник'} sortName={'last_name'} />,
        filter: (
            <FormTextField
                name={'full_name'}
                size={'small'}
                isSearch
                // hasSearchIcon
                placeholder={'Поиск по ФИО'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Avatar
                    {...stringAvatar(item?.last_name + ' ' + item?.first_name)}
                    src={item?.avatar?.url}
                    sx={{
                        width: 36,
                        height: 36,
                        fontSize: '16px'
                    }}
                />
                <Stack>
                    <Typography variant={'body2'}>{item?.last_name + ' ' + item?.first_name + ' '}</Typography>
                    <Typography variant={'body2'}>{item?.patronymic || ''}</Typography>
                </Stack>
            </Stack>
        ),
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Почта'} sortName={'email'} />,
        filter: (
            <FormTextField
                name={'email'}
                size={'small'}
                isSearch
                // hasSearchIcon
                placeholder={'Поиск по email'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.email,
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Дата рождения'} sortName={'date_of_birth'} />,
        filter: <FormDatePicker name={'date_of_birth'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) =>
            dayjs(item?.date_of_birth).isValid() ? dayjs(item?.date_of_birth).format('DD MMMM YYYY') : '',
        sx: {width: '10%'}
    },

    //TODO
    {
        title: 'Подразделение',
        filter: (
            <FormTextField
                name={'department'}
                size={'small'}
                isSearch
                placeholder={'Поиск по подразделению'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.employee_links?.map((link: any) => link.department.name)?.join('\n'),
        sx: {width: '10%'}
    },
    //TODO Добавить сортировку
    {
        title: 'Должность',
        filter: (
            <FormTextField
                name={'position'}
                size={'small'}
                isSearch
                placeholder={'Поиск по должности'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) =>
            item?.employee_links
                ?.filter((link: any) => link?.position_link?.name)
                .map((link: any) => link?.position_link?.name)
                .join('\n'),
        sx: {width: '10%'}
    },
    //TODO
    {
        title: <SortButton title={'Дата найма'} sortName={'hiring_date'} />,
        filter: <FormDatePicker name={'hiring_date'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) =>
            item?.employee_links
                ?.filter((link: any) => link.department.name)
                ?.map((link: any) =>
                    dayjs(link.hiring_date).isValid() ? dayjs(link.hiring_date).format('DD MMMM YYYY') : ''
                )
                ?.join('\n'),
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Источник'} sortName={'source_id'} />,
        filter: (
            <FormTextField
                name={'source_id'}
                size={'small'}
                isSearch
                placeholder={'Поиск по источнику'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.employee_links?.map((link: any) => link.source_id)?.join('\n'),
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Активность'} sortName={'is_active'} />,
        filter: (
            <FormSelect
                name={'is_active'}
                options={ACTIVE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'Активность'}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (item?.is_active ? 'Работает' : 'Уволен'),
        sx: {width: '10%'}
    },
    {
        title: 'Статус',
        // <SortButton
        //     title={'Активность'}
        //     sortName={'status'}
        //
        //     sx={{justifyContent: 'flex-end'}}
        // />
        filter: (
            <FormAutocompleteAsyncPaginate
                name={'status'}
                placeholder={'Статус'}
                optionValue={'value'}
                options={statuses || []}
                multiple
                isSearch
                labelSx={{alignItems: 'flex-end'}}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (
            <Stack gap={0.5} direction={'row'} flexWrap={'wrap'}>
                {item?.status.map((item: any) => (
                    <ChipStatus item={item} key={item.name} />
                ))}
            </Stack>
        ),
        sx: {width: '10%', minWidth: 150}
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item, navigate)} />,
        align: 'right'
    }
];
const ITEM_ACTIONS = (item: any, navigate: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => {
            navigate(ROUTES.USERS_ACCOUNTS + '/' + item?.id + '?tab=' + ACCOUNT_TAB_ENUM.ACCOUNT);
            scrollBodyToTop();
        }
    },
    {
        title: 'Профиль',
        icon: <UserIcon />,
        onClick: () => {
            navigate(ROUTES.PROFILE + '/' + item?.id);
            scrollBodyToTop();
        }
    }
];
