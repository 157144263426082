import {capitalizeFLetter} from 'utils/functions';
import {alpha, Stack} from '@mui/material';
import {MergedItem} from '../types';
import {COLORS_TIME_SLOT} from '../utils';
import {useTheme} from '@mui/material/styles';

export const CalendarDayItem = ({day, isActive}: {day: MergedItem; isActive?: boolean}) => {
    const dayOfTheWeek = capitalizeFLetter(day?.date.format('dd'));
    const color = day.timeslot ? COLORS_TIME_SLOT[day.timeslot.table_type_id] || '#ff0000' : undefined;
    const theme = useTheme();
    const isHolidays = dayOfTheWeek === 'Сб' || dayOfTheWeek === 'Вс';
    const defaultBackground = isHolidays ? alpha(theme.palette.secondary.main, 0.1) : 'transparent';
    return (
        <Stack
            sx={{
                minWidth: 36,
                height: '100%',
                border: (theme) =>
                    '1px solid ' + (isActive ? theme.palette.primary.main : color ? color : theme.palette.divider),
                alignItems: 'center',
                justifyContent: 'center',
                background: color
                    ? `repeating-linear-gradient(-45deg, ${alpha(color, 0.2)},  ${alpha(
                          color,
                          0.8
                      )} 5px, ${defaultBackground} 5px, ${defaultBackground} 10px)`
                    : defaultBackground
            }}>
            {/*{title}*/}
        </Stack>
    );
};
