import {createEvent, createStore} from 'effector';
import dayjs from 'dayjs';

export const setCalendarDays = createEvent<any>();
export const setSchedulerMonth = createEvent<dayjs.Dayjs>();
export const $calendarDays = createStore<any>([]);
export const $schedulerMonth = createStore<dayjs.Dayjs>(dayjs());

export const $selectedUser = createStore<any>(null);
export const setSelectedUser = createEvent<any>();
$selectedUser.on(setSelectedUser, (_, newSelectedUser) => newSelectedUser);

$calendarDays.on(setCalendarDays, (_, newCalendarDays) => newCalendarDays);

$schedulerMonth.on(setSchedulerMonth, (_, newSchedulerMonth) => newSchedulerMonth);
