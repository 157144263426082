/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import {useForm} from 'react-hook-form';
import {FormTextField} from 'components/form/FormTextField';
import React, {useEffect} from 'react';
import {
    getGetApiBandaSystemAccessPermissionShowQueryKey,
    useGetApiBandaSystemAccessPermissionShow,
    usePatchApiBandaSystemAccessRoleUpdate,
    usePutApiBandaSystemAccessRoleCreate
} from 'api/система';
import {LoadingButton} from '@mui/lab';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';
import {closeModal, openModal} from 'context/modalEffector';
import {errorLayoutMessage} from 'utils/functions';
import {GroupsInput} from 'components/custom-inputs/GroupsInput';

export const handleOpenPermissionAccessModal = (name?: string, row?: any) => {
    openModal({
        title: name,
        body: <AddPermissionAccessModal row={row} />,
        maxWidth: 'sm'
    });
};

export const AddPermissionAccessModal = ({row}: {row?: any}) => {
    const form = useForm(schema);
    const {reset} = form;
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    useEffect(() => {
        if (row) {
            reset({name: row.name, permissions: row.permissions});
        }
    }, [row]);
    //@ts-ignore
    const {data: permissions, isLoading} = useGetApiBandaSystemAccessPermissionShow({}, {page: 1, per_page: 500});
    //@ts-ignore
    const data = permissions?.data.map((el) => ({
        id: el.id,
        name: el.name,
        title: el.module?.name,
        options: el.options
    }));
    const newRoleMutation = row ? usePatchApiBandaSystemAccessRoleUpdate() : usePutApiBandaSystemAccessRoleCreate();

    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemAccessPermissionShowQueryKey({
                page: params.page || 1,
                per_page: params.per_page || '25'
            })
        });
    };

    const onSubmit = (data: any) => {
        newRoleMutation
            .mutateAsync({
                data: {
                    id: row ? row.id : undefined,
                    name: data.name,
                    permissions: data.permissions.map((el: any) => el.name)
                }
            })
            .then((r) => {
                openToast({
                    text: 'Роль успешно создана',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <FormTextField name={'name'} placeholder={'Название доступа'} />
                <GroupsInput />
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={newRoleMutation.isPending}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            name: yup.string().required(ERROR_MESSAGE.REQUIRED),
            permissions: yup.array()
        })
    )
};
