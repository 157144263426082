import React, {useState} from 'react';
import {alpha, Box, Button, Stack} from '@mui/material';
import {SimpleTreeView, TreeItem, treeItemClasses} from '@mui/x-tree-view';
import {useGetApiBandaSystemDepartmentsTree} from 'api/система';
import {Loader} from 'components/accessory/Loader';
import {styled} from '@mui/material/styles';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

function ExpandIcon(props: React.PropsWithoutRef<typeof AddBoxRoundedIcon>) {
    return <AddBoxRoundedIcon {...props} sx={{opacity: 0.8}} />;
}

function CollapseIcon(props: React.PropsWithoutRef<typeof IndeterminateCheckBoxRoundedIcon>) {
    return <IndeterminateCheckBoxRoundedIcon {...props} sx={{opacity: 0.8}} />;
}

function EndIcon(props: React.PropsWithoutRef<typeof DisabledByDefaultRoundedIcon>) {
    return <DisabledByDefaultRoundedIcon {...props} sx={{opacity: 0.3}} />;
}

const StyledTreeItem = styled(TreeItem)(({theme}) => ({
    [`& .${treeItemClasses.content}`]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0)
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3
        }
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
    }
}));

export const TreeDepartments = ({form}: {form: any}) => {
    const {data, isLoading} = useGetApiBandaSystemDepartmentsTree();
    const [openItems, setOpenItems] = useState(false);
    const [expandedItems, setExpandedItems] = useState<any[]>([]);
    //@ts-ignore
    const idArrays: any[] = extractIds(data?.data || []);
    const onClick = () => {
        setOpenItems(!openItems);
        openItems ? setExpandedItems([]) : setExpandedItems(idArrays);
    };
    const toggleIdInArray = (id: any) => {
        const index = expandedItems.indexOf(id);

        if (index > -1) {
            // Если ID существует, удаляем его
            setExpandedItems(expandedItems.filter((item) => item !== id));
        } else {
            // Если ID не существует, добавляем его
            setExpandedItems([...expandedItems, id]);
        }
    };
    return (
        <Stack>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Button onClick={onClick} variant={'outlined'} color={'inherit'} sx={{mb: 3, width: 200}}>
                        {openItems ? 'Свернуть все' : 'Развернуть всё'}
                    </Button>
                    <Box sx={{minHeight: 352, minWidth: 250}}>
                        <SimpleTreeView
                            expandedItems={expandedItems}
                            // className={classes.root}
                            slots={{
                                expandIcon: ExpandIcon,
                                collapseIcon: CollapseIcon,
                                endIcon: EndIcon
                            }}>
                            {/*@ts-ignore*/}
                            {data?.data?.map((item: any) => (
                                <InfinityTreeItem item={item} key={item.id} onClick={toggleIdInArray} />
                            ))}
                        </SimpleTreeView>
                    </Box>
                </>
            )}
        </Stack>
    );
};
const InfinityTreeItem = ({item, onClick}: {item: any; onClick: any}) => {
    return (
        <StyledTreeItem
            itemId={item.id}
            label={item.name}
            sx={{
                textDecoration: item.is_active ? 'none' : 'line-through',
                '& .MuiTreeItem-label': {
                    color: (theme) => (item.is_active ? 'inherit' : alpha(theme.palette.text.primary, 0.3))
                }
            }}
            onClick={() => (item.children?.length ? onClick(item.id) : {})}>
            {item.children.map((children: any) => (
                <InfinityTreeItem item={children} key={children.id} onClick={onClick} />
            ))}
        </StyledTreeItem>
    );
};

function extractIds(nestedArray: any[]) {
    const allIds: any[] = [];

    function traverse(array: any[]) {
        for (const item of array) {
            if (item.id) {
                allIds.push(item.id);
            }
            if (item.children && Array.isArray(item.children)) {
                traverse(item.children);
            }
        }
    }

    traverse(nestedArray);
    return allIds;
}
