import {IconButton} from '@mui/material';
import {handleOpenPopover} from 'context/popoverContext';
import {DotsIcon} from 'assets/icons';
import * as React from 'react';
import {IconButtonProps} from '@mui/material/IconButton/IconButton';

export const ActionsTableButton = (props: {actions: any[]} & IconButtonProps) => {
    const {actions, sx, ...rest} = props;
    return (
        <IconButton
            size={'small'}
            sx={{width: 30, height: 30, ...sx}}
            color={'primary'}
            onClick={(event) => {
                handleOpenPopover({
                    anchor: event.currentTarget,
                    items: actions
                });
            }}
            {...rest}>
            <DotsIcon />
        </IconButton>
    );
};
