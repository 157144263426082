import {ButtonBase, Divider, Menu, MenuItem, Stack, useMediaQuery} from '@mui/material';
import {removeDuplicatesById} from 'utils/functions';
import {getTokens, removeTokens, setTokens} from 'utils/functions/auth';
import {UserLoginItem} from 'components/accessory/UserLoginItem';
import {ROUTES} from 'utils/constants';
import * as React from 'react';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {useAuthContext} from 'context/authContext';
import {LogoutIcon, SettingsIcon, UserIcon, UsersAddIcon} from 'assets/icons';
import {MainAvatar} from 'components/accessory/Avatar';
import {ACCOUNT_TAB_ENUM} from 'utils/constants/menu';
import {Theme} from '@mui/material/styles';

export const UserMenu = ({isOpenLeftMenu}: {isOpenLeftMenu: boolean}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {accessToken} = getTokens();
    const {me, usersLogin} = useAuthContext();
    const usersList = useMemo(() => {
        const filterWithoutAccess = usersLogin?.filter((user) => user.token !== accessToken && user.id !== me.id) || [];
        const removeDuplicateArray = removeDuplicatesById(filterWithoutAccess);
        localStorage.setItem(
            'tokens',
            JSON.stringify([accessToken, ...removeDuplicateArray.map((user) => user.token)])
        );
        return removeDuplicateArray;
    }, [usersLogin, accessToken]);
    const exitClick = () => {
        removeTokens();
        queryClient.clear();
        navigate(ROUTES.AUTH);
        handleCloseMenu();
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <ButtonBase
                sx={{borderRadius: '100%', ml: 1}}
                id='user-menu-button'
                aria-controls={isOpenLeftMenu ? 'user-basic-menu' : undefined}
                aria-haspopup='true'
                onClick={handleOpenMenu}
                aria-expanded={isOpenLeftMenu ? 'true' : undefined}>
                <MainAvatar user={me} dot />
            </ButtonBase>
            <Menu
                id='user-basic-menu'
                anchorEl={anchorEl}
                open={isOpenMenu}
                disableScrollLock
                slotProps={{
                    paper: {
                        sx: {
                            mt: {xs: 5, md: 2.5},
                            mb: 2,
                            // boxShadow: (theme) => boxShadow[theme.palette.mode],
                            '& .MuiMenuItem-root': {
                                color: (theme) => theme.palette.text.heading
                            }
                        },
                        className: 'custom-scroll'
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleCloseMenu}
                MenuListProps={{
                    component: 'div',
                    sx: {py: 0},
                    'aria-labelledby': 'user-menu-button'
                }}>
                <Stack sx={{width: {xs: 'calc(100vw - 64px)', md: 292}, p: 1, m: 0}} component={'ul'}>
                    <UserLoginItem user={me} sx={{background: 'transparent'}} dot />
                    <Divider sx={{my: 0.5, mx: -1}} />
                    <MenuItem
                        onClick={() => {
                            navigate(ROUTES.PROFILE);
                            if (!isMd) handleCloseMenu();
                        }}
                        sx={{mx: 0}}>
                        <UserIcon style={iconStyle} /> Мой профиль
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate(ROUTES.PROFILE_SETTINGS + '?tab=' + ACCOUNT_TAB_ENUM.ACCOUNT);
                            if (!isMd) handleCloseMenu();
                        }}
                        sx={{mx: 0}}>
                        <SettingsIcon style={iconStyle} /> Настройки
                    </MenuItem>
                    {/*<UserInfo me={me} />*/}
                    <Divider sx={{my: 0.5, mx: -1}} />
                    {!!usersList.length && (
                        <>
                            {usersList?.map((user) => (
                                <UserLoginItem
                                    key={user.id}
                                    user={user}
                                    sx={{background: 'transparent'}}
                                    onClick={() => {
                                        // eslint-disable-next-line camelcase
                                        setTokens({access_token: user?.token as string});
                                        location.reload();
                                    }}
                                />
                            ))}
                        </>
                    )}
                    <MenuItem onClick={() => navigate(ROUTES.AUTH, {state: {openAuth: true}})} sx={{mx: 0}}>
                        <UsersAddIcon style={iconStyle} /> Добавить аккаунт
                    </MenuItem>
                    <MenuItem onClick={exitClick} sx={{mx: 0}}>
                        <LogoutIcon style={iconStyle} /> Выход
                    </MenuItem>
                </Stack>
            </Menu>
        </>
    );
};
const iconStyle = {width: 22, height: 22};
