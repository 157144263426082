import {Button} from '@mui/material';
import {CloseIcon} from 'assets/icons';

export const ResetButton = ({onClick}: any) => {
    return (
        <Button
            variant={'outlined'}
            color={'inherit'}
            onClick={onClick}
            startIcon={<CloseIcon style={{width: 16, height: 16}} />}>
            Сбросить
        </Button>
    );
};
