/* eslint-disable camelcase */
import {Button, Stack, Typography, useMediaQuery} from '@mui/material';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {ROUTES} from 'utils/constants';
import {
    getGetApiBandaNewsIdQueryKey,
    useGetApiBandaNewsId,
    usePutApiBandaNewsLikesCreate,
    usePutApiBandaNewsViewsCreate
} from 'api/новости';
import {useParams} from 'react-router-dom';
import {
    AdmonitionDirectiveDescriptor,
    codeBlockPlugin,
    codeMirrorPlugin,
    diffSourcePlugin,
    directivesPlugin,
    frontmatterPlugin,
    headingsPlugin,
    imagePlugin,
    linkPlugin,
    listsPlugin,
    markdownShortcutPlugin,
    MDXEditor,
    quotePlugin,
    sandpackPlugin,
    tablePlugin,
    thematicBreakPlugin
} from '@mdxeditor/editor';
import {CheckIcon, EyeIcon, HeartContainedIcon, HeartIcon} from 'assets/icons';
import {CountInfo} from './CountInfo';
import {Loader} from 'components/accessory/Loader';
import {Theme, useTheme} from '@mui/material/styles';
import {useQueryClient} from '@tanstack/react-query';
import {CardHeader, handleUpdateLikeItem} from './NewsCard';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';
import {highlightPlugin} from 'mdxe-plugin-highlight';

export const handleOpenNewsModal = (navigate?: any) => {
    openModal({
        hasTitle: false,
        body: <ViewNewsModal />,
        maxWidth: 'sm',
        onClose: () => {
            closeModal();
            setTimeout(() => {
                navigate?.(ROUTES.NEWS);
            }, 200);
        }
    });
};
export const ViewNewsModal = () => {
    const {newsId} = useParams();
    const {data: info, isLoading, refetch} = useGetApiBandaNewsId(newsId as string);
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const queryClient = useQueryClient();
    //@ts-ignore
    const infoNew = info?.data;

    const likeMutate = usePutApiBandaNewsLikesCreate();
    const readMutate = usePutApiBandaNewsViewsCreate();

    const likeClick = () => {
        queryClient.setQueryData(getGetApiBandaNewsIdQueryKey(newsId as string), (old: any) => {
            if (!old) return old;
            return {
                ...old,
                data: {
                    ...infoNew,
                    is_liked: !infoNew.is_liked,
                    count_likes: infoNew?.count_likes + (infoNew.is_liked ? -1 : 1)
                }
            };
        });
        likeMutate
            .mutateAsync({
                data: {news_id: newsId as string}
            })
            .then(() => {
                refetch().then((data: any) => {
                    if (data?.data?.data) {
                        handleUpdateLikeItem(newsId as string, data?.data?.data, queryClient);
                    }
                });
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    const readClick = () => {
        readMutate
            .mutateAsync({
                data: {news_id: newsId as string}
            })
            .then(() => {
                refetch();
            });
    };
    const theme = useTheme();
    const functionsClick = {
        like: likeClick,
        read: () => {}
    };
    return (
        <Stack gap={2}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} sx={{gap: 2}}>
                        <CardHeader item={infoNew} sx={{p: 0}} />
                    </Stack>

                    {infoNew?.thumbnail?.url && (
                        <Stack
                            alignItems={'center'}
                            sx={{
                                '& img': {
                                    mx: {xs: -2, sm: -3},
                                    width: {xs: 'calc(100% + 32px)', sm: 'calc(100% + 48px)'}
                                    // maxWidth: {xs: 'calc(100% + 24px)', sm: 'calc(100% + 48px)'}
                                }
                            }}>
                            <img
                                alt={'image'}
                                width={isSm ? 'calc(100% + 48px)' : 'calc(100% + 32px)'}
                                src={infoNew?.thumbnail?.url}
                            />
                        </Stack>
                    )}
                    <CountInfo newItem={infoNew} arrayCount={COUNT_ITEMS(infoNew, theme, functionsClick)} />

                    <Typography variant={'h5'}>{infoNew?.title}</Typography>
                    {infoNew?.full_text && (
                        <Stack
                            sx={{
                                whiteSpace: 'pre-wrap',
                                '& [aria-label="editable markdown"]': {
                                    p: 0,
                                    color: (theme) => theme.palette.text.primary
                                }
                            }}>
                            <MDXEditor
                                markdown={infoNew?.full_text?.replace(/\n/g, '<br />')}
                                readOnly
                                plugins={[
                                    // diffSourcePlugin(),
                                    frontmatterPlugin(),
                                    imagePlugin(),
                                    sandpackPlugin(),
                                    tablePlugin(),
                                    thematicBreakPlugin(),
                                    headingsPlugin(),
                                    listsPlugin(),
                                    linkPlugin(),
                                    quotePlugin(),
                                    markdownShortcutPlugin(),
                                    directivesPlugin({directiveDescriptors: [AdmonitionDirectiveDescriptor]}),
                                    codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
                                    // sandpackPlugin({sandpackConfig: simpleSandpackConfig}),
                                    codeMirrorPlugin({codeBlockLanguages: {js: 'JavaScript', css: 'CSS'}}),
                                    diffSourcePlugin({
                                        viewMode: 'rich-text'
                                    }),
                                    highlightPlugin({
                                        stringsToHighlight: ['Hello'],
                                        highlightColor: 'blue'
                                    })
                                ]}
                            />
                        </Stack>
                    )}
                    <Stack direction={'row'} gap={2} justifyContent={'flex-end'} my={2}>
                        {/*<Button*/}
                        {/*    startIcon={<HeartIcon />}*/}
                        {/*    variant={'outlined'}*/}
                        {/*    color={infoNew?.is_liked ? 'primary' : 'inherit'}*/}
                        {/*    sx={{width: 150}}*/}
                        {/*    onClick={likeClick}>*/}
                        {/*    {infoNew?.is_liked ? 'Понравилось' : 'Нравится'}*/}
                        {/*</Button>*/}
                        {infoNew?.is_must_read && (
                            <Button
                                startIcon={<CheckIcon />}
                                variant={'outlined'}
                                sx={{width: 150}}
                                disabled={infoNew?.is_read}
                                onClick={() => readClick()}
                                color={infoNew?.is_read ? 'primary' : 'inherit'}>
                                Прочитано
                            </Button>
                        )}
                    </Stack>
                </>
            )}
        </Stack>
    );
};
const iconStyle = {width: 20, height: 20};
const COUNT_ITEMS = (infoNew: any, theme: Theme, functions: any) => [
    {
        value: 'count_likes',
        icon: infoNew?.is_liked ? (
            <HeartContainedIcon style={{...iconStyle, color: theme.palette.secondary.main}} />
        ) : (
            <HeartIcon style={iconStyle} />
        ),
        onClick: functions.like
    },
    {
        value: 'count_views',
        icon: <EyeIcon style={{...iconStyle}} />
    },
    ...(infoNew?.is_must_read
        ? [
              {
                  value: 'is_must_read',
                  icon: (
                      <CheckIcon
                          style={{
                              ...iconStyle,
                              color: infoNew?.is_read ? theme.palette.success.main : 'inherit'
                          }}
                      />
                  )
              }
          ]
        : [])
];
