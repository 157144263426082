import {IconButton, Menu, MenuItem, Stack, useMediaQuery} from '@mui/material';
import {$theme, changeTheme} from 'context/themeEffector';
import {MODE} from 'theme';
import * as React from 'react';
import {useUnit} from 'effector-react/effector-react.mjs';
import {DesktopIcon, MoonIcon, SunIcon} from 'assets/icons';
import {Theme} from '@mui/material/styles';

export const SwitchTheme = ({isOpenLeftMenu}: {isOpenLeftMenu: boolean}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return (
        <>
            <IconButton
                id='switch-theme-button'
                aria-controls={isOpenLeftMenu ? 'switch-theme-menu' : undefined}
                aria-haspopup='true'
                onClick={handleOpenMenu}
                sx={{
                    color: (theme) => theme.palette.text.heading
                }}
                aria-expanded={isOpenLeftMenu ? 'true' : undefined}>
                {THEME_ICONS[useUnit($theme)]}
            </IconButton>
            <Menu
                id='switch-theme-menu'
                anchorEl={anchorEl}
                open={isOpenMenu}
                disableScrollLock
                slotProps={{
                    paper: {
                        sx: {mt: {xs: 5, md: 2.5}, mb: 2, left: !isMd ? '32px !important' : undefined},
                        className: 'custom-scroll'
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'switch-theme-button'
                }}>
                <Stack gap={0.5} sx={{width: {xs: 'calc(100vw - 64px)', md: 160}}}>
                    {THEME_ITEMS.map((themeItem) => (
                        <MenuItem
                            key={themeItem.value}
                            onClick={() => {
                                changeTheme(themeItem.value);
                                handleCloseMenu();
                            }}
                            selected={useUnit($theme) === themeItem.value}>
                            {themeItem.icon} {themeItem.text}
                        </MenuItem>
                    ))}
                </Stack>
            </Menu>
        </>
    );
};

const iconStyle = {width: 22, height: 22};
const THEME_ICONS = {
    [MODE.LIGHT]: <SunIcon style={iconStyle} />,
    [MODE.DARK]: <MoonIcon style={iconStyle} />,
    [MODE.SYSTEM]: <DesktopIcon style={iconStyle} />
};
const THEME_ITEMS = [
    {text: 'Светлая', icon: THEME_ICONS[MODE.LIGHT], value: MODE.LIGHT},
    {text: 'Тёмная', icon: THEME_ICONS[MODE.DARK], value: MODE.DARK},
    {text: 'Системная', icon: THEME_ICONS[MODE.SYSTEM], value: MODE.SYSTEM}
];
