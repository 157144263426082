import {FormAutocompleteScroll} from 'components/accessory/FormAutocompleteScroll';
import {getApiBandaSystemDirectoryEmployeeLink, getGetApiBandaSystemDirectoryEmployeeLinkQueryKey} from 'api/система';
import * as React from 'react';

export const EmployeesLinkInput = (props: {disabled?: boolean; params?: object; [key: string]: any}) => {
    const {disabled, params, ...rest} = props;
    return (
        <FormAutocompleteScroll
            name={rest?.name || 'employees_link_id'}
            queryKey={getGetApiBandaSystemDirectoryEmployeeLinkQueryKey}
            queryFn={getApiBandaSystemDirectoryEmployeeLink}
            settingsKey={{module: 'system', sort: 'id', ...params}}
            searchName={'filter[search_employee]'}
            placeholder={rest?.placeholder || 'Рабочие профили'}
            label={rest?.label}
            disabled={disabled}
            {...rest}
        />
    );
};
