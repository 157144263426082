import {useDeleteApiBandaUploadAvatarRemove, usePostApiBandaUploadAvatar} from 'api/файлы';
import {useAuthContext} from 'context/authContext';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';
import {deleteAvatarMessage, uploadAvatarMessage} from 'utils/constants/messages';
import {Box, Button, Stack, Typography, useMediaQuery} from '@mui/material';
import emptyAvatar from 'assets/images/empty-avatar.png';
import * as React from 'react';
import {ModalWidth, openModal} from 'context/modalEffector';
import {UploadAvatarModal} from '../../../modals/UploadAvatar';
import {handleOpenDeleteAvatarModal} from './DeleteAvatarModal';
import {Theme} from '@mui/material/styles';
import {RefreshIcon, UploadIcon} from 'assets/icons';

export const EditAvatar = ({avatarUrl}: {avatarUrl?: string}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const uploadAvatarMutation = usePostApiBandaUploadAvatar();
    const deleteAvatarMutation = useDeleteApiBandaUploadAvatarRemove();
    const {refetchProfile, me} = useAuthContext();
    const setPhoto = (photo: any) => {
        const formData = new FormData();
        const thenPromise = (text: string) => {
            openToast({
                text,
                type: TOAST_TYPE.SUCCESS
            });
            refetchProfile();
        };
        const catchPromise = (error: any) => {
            openToast({
                text: errorLayoutMessage(error),
                type: TOAST_TYPE.ERROR
            });
        };
        if (photo) {
            formData.append('file', photo);
            uploadAvatarMutation
                .mutateAsync({
                    data: {file: photo}
                })
                .then(() => {
                    thenPromise(uploadAvatarMessage);
                })
                .catch((error) => {
                    catchPromise(error);
                });
        } else {
            deleteAvatarMutation
                .mutateAsync()
                .then(() => {
                    thenPromise(deleteAvatarMessage);
                })
                .catch((error) => {
                    catchPromise(error);
                });
        }
    };

    return (
        <>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Box
                    width={100}
                    height={100}
                    src={avatarUrl || emptyAvatar}
                    component={'img'}
                    sx={{
                        borderRadius: '6px',
                        objectFit: 'contain',
                        maxWidth: 120,
                        border: (theme) => '1px solid ' + theme.palette.divider
                    }}
                />
                <Stack gap={1}>
                    <Stack direction={'row'} gap={2}>
                        <Button
                            variant={'contained'}
                            onClick={() =>
                                openModal({
                                    title: 'Редактирование фото',
                                    body: <UploadAvatarModal {...{setPhoto, me}} />,
                                    sx: ModalWidth(422)
                                })
                            }>
                            {isMd ? 'Загрузить фото' : <UploadIcon />}
                        </Button>
                        <Button variant={'contained'} color={'info'} onClick={() => handleOpenDeleteAvatarModal()}>
                            {isMd ? 'Удалить' : <RefreshIcon />}
                        </Button>
                    </Stack>
                    <Typography>Обязательно улыбнитесь 🙂</Typography>
                </Stack>
            </Stack>
        </>
    );
};
