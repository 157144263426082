import {useCallback, useEffect, useRef} from 'react';
import {
    AdmonitionDirectiveDescriptor,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    ChangeCodeMirrorLanguage,
    codeBlockPlugin,
    codeMirrorPlugin,
    CodeToggle,
    ConditionalContents,
    CreateLink,
    diffSourcePlugin,
    DiffSourceToggleWrapper,
    directivesPlugin,
    frontmatterPlugin,
    headingsPlugin,
    imagePlugin,
    InsertAdmonition,
    InsertCodeBlock,
    InsertFrontmatter,
    InsertImage,
    InsertSandpack,
    InsertTable,
    InsertThematicBreak,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    markdownShortcutPlugin,
    MDXEditor,
    MDXEditorMethods,
    quotePlugin,
    SandpackConfig,
    sandpackPlugin,
    ShowSandpackInfo,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import {highlightPlugin} from 'mdxe-plugin-highlight';
import {Stack} from '@mui/material';
import {useController} from 'react-hook-form';

export const Editor = ({name}: {name: string}) => {
    const {
        field,
        fieldState: {error}
    } = useController({name, defaultValue: ''});
    const ref = useRef<MDXEditorMethods>(null);
    const defaultSnippetContent = `
export default function App() {
  return (
    <div className='App'>
      <h1>CodeSandbox</h1>
      <h2>Начните редактирование!</h2>
    </div>
  );
}
`.trim();
    const simpleSandpackConfig: SandpackConfig = {
        defaultPreset: 'react',
        presets: [
            {
                label: 'React',
                name: 'react',
                meta: 'live react',
                sandpackTemplate: 'react',
                sandpackTheme: 'light',
                snippetFileName: '/App.js',
                snippetLanguage: 'jsx',
                initialSnippetContent: defaultSnippetContent
            }
        ]
    };
    const handleTextChange = useCallback(
        (value: string) => {
            field.onChange(value);
        },
        [field.onChange]
    );
    useEffect(() => {
        ref?.current?.setMarkdown(field.value);
    }, [field.value]);

    return (
        <Stack
            sx={{
                border: (theme) => '1px solid ' + theme.palette.divider,
                '& .wmde-markdown, .w-md-editor-preview': {
                    color: (theme) => theme.palette.text.primary,
                    background: (theme) => theme.palette.background.paper
                },
                '& .wmde-markdown pre': {
                    color: (theme) => theme.palette.primary.contrastText
                },
                '& [aria-label="editable markdown"]': {
                    p: 1,
                    minHeight: '300px',
                    color: (theme) => theme.palette.text.primary
                }
            }}>
            <MDXEditor
                ref={ref}
                markdown={`${field.value}`}
                onChange={handleTextChange}
                plugins={[
                    // diffSourcePlugin(),
                    frontmatterPlugin(),
                    imagePlugin(),
                    sandpackPlugin(),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    headingsPlugin(),
                    listsPlugin(),
                    linkPlugin(),
                    quotePlugin(),
                    markdownShortcutPlugin(),
                    directivesPlugin({directiveDescriptors: [AdmonitionDirectiveDescriptor]}),
                    codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
                    sandpackPlugin({sandpackConfig: simpleSandpackConfig}),
                    codeMirrorPlugin({codeBlockLanguages: {js: 'JavaScript', css: 'CSS'}}),
                    diffSourcePlugin({
                        viewMode: 'rich-text'
                    }),
                    highlightPlugin({
                        stringsToHighlight: ['Hello'],
                        highlightColor: 'blue'
                    }),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                                {/*<UndoRedo />*/}
                                <BlockTypeSelect />
                                <BoldItalicUnderlineToggles />
                                {/*<ChangeAdmonitionType />*/}
                                {/*<ChangeCodeMirrorLanguage />*/}
                                <CodeToggle />
                                <CreateLink />
                                {/*<DiffSourceToggleWrapper />*/}
                                <InsertAdmonition />
                                {/*<InsertCodeBlock />*/}
                                <InsertFrontmatter />
                                <InsertImage />
                                {/*<InsertSandpack />*/}
                                <InsertTable />
                                <InsertThematicBreak />
                                <ListsToggle />
                                <ConditionalContents
                                    options={[
                                        {
                                            when: (editor) => editor?.editorType === 'codeblock',
                                            contents: () => <ChangeCodeMirrorLanguage />
                                        },
                                        {
                                            when: (editor) => editor?.editorType === 'sandpack',
                                            contents: () => <ShowSandpackInfo />
                                        },
                                        {
                                            fallback: () => (
                                                <>
                                                    <InsertCodeBlock />
                                                    <InsertSandpack />
                                                </>
                                            )
                                        }
                                    ]}
                                />
                                <DiffSourceToggleWrapper>{/*<UndoRedo />*/}</DiffSourceToggleWrapper>
                                {/*<ShowSandpackInfo />*/}
                            </>
                        )
                    })
                ]}
            />
        </Stack>
    );
};
