/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {useEffect} from 'react';
import {Form} from 'components/form/Form';
import {useForm} from 'react-hook-form';
import {FormTextField} from 'components/form/FormTextField';
import {AccordionSelect} from './AccordionSelect';
import {AccordionSelectPosition} from './AccordionSelectPosition';
import {LoadingButton} from '@mui/lab';
import {
    getGetApiBandaSystemAccessGroupShowQueryKey,
    usePatchApiBandaSystemAccessGroupUpdate,
    usePutApiBandaSystemAccessGroupCreate
} from 'api/система';
import {errorMessage, successMessage} from 'utils/functions';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {PermissionGroupsParams} from './PermissionGroupsTable';

export const handleOpenEditGroupModal = (title?: string, item?: any) => {
    openModal({
        title: title,
        body: <AddEditGroupModal row={item} />,
        maxWidth: 'sm'
    });
};
export const AddEditGroupModal = ({row}: {row?: any}) => {
    const form = useForm();
    const {reset, watch} = form;
    const mutation = row ? usePatchApiBandaSystemAccessGroupUpdate() : usePutApiBandaSystemAccessGroupCreate();
    const [params] = useQsParams();
    const queryClient = useQueryClient();
    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemAccessGroupShowQueryKey(PermissionGroupsParams(params))
        });
    };
    useEffect(() => {
        if (row) {
            reset({
                name: row?.name,
                description: row?.description,
                departments: row?.departments?.map((el: any) => ({...el, id: el?.department_id})),
                employees: row?.employees?.map((em: any) => ({
                    id: em?.employee_link_id,
                    employee: {
                        name: em?.name,
                        first_name: em?.first_name,
                        last_name: em?.last_name
                    },
                    position_sl: em?.position_sl
                })),
                position: row?.dp_list?.map((item: any) => ({
                    id: item?.department_id,
                    name: item?.department_name,
                    position: {id: item?.position_link_id, name: item?.position_link_name}
                }))
            });
        }
    }, [row]);
    const onSubmit = (data: any) => {
        const submitData = {
            id: row?.id || undefined,
            name: data?.name,
            description: data?.description,
            departments: data?.departments?.map((dep: any) => dep?.department_id || dep?.id) || [],
            employee_links: data?.employees?.map((em: any) => em.id) || [],
            dp_list: data?.position?.map((p: any) => ({department_id: p.id, position_link_id: p.position?.id}))
        };
        mutation
            .mutateAsync({
                data: submitData
            })
            .then((r) => {
                successMessage(`Группа успешно ${row ? 'отредактирована' : 'создана'}`);
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                errorMessage(error);
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <FormTextField name={'name'} placeholder={'Название'} label={'Название'} />
                <FormTextField name={'description'} placeholder={'Описание'} multiline rows={2} label={'Описание'} />
                <AccordionSelect type={'employees'} form={form} />
                <AccordionSelectPosition form={form} />
                <AccordionSelect type={'departments'} form={form} />
                <Stack direction={'row'} justifyContent={'flex-end'} gap={2}>
                    <LoadingButton type={'submit'} variant={'contained'} loading={mutation.isPending}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'outlined'} color={'inherit'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
