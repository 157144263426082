import {IconButton, Stack, Typography} from '@mui/material';
import * as React from 'react';

export const CountInfo = ({newItem, arrayCount}: {newItem: any; arrayCount: any[]}) => {
    return (
        <Stack gap={1} direction={'row'} alignItems={'center'} onClick={(e) => e.stopPropagation()}>
            {arrayCount.map((item) => (
                <Stack direction={'row'} key={item.value} alignItems={'center'}>
                    <IconButton
                        size={'small'}
                        disableFocusRipple={!item?.onClick}
                        disableRipple={!item?.onClick}
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            cursor: item?.onClick ? 'pointer' : 'default'
                        }}
                        onClick={() => item?.onClick?.()}>
                        {item.icon}
                    </IconButton>
                    <Typography sx={{minWidth: '10px'}}>{newItem?.[item.value]}</Typography>
                </Stack>
            ))}
        </Stack>
    );
};
