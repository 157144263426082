import {
    alpha,
    Button,
    Pagination,
    PaginationRenderItemParams,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Skeleton} from '@mui/lab';
import {Loader} from 'components/accessory/Loader';
import {ArrowDownIcon, ArrowUpIcon, LeftIcon, RightIcon} from 'assets/icons';
import {FormSelect} from 'components/form/FormSelect';

export const TableLayout = ({
    columns,
    rows,
    isLoading,
    filterRow,
    minWidth,
    pagination,
    negativeMargin,
    onClick
}: {
    columns?: any[];
    rows?: any[];
    isLoading?: boolean;
    filterRow?: boolean;
    minWidth?: number;
    pagination?: any;
    negativeMargin?: boolean;
    onClick?: (row: any) => void;
}) => {
    const [prevLengthRows, setPrevLengthRows] = useState(0);
    const [uploaded, setUploaded] = useState(false);
    useEffect(() => {
        if (isLoading) {
            setUploaded(true);
        }
    }, [isLoading]);
    const emptyArray = prevLengthRows ? new Array(prevLengthRows).fill(0) : [];
    useEffect(() => {
        if (rows?.length && rows.length !== prevLengthRows) {
            setPrevLengthRows(rows.length);
        }
    }, [rows?.length]);
    return (
        <Stack gap={2} sx={{mx: negativeMargin ? {xs: -2, sm: -3} : undefined}}>
            <TableContainer
                sx={{
                    '& .MuiTableCell-root:first-of-type': {
                        paddingLeft: {xs: 2, sm: 3}
                    },
                    '& .MuiTableCell-root:last-of-type': {
                        paddingLeft: {xs: 2, sm: 3}
                    }
                }}>
                <Table sx={{minWidth: minWidth || 650}} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            {columns?.map((col, index) => (
                                <TableCell
                                    key={col?.name || index}
                                    align={col?.align as AlignType}
                                    variant={'head'}
                                    sx={col?.sx}>
                                    {col.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filterRow && (
                            <TableRow>
                                {columns?.map((col, index) => (
                                    <TableCell
                                        key={col?.name || index}
                                        align={col?.align as AlignType}
                                        variant={'head'}
                                        sx={col?.sx}>
                                        {col.filter}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                        {!prevLengthRows && isLoading ? (
                            <></>
                        ) : (
                            <>
                                {isLoading ? (
                                    <>
                                        <>
                                            {emptyArray?.map((row, index) => (
                                                <Rows {...{columns, row}} isSkeleton key={index} />
                                            ))}
                                        </>
                                    </>
                                ) : (
                                    <>
                                        {!!rows?.length && (
                                            <>
                                                {rows?.map((row) => (
                                                    <Rows
                                                        {...{columns, row}}
                                                        key={row.id}
                                                        onClick={onClick ? () => onClick?.(row) : undefined}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
                {uploaded && !isLoading && !rows?.length && (
                    <Stack sx={{p: 3, pb: 2, justifyContent: 'center', alignItems: 'center'}}>
                        <Typography>Кажется, здесь ничего нет :(</Typography>
                    </Stack>
                )}
                {!prevLengthRows && isLoading && <Loader />}
            </TableContainer>
            {!!pagination && (
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}
                    mx={negativeMargin ? {xs: 2, sm: 3} : undefined}>
                    <PaginationInfo pagination={pagination} />
                    <PaginationTable total={pagination?.last_page} />
                </Stack>
            )}
        </Stack>
    );
};
const Rows = ({columns, row, isSkeleton, onClick}: any) => {
    return (
        <TableRow
            sx={{
                '&:hover': {backgroundColor: (theme) => theme.palette.action.hover},
                cursor: onClick ? 'pointer' : 'initial'
            }}
            onClick={onClick}>
            {columns?.map((col: any, index: any) => (
                <TableCell key={col?.name || index} align={col?.align as AlignType}>
                    {isSkeleton ? (
                        <Typography
                            component={'div'}
                            sx={{
                                minHeight: '40px'
                            }}>
                            <Skeleton />
                        </Typography>
                    ) : (
                        col.value?.(row)
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};
export const PaginationInfo = ({pagination}: any) => {
    return (
        <Stack sx={{alignItems: 'center'}}>
            <Typography>
                От {pagination?.from} до {pagination?.to} из {pagination?.total} записей
            </Typography>
        </Stack>
    );
};
export const PaginationTable = ({total}: {total?: number}) => {
    const [totalPage, setTotalPage] = useState(total);
    useEffect(() => {
        if (total && total !== totalPage) setTotalPage(total);
    }, [total]);
    const [params, , setParamsSmart] = useQsParams();
    const page = Number(params.page || 1);
    const setNewPage = (item: PaginationRenderItemParams) => {
        const thisPage = page;
        setParamsSmart({
            page:
                item?.type === 'end-ellipsis'
                    ? thisPage + 5
                    : item?.type === 'start-ellipsis'
                    ? thisPage - 5
                    : item?.page || 1
        });
    };
    useEffect(() => {
        if (totalPage) {
            if (page <= 0 || !page) setParamsSmart({page: 1});
            if (page > totalPage) setParamsSmart({page: totalPage});
        }
    }, [page, totalPage]);
    return (
        <Stack>
            {totalPage && (
                <Pagination
                    count={totalPage}
                    page={page}
                    defaultPage={1}
                    boundaryCount={2}
                    size={'large'}
                    sx={{'& .MuiPagination-ul': {gap: 1}}}
                    renderItem={(item) => {
                        return <PaginationItem {...{item}} onClick={setNewPage} />;
                    }}
                />
            )}
        </Stack>
    );
};
const PaginationItem = ({
    item,
    onClick
}: {
    item: PaginationRenderItemParams;
    onClick: (page: PaginationRenderItemParams) => void;
}) => {
    const [hover, setHover] = useState(false);
    const textType = {
        next: () => <RightIcon />,
        previous: () => <LeftIcon />,
        'end-ellipsis': () => (hover ? <RightIcon /> : '...'),
        'start-ellipsis': () => (hover ? <LeftIcon /> : '...'),
        page: () => item.page
    };
    return (
        <Button
            variant={'text'}
            onClick={() => onClick(item)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            disabled={item.disabled}
            sx={{
                width: '38px',
                height: '38px',
                p: 0,
                minWidth: '38px',
                border: (theme) =>
                    '1px solid ' +
                    (item.selected
                        ? theme.palette.primary.main
                        : item.type === 'page'
                        ? theme.palette.divider
                        : 'transparent'),
                ' path': {stroke: (theme) => theme.palette.text.primary},
                color: (theme) => (item.selected ? 'white' : theme.palette.text.primary),
                background: (theme) => (item.selected ? theme.palette.primary.main : theme.palette.background.default),
                '&:hover': {
                    background: (theme) =>
                        item.selected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.05)
                },
                '& path': {stroke: (theme) => (item.disabled ? theme.palette.divider : theme.palette.text.primary)},
                '&:hover path': {stroke: (theme) => theme.palette.text.primary}
            }}>
            {textType[item.type as keyof typeof textType]?.() || ''}
        </Button>
    );
};

export const TablePerPage = () => {
    return (
        <Stack gap={1} direction={'row'} alignItems={'center'}>
            <FormSelect
                name={'per_page'}
                options={PER_PAGE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'25'}
                sx={{width: {md: '88px', xs: '100%'}}}
            />
        </Stack>
    );
};
const SortIcon = ({sortName}: {sortName: string}) => {
    const [params] = useQsParams();
    return (
        <>
            {params.sort?.includes('-' + sortName) ? (
                <ArrowUpIcon style={{width: 16, height: 16}} />
            ) : params.sort?.includes(sortName) ? (
                <ArrowDownIcon style={{width: 16, height: 16}} />
            ) : (
                ''
            )}
        </>
    );
};

export const SortButton = ({title, sortName, sx}: {title: string; sortName: string; sx?: SxProps}) => {
    const [params, , setParamsSmart] = useQsParams();
    const sortClick = (sortName: string) => {
        const newValue = params.sort?.includes('-' + sortName)
            ? undefined
            : params.sort?.includes(sortName)
            ? '-' + sortName
            : sortName;
        setParamsSmart({sort: newValue});
    };
    return (
        <Stack
            direction={'row'}
            sx={{
                width: '100%',
                minHeight: 24,
                lineHeight: '20px',
                justifyContent: 'inherit',
                alignItems: 'center',
                cursor: 'pointer',
                ...sx
            }}
            onClick={() => sortClick(sortName)}>
            {title} <SortIcon sortName={sortName} />
        </Stack>
    );
};
const PER_PAGE_OPTIONS = [
    {
        id: 1,
        name: '10',
        value: '10'
    },
    {
        id: 2,
        name: '25',
        value: '25'
    },
    {
        id: 3,
        name: '50',
        value: '50'
    },
    {
        id: 4,
        name: '100',
        value: '100'
    }
];

type AlignType = 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined;
