import {useForm} from 'react-hook-form';
import {Paper} from 'components/accessory/Paper';
import {Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import React from 'react';
import PermissionRolesTable from './components/PermissionRolesTable';
import {StandardTableHeader} from 'components/accessory/StandardTableHeader';
import {AddButton} from 'components/accessory/AddButton';
import {handleOpenRolesModal} from './components/AddRolesModal';

export const PermissionsRoles = () => {
    const form = useForm();

    return (
        <Paper sx={{p: {xs: 2, sm: 3}, height: '100%'}}>
            <Stack gap={2}>
                <StandardTableHeader
                    form={form}
                    title={'Список ролей'}
                    buttons={
                        <AddButton
                            onClick={() => handleOpenRolesModal(undefined, 'Добавление роли')}
                            title={'Добавить роль'}
                        />
                    }
                />
                <Form form={form}>
                    <PermissionRolesTable form={form} />
                </Form>
            </Stack>
        </Paper>
    );
};
