import {PlusIcon} from 'assets/icons';
import {Button} from '@mui/material';
import * as React from 'react';

export const AddButton = ({onClick, title}: {onClick: any; title: string}) => {
    return (
        <Button variant={'contained'} startIcon={<PlusIcon />} onClick={() => onClick()}>
            {title}
        </Button>
    );
};
