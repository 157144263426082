import {
    Box,
    Collapse,
    createSvgIcon,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {MENU_ROUTES} from 'utils/constants/menu';
import {Link} from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {svgColorMixin} from 'utils/mixins';
import {CircleIcon} from 'assets/icons';
import {useAuthContext} from 'context/authContext';

export const LeftPanelList = ({open}: {open: boolean}) => {
    const {
        me: {menu}
    } = useAuthContext();
    return (
        <>
            {menu.map((array: any, index: number) => (
                <React.Fragment key={index}>
                    {array.title && (
                        <Stack sx={{px: '20px', pt: 2, height: 32, justifyContent: 'center'}}>
                            <Typography variant={'subtitle2'} color={'text.disabled'} sx={{fontWeight: 300}}>
                                {open ? array.title : <Divider />}
                            </Typography>
                        </Stack>
                    )}

                    <List sx={{pt: 0.5}}>
                        {array.links.map((item: any) => (
                            <React.Fragment key={item.title}>
                                {!!MENU_ROUTES?.[item.key as keyof typeof MENU_ROUTES] && (
                                    <MenuListItem
                                        itemRoute={MENU_ROUTES[item.key as keyof typeof MENU_ROUTES]}
                                        itemApi={item}
                                        openDrawer={open}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </React.Fragment>
            ))}
            {/*{MENU_ITEMS.map((array, index) => (*/}
            {/*    <React.Fragment key={index}>*/}
            {/*        {array.title && (*/}
            {/*            <Stack sx={{px: '20px', pt: 2, height: 32, justifyContent: 'center'}}>*/}
            {/*                <Typography variant={'subtitle2'} color={'text.disabled'}>*/}
            {/*                    {open ? array.title : <Divider />}*/}
            {/*                </Typography>*/}
            {/*            </Stack>*/}
            {/*        )}*/}

            {/*        <List sx={{pt: 0.5}}>*/}
            {/*            {array.links.map((item) => (*/}
            {/*                <MenuListItem item={item} key={item.title} openDrawer={open} />*/}
            {/*            ))}*/}
            {/*        </List>*/}
            {/*    </React.Fragment>*/}
            {/*))}*/}
        </>
    );
};

const MenuListItem = ({itemRoute, itemApi, openDrawer}: {itemRoute: any; itemApi?: any; openDrawer: boolean}) => {
    const [collapseOpen, setCollapseOpen] = useState(false);
    const isVisibleCollapse = itemApi?.children?.length && openDrawer;
    const childrenEqualityPathname = itemApi?.children?.find((child: any) =>
        //@ts-ignore
        location.pathname.includes(MENU_ROUTES[child.key as keyof typeof MENU_ROUTES]?.href)
    );
    useEffect(() => {
        if (childrenEqualityPathname) {
            setCollapseOpen(true);
        }
    }, [itemApi]);
    const isSelected = location.pathname.includes(itemRoute?.href);
    const Icon = createSvgIcon(itemRoute?.icon, 'item');
    return (
        <>
            <ListItem disablePadding sx={{display: 'block'}}>
                <LinkOrButtonList
                    href={itemApi?.link || itemRoute?.href}
                    target={itemApi?.link ? '_blank' : '_self'}
                    onClick={(e: MouseEvent) => {
                        e.stopPropagation();
                        setCollapseOpen(!collapseOpen);
                    }}>
                    <ListItemButton
                        disableGutters
                        selected={isSelected}
                        sx={(theme) => ({
                            minHeight: 38,
                            justifyContent: openDrawer ? 'initial' : 'space-between',
                            mx: 1.5,
                            my: 0.75,
                            borderRadius: '6px',
                            color:
                                !openDrawer && childrenEqualityPathname
                                    ? theme.palette.text.white + ' !important'
                                    : undefined,
                            background:
                                !openDrawer && childrenEqualityPathname
                                    ? theme.palette.primary.main + ' !important'
                                    : openDrawer && collapseOpen && childrenEqualityPathname
                                    ? theme.palette.action.hover + '!important'
                                    : undefined,
                            px: 1.5
                        })}>
                        <ListItemIcon
                            sx={(theme) => ({
                                height: 22,
                                width: 22,
                                minWidth: 0,
                                mr: openDrawer ? 1 : 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ...svgColorMixin('currentColor')
                            })}>
                            {itemRoute?.icon ? (
                                <Icon width={22} height={22} style={{width: 22, height: 22}} />
                            ) : (
                                <CircleIcon style={{width: 12, height: 12}} />
                            )}
                        </ListItemIcon>

                        <ListItemText
                            primary={itemApi?.title || itemRoute.title}
                            sx={{
                                my: 0,
                                opacity: openDrawer ? 1 : 0,
                                whiteSpace: 'nowrap',
                                // textWrap: 'wrap',
                                minWidth: '143px',
                                '& .MuiTypography-root': {
                                    fontSize: '15px',
                                    lineHeight: '22px !important'
                                }
                            }}
                        />
                        {isVisibleCollapse && <> {collapseOpen ? <ExpandLess /> : <ExpandMore />}</>}
                    </ListItemButton>
                </LinkOrButtonList>
            </ListItem>

            {isVisibleCollapse && (
                <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {itemApi?.children &&
                            itemApi?.children?.map((children: any) => (
                                <MenuListItem
                                    itemRoute={MENU_ROUTES[children.key as keyof typeof MENU_ROUTES]}
                                    itemApi={children}
                                    key={children.title}
                                    openDrawer={openDrawer}
                                />
                            ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

const LinkOrButtonList = ({
    href,
    target,
    children,
    onClick
}: {
    href?: string;
    target?: string;
    children: ReactNode;
    onClick?: (e: any) => void;
}) => {
    return (
        <>
            {href ? (
                <Link to={href} target={target}>
                    {children}
                </Link>
            ) : (
                <Box onClick={onClick}>{children}</Box>
            )}
        </>
    );
};
