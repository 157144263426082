import {IconButton, SxProps, Theme} from '@mui/material';
import {ReactNode, useState} from 'react';
import {IconButtonProps} from '@mui/material/IconButton/IconButton';

export const RotateIcon = (
    props: {children?: ReactNode; color?: Color; sx: SxProps<Theme>; onClick?: () => void} & IconButtonProps
) => {
    const {children, color, onClick, sx, ...rest} = props;
    const [rotate, setRotate] = useState(1);

    const handleClick = () => {
        onClick?.();
        setRotate(rotate + 1);
    };
    return (
        <IconButton
            onClick={handleClick}
            color={color}
            sx={{
                transition: 'transform 0.5s ease !important',
                transform: `rotate(${rotate * 360}deg)`,
                ...sx
            }}
            {...rest}>
            {children}
        </IconButton>
    );
};
type Color = 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
