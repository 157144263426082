import {Grid, Stack, Typography} from '@mui/material';
import {Paper} from 'components/accessory/Paper';
import {useParams} from 'react-router-dom';
import {EditAvatar} from './components/EditAvatar';
import {ACCOUNT_INFO_TABS, ACCOUNT_TAB_ENUM} from 'utils/constants/menu';
import {AccountInfo} from './components/AccountInfo';
import {AccountSecurity} from './components/AccountSecurity';
import {useQsParams} from 'utils/hooks/useQsParams';
import {PersonalInfo} from './components/PersonalInfo';
import {PersonalInfoTabs} from 'components/accessory/PersonalInfoTabs';
import {useGetApiBandaSystemEmployeesAccountsDetailEmployeesId} from 'api/система';

export const UserInfo = () => {
    const [params] = useQsParams();
    const {tab} = params;
    const {userId} = useParams();
    const {data, isLoading: isLoadingUserInfo} = useGetApiBandaSystemEmployeesAccountsDetailEmployeesId(userId!, {
        query: {enabled: !!userId}
    });
    //@ts-ignore
    const userInfo = data?.data;

    return (
        <Grid container spacing={{xs: 2, sm: 3}}>
            <Grid item md={4} xs={12}>
                <Paper sx={{p: {xs: 2, sm: 3}, pt: 6}}>
                    <Stack gap={{xs: 2, sm: 3}}>
                        <Stack alignItems={'center'} gap={1}>
                            {/*@ts-ignore*/}
                            <EditAvatar avatarUrl={userInfo?.avatar?.url} />
                            <Typography variant={'h5'} lineHeight={'20px'} textAlign={'center'}>
                                {userInfo?.full_name}
                            </Typography>
                        </Stack>
                        <PersonalInfo userInfo={userInfo} />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item md={8} xs={12}>
                <Stack gap={{xs: 2, sm: 3}}>
                    <PersonalInfoTabs tabsArray={ACCOUNT_INFO_TABS} />
                    {TABS_COMPONENTS(userInfo)[tab]}
                </Stack>
            </Grid>
        </Grid>
    );
};

const TABS_COMPONENTS = (userInfo: any) => ({
    [ACCOUNT_TAB_ENUM.ACCOUNT]: <AccountInfo userInfo={userInfo} />,
    [ACCOUNT_TAB_ENUM.SECURITY]: <AccountSecurity />,
    [ACCOUNT_TAB_ENUM.NOTIFICATIONS]: <></>
});
