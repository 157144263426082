interface GenerateOptions {
    length: number;
    lowercase: boolean;
    uppercase: boolean;
    numbers: boolean;
    symbols: boolean;
    exclude?: string;
    minLengthLowercase?: number;
    minLengthUppercase?: number;
    minLengthNumbers?: number;
    minLengthSymbols?: number;
}

const defaultOptions: GenerateOptions = {
    length: 10,
    lowercase: true,
    uppercase: true,
    numbers: true,
    symbols: false,
    minLengthLowercase: 1,
    minLengthUppercase: 1,
    minLengthNumbers: 1,
    minLengthSymbols: 0
};

const getRandomInt = (max: number): number => Math.floor(Math.random() * max);

const removeChars = (str: string, charsToRemove?: string): string => {
    return charsToRemove
        ? str
            .split('')
            .filter((char) => !charsToRemove.includes(char))
            .join('')
        : str;
};

const shuffle = (str: string): string => {
    const arr = str.split('');
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr.join('');
};

const generatePassword = (options: Partial<GenerateOptions> = {}): string => {
    const mergedOptions = {...defaultOptions, ...options};
    validateOptions(mergedOptions);

    const lowercaseChars = removeChars('abcdefghijklmnopqrstuvwxyz', mergedOptions.exclude);
    const uppercaseChars = removeChars('ABCDEFGHIJKLMNOPQRSTUVWXYZ', mergedOptions.exclude);
    const numberChars = removeChars('0123456789', mergedOptions.exclude);
    const symbolChars = removeChars("!#$%&'()*+,-./:;<=>?@[]^_{|}~", mergedOptions.exclude);

    let password = '';
    //Generate minimum required characters
    // @ts-ignore
    password += generateMinChars(lowercaseChars, mergedOptions.minLengthLowercase);
    // @ts-ignore
    password += generateMinChars(uppercaseChars, mergedOptions.minLengthUppercase);
    // @ts-ignore
    password += generateMinChars(numberChars, mergedOptions.minLengthNumbers);
    // @ts-ignore
    password += generateMinChars(symbolChars, mergedOptions.minLengthSymbols);

    //Fill the rest of the password
    const remainingLength = mergedOptions.length - password.length;
    const availableChars =
        (mergedOptions.lowercase ? lowercaseChars : '') +
        (mergedOptions.uppercase ? uppercaseChars : '') +
        (mergedOptions.numbers ? numberChars : '') +
        (mergedOptions.symbols ? symbolChars : '');
    for (let i = 0; i < remainingLength; i++) {
        password += availableChars.charAt(getRandomInt(availableChars.length));
    }

    return shuffle(password);
};

const generateMinChars = (chars: string, minLength: number): string => {
    let result = '';
    for (let i = 0; i < minLength; i++) {
        result += chars.charAt(getRandomInt(chars.length));
    }
    return result;
};

const validateOptions = (options: GenerateOptions): void => {

    const minLengthSum =
        // @ts-ignore
        options.minLengthLowercase + options.minLengthUppercase + options.minLengthNumbers + options.minLengthSymbols;
    if (minLengthSum > options.length) {
        throw new Error('Sum of minimum character lengths exceeds the total password length.');
    }
    if (!options.lowercase && !options.uppercase && !options.numbers && !options.symbols) {
        throw new Error('At least one character type must be enabled.');
    }
};

export {generatePassword};
