/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';


const style = {
    p: 0,
    width: '100%',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
};

export const handleOpenModal = (title?: string, item?: any) => {
    openModal({
        title: title,
        body: <ShowLogModal row={item} />,
        maxWidth: 'sm'
    });
};


export const ShowLogModal = ({row}: {row?: any}) => {
    return (

            <Stack gap={2}>
                <List sx={style} aria-label="mailbox folders">
                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>ID: &nbsp;</Typography>
                        <Typography> {row.id}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>Дата: &nbsp;</Typography>
                        <Typography> {dayjs(row?.created_at).isValid() ? dayjs(row?.created_at).format('DD MMMM YYYY h:m:s') : ''}</Typography>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>URL: &nbsp;</Typography>
                        <Typography> {row.source}</Typography>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>Метод: &nbsp;</Typography>
                        <Typography> {row.tag}</Typography>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>Время ответа: &nbsp;</Typography>
                        <Typography> {row.timeout} мс</Typography>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>Код ответа: &nbsp;</Typography>
                        <Typography> {row.httpCode}</Typography>
                    </ListItem>

                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>Запрос: &nbsp;</Typography>
                        <Typography> {row.message}</Typography>
                    </ListItem>

                    <ListItem>
                        <Typography  sx={{ fontWeight: 'bold' }}>Клиент: &nbsp;</Typography>
                        <Typography> {row.context}</Typography>
                    </ListItem>
                </List>
                <Stack direction={'row'} justifyContent={'flex-end'} gap={2}>
                    <Button variant={'outlined'} color={'inherit'} onClick={() => closeModal()}>
                        Закрыть
                    </Button>
                </Stack>
            </Stack>

    );
};