import axios, {RawAxiosRequestConfig} from 'axios';
import cookies from 'js-cookie';
import {onRejected} from 'api/mutator/axios-interceptor';
import qs from 'qs';
import {ROUTES} from 'utils/constants';

export const AXIOS_INSTANCE = axios.create({
    withCredentials: false,
    baseURL: process.env.REACT_APP_API_URL,
    paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
    }
});

export const axiosInstance = <T>(config: RawAxiosRequestConfig): Promise<T> => {
    const source = axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
        ...config,
        headers: {
            Authorization: 'Bearer ' + cookies.get('accessToken')
        },
        cancelToken: source.token
    })
        .then(({data}) => data)
        .catch((err) => {
            if (
                err.response?.status === 401 &&
                window.location.pathname !== ROUTES.AUTH &&
                window.location.pathname !== ROUTES.SSO_AUTH &&
                !window.location.pathname.includes(ROUTES.ERROR)
            ) {
                // eslint-disable-next-line no-restricted-globals
                window.location.href = ROUTES.AUTH + `?redirectUrl=${encodeURIComponent(location.href)}`;
            } else return Promise.reject(err.response?.data);
        });

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled');
    };

    return promise;
};

AXIOS_INSTANCE.interceptors.response.use(
    (response) => response,
    (error) => onRejected(error)
);
