import {typography} from './typography';
import {alpha, lighten, ThemeOptions} from '@mui/material';
import {backdropColor, boxShadow, darkenLighten} from 'utils/mixins';

declare module '@mui/material/styles' {
    interface PaletteColor {
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
        800?: string;
        900?: string;
        background?: string;
    }

    interface TypeBackground {
        body?: string;
        buttonContained: string;
    }

    interface CommonColors {
        border?: string;
        shadow?: string;
    }

    interface TypeText {
        white?: string;
        heading?: string;
        buttonContained?: string;
    }

    interface SimplePaletteColorOptions {
        background?: string;
    }
}

export const baseTheme: ThemeOptions = {
    typography,
    shape: {
        borderRadius: 5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1440,
            xl: 1736
        }
    },
    mixins: {
        toolbar: {
            minHeight: 64
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: ({theme}) => ({color: theme.palette.text.heading}),
                h2: ({theme}) => ({color: theme.palette.text.heading}),
                h3: ({theme}) => ({color: theme.palette.text.heading}),
                h4: ({theme}) => ({color: theme.palette.text.heading}),
                h5: ({theme}) => ({color: theme.palette.text.heading}),
                h6: ({theme}) => ({color: theme.palette.text.heading})
            }
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: 'lg'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    // [theme.breakpoints.down('md')]: {
                    //     padding: '0 20px'
                    // },
                    // [theme.breakpoints.up('md')]: {
                    //     padding: '0 60px'
                    // }
                })
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0,
                    position: 'absolute',
                    top: 'calc(100% - 1px)',
                    left: 0
                }
            }
        },
        //@ts-ignore
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    height: 'auto',
                    paddingBottom: '24px'
                }
            }
        },

        MuiIconButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    // color: theme.palette.text.heading
                })
            }
        },

        MuiListItemButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    transition: 'color 0s',
                    color: theme.palette.text.heading,
                    '&.Mui-selected': {
                        '&:hover': {
                            background: theme.palette.primary.main
                        },
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.text.white
                        },
                        background: theme.palette.primary.main,
                        color: theme.palette.text.white
                    },
                    '& .MuiTouchRipple-root': {
                        color: alpha(theme.palette.primary.main, 0.25)
                    }
                })
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: ({theme}) => ({
                    gap: 12,
                    marginRight: 8,
                    marginLeft: 8,
                    borderRadius: 6,
                    minHeight: '36px !important',
                    '&.Mui-selected': {
                        background: theme.palette.action.selected,
                        color: theme.palette.primary.main,
                        '&:hover': {
                            background: theme.palette.action.selected
                        }
                    },
                    '& .MuiTouchRipple-root': {
                        color: alpha(theme.palette.primary.main, 0.25)
                    }
                })
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: ({theme}) => ({backgroundImage: 'none', boxShadow: boxShadow[theme.palette.mode]})
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({theme}) => ({
                    color: 'currentColor'
                })
            }
        },
        MuiInputBase: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                multiline: {alignItems: 'flex-start'},
                root: ({theme}) => ({
                    '&:not( .MuiAutocomplete-inputRoot):not( .MuiInputBase-multiline)': {
                        height: '38px !important'
                    },
                    '&:before,:after': {
                        borderBottom: 'none !important'
                    },
                    '& textarea': {
                        paddingTop: '4px'
                    }
                }),
                sizeSmall: {
                    minHeight: '38px',
                    paddingTop: '4px !important',
                    paddingBottom: '4px !important'
                },
                input: {}
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({theme}) => ({
                    paddingRight: '12px',
                    height: '100%',
                    textAlign: 'start',
                    '&:hover:not(.Mui-disabled)': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            transition: theme.transitions.create('border-color', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.standard
                            }),
                            borderColor: theme.palette.primary.main + ' !important'
                        }
                    }
                }),
                input: ({theme}) => ({
                    '&::placeholder': {
                        // color: '#2F2B3D'
                    }
                }),
                notchedOutline: ({theme}) => ({
                    top: '-4px',
                    marginTop: '-1px',
                    borderColor: theme.palette.common.border,
                    transition: theme.transitions.create('border-color', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.standard
                    })
                })
            }
        },
        MuiTable: {
            styleOverrides: {}
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {'&.Mui-focusVisible': {backgroundColor: 'inherit'}}
            }
        },
        MuiSvgIcon: {
            defaultProps: {
                fontSize: 'small'
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: ({theme}) => ({
                    backgroundColor: theme.palette.background.paper
                })
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: ({theme}) => ({
                    '&:not(.MuiBackdrop-invisible)': {
                        boxShadow: 'none',
                        opacity: '0.7 !important',
                        backgroundColor: backdropColor[theme.palette.mode]
                    }
                })
            }
        },

        MuiPaper: {
            styleOverrides: {
                elevation8: ({theme}) => ({backgroundImage: 'none', boxShadow: boxShadow[theme.palette.mode]}),
                root: {
                    '& .MuiPickersCalendarHeader-label': {textTransform: 'capitalize'}
                }
            },
            defaultProps: {
                elevation: 0
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: ({theme}) => ({background: theme.palette.background.paper})
            }
        },
        MuiChip: {
            styleOverrides: {
                root: ({theme}) => ({borderRadius: '4px'})
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none !important',
                    borderRadius: '6px',
                    fontWeight: 400
                },
                containedPrimary: ({theme}) => ({
                    '&:hover': {
                        backgroundColor: lighten(theme.palette.primary.main, 0.2)
                    },
                    color: theme.palette.text.white
                }),
                containedSecondary: ({theme}) => ({
                    '&:hover': {
                        backgroundColor: lighten(theme.palette.secondary.main, 0.2)
                    },
                    color: theme.palette.text.white
                }),
                containedInfo: ({theme}) => ({
                    color: theme.palette.text.buttonContained,
                    background: theme.palette.background.buttonContained,
                    '&:hover': {
                        backgroundColor: darkenLighten[theme.palette.mode]?.(theme.palette.background.buttonContained)
                    }
                }),
                containedError: ({theme}) => ({
                    color: theme.palette.error.main,
                    background: theme.palette.error.background,
                    '&:hover': {
                        backgroundColor: darkenLighten[theme.palette.mode]?.(theme.palette.error.background as string)
                    }
                }),
                outlinedInherit: ({theme}) => ({
                    borderColor: theme.palette.common.border
                })
            }
        },

        //Таблицы
        MuiTableCell: {
            styleOverrides: {
                root: ({theme}) => ({
                    whiteSpace: 'pre-wrap',
                    padding: 12
                    // letterSpacing: '0.2px'
                }),
                head: ({theme}) => ({
                    letterSpacing: '0.2px',
                    color: theme.palette.text.heading,
                    textTransform: 'uppercase',
                    fontWeight: 500
                })
            }
        }
    }
};
// root: ({theme}) => ({})
