/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import {useForm} from 'react-hook-form';
import {FormTextField} from 'components/form/FormTextField';
import React, {useEffect} from 'react';
import {
    getGetApiBandaSystemAccessRoleShowQueryKey,
    useGetApiBandaSystemAccessPermissionGroupsShow,
    useGetApiBandaSystemAccessPermissionShow,
    usePatchApiBandaSystemAccessRoleUpdate,
    usePutApiBandaSystemAccessRoleCreate
} from 'api/система';
import {LoadingButton} from '@mui/lab';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';
import {closeModal, openModal} from 'context/modalEffector';
import {errorLayoutMessage} from 'utils/functions';
import {TransferRole} from 'components/accessory/TransferRole';
import {fetchRoles} from './PermissionRolesTable';

export const handleOpenRolesModal = (row?: any, name?: string) => {
    openModal({
        title: name,
        body: <AddRolesModal row={row} />,
        maxWidth: 'md'
    });
};

export const AddRolesModal = ({row}: {row?: any}) => {
    const form = useForm(schema);
    const {reset, watch, setValue} = form;
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    const watchModule = watch('module');
    useEffect(() => {
        if (row) {
            const copyRow = {...row.permissions};
            Object.keys(row.permissions).forEach(
                (key) =>
                    (copyRow[key] = copyRow[key]?.map((el: any) => ({
                        ...el,
                        ...(el.options && Object.keys(el.options).length
                            ? {options: {[Object.keys(el.options)[0]]: Object.values(el.options)[0]}}
                            : {})
                    })))
            );

            reset({
                name: row.name,
                permissions: copyRow,
                //@ts-ignore
                description: row?.description
            });
            Object.keys(row.permissions).forEach(
                (key) =>
                    (copyRow[key] = copyRow[key]?.forEach((el: any) => {
                        if (el.options) {
                            setValue(`slugs.${el.slug}`, Object.keys(el.options)?.[0]);
                        }
                    }))
            );
        }
    }, [row]);
    const {data: permissions, isLoading} = useGetApiBandaSystemAccessPermissionShow(
        {
            page: 1,
            per_page: 500,
            //@ts-ignore
            'filter[search_module_slug]': watchModule?.slug || undefined
        },
        //@ts-ignore
        {query: {enabled: !!watchModule?.slug}}
    );
    const {data: groups} = useGetApiBandaSystemAccessPermissionGroupsShow();
    //@ts-ignore
    const permissionsGroups = groups?.data;
    const formatPermissionsGroups = {
        ...permissionsGroups
            ?.map((group: any) => ({[group.slug]: group.name}))
            .reduce((acc: any, obj: any) => ({...acc, ...obj}), {})
    };
    const newRoleMutation = row ? usePatchApiBandaSystemAccessRoleUpdate() : usePutApiBandaSystemAccessRoleCreate();

    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemAccessRoleShowQueryKey(fetchRoles(params))
        });
    };
    const allPermis = watch().permissions
        ? Object.keys(watch().permissions)?.map((key) => ({
              key,
              name: formatPermissionsGroups[key],
              value: watch().permissions?.[key]?.map((item: any) => {
                  const nestedValue = getNestedValueShort(watch()?.slugs, item.slug);
                  return {item, mainOption: item?.options?.[nestedValue] || undefined};
              })
          }))
        : [];
    const onSubmit = (data: any) => {
        const allPermissions = Object.keys(data.permissions)
            ?.map((key) =>
                data.permissions?.[key]?.map((item: any) => {
                    const nestedValue = getNestedValueShort(data.slugs, item.slug);
                    return nestedValue ? item.slug + ':' + nestedValue : item.slug;
                })
            )
            .flat();
        newRoleMutation
            .mutateAsync({
                data: {
                    id: row ? row.id : undefined,
                    name: data.name,
                    permissions: allPermissions,
                    description: data.description
                }
            })
            .then((r) => {
                openToast({
                    text: 'Роль успешно ' + (row ? 'отредактирована' : 'создана'),
                    type: TOAST_TYPE.SUCCESS
                });
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <FormTextField name={'name'} placeholder={'Название роли'} />
                <FormTextField name={'description'} placeholder={'Описание'} multiline rows={2} />

                {/*@ts-ignore*/}
                <TransferRole
                    form={form}
                    permissions={permissions?.data || []}
                    //@ts-ignore
                    module={watchModule?.slug}
                    isLoading={isLoading}
                    allPermissions={allPermis}
                    permissionsGroups={permissionsGroups}
                />
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={newRoleMutation.isPending}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            name: yup.string().required(ERROR_MESSAGE.REQUIRED),
            module: yup.mixed().nullable(),
            permissions: yup.mixed(),
            slugs: yup.mixed()
        })
    )
};

function getNestedValueShort(obj: any, path: string) {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
}
