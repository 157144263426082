import {useForm} from 'react-hook-form';
import {Paper} from 'components/accessory/Paper';
import {Button, Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import React from 'react';
import PermissionGroupsTable from './components/PermissionGroupsTable';
import {StandardTableHeader} from 'components/accessory/StandardTableHeader';
import {PlusIcon} from 'assets/icons';
import {handleOpenEditGroupModal} from './components/AddEditGroupModal';

export const PermissionsGroups = () => {
    const form = useForm();

    return (
        <Paper sx={{p: {xs: 2, sm: 3}, height: '100%'}}>
            <Stack gap={2}>
                <StandardTableHeader
                    form={form}
                    title={'Список групп'}
                    buttons={
                        <Button
                            variant={'contained'}
                            startIcon={<PlusIcon />}
                            onClick={() => handleOpenEditGroupModal('Создать группу')}>
                            Добавить группу
                        </Button>
                    }
                />
                <Form form={form}>
                    <PermissionGroupsTable form={form} />
                </Form>
            </Stack>
        </Paper>
    );
};
