/* eslint-disable camelcase */
import {Button, Stack, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetApiBandaNewsId, usePostApiBandaNewsCreate, usePostApiBandaNewsUpdate} from 'api/новости';
import {Paper} from 'components/accessory/Paper';
import {paperHeight, standardPadding} from 'utils/mixins';
import React, {useEffect} from 'react';
import {Form} from 'components/form/Form';
import {useForm} from 'react-hook-form';
import {FormTextField} from 'components/form/FormTextField';
import {Editor} from 'components/form/Editor';
import {FormSwitch} from 'components/form/FormSwitch';
import {LoadingButton} from '@mui/lab';
import {closeModal} from 'context/modalEffector';
import {errorMessage, successMessage} from 'utils/functions';
import {ROUTES} from 'utils/constants';
import {Loader} from 'components/accessory/Loader';

export const NewsEdit = () => {
    const {newsId} = useParams();
    const {data, isLoading, refetch} = useGetApiBandaNewsId(newsId as string);
    //@ts-ignore
    const newsData = data?.data;
    const form = useForm();
    const {reset, watch} = form;
    const updateNews = newsData ? usePostApiBandaNewsUpdate() : usePostApiBandaNewsCreate();
    const resetData = (newsData: any) => {
        reset({
            title: newsData?.title || undefined,
            intro_text: newsData?.intro_text || undefined,
            full_text: newsData?.full_text?.replace(/\n/g, '<br />') || undefined,
            is_must_read: newsData?.is_must_read || undefined
        });
    };
    useEffect(() => {
        if (newsData) {
            resetData(newsData);
        }
    }, [newsData]);
    const navigate = useNavigate();
    const onSubmit = (data: any) => {
        updateNews
            .mutateAsync({
                data: {...data, id: newsId || undefined}
            })
            .then((r) => {
                successMessage('Новость успешно ' + (newsData ? 'отредактирована' : 'создана'));
                if (newsData) {
                    refetch();
                } else navigate(ROUTES.NEWS);
                closeModal();
            })
            .catch((error) => {
                errorMessage(error);
            });
    };
    return (
        <Paper sx={{p: standardPadding, height: '100%', minHeight: paperHeight}}>
            <Form form={form} onSubmit={onSubmit}>
                <Stack gap={2}>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Typography variant={'h5'}>
                                {newsId ? 'Редактирование новости' : 'Создание новости'}
                            </Typography>
                            <FormTextField
                                name={'title'}
                                placeholder={'Название'}
                                sx={{maxWidth: {xs: '100%', md: 400}}}
                            />
                            <FormTextField
                                name={'intro_text'}
                                placeholder={'Предварительный текст'}
                                sx={{maxWidth: {xs: '100%', md: 400}}}
                                multiline
                                rows={4}
                            />
                            <Editor name={'full_text'} />
                            <FormSwitch name={'is_must_read'} label={'Обязательно к прочтению'} />
                            <Stack direction={'row'} gap={2} mt={2}>
                                <LoadingButton loading={updateNews.isPending} variant={'contained'} type={'submit'}>
                                    Сохранить
                                </LoadingButton>
                                <Button variant={'outlined'}>Сбросить</Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Form>
        </Paper>
    );
};
