import {useForm} from 'react-hook-form';

import {Box, CircularProgress, Link, Stack, Typography, useMediaQuery} from '@mui/material';
import {FormTextField} from 'components/form/FormTextField';
import {Form} from 'components/form/Form';
import {LoadingButton} from '@mui/lab';
import {Paper} from 'components/accessory/Paper';

import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {ERROR_MESSAGE, ROUTES} from 'utils/constants';
import {getGetApiBandaAuthenticationUserQueryKey, usePostApiBandaAuthenticationLoginOrEmail} from 'api/авторизация';
import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {useQsParams} from 'utils/hooks/useQsParams';
import {useQueryClient} from '@tanstack/react-query';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {
    usePostApiBandaSsoCheckSession,
    usePostApiBandaSsoLoginAuthRequest,
    usePostApiBandaSsoTokenAuthRequest
} from 'api/sso-авторизация';
import {addNewToken, errorLayoutMessage, getTokensArray} from 'utils/functions';
import {UsersList} from './components/UsersList';
import {setTokens} from 'utils/functions/auth';
import {useLocation} from 'react-router-dom';
import {MainImage} from './components/MainImage';
import {Theme, useTheme} from '@mui/material/styles';
import {BackIcon, FullLogoIcon} from 'assets/icons';
import {logoColorMixin} from 'utils/mixins';

export const Authorization = () => {
    const form = useForm(schema);
    const {reset} = form;
    const authorizationQuery = usePostApiBandaAuthenticationLoginOrEmail();
    const authorizationSSOQuery = usePostApiBandaSsoLoginAuthRequest();
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    const isSSO = window.location.pathname === ROUTES.SSO_AUTH;
    const SSOSuccess = (r: any) => {
        addNewToken(r.result.token);
        const {SAMLResponse, RelayState, action} = r.result.auth.data;
        ssoQueryPost({data: {SAMLResponse, RelayState}, location: action});
    };
    const StandardSuccess = (r: any) => {
        addNewToken(r.token);
        queryClient.setQueryData(getGetApiBandaAuthenticationUserQueryKey(), r.data);
        window.location.href = params.redirectUrl || ROUTES.PROFILE;
    };
    const StandardError = (error: any) => {
        openToast({
            text: errorLayoutMessage(error),
            type: TOAST_TYPE.ERROR
        });
    };
    const onSubmit = (data: any) => {
        const query = isSSO
            ? authorizationSSOQuery.mutateAsync({
                  data: {...data, RelayState: params.RelayState, SAMLRequest: params.SAMLRequest}
              })
            : authorizationQuery.mutateAsync({
                  data
              });
        query
            .then((r) => {
                if (isSSO) {
                    SSOSuccess(r);
                } else {
                    StandardSuccess(r);
                }
            })
            .catch((error) => {
                StandardError(error);
            });
    };
    const samlAuthMutation = usePostApiBandaSsoTokenAuthRequest();
    const SamlAuth = (token: string) => {
        samlAuthMutation
            .mutateAsync({
                data: {token, RelayState: params.RelayState, SAMLRequest: params.SAMLRequest}
            })
            .then((r) => {
                SSOSuccess(r);
            })
            .catch((error) => {
                StandardError(error);
            });
    };
    const standardAuth = (token: string) => {
        // eslint-disable-next-line camelcase
        setTokens({access_token: token as string});
        window.location.href = params.redirectUrl || ROUTES.PROFILE;
    };
    useEffect(() => {
        // reset({loginOrEmail: 'sinkevich.filipp@teh85.ru', password: 'rK2u%uKS'});
    }, []);

    const checkSessionMutate = usePostApiBandaSsoCheckSession();
    const [users, setUsers] = useState<null | any[]>(null);
    useEffect(() => {
        // if (isSSO) {
        const tokensArray = getTokensArray();
        checkSessionMutate
            .mutateAsync({
                data: {auth: tokensArray}
            })
            .then((r) => {
                //@ts-ignore
                if (r.auth_token_force && isSSO) {
                    //@ts-ignore
                    SamlAuth(r.auth_token_force);
                    // //@ts-ignore
                    setUsers([]);
                } else {
                    //@ts-ignore
                    setUsers(r.result);
                }
            })
            .catch(() => setUsers([]));
        // }
    }, []);
    const location = useLocation();
    const [openAuth, setOpenAuth] = useState(location.state?.openAuth || false);

    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {
        palette: {mode: thisTheme}
    } = useTheme();
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                backgroundColor: (theme) => theme.palette.background.default
            }}>
            {isMd && (
                <>
                    <Link
                        href={ROUTES.PROFILE}
                        sx={{
                            position: 'absolute',
                            zIndex: 2,
                            top: '2.5rem',
                            left: '1.5rem',
                            ...logoColorMixin(thisTheme)
                        }}>
                        <FullLogoIcon />
                    </Link>
                    <MainImage />
                </>
            )}
            <Form
                form={form}
                onSubmit={onSubmit}
                style={{maxHeight: '100vh', height: '100%', width: isMd ? 'auto' : '100%'}}>
                <Paper
                    sx={{
                        minHeight: '600px',
                        p: {xs: 2, sm: 3, md: 6},
                        borderRadius: 0,
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        minWidth: {xs: '100%', md: '400px'},
                        maxWidth: '100%',
                        width: {xs: '100%', md: '33.33333333vw'}
                    }}
                    className={'custom-scroll'}>
                    {!isMd && (
                        <Box sx={{position: 'absolute', top: 20, left: {xs: 16, sm: 24}, ...logoColorMixin(thisTheme)}}>
                            <FullLogoIcon />
                        </Box>
                    )}

                    <Stack
                        gap={{xs: 2, sm: 3}}
                        sx={{
                            minWidth: {xs: '100%', sm: 350},
                            width: {xs: '100%', sm: 400},
                            maxWidth: '100%',
                            mt: {md: 6}
                        }}>
                        {!users ? (
                            <CircularProgress size={48} sx={{marginLeft: 'auto', marginRight: 'auto'}} />
                        ) : (
                            <>
                                {users && users?.length && !openAuth ? (
                                    <>
                                        <Stack>
                                            <HeaderAuth>Выберите аккаунт для входа</HeaderAuth>
                                        </Stack>
                                        <UsersList
                                            {...{users}}
                                            onClick={(token) => (isSSO ? SamlAuth(token) : standardAuth(token))}
                                        />
                                        <Typography textAlign={'center'}>
                                            Нет подходящего аккаунта?{' '}
                                            <Link
                                                color={'primary'}
                                                onClick={() => setOpenAuth(true)}
                                                underline='none'
                                                sx={{cursor: 'pointer'}}>
                                                Авторизуйтесь
                                            </Link>
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Stack>
                                            {((users && users?.length) || openAuth) && (
                                                <Link
                                                    onClick={() => setOpenAuth(false)}
                                                    underline='none'
                                                    color={'primary'}
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 0.5,
                                                        pb: 1,
                                                        cursor: 'pointer',
                                                        alignItems: 'center',
                                                        '& path': {stroke: (theme) => theme.palette.primary.main}
                                                    }}>
                                                    <BackIcon height={10} style={{marginBottom: '1.5px'}} /> Выбрать
                                                    аккаунт
                                                </Link>
                                            )}
                                            <HeaderAuth>Введите свои данные, чтобы войти в систему</HeaderAuth>
                                        </Stack>

                                        <FormTextField
                                            name={'loginOrEmail'}
                                            placeholder={'Укажите логин или E-mail'}
                                            sx={{mb: 0.5}}
                                            label={'Логин или E-mail'}
                                        />
                                        <FormTextField
                                            name={'password'}
                                            password
                                            placeholder={'············'}
                                            label={'Пароль'}
                                        />
                                        <LoadingButton
                                            variant={'contained'}
                                            type={'submit'}
                                            size={'large'}
                                            color={'primary'}
                                            loading={authorizationQuery.isPending || samlAuthMutation.isPending}>
                                            Войти
                                        </LoadingButton>
                                        <Typography textAlign={'center'}>
                                            Не можете войти? <Link underline={'none'}>Поддержка</Link>
                                        </Typography>
                                    </>
                                )}
                            </>
                        )}
                    </Stack>
                </Paper>
            </Form>
        </Stack>
    );
};

const HeaderAuth = ({children}: {children: ReactNode}) => {
    return (
        <>
            <Typography variant={'h4'} sx={{mb: 0.5}}>
                Добро пожаловать в Банду! 👋
            </Typography>
            <Typography color={'text.secondary'}>{children}</Typography>
        </>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            loginOrEmail: yup.string().required(ERROR_MESSAGE.REQUIRED),
            password: yup.string().required(ERROR_MESSAGE.REQUIRED).min(6, 'Минимальное количество символов - 6')
        })
    )
};

const ssoQueryPost = ({
    data,
    location
}: {
    data: {
        SAMLResponse: any;
        RelayState: any;
    };
    location: any;
}) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = location;

    // Добавляем скрытые поля с данными
    for (const key in data) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key as keyof typeof data];
        form.appendChild(input);
    }

    // Добавляем форму на страницу и отправляем
    document.body.appendChild(form);
    form.submit();
};
