import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, Stack, Typography} from '@mui/material';
import {Paper} from 'components/accessory/Paper';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {FormTextField} from 'components/form/FormTextField';
import {MASKS} from 'utils/constants';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

import {useGetApiBandaTableShow} from 'api/табель';
import {CalendarHeader} from './components/CalendarHeader';
import {standardPadding} from 'utils/mixins';
import {leftColumnWidth} from './utils';
import {CalendarRowsList} from './components/CalendarRowsList';
import {DepartmentsInput} from 'components/custom-inputs/DepartmentsInput';
import {useQsParams} from 'utils/hooks/useQsParams';
import {LeftEmptyWhiteColumn} from './components/LeftEmptyWhiteColumn';
import {FormDatePicker} from 'components/form/FormDatePicker';
import dayjs from 'dayjs';
import {SquareHalfIcon, SquareIcon, TableDownIcon} from 'assets/icons';
import {$schedulerMonth, setCalendarDays, setSchedulerMonth} from 'context/schedulerEffector';
import {useUnit} from 'effector-react';
import {FormButtonsTab} from 'components/form/FormButtonsTab';
import {getDaysInMonth} from 'utils/functions';

export const Scheduler = () => {
    const [isCustomTime, setIsCustomTime] = useState(false);

    const form = useForm();
    const {reset, watch} = form;
    const watchMonth = watch('month');
    const watchTypeMonth = watch('typeMonth');
    const [params] = useQsParams();
    const department = params?.department;

    const {data, isLoading} = useGetApiBandaTableShow({
        departmentId: department?.id || '01JETY6XJ6X0SY4NB64GJPWV0Y',
        startedAt: useUnit($schedulerMonth).startOf('month').format('YYYY-MM-DD'),
        endedAt: useUnit($schedulerMonth).endOf('month').format('YYYY-MM-DD')
    });
    const userList = data?.data?.items;

    useEffect(() => {
        reset({timeFrom: '09:00', timeTo: '18:00', month: dayjs(), typeMonth: 'full', department});
    }, []);

    useEffect(() => {
        const dayjsData = dayjs(watchMonth);
        setCalendarDays(getDaysInMonth(dayjsData.month(), dayjsData.year(), watchTypeMonth));
        setSchedulerMonth(dayjs(watchMonth));
        console.log();
    }, [watchMonth, watchTypeMonth]);
    return (
        <Form form={form}>
            <Stack gap={2}>
                <Paper sx={{padding: standardPadding}}>
                    <Stack gap={{xs: 2, sm: 3}}>
                        <Stack gap={2} direction={'row'} flexWrap={'wrap'}>
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <IconButton
                                    onClick={() => setIsCustomTime(!isCustomTime)}
                                    color={isCustomTime ? 'primary' : 'default'}>
                                    <AccessAlarmsIcon />
                                </IconButton>
                                <FormTextField
                                    name={'timeFrom'}
                                    placeholder={'чч:мм'}
                                    sx={{width: 64}}
                                    maskProps={MASKS.TIME}
                                    closeIcon={false}
                                />
                                <Box>{'-'}</Box>
                                <FormTextField
                                    name={'timeTo'}
                                    placeholder={'чч:мм'}
                                    sx={{width: 64}}
                                    maskProps={MASKS.TIME}
                                    closeIcon={false}
                                />
                            </Stack>
                            <Button color={'primary'} variant={'contained'}>
                                Опубликовать
                            </Button>
                            <Button color={'error'} variant={'outlined'}>
                                Отмена
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
                <Paper sx={{p: standardPadding}}>
                    <Stack gap={2}>
                        <Stack direction={'row'} alignItems={'flex-end'} gap={2}>
                            <FormDatePicker
                                name={'month'}
                                sx={{width: inputWidth}}
                                autoComplete='off'
                                openTo={'month'}
                                label={'Месяц'}
                            />
                            <FormButtonsTab tabs={FOLDERS} name={'typeMonth'} />
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} gap={2}>
                            <DepartmentsInput
                                sx={{width: inputWidth}}
                                isSearch
                                name={'department'}
                                label={'Подразделение'}
                            />
                        </Stack>
                    </Stack>
                </Paper>
                <Paper sx={{p: standardPadding}}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant={'h5'} color={'text.secondary'}>
                            {department?.name}
                        </Typography>
                        <Stack direction={'row'} gap={2}>
                            <Button
                                variant={'outlined'}
                                startIcon={<TableDownIcon style={{width: 20, height: 20}} />}
                                color={'success'}>
                                Скачать табель
                            </Button>
                            {/*<Button variant={'outlined'} color={'inherit'}>*/}
                            {/*    Добавить сотрудника +*/}
                            {/*</Button>*/}
                        </Stack>
                    </Stack>
                </Paper>
                <Paper
                    sx={{
                        p: standardPadding,
                        paddingLeft: '0 !important',
                        height: '100%',
                        overflow: 'auto',
                        minHeight: '600px'
                    }}>
                    <Stack gap={0.25} sx={{width: 'min-content', height: '100%', position: 'relative'}}>
                        <Stack direction={'row'} gap={2}>
                            <LeftEmptyWhiteColumn />
                            <Stack
                                sx={{
                                    width: leftColumnWidth,
                                    minWidth: leftColumnWidth,
                                    border: (theme) => '1px solid ' + theme.palette.divider,
                                    px: 1,
                                    position: 'sticky',
                                    left: {xs: '16px', md: '24px'},
                                    borderRadius: '8px',
                                    alignItems: 'flex-end',
                                    zIndex: 2,
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }}
                            />
                            <Stack direction={'row'} sx={{gap: 2}}>
                                <Stack
                                    width={{
                                        minWidth: 265,
                                        position: 'sticky',
                                        height: '100%',
                                        left: 0
                                    }}>
                                    <CalendarHeader />
                                </Stack>
                            </Stack>
                        </Stack>

                        <CalendarRowsList userList={userList} isLoading={isLoading} />
                    </Stack>
                </Paper>
            </Stack>
        </Form>
    );
};
const inputWidth = {xs: '100%', sm: 300};
export const FOLDERS = [
    {name: <SquareHalfIcon style={{transform: 'scale(-1, -1)'}} />, id: 'firstHalf'},
    {name: <SquareHalfIcon />, id: 'lastHalf'},
    {name: <SquareIcon />, id: 'full'}
];
