import {Stack} from '@mui/material';
import {ROUTES} from 'utils/constants';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(ROUTES.NEWS);
    }, []);
    return <Stack />;
};
