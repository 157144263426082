import {Button, Divider, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import {handleOpenBlockModal} from './BlockUnblockModal';
import {handleOpenEditUserModal} from './EditUserInfoModal';
import {ChipStatus} from 'components/accessory/ChipStatus';

export const PersonalInfo = ({userInfo}: {userInfo?: any}) => {
    return (
        <Stack gap={2}>
            <Typography variant={'h5'}>Детали</Typography>
            <Divider />
            <Stack gap={1}>
                {USER_INFO.map((row: any, index: number) => (
                    <Stack direction={'row'} flexWrap={'wrap'} key={index}>
                        <Typography color={'text.heading'} fontWeight={'500'}>
                            {row?.title}
                            :&nbsp;
                        </Typography>
                        <Typography color={'text.secondary'} sx={{whiteSpace: 'pre-wrap'}}>
                            {(row.value?.(userInfo) as string) || ' - '}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
            <Stack direction={'row'} gap={2} flexWrap={'wrap'} justifyContent={'center'}>
                <Button variant={'contained'} onClick={() => handleOpenEditUserModal(userInfo)}>
                    Редактировать
                </Button>
                <Button color={'error'} variant={'contained'} onClick={() => handleOpenBlockModal(userInfo)}>
                    {userInfo?.is_blocked ? 'Разблокировать' : 'Заблокировать'}
                </Button>
            </Stack>
        </Stack>
    );
};

const USER_INFO = [
    {title: 'E-mail', value: (item: any) => item?.email},
    {
        title: 'Телефон',
        value: (item: any) => item?.phone
    },
    {
        title: 'Дата найма',
        value: (item: any) => (item?.first_hiring_date ? dayjs(item.first_hiring_date).format('DD.MM.YYYY') : '')
    },
    {
        title: 'Подразделения',
        value: (item: any) => item?.departments?.map((el: any) => el.name).join(', ')
    },
    {
        title: 'Должности',
        value: (item: any) => item?.positions?.map((el: any) => el.name).join(', ')
    },
    {
        title: 'Статусы',
        value: (item: any) => (
            <Stack gap={0.5} direction={'row'} flexWrap={'wrap'}>
                {item?.status.map((item: any) => (
                    <ChipStatus item={item} key={item.name} />
                ))}
            </Stack>
        )
    }
];
