import {FormControlLabel, FormHelperText, Radio, RadioGroup, SelectProps, Stack, SxProps} from '@mui/material';
import {ControllerProps, useController} from 'react-hook-form';
import React from 'react';
import {Labeled} from './Labeled';
import {Theme} from '@mui/material/styles';

const FormRadioGroup = <
    T extends {id: number | string; [key: string]: any},
    M extends boolean | undefined,
    K extends keyof T
>(
    props: Omit<ControllerProps, 'render'> &
        Omit<SelectProps<T>, 'renderInput'> & {
            label?: string;
            options?: T[];
            fullWidth?: boolean;
            direction?: 'row' | 'column';
            inputSx?: SxProps<Theme>;
            labelSx?: SxProps<Theme>;
            disabled?: boolean;
            horizontal?: boolean;
        }
) => {
    const {
        name,
        options = [],
        label,
        rules,
        sx,
        fullWidth,
        direction,
        labelSx,
        disabled,
        horizontal,
        defaultValue
    } = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: defaultValue.value});
    return (
        <Labeled label={label} fullWidth={fullWidth} direction={direction} labelSx={labelSx}>
            <Stack direction={'column'} position={'relative'} width={'100%'}>
                <RadioGroup {...field} aria-label='reason' row={horizontal} value={field.value || ''} sx={{gap: 1}}>
                    {options.map((item, index) => (
                        <FormControlLabel
                            key={`radio-${index}`}
                            value={item.value}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            control={<Radio sx={{p: 0}} size={'small'} />}
                            label={item.label}
                            sx={{ml: 0, mr: 0, gap: 1, ...sx}}
                            disabled={disabled}
                        />
                    ))}
                </RadioGroup>
                {error?.message && (
                    <FormHelperText error sx={{mb: 1}}>
                        {error?.message}
                    </FormHelperText>
                )}
            </Stack>
        </Labeled>
    );
};

export default FormRadioGroup;
