import {Box, Tab, Tabs} from '@mui/material';
import React, {SyntheticEvent} from 'react';
import {Theme} from '@mui/material/styles';
import {useQsParams} from 'utils/hooks/useQsParams';
import DepartmentsTable from './DepartmentsTable';
import {TreeDepartments} from './TreeDepartments';

export const DepartmentsTabs = () => {
    const [params, , setParamsSmart] = useQsParams();

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setParamsSmart({tab: newValue});
    };
    return (
        <Box sx={{mb: -2, ml: -3}}>
            <Tabs
                value={params?.tab || 'list'}
                onChange={handleChange}
                aria-label='basic tabs example'
                scrollButtons={'auto'}
                variant={'scrollable'}>
                {DEPARTMENTS_TAB.map((tab) => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        sx={(theme: Theme) => ({...theme.typography.h5})}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export const DEPARTMENTS_TAB_ENUM = {LIST: 'list', STRUCTURE: 'structure'};
export const DEPARTMENTS_TAB = [
    {
        label: 'Список подразделений',
        value: DEPARTMENTS_TAB_ENUM.LIST
    },
    {
        label: 'Структура подразделений',
        value: DEPARTMENTS_TAB_ENUM.STRUCTURE
    }
];
export const DEPARTMENTS_COMPONENTS = {
    [DEPARTMENTS_TAB_ENUM.LIST]: (form: any) => <DepartmentsTable form={form} />,
    [DEPARTMENTS_TAB_ENUM.STRUCTURE]: (form: any) => <TreeDepartments form={form} />
};
