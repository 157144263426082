// @ts-nocheck
import {getTokens, removeTokens} from 'utils/functions/auth';
import {ROUTES} from 'utils/constants';

// let isRefreshing = false;
// let failedQueue: any[] = [];
//
// const processQueue = (error: null, token: string | null) => {
//     failedQueue.forEach((prom) => {
//         if (error) {
//             prom.reject(error);
//         } else {
//             prom.resolve(token);
//         }
//     });
//
//     failedQueue = [];
// };

//Если 401 ошибка и не на страницах авторизации, то редирект, если какие-то другие ошибки, то улетает в rejected

export const onRejected = (error: any) => {
    const originalRequest = error.config;
    // Если повалился refresh запрос
    // if (originalRequest.url === '/token/refresh_token') {
    //     removeTokens();
    //     if (window.location.pathname !== ROUTES.AUTH) {
    //         window.location.href = ROUTES.AUTH + `?redirectUrl=${encodeURIComponent(window.location.href)}`;
    //     }
    //     return Promise.reject(error);
    // }
    if (error.response?.status === 401 && !originalRequest._retry) {
        console.log('Unauthorized');
        const {accessToken} = getTokens();
        if (!accessToken) {
            return Promise.reject(error);
        }
        // if (isRefreshing) {
        //     console.log('isRefreshing');
        //     return new Promise((resolve, reject) => {
        //         failedQueue.push({resolve, reject});
        //     })
        //         .then((token) => {
        //             originalRequest.headers['Authorization'] = 'Bearer ' + token;
        //             return axios(originalRequest);
        //         })
        //         .catch((err) => {
        //             return Promise.reject(err);
        //         });
        // }

        // originalRequest._retry = true;
        // isRefreshing = true;

        // return new Promise((resolve, reject) => {
        //     refreshTokenTokenRefreshTokenPost({
        //         refresh_token: refreshToken
        //     })
        //         .then((data) => {
        //             setTokens(data);
        //             originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
        //             processQueue(null, data.access_token);
        //             resolve(axios(originalRequest));
        //         })
        //         .catch((err) => {
        //             processQueue(err, null);
        //             reject(err);
        //         })
        //         .finally(() => {
        //             isRefreshing = false;
        //         });
        // });
    } else if (
        window.location.pathname !== ROUTES.AUTH &&
        window.location.pathname !== ROUTES.SSO_AUTH &&
        !window.location.pathname.includes(ROUTES.ERROR) &&
        originalRequest.url === '/api/v1/authentication/user'
    ) {
        // Если запрос me упал с какой-то другой ошибкой
        window.location.href = ROUTES.AUTH + `?redirectUrl=${encodeURIComponent(window.location.href)}`;
        removeTokens();
        return Promise.reject(error);
    }
    return Promise.reject(error);
};
