import {RouteObject} from 'react-router';
import MainLayout from 'components/layouts/MainLayout';
import {Authorization, Home, News, Profile, Scheduler} from 'pages';
import {ERROR_PAGES_INFO, ROUTES} from 'utils/constants';
import {UserAccounts} from './pages/Users/pages/Accounts';
import {PermissionsList} from './pages/Permissions/pages/List';
import {PermissionsGroups} from './pages/Permissions/pages/Groups';
import {PermissionsRoles} from './pages/Permissions/pages/Roles';
import {Error} from './pages/Error';
import {Departments} from './pages/Users/pages/Departments';
import {Positions} from './pages/Users/pages/Positions';
import {UserSchedulers} from './pages/Users/pages/UserSchedulers';
import {WorkProfiles} from './pages/Users/pages/WorkProfiles';
import {UserInfo} from './pages/Users/pages/Accounts/pages/UserInfo';
import {ProfileSettings} from './pages/Profile/pages/ProfileSettings';
import {PermissionsAccess} from './pages/Permissions/pages/PermissionsAccess';
import {Wiki} from './pages/Wiki';
import {NewsEdit} from './pages/News/pages/Edit';
import {LogsAuth} from './pages/Logs/pages/Auth';

export const routes: RouteObject[] = [
    {
        path: '*',
        element: <Error error={ERROR_PAGES_INFO[404]} />
    },
    {
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },

            {
                path: ROUTES.AUTH,
                element: <Authorization />
            },
            {
                path: ROUTES.SSO_AUTH,
                element: <Authorization />
            },
            {
                path: ROUTES.PROFILE,
                children: [
                    {index: true, element: <Profile />},
                    {
                        path: ROUTES.PROFILE + '/:userId',
                        element: <Profile />
                    }
                ]
            },
            {
                path: ROUTES.PROFILE_SETTINGS,
                element: <ProfileSettings />
            },
            {
                path: ROUTES.NEWS,
                children: [
                    {index: true, element: <News />},
                    {
                        path: ROUTES.NEWS + '/:newsId',
                        element: <News />
                    },
                    {
                        path: ROUTES.NEWS_EDITOR,
                        element: <NewsEdit />
                    },
                    {
                        path: ROUTES.NEWS_EDITOR + '/:newsId',
                        element: <NewsEdit />
                    }
                ]
            },
            {
                path: ROUTES.REQUESTS,
                element: <div />
            },
            {
                path: ROUTES.AUDITS,
                element: <div />
            },
            {
                path: ROUTES.TABLE,
                element: <Scheduler />
            },
            {
                path: ROUTES.SUBSTITUTIONS,
                element: <div />
            },
            {
                path: ROUTES.VACATIONS,
                element: <div />
            },
            {
                path: ROUTES.CONTACTS,
                element: <div />
            },
            {
                path: ROUTES.BIRTHDAYS,
                element: <div />
            },
            {
                path: ROUTES.STRUCTURE,
                element: <div />
            },
            {
                path: ROUTES.WIKI,
                element: <Wiki />
            },
            {
                path: ROUTES.REQUESTS,
                element: <div />
            },
            {
                path: ROUTES.POLLS,
                element: <div />
            },
            {
                path: ROUTES.TESTS,
                element: <div />
            },
            {
                path: ROUTES.CHECK_LISTS,
                element: <div />
            },
            {
                path: ROUTES.AUDITS,
                element: <div />
            },
            {
                path: ROUTES.SYSTEM,
                children: [
                    {
                        path: ROUTES.USERS,
                        children: [
                            {index: false, element: <div />},
                            {
                                path: ROUTES.USERS_SERVICE_ACCOUNTS,
                                element: <div />
                            },
                            {
                                path: ROUTES.USERS_ACCOUNTS,
                                element: <UserAccounts />
                            },
                            {
                                path: ROUTES.USERS_ACCOUNTS + '/:userId',
                                element: <UserInfo />
                            },
                            {
                                path: ROUTES.USERS_GROUPS,
                                element: <div />
                            },
                            {
                                path: ROUTES.USERS_PROFILES,
                                element: <WorkProfiles />
                            },
                            {
                                path: ROUTES.USERS_SCHEDULERS,
                                element: <UserSchedulers />
                            },
                            {
                                path: ROUTES.USERS_POSITIONS,
                                element: <Positions />
                            },
                            {
                                path: ROUTES.USERS_DIVISIONS,
                                element: <Departments />
                            }
                        ]
                    },
                    {
                        path: ROUTES.LOGS,
                        children: [
                            {index: false, element: <div />},
                            {
                                path: ROUTES.LOGS_READ,
                                element: <div />
                            },
                            {
                                path: ROUTES.LOGS_WRITE,
                                element: <div />
                            },
                            {
                                path: ROUTES.LOGS_INTEGRATIONS,
                                element: <div />
                            },
                            {
                                path: ROUTES.LOGS_ERRORS,
                                element: <div />
                            },
                            {
                                path: ROUTES.LOGS_AUTH,
                                element: <LogsAuth />
                            }
                        ]
                    }
                ]
            },

            {
                path: ROUTES.PERMISSIONS,
                children: [
                    {index: true, element: <div />},
                    {
                        path: ROUTES.PERMISSIONS_ACCESS,
                        element: <PermissionsAccess />
                    },
                    {
                        path: ROUTES.PERMISSIONS_LIST,
                        element: <PermissionsList />
                    },
                    {
                        path: ROUTES.PERMISSIONS_GROUPS,
                        element: <PermissionsGroups />
                    },
                    {
                        path: ROUTES.PERMISSIONS_ROLES,
                        element: <PermissionsRoles />
                    }
                ]
            },
            {
                path: ROUTES.WIKI,
                children: [
                    {index: false},
                    {
                        path: ROUTES.HELP,
                        element: <div />
                    },
                    {
                        path: ROUTES.DOCUMENTATION,
                        element: <div />
                    }
                ]
            },

            {
                path: ROUTES.IDEA,
                element: <div />
            },
            {
                path: ROUTES.ERROR_403,
                element: <Error error={ERROR_PAGES_INFO[403]} />
            },
            {
                path: ROUTES.ERROR_404,
                element: <Error error={ERROR_PAGES_INFO[404]} />
            }
        ]
    }
];
