import {useForm} from 'react-hook-form';
import {Paper} from 'components/accessory/Paper';
import {Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import React from 'react';
import {StandardTableHeader} from 'components/accessory/StandardTableHeader';
import {DEPARTMENTS_COMPONENTS, DEPARTMENTS_TAB_ENUM, DepartmentsTabs} from './components/DepartmentsTabs';
import {useQsParams} from 'utils/hooks/useQsParams';
import {paperHeight} from 'utils/mixins';

export const Departments = () => {
    const form = useForm({});
    const [params, , setParamsSmart] = useQsParams();
    return (
        <Paper sx={{p: {xs: 2, sm: 3}, height: '100%', minHeight: paperHeight}}>
            <Stack gap={2}>
                <StandardTableHeader
                    form={form}
                    title={<DepartmentsTabs />}
                    resetButton={params.tab !== DEPARTMENTS_TAB_ENUM.STRUCTURE}
                />
                <Form form={form}>
                    {DEPARTMENTS_COMPONENTS[params.tab || DEPARTMENTS_TAB_ENUM.LIST](form)}
                    {/*<DepartmentsTable form={form} />*/}
                </Form>
            </Stack>
        </Paper>
    );
};
