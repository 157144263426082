import {ControllerProps, useController} from 'react-hook-form';
import React, {useState} from 'react';
import {Labeled} from './Labeled';
import {
    IconButton,
    MenuItem,
    SelectChangeEvent,
    SelectProps,
    SxProps,
    TextField,
    TextFieldVariants,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useQsParams} from 'utils/hooks/useQsParams';

export const FormSelect = <
    T extends {id: number | string; [key: string]: any},
    M extends boolean | undefined,
    K extends keyof T
>(
    props: Omit<ControllerProps, 'render'> &
        Omit<SelectProps<T>, 'renderInput'> & {
            label?: string;
            options?: T[];
            optionText?: keyof T;
            optionValue?: keyof T;
            fullWidth?: boolean;
            direction?: 'row' | 'column';
            inputSx?: SxProps<Theme>;
            labelSx?: SxProps<Theme>;
            disabled?: boolean;
            onChange?: (e: SelectChangeEvent<unknown>) => void;
            variant?: TextFieldVariants;
            isSearch?: boolean;
        }
) => {
    const {
        name,
        options = [],
        label,
        optionText = 'name',
        optionValue = 'id',
        placeholder,
        rules,
        sx,
        fullWidth,
        direction,
        labelSx,
        disabled,
        onChange,
        variant,
        isSearch
    } = props;

    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: null});
    const [open, setOpen] = useState(false);
    const [, , setParamsSmart] = useQsParams();
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open);
    };
    return (
        <Labeled label={label} fullWidth={fullWidth} direction={direction} labelSx={labelSx}>
            <TextField
                {...field}
                variant={variant}
                id={'select'}
                value={field.value || ''}
                fullWidth
                error={!!error}
                select
                sx={{...sx, '& .MuiSelect-select': {pr: '14px !important'}, cursor: 'pointer'}}
                onClick={handleOpen}
                SelectProps={{
                    onChange: (e, data) => {
                        field.onChange(e);
                        onChange?.(e);
                        if (isSearch) setParamsSmart({[name]: e.target?.value || ''});
                    },
                    renderValue: (selected) => (
                        <>
                            {options?.find((i) => i[optionValue] === selected)?.[optionText] || (
                                <Typography sx={{opacity: '0.5', fontSize: '15px', pt: '1px'}}>
                                    {placeholder}
                                </Typography>
                            )}
                        </>
                    ),
                    displayEmpty: true,
                    disabled: disabled,
                    open: open,
                    onClose: () => handleClose(),
                    onOpen: () => handleOpen(),
                    IconComponent: () => (
                        <IconButton
                            disabled={disabled}
                            sx={{
                                p: '2px'
                                // mr: '-9px'
                            }}>
                            {!disabled && <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</>}
                        </IconButton>
                    ),
                    MenuProps: {
                        disableScrollLock: true,
                        slotProps: {
                            root: {
                                onBackdropClick: async (e: any) => {
                                    await handleClose();
                                    setTimeout(() => {
                                        const starter: any = document.querySelectorAll(':hover');
                                        starter[starter.length - 1]?.focus();
                                    }, 251);
                                },
                                slotProps: {
                                    backdrop: {
                                        style: {
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }}>
                {options?.map((option) => (
                    <MenuItem key={option.id} value={option[optionValue]} disableRipple>
                        <>{option?.[optionText as keyof typeof option]}</>
                    </MenuItem>
                ))}
            </TextField>
        </Labeled>
    );
};
