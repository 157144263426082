import {Paper} from 'components/accessory/Paper';
import {Stack} from '@mui/material';
import {EditAvatar} from './EditAvatar';
import {PersonalInfo} from './PersonalInfo';
import {WorkProfiles} from './WorkProfiles';
import {useAuthContext} from 'context/authContext';

export const Account = () => {
    const {me} = useAuthContext();
    return (
        <>
            <Paper sx={{p: {xs: 2, sm: 3}}}>
                <Stack gap={4}>
                    <EditAvatar avatarUrl={me?.avatar?.url} />
                    <PersonalInfo />
                </Stack>
            </Paper>
            <WorkProfiles />
        </>
    );
};
