import {ButtonBase, CircularProgress, Stack} from '@mui/material';
import React, {ReactNode} from 'react';
import {useController} from 'react-hook-form';

export const FormButtonsTab = ({
    name,
    tabs,
    children,
    isLoading
}: {
    name: string;
    tabs: any[];
    children?: ReactNode;
    isLoading?: boolean;
}) => {
    const {
        field,
        fieldState: {error}
    } = useController({name, defaultValue: undefined});
    return (
        <Stack
            sx={{
                minHeight: 'fit-content',
                height: 38,
                alignItems: 'center',
                borderRadius: '5px',
                border: (theme) => '1px solid ' + theme.palette.divider,
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}
            direction={'row'}>
            {isLoading ? (
                <CircularProgress sx={{color: (theme) => theme.palette.primary.main, my: 'auto', mx: 0.5}} size={24} />
            ) : (
                <>
                    {tabs?.map((item) => (
                        <Tab
                            key={item.id}
                            folder={item}
                            isSelected={field.value === item.id}
                            onClick={() => field.onChange(item.id)}
                        />
                    ))}
                </>
            )}

            {children}
        </Stack>
    );
};
const Tab = ({folder, isSelected, onClick}: {folder: any; isSelected: boolean; onClick: () => void}) => {
    return (
        <ButtonBase
            onClick={onClick}
            sx={(theme) => ({
                color: isSelected ? theme.palette.common.white : theme.palette.primary.main,
                ...theme.typography.body1,
                fontWeight: 600,
                px: 2,
                py: 1,
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                height: '100%',
                background: isSelected ? theme.palette.primary.main : 'transparent'
            })}>
            {folder?.ru_name || folder?.name}
        </ButtonBase>
    );
};
