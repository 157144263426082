/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemEmployeesPositionSlShow} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import dayjs from 'dayjs';
import {useQsParams} from 'utils/hooks/useQsParams';
import {FormTextField} from 'components/form/FormTextField';
import {FormDatePicker} from 'components/form/FormDatePicker';
import {FormSelect} from 'components/form/FormSelect';

export default function Table({form}: {form: any}) {
    const {watch, reset} = form;
    const [params, , setParamsSmart] = useQsParams();
    useEffect(() => {
        reset({
            page: params.page || 1,
            per_page: params.per_page || 25,
            is_active: params.is_active,
            sort: params.sort,

            name: params.name,
            created_at: params.created_at,
            source_id: params.source_id,
            department: params.department,
            position: params.position,

            status: params.status
        });
    }, []);
    const {
        data: users,
        isLoading,
        refetch
    } = useGetApiBandaSystemEmployeesPositionSlShow({
        page: params.page || 1,
        sort: params.sort,
        per_page: params.per_page || '25',
        'filter[name]': params.name || '',
        'filter[source_id]': params.source_id || '',
        'filter[created_at]': dayjs(params.created_at).isValid() ? params.created_at : '' || '',
        'filter[search_department]': params.department || '',
        'filter[search_position]': params.position || '',
        is_active: params.is_active ? Boolean(+params.is_active) : undefined
    });

    return (
        <TableLayout
            columns={REPORTS_STRUCTURE}
            rows={users?.data || []}
            isLoading={isLoading}
            filterRow
            pagination={users?.pagination}
            negativeMargin
            minWidth={1500}
        />
    );
}
const ACTIVE_OPTIONS = [
    {
        id: 1,
        name: 'Активен',
        value: '1'
    },
    {
        id: 2,
        name: 'Не активен',
        value: '0'
    }
];

const REPORTS_STRUCTURE = [
    {
        title: <SortButton title={'Название'} sortName={'name'} />,
        filter: (
            <FormTextField
                name={'name'}
                size={'small'}
                isSearch
                // hasSearchIcon
                placeholder={'Поиск по названию'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item.name,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Дата создания'} sortName={'created_at'} />,
        filter: <FormDatePicker name={'created_at'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) => (dayjs(item?.created_at).isValid() ? dayjs(item?.created_at).format('DD MMMM YYYY') : ''),
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Подразделение'} sortName={'department'} />,
        filter: (
            <FormTextField
                name={'department'}
                size={'small'}
                isSearch
                placeholder={'Поиск по подразделению'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.department.name,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Должность'} sortName={'position'} />,
        filter: (
            <FormTextField
                name={'position'}
                size={'small'}
                isSearch
                placeholder={'Поиск по должности'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.position.name,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Источник'} sortName={'source_id'} />,
        filter: (
            <FormTextField
                name={'source_id'}
                size={'small'}
                isSearch
                placeholder={'Поиск по источнику'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.source_id,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Активность'} sortName={'is_active'} />,
        filter: (
            <FormSelect
                name={'is_active'}
                options={ACTIVE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'Активность'}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (item?.is_active ? 'Активен' : 'Не активен'),
        align: 'right',
        sx: {width: '10%'}
    }
];
