/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemAccessPermissionShow} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {FormTextField} from 'components/form/FormTextField';
import {isObject} from 'utils/functions';

export const fetchPermissions = (params: {[p: string]: any}) => ({
    page: params.page || 1,
    per_page: params.per_page || '25',
    sort: params.sort,
    'filter[search]': params.name || '',
    'filter[search_module]': params.module || ''
});
export default function PermissionListTable({form}: {form: any}) {
    const [params] = useQsParams();
    const {reset} = form;
    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            name: params.name,
            module: params.module,
            is_active: params.is_active
        });
    }, []);
    const {data: permissions, isLoading} = useGetApiBandaSystemAccessPermissionShow(fetchPermissions(params));
    return (
        <TableLayout
            columns={PERMISSION_STRUCTURE}
            rows={permissions?.data || []}
            isLoading={isLoading}
            filterRow
            pagination={permissions?.pagination}
            negativeMargin
        />
    );
}

const PERMISSION_STRUCTURE = [
    {
        title: <SortButton title={'Название'} sortName={'name'} />,
        filter: (
            <FormTextField
                name={'name'}
                isSearch
                placeholder={'Поиск по названию'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.name || '',
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Модуль'} sortName={'module'} />,
        filter: (
            <FormTextField
                name={'module'}
                isSearch
                placeholder={'Поиск по модулю'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.module?.priority?.join(' -> ') || '',
        sx: {width: '30%'}
    },

    {
        title: 'Описание',
        value: (item: any) => item?.module?.description,
        sx: {width: '20%'}
    },
    {
        title: 'slug',
        value: (item: any) => item?.slug,
        sx: {width: '10%'}
    },
    {
        title: 'Опции',
        value: (item: any) => (isObject(item?.options) ? Object.values(item?.options)?.join(', ') : ''),
        sx: {width: '20%'}
    }
    // {
    //     title: 'Действия',
    //     value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item)} />,
    //     align: 'right',
    //     sx: {width: '5%'}
    // }
];
// const ITEM_ACTIONS = (item: any) => [
//     {
//         title: 'Редактировать',
//         icon: <PencilIcon />,
//         disabled: true,
//         onClick: () => {}
//     }
// ];
