/* eslint-disable camelcase */
import {Paper} from 'components/accessory/Paper';
import {IconButton, LinearProgress, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {FormTextField} from 'components/form/FormTextField';
import {generatePassword} from './generatePassword';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {LoadingButton} from '@mui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';
import {CopyIcon, DiceIcon} from 'assets/icons';
import {RotateIcon} from 'components/accessory/RotateIcon';
import {
    usePatchApiBandaSystemEmployeesAccountsPasswordChangeByManager,
    usePostApiBandaSystemEmployeesAccountsPasswordGenerate
} from 'api/система';
import {errorLayoutMessage} from 'utils/functions';
import {useParams} from 'react-router-dom';

export const AccountSecurity = () => {
    return (
        <>
            <Paper sx={{p: {xs: 2, sm: 3}}}>
                <EditPassword />
            </Paper>
        </>
    );
};

const EditPassword = () => {
    const {userId} = useParams();
    const form = useForm(schema);
    const {watch, reset} = form;
    const newPassword = watch('password');
    const passwordGenerateMutation = usePostApiBandaSystemEmployeesAccountsPasswordGenerate();
    const editPasswordMutation = usePatchApiBandaSystemEmployeesAccountsPasswordChangeByManager();
    const options = (length: number) => ({
        length: length,
        lowercase: true,
        uppercase: true,
        numbers: true,
        symbols: false,
        exclude: '',
        minLengthLowercase: 1,
        minLengthUppercase: 1,
        minLengthNumbers: 1,
        minLengthSymbols: 0
    });
    const [loading, setLoading] = useState(0);
    const seconds = [3, 4, 5, 6, 7, 8];

    const getNewPassword = () => {
        passwordGenerateMutation
            .mutateAsync()
            .then((r) => {
                reset({
                    //@ts-ignore
                    password: r?.data
                });
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    const generatePasswordFn = () => {
        seconds.forEach((num, index) => {
            setTimeout(() => {
                if (index === 5) {
                    getNewPassword();
                } else {
                    reset({
                        password: generatePassword(options(num))
                    });
                }

                setLoading(25 + index * 15);
            }, 80 * index);
        });
        setTimeout(() => {
            setLoading(0);
        }, 800);
    };
    const onSubmit = (data: {password: string}) => {
        if (userId) {
            editPasswordMutation
                .mutateAsync({data: {employee_id: userId, password: data.password}})
                .then((r) => {
                    openToast({
                        text: `Пароль изменен на ${newPassword}`,
                        type: TOAST_TYPE.SUCCESS
                    });
                })
                .catch((error) => {
                    openToast({
                        text: errorLayoutMessage(error),
                        type: TOAST_TYPE.ERROR
                    });
                });
        }
    };
    return (
        <Stack gap={{xs: 2, sm: 3}}>
            <Typography variant={'h5'}>Смена пароля</Typography>

            <Form form={form} onSubmit={onSubmit}>
                <Stack gap={2} alignItems={'flex-start'}>
                    <Stack direction={'row'} gap={0.5} alignItems={'flex-end'}>
                        <FormTextField
                            name={'password'}
                            disabled
                            placeholder={'············'}
                            label={'Новый пароль'}
                            autoComplete='off'
                            sx={{width: {md: 300}}}
                            customEnd={
                                <RotateIcon size={'small'} onClick={generatePasswordFn} sx={{mr: -0.5}}>
                                    <DiceIcon style={{width: '20px', height: '20px'}} />
                                </RotateIcon>
                            }
                        />
                        <IconButton
                            color={'primary'}
                            disabled={newPassword?.length < 8}
                            onClick={() => {
                                navigator.clipboard.writeText(newPassword);
                                openToast({
                                    text: 'Скопировано в буфер обмена',
                                    type: TOAST_TYPE.SUCCESS
                                });
                            }}>
                            <CopyIcon />
                        </IconButton>
                    </Stack>
                    <LinearProgress
                        variant='determinate'
                        value={loading}
                        sx={{width: {xs: '100%', md: 300}}}
                        color={'success'}
                    />
                    <LoadingButton
                        type={'submit'}
                        variant={'contained'}
                        loading={editPasswordMutation.isPending}
                        disabled={newPassword?.length !== 8 || !!loading}>
                        Сохранить
                    </LoadingButton>
                </Stack>
            </Form>
        </Stack>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            password: yup.string().required(ERROR_MESSAGE.REQUIRED)
        })
    )
};
