/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    GetApiBandaProfileEmployeesId200,
    GetApiBandaProfileEmployeesId401,
    GetApiBandaProfileEmployeesId403,
    GetApiBandaProfileEmployeesId422,
    GetApiBandaProfileEmployeesId500,
    GetApiBandaProfileInfoEmployeeLink401,
    GetApiBandaProfileInfoEmployeeLink403,
    GetApiBandaProfileInfoEmployeeLink422,
    GetApiBandaProfileInfoEmployeeLink500,
    GetApiBandaProfileInfoEmployeeLinkParams,
    GetApiBandaProfileSettingsShow401,
    GetApiBandaProfileSettingsShow403,
    GetApiBandaProfileSettingsShow422,
    GetApiBandaProfileSettingsShow500,
    PatchApiBandaProfileChangePassword401,
    PatchApiBandaProfileChangePassword403,
    PatchApiBandaProfileChangePassword422,
    PatchApiBandaProfileChangePassword500,
    PatchApiBandaProfileChangePasswordBody,
    PatchApiBandaProfileSettingsUpdate401,
    PatchApiBandaProfileSettingsUpdate403,
    PatchApiBandaProfileSettingsUpdate422,
    PatchApiBandaProfileSettingsUpdate500,
    PatchApiBandaProfileSettingsUpdateBody
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Передаем юлид сотрудника
 * @summary Профиль пользователя
 */
export const getApiBandaProfileEmployeesId = (employeesId: string, signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaProfileEmployeesId200>({
        url: `/api/banda/profile/${employeesId}`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaProfileEmployeesIdQueryKey = (employeesId: string) => {
    return [`/api/banda/profile/${employeesId}`] as const;
};

export const getGetApiBandaProfileEmployeesIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>,
    TError =
        | GetApiBandaProfileEmployeesId401
        | GetApiBandaProfileEmployeesId403
        | GetApiBandaProfileEmployeesId422
        | GetApiBandaProfileEmployeesId500
>(
    employeesId: string,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaProfileEmployeesIdQueryKey(employeesId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>> = ({signal}) =>
        getApiBandaProfileEmployeesId(employeesId, signal);

    return {queryKey, queryFn, enabled: !!employeesId, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaProfileEmployeesIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>
>;
export type GetApiBandaProfileEmployeesIdQueryError =
    | GetApiBandaProfileEmployeesId401
    | GetApiBandaProfileEmployeesId403
    | GetApiBandaProfileEmployeesId422
    | GetApiBandaProfileEmployeesId500;

/**
 * @summary Профиль пользователя
 */
export const useGetApiBandaProfileEmployeesId = <
    TData = Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>,
    TError =
        | GetApiBandaProfileEmployeesId401
        | GetApiBandaProfileEmployeesId403
        | GetApiBandaProfileEmployeesId422
        | GetApiBandaProfileEmployeesId500
>(
    employeesId: string,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaProfileEmployeesId>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaProfileEmployeesIdQueryOptions(employeesId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Смена пароля пользователем
 */
export const patchApiBandaProfileChangePassword = (
    patchApiBandaProfileChangePasswordBody: PatchApiBandaProfileChangePasswordBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/profile/changePassword`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaProfileChangePasswordBody
    });
};

export const getPatchApiBandaProfileChangePasswordMutationOptions = <
    TError =
        | PatchApiBandaProfileChangePassword401
        | PatchApiBandaProfileChangePassword403
        | PatchApiBandaProfileChangePassword422
        | PatchApiBandaProfileChangePassword500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaProfileChangePassword>>,
        TError,
        {data: PatchApiBandaProfileChangePasswordBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaProfileChangePassword>>,
    TError,
    {data: PatchApiBandaProfileChangePasswordBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaProfileChangePassword>>,
        {data: PatchApiBandaProfileChangePasswordBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaProfileChangePassword(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaProfileChangePasswordMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaProfileChangePassword>>
>;
export type PatchApiBandaProfileChangePasswordMutationBody = PatchApiBandaProfileChangePasswordBody;
export type PatchApiBandaProfileChangePasswordMutationError =
    | PatchApiBandaProfileChangePassword401
    | PatchApiBandaProfileChangePassword403
    | PatchApiBandaProfileChangePassword422
    | PatchApiBandaProfileChangePassword500;

/**
 * @summary Смена пароля пользователем
 */
export const usePatchApiBandaProfileChangePassword = <
    TError =
        | PatchApiBandaProfileChangePassword401
        | PatchApiBandaProfileChangePassword403
        | PatchApiBandaProfileChangePassword422
        | PatchApiBandaProfileChangePassword500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaProfileChangePassword>>,
        TError,
        {data: PatchApiBandaProfileChangePasswordBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaProfileChangePassword>>,
    TError,
    {data: PatchApiBandaProfileChangePasswordBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaProfileChangePasswordMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Рабочие профили сотрудника
 */
export const getApiBandaProfileInfoEmployeeLink = (
    params?: GetApiBandaProfileInfoEmployeeLinkParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({url: `/api/banda/profile/info/employeeLink`, method: 'GET', params, signal});
};

export const getGetApiBandaProfileInfoEmployeeLinkQueryKey = (params?: GetApiBandaProfileInfoEmployeeLinkParams) => {
    return [`/api/banda/profile/info/employeeLink`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaProfileInfoEmployeeLinkQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>,
    TError =
        | GetApiBandaProfileInfoEmployeeLink401
        | GetApiBandaProfileInfoEmployeeLink403
        | GetApiBandaProfileInfoEmployeeLink422
        | GetApiBandaProfileInfoEmployeeLink500
>(
    params?: GetApiBandaProfileInfoEmployeeLinkParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaProfileInfoEmployeeLinkQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>> = ({signal}) =>
        getApiBandaProfileInfoEmployeeLink(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaProfileInfoEmployeeLinkQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>
>;
export type GetApiBandaProfileInfoEmployeeLinkQueryError =
    | GetApiBandaProfileInfoEmployeeLink401
    | GetApiBandaProfileInfoEmployeeLink403
    | GetApiBandaProfileInfoEmployeeLink422
    | GetApiBandaProfileInfoEmployeeLink500;

/**
 * @summary Рабочие профили сотрудника
 */
export const useGetApiBandaProfileInfoEmployeeLink = <
    TData = Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>,
    TError =
        | GetApiBandaProfileInfoEmployeeLink401
        | GetApiBandaProfileInfoEmployeeLink403
        | GetApiBandaProfileInfoEmployeeLink422
        | GetApiBandaProfileInfoEmployeeLink500
>(
    params?: GetApiBandaProfileInfoEmployeeLinkParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaProfileInfoEmployeeLink>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaProfileInfoEmployeeLinkQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Текущие настройки пользователя
 */
export const getApiBandaProfileSettingsShow = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/profile/settings/show`, method: 'GET', signal});
};

export const getGetApiBandaProfileSettingsShowQueryKey = () => {
    return [`/api/banda/profile/settings/show`] as const;
};

export const getGetApiBandaProfileSettingsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>,
    TError =
        | GetApiBandaProfileSettingsShow401
        | GetApiBandaProfileSettingsShow403
        | GetApiBandaProfileSettingsShow422
        | GetApiBandaProfileSettingsShow500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaProfileSettingsShowQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>> = ({signal}) =>
        getApiBandaProfileSettingsShow(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaProfileSettingsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>
>;
export type GetApiBandaProfileSettingsShowQueryError =
    | GetApiBandaProfileSettingsShow401
    | GetApiBandaProfileSettingsShow403
    | GetApiBandaProfileSettingsShow422
    | GetApiBandaProfileSettingsShow500;

/**
 * @summary Текущие настройки пользователя
 */
export const useGetApiBandaProfileSettingsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>,
    TError =
        | GetApiBandaProfileSettingsShow401
        | GetApiBandaProfileSettingsShow403
        | GetApiBandaProfileSettingsShow422
        | GetApiBandaProfileSettingsShow500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaProfileSettingsShow>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaProfileSettingsShowQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Обновить настройку пользователя
 */
export const patchApiBandaProfileSettingsUpdate = (
    patchApiBandaProfileSettingsUpdateBody: PatchApiBandaProfileSettingsUpdateBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/profile/settings/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaProfileSettingsUpdateBody
    });
};

export const getPatchApiBandaProfileSettingsUpdateMutationOptions = <
    TError =
        | PatchApiBandaProfileSettingsUpdate401
        | PatchApiBandaProfileSettingsUpdate403
        | PatchApiBandaProfileSettingsUpdate422
        | PatchApiBandaProfileSettingsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaProfileSettingsUpdate>>,
        TError,
        {data: PatchApiBandaProfileSettingsUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaProfileSettingsUpdate>>,
    TError,
    {data: PatchApiBandaProfileSettingsUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaProfileSettingsUpdate>>,
        {data: PatchApiBandaProfileSettingsUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaProfileSettingsUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaProfileSettingsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaProfileSettingsUpdate>>
>;
export type PatchApiBandaProfileSettingsUpdateMutationBody = PatchApiBandaProfileSettingsUpdateBody;
export type PatchApiBandaProfileSettingsUpdateMutationError =
    | PatchApiBandaProfileSettingsUpdate401
    | PatchApiBandaProfileSettingsUpdate403
    | PatchApiBandaProfileSettingsUpdate422
    | PatchApiBandaProfileSettingsUpdate500;

/**
 * @summary Обновить настройку пользователя
 */
export const usePatchApiBandaProfileSettingsUpdate = <
    TError =
        | PatchApiBandaProfileSettingsUpdate401
        | PatchApiBandaProfileSettingsUpdate403
        | PatchApiBandaProfileSettingsUpdate422
        | PatchApiBandaProfileSettingsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaProfileSettingsUpdate>>,
        TError,
        {data: PatchApiBandaProfileSettingsUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaProfileSettingsUpdate>>,
    TError,
    {data: PatchApiBandaProfileSettingsUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaProfileSettingsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
