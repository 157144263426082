export const leftColumnWidth = 300;

export const COLORS_TIME_SLOT: {[key: string]: string} = {
    '01JJ46ZJ1Y7FZ6VRA684SQYYPJ': '#90cdf4', // Выходной (светло-голубой) - #a0e4cb светло-зеленый
    '01JJ46ZJ1Y7FZ6VRA684SQYYPK': '#68d391', // Явка (зеленый)
    '01JJ46ZJ1Y7FZ6VRA684SQYYPM': '#4299e1', // Оплачиваемый отпуск (синий)
    '01JJ46ZJ1Y7FZ6VRA684SQYYPN': '#fbb6ce', // Неоплачиваемый отпуск (розовый)
    '01JJ46ZJ1Y7FZ6VRA684SQYYPP': '#e53e3e', // Отсутствие без уважительной причины (красный)
    '01JJ46ZJ1Y7FZ6VRA684SQYYPQ': '#f6e05e', // Больничный (желтый)
    '01JJ46ZJ1Y7FZ6VRA684SQYYPR': '#ed8936', // Отпуск по уходу за ребенком (оранжевый)
    '01JJ46ZJ1Y7FZ6VRA684SQYYPS': '#9f7aea' // Отпуск по беременности (фиолетовый)
};
