import {FormAutocompleteScroll} from 'components/accessory/FormAutocompleteScroll';
import {getApiBandaSystemDirectoryPositions, getGetApiBandaSystemDirectoryPositionsQueryKey} from 'api/система';
import * as React from 'react';

export const PositionsInputs = (props: {disabled?: boolean; params?: object; [key: string]: any}) => {
    const {disabled, params, ...rest} = props;
    return (
        <FormAutocompleteScroll
            name={rest?.name || 'position_link_id'}
            queryKey={getGetApiBandaSystemDirectoryPositionsQueryKey}
            queryFn={getApiBandaSystemDirectoryPositions}
            settingsKey={{module: 'system', ...params}}
            placeholder={'Должность'}
            label={rest?.label}
            disabled={disabled}
            {...rest}
        />
    );
};
