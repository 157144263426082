import {Button, Stack} from '@mui/material';
import {ACCOUNT_TAB_ENUM} from 'utils/constants/menu';
import {useQsParams} from 'utils/hooks/useQsParams';
import {useEffect} from 'react';
import {transitionMixin} from 'utils/mixins';

export const PersonalInfoTabs = ({tabsArray}: {tabsArray: any[]}) => {
    const [params, , setParamsSmart] = useQsParams();
    const {tab} = params;
    useEffect(() => {
        if (!tab || isInvalidTab(tab)) {
            setParamsSmart({tab: ACCOUNT_TAB_ENUM.ACCOUNT});
        }
    }, [tab]);

    const onClick = (tab: string) => {
        setParamsSmart({tab});
    };
    return (
        <Stack gap={1} direction={'row'} overflow={'auto'}>
            {tabsArray.map((item, index) => (
                <Button
                    startIcon={item.icon}
                    variant={item.tab === tab ? 'contained' : 'text'}
                    key={index}
                    onClick={() => onClick(item.tab)}
                    disabled={item?.disabled}
                    sx={(theme) => ({
                        minWidth: 160,
                        ...transitionMixin(theme),
                        '&:not(:hover):not(:disabled)': {
                            color: item.tab !== tab ? theme.palette.text.heading : undefined
                        },
                        fontWeight: 500
                    })}>
                    {item.title}
                </Button>
            ))}
        </Stack>
    );
};
const isInvalidTab = (tab: string) => {
    return !Object.keys(ACCOUNT_TAB_ENUM).some((key) => ACCOUNT_TAB_ENUM[key as keyof typeof ACCOUNT_TAB_ENUM] === tab);
};
