/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    DeleteApiBandaNewsDestroy200,
    DeleteApiBandaNewsDestroy401,
    DeleteApiBandaNewsDestroy403,
    DeleteApiBandaNewsDestroy422,
    DeleteApiBandaNewsDestroy500,
    DeleteApiBandaNewsDestroyBody,
    GetApiBandaNews200,
    GetApiBandaNews401,
    GetApiBandaNews403,
    GetApiBandaNews422,
    GetApiBandaNews500,
    GetApiBandaNewsId200,
    GetApiBandaNewsId401,
    GetApiBandaNewsId403,
    GetApiBandaNewsId422,
    GetApiBandaNewsId500,
    GetApiBandaNewsLikesShow200,
    GetApiBandaNewsLikesShow401,
    GetApiBandaNewsLikesShow403,
    GetApiBandaNewsLikesShow422,
    GetApiBandaNewsLikesShow500,
    GetApiBandaNewsParams,
    GetApiBandaNewsViewsShow200,
    GetApiBandaNewsViewsShow401,
    GetApiBandaNewsViewsShow403,
    GetApiBandaNewsViewsShow422,
    GetApiBandaNewsViewsShow500,
    PostApiBandaNewsCreate200,
    PostApiBandaNewsCreate401,
    PostApiBandaNewsCreate403,
    PostApiBandaNewsCreate422,
    PostApiBandaNewsCreate500,
    PostApiBandaNewsCreateBody,
    PostApiBandaNewsUpdate200,
    PostApiBandaNewsUpdate401,
    PostApiBandaNewsUpdate403,
    PostApiBandaNewsUpdate422,
    PostApiBandaNewsUpdate500,
    PostApiBandaNewsUpdateBody,
    PutApiBandaNewsLikesCreate200,
    PutApiBandaNewsLikesCreate401,
    PutApiBandaNewsLikesCreate403,
    PutApiBandaNewsLikesCreate422,
    PutApiBandaNewsLikesCreate500,
    PutApiBandaNewsLikesCreateBody,
    PutApiBandaNewsViewsCreate200,
    PutApiBandaNewsViewsCreate401,
    PutApiBandaNewsViewsCreate403,
    PutApiBandaNewsViewsCreate422,
    PutApiBandaNewsViewsCreate500,
    PutApiBandaNewsViewsCreateBody
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Список новостей
 */
export const getApiBandaNews = (params?: GetApiBandaNewsParams, signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaNews200>({url: `/api/banda/news`, method: 'GET', params, signal});
};

export const getGetApiBandaNewsQueryKey = (params?: GetApiBandaNewsParams) => {
    return [`/api/banda/news`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaNewsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaNews>>,
    TError = GetApiBandaNews401 | GetApiBandaNews403 | GetApiBandaNews422 | GetApiBandaNews500
>(
    params?: GetApiBandaNewsParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNews>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaNewsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaNews>>> = ({signal}) =>
        getApiBandaNews(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaNews>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaNewsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaNews>>>;
export type GetApiBandaNewsQueryError =
    | GetApiBandaNews401
    | GetApiBandaNews403
    | GetApiBandaNews422
    | GetApiBandaNews500;

/**
 * @summary Список новостей
 */
export const useGetApiBandaNews = <
    TData = Awaited<ReturnType<typeof getApiBandaNews>>,
    TError = GetApiBandaNews401 | GetApiBandaNews403 | GetApiBandaNews422 | GetApiBandaNews500
>(
    params?: GetApiBandaNewsParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNews>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaNewsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Детали новости
 */
export const getApiBandaNewsId = (id: string, signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaNewsId200>({url: `/api/banda/news/${id}`, method: 'GET', signal});
};

export const getGetApiBandaNewsIdQueryKey = (id: string) => {
    return [`/api/banda/news/${id}`] as const;
};

export const getGetApiBandaNewsIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaNewsId>>,
    TError = GetApiBandaNewsId401 | GetApiBandaNewsId403 | GetApiBandaNewsId422 | GetApiBandaNewsId500
>(
    id: string,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNewsId>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaNewsIdQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaNewsId>>> = ({signal}) =>
        getApiBandaNewsId(id, signal);

    return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaNewsId>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaNewsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaNewsId>>>;
export type GetApiBandaNewsIdQueryError =
    | GetApiBandaNewsId401
    | GetApiBandaNewsId403
    | GetApiBandaNewsId422
    | GetApiBandaNewsId500;

/**
 * @summary Детали новости
 */
export const useGetApiBandaNewsId = <
    TData = Awaited<ReturnType<typeof getApiBandaNewsId>>,
    TError = GetApiBandaNewsId401 | GetApiBandaNewsId403 | GetApiBandaNewsId422 | GetApiBandaNewsId500
>(
    id: string,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNewsId>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaNewsIdQueryOptions(id, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создать новость
 */
export const postApiBandaNewsCreate = (postApiBandaNewsCreateBody: PostApiBandaNewsCreateBody) => {
    const formData = new FormData();
    formData.append('title', postApiBandaNewsCreateBody.title);
    if (postApiBandaNewsCreateBody.thumbnail !== undefined && postApiBandaNewsCreateBody.thumbnail !== null) {
        formData.append('thumbnail', postApiBandaNewsCreateBody.thumbnail);
    }
    formData.append('is_must_read', postApiBandaNewsCreateBody.is_must_read.toString());
    formData.append('intro_text', postApiBandaNewsCreateBody.intro_text);
    formData.append('full_text', postApiBandaNewsCreateBody.full_text);

    return axiosInstance<PostApiBandaNewsCreate200>({
        url: `/api/banda/news/create`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
    });
};

export const getPostApiBandaNewsCreateMutationOptions = <
    TError =
        | PostApiBandaNewsCreate401
        | PostApiBandaNewsCreate403
        | PostApiBandaNewsCreate422
        | PostApiBandaNewsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaNewsCreate>>,
        TError,
        {data: PostApiBandaNewsCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaNewsCreate>>,
    TError,
    {data: PostApiBandaNewsCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaNewsCreate>>,
        {data: PostApiBandaNewsCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaNewsCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaNewsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiBandaNewsCreate>>>;
export type PostApiBandaNewsCreateMutationBody = PostApiBandaNewsCreateBody;
export type PostApiBandaNewsCreateMutationError =
    | PostApiBandaNewsCreate401
    | PostApiBandaNewsCreate403
    | PostApiBandaNewsCreate422
    | PostApiBandaNewsCreate500;

/**
 * @summary Создать новость
 */
export const usePostApiBandaNewsCreate = <
    TError =
        | PostApiBandaNewsCreate401
        | PostApiBandaNewsCreate403
        | PostApiBandaNewsCreate422
        | PostApiBandaNewsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaNewsCreate>>,
        TError,
        {data: PostApiBandaNewsCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaNewsCreate>>,
    TError,
    {data: PostApiBandaNewsCreateBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaNewsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактировать новость
 */
export const postApiBandaNewsUpdate = (postApiBandaNewsUpdateBody: PostApiBandaNewsUpdateBody) => {
    const formData = new FormData();
    formData.append('id', postApiBandaNewsUpdateBody.id);
    formData.append('title', postApiBandaNewsUpdateBody.title);
    if (postApiBandaNewsUpdateBody.thumbnail !== undefined && postApiBandaNewsUpdateBody.thumbnail !== null) {
        formData.append('thumbnail', postApiBandaNewsUpdateBody.thumbnail);
    }
    formData.append('is_must_read', postApiBandaNewsUpdateBody.is_must_read.toString());
    formData.append('intro_text', postApiBandaNewsUpdateBody.intro_text);
    formData.append('full_text', postApiBandaNewsUpdateBody.full_text);

    return axiosInstance<PostApiBandaNewsUpdate200>({
        url: `/api/banda/news/update`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
    });
};

export const getPostApiBandaNewsUpdateMutationOptions = <
    TError =
        | PostApiBandaNewsUpdate401
        | PostApiBandaNewsUpdate403
        | PostApiBandaNewsUpdate422
        | PostApiBandaNewsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaNewsUpdate>>,
        TError,
        {data: PostApiBandaNewsUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaNewsUpdate>>,
    TError,
    {data: PostApiBandaNewsUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaNewsUpdate>>,
        {data: PostApiBandaNewsUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaNewsUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaNewsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiBandaNewsUpdate>>>;
export type PostApiBandaNewsUpdateMutationBody = PostApiBandaNewsUpdateBody;
export type PostApiBandaNewsUpdateMutationError =
    | PostApiBandaNewsUpdate401
    | PostApiBandaNewsUpdate403
    | PostApiBandaNewsUpdate422
    | PostApiBandaNewsUpdate500;

/**
 * @summary Редактировать новость
 */
export const usePostApiBandaNewsUpdate = <
    TError =
        | PostApiBandaNewsUpdate401
        | PostApiBandaNewsUpdate403
        | PostApiBandaNewsUpdate422
        | PostApiBandaNewsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaNewsUpdate>>,
        TError,
        {data: PostApiBandaNewsUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaNewsUpdate>>,
    TError,
    {data: PostApiBandaNewsUpdateBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaNewsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Удалить новость
 */
export const deleteApiBandaNewsDestroy = (deleteApiBandaNewsDestroyBody: DeleteApiBandaNewsDestroyBody) => {
    return axiosInstance<DeleteApiBandaNewsDestroy200>({
        url: `/api/banda/news/destroy`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        data: deleteApiBandaNewsDestroyBody
    });
};

export const getDeleteApiBandaNewsDestroyMutationOptions = <
    TError =
        | DeleteApiBandaNewsDestroy401
        | DeleteApiBandaNewsDestroy403
        | DeleteApiBandaNewsDestroy422
        | DeleteApiBandaNewsDestroy500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteApiBandaNewsDestroy>>,
        TError,
        {data: DeleteApiBandaNewsDestroyBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiBandaNewsDestroy>>,
    TError,
    {data: DeleteApiBandaNewsDestroyBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteApiBandaNewsDestroy>>,
        {data: DeleteApiBandaNewsDestroyBody}
    > = (props) => {
        const {data} = props ?? {};

        return deleteApiBandaNewsDestroy(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type DeleteApiBandaNewsDestroyMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteApiBandaNewsDestroy>>
>;
export type DeleteApiBandaNewsDestroyMutationBody = DeleteApiBandaNewsDestroyBody;
export type DeleteApiBandaNewsDestroyMutationError =
    | DeleteApiBandaNewsDestroy401
    | DeleteApiBandaNewsDestroy403
    | DeleteApiBandaNewsDestroy422
    | DeleteApiBandaNewsDestroy500;

/**
 * @summary Удалить новость
 */
export const useDeleteApiBandaNewsDestroy = <
    TError =
        | DeleteApiBandaNewsDestroy401
        | DeleteApiBandaNewsDestroy403
        | DeleteApiBandaNewsDestroy422
        | DeleteApiBandaNewsDestroy500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteApiBandaNewsDestroy>>,
        TError,
        {data: DeleteApiBandaNewsDestroyBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteApiBandaNewsDestroy>>,
    TError,
    {data: DeleteApiBandaNewsDestroyBody},
    TContext
> => {
    const mutationOptions = getDeleteApiBandaNewsDestroyMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список лайков новостей
 */
export const getApiBandaNewsLikesShow = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaNewsLikesShow200>({url: `/api/banda/news/likes/show`, method: 'GET', signal});
};

export const getGetApiBandaNewsLikesShowQueryKey = () => {
    return [`/api/banda/news/likes/show`] as const;
};

export const getGetApiBandaNewsLikesShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>,
    TError =
        | GetApiBandaNewsLikesShow401
        | GetApiBandaNewsLikesShow403
        | GetApiBandaNewsLikesShow422
        | GetApiBandaNewsLikesShow500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaNewsLikesShowQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>> = ({signal}) =>
        getApiBandaNewsLikesShow(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaNewsLikesShowQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>>;
export type GetApiBandaNewsLikesShowQueryError =
    | GetApiBandaNewsLikesShow401
    | GetApiBandaNewsLikesShow403
    | GetApiBandaNewsLikesShow422
    | GetApiBandaNewsLikesShow500;

/**
 * @summary Список лайков новостей
 */
export const useGetApiBandaNewsLikesShow = <
    TData = Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>,
    TError =
        | GetApiBandaNewsLikesShow401
        | GetApiBandaNewsLikesShow403
        | GetApiBandaNewsLikesShow422
        | GetApiBandaNewsLikesShow500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNewsLikesShow>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaNewsLikesShowQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Поставить лайк новости
 */
export const putApiBandaNewsLikesCreate = (putApiBandaNewsLikesCreateBody: PutApiBandaNewsLikesCreateBody) => {
    return axiosInstance<PutApiBandaNewsLikesCreate200>({
        url: `/api/banda/news/likes/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaNewsLikesCreateBody
    });
};

export const getPutApiBandaNewsLikesCreateMutationOptions = <
    TError =
        | PutApiBandaNewsLikesCreate401
        | PutApiBandaNewsLikesCreate403
        | PutApiBandaNewsLikesCreate422
        | PutApiBandaNewsLikesCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaNewsLikesCreate>>,
        TError,
        {data: PutApiBandaNewsLikesCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaNewsLikesCreate>>,
    TError,
    {data: PutApiBandaNewsLikesCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaNewsLikesCreate>>,
        {data: PutApiBandaNewsLikesCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaNewsLikesCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaNewsLikesCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaNewsLikesCreate>>
>;
export type PutApiBandaNewsLikesCreateMutationBody = PutApiBandaNewsLikesCreateBody;
export type PutApiBandaNewsLikesCreateMutationError =
    | PutApiBandaNewsLikesCreate401
    | PutApiBandaNewsLikesCreate403
    | PutApiBandaNewsLikesCreate422
    | PutApiBandaNewsLikesCreate500;

/**
 * @summary Поставить лайк новости
 */
export const usePutApiBandaNewsLikesCreate = <
    TError =
        | PutApiBandaNewsLikesCreate401
        | PutApiBandaNewsLikesCreate403
        | PutApiBandaNewsLikesCreate422
        | PutApiBandaNewsLikesCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaNewsLikesCreate>>,
        TError,
        {data: PutApiBandaNewsLikesCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaNewsLikesCreate>>,
    TError,
    {data: PutApiBandaNewsLikesCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaNewsLikesCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список просмотров новостей
 */
export const getApiBandaNewsViewsShow = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaNewsViewsShow200>({url: `/api/banda/news/views/show`, method: 'GET', signal});
};

export const getGetApiBandaNewsViewsShowQueryKey = () => {
    return [`/api/banda/news/views/show`] as const;
};

export const getGetApiBandaNewsViewsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>,
    TError =
        | GetApiBandaNewsViewsShow401
        | GetApiBandaNewsViewsShow403
        | GetApiBandaNewsViewsShow422
        | GetApiBandaNewsViewsShow500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaNewsViewsShowQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>> = ({signal}) =>
        getApiBandaNewsViewsShow(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaNewsViewsShowQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>>;
export type GetApiBandaNewsViewsShowQueryError =
    | GetApiBandaNewsViewsShow401
    | GetApiBandaNewsViewsShow403
    | GetApiBandaNewsViewsShow422
    | GetApiBandaNewsViewsShow500;

/**
 * @summary Список просмотров новостей
 */
export const useGetApiBandaNewsViewsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>,
    TError =
        | GetApiBandaNewsViewsShow401
        | GetApiBandaNewsViewsShow403
        | GetApiBandaNewsViewsShow422
        | GetApiBandaNewsViewsShow500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaNewsViewsShow>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaNewsViewsShowQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * by Михаил & ft. MR.Fil
 * @summary Создать просмотр новости
 */
export const putApiBandaNewsViewsCreate = (putApiBandaNewsViewsCreateBody: PutApiBandaNewsViewsCreateBody) => {
    return axiosInstance<PutApiBandaNewsViewsCreate200>({
        url: `/api/banda/news/views/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaNewsViewsCreateBody
    });
};

export const getPutApiBandaNewsViewsCreateMutationOptions = <
    TError =
        | PutApiBandaNewsViewsCreate401
        | PutApiBandaNewsViewsCreate403
        | PutApiBandaNewsViewsCreate422
        | PutApiBandaNewsViewsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaNewsViewsCreate>>,
        TError,
        {data: PutApiBandaNewsViewsCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaNewsViewsCreate>>,
    TError,
    {data: PutApiBandaNewsViewsCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaNewsViewsCreate>>,
        {data: PutApiBandaNewsViewsCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaNewsViewsCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaNewsViewsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaNewsViewsCreate>>
>;
export type PutApiBandaNewsViewsCreateMutationBody = PutApiBandaNewsViewsCreateBody;
export type PutApiBandaNewsViewsCreateMutationError =
    | PutApiBandaNewsViewsCreate401
    | PutApiBandaNewsViewsCreate403
    | PutApiBandaNewsViewsCreate422
    | PutApiBandaNewsViewsCreate500;

/**
 * @summary Создать просмотр новости
 */
export const usePutApiBandaNewsViewsCreate = <
    TError =
        | PutApiBandaNewsViewsCreate401
        | PutApiBandaNewsViewsCreate403
        | PutApiBandaNewsViewsCreate422
        | PutApiBandaNewsViewsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaNewsViewsCreate>>,
        TError,
        {data: PutApiBandaNewsViewsCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaNewsViewsCreate>>,
    TError,
    {data: PutApiBandaNewsViewsCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaNewsViewsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
