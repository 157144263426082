import {Paper} from 'components/accessory/Paper';
import {Button, Divider, Stack, Typography} from '@mui/material';
import {useGetApiBandaSystemEmployeesEmployeeLinkShow} from 'api/система';
import {TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import * as React from 'react';
import dayjs from 'dayjs';
import {PencilIcon, PlusIcon} from 'assets/icons';
import {handleOpenAddWorkProfileModal} from './AddWorkProfileModal';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';

export const AccountInfo = ({userInfo}: {userInfo?: any}) => {
    return (
        <>
            <WorkProfiles userInfo={userInfo} />
        </>
    );
};

const WorkProfiles = ({userInfo}: {userInfo?: any}) => {
    const {data, isLoading} = useGetApiBandaSystemEmployeesEmployeeLinkShow(
        {
            'filter[employee_id]': userInfo?.id
        },
        {
            query: {enabled: !!userInfo}
        }
    );
    console.log(data?.data);
    return (
        <Paper sx={{p: {xs: 2, sm: 3}}}>
            <Stack sx={{mb: 3}} direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h5'}>Рабочие профили</Typography>
                <Button
                    variant={'contained'}
                    startIcon={<PlusIcon />}
                    onClick={() => handleOpenAddWorkProfileModal(userInfo)}>
                    Добавить профиль
                </Button>
            </Stack>
            <Divider sx={{mx: {xs: -2, sm: -3}}} />
            <TableLayout
                columns={STRUCTURE(userInfo)}
                //@ts-ignore
                rows={data?.data || []}
                isLoading={isLoading}
                //@ts-ignore
                pagination={data?.pagination}
                negativeMargin
            />
        </Paper>
    );
};
const STRUCTURE = (data: any) => [
    {
        title: 'ФИО',
        value: (item: any) =>
            item.employee?.last_name + ' ' + item.employee?.first_name + ' ' + item.employee?.patronymic,
        sx: {width: '25%'}
    },
    {
        title: 'Подразделение',
        value: (item: any) => item?.department?.name,
        sx: {width: '20%'}
    },
    {
        title: 'Должность',
        value: (item: any) => item?.position_link?.name,
        sx: {width: '20%'}
    },
    {
        title: 'Дата найма',
        value: (item: any) => (dayjs(item?.hiring_date).isValid() ? dayjs(item?.hiring_date).format('DD.MM.YYYY') : ''),
        sx: {width: '15%'}
    },
    {
        title: 'Статус',
        value: (item: any) => (item?.is_active ? 'Активен' : 'Не активен'),
        sx: {width: '15%'}
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(data, item)} />,
        align: 'right',
        sx: {width: '5%'}
    }
];
const ITEM_ACTIONS = (data: any, item: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => handleOpenAddWorkProfileModal(data, item)
    }
];
