import {CircularProgress, Stack, SxProps} from '@mui/material';
import React from 'react';
import {Theme} from '@mui/material/styles';

export const Loader = ({sx}: {sx?: SxProps<Theme>}) => {
    return (
        <Stack sx={{height: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', ...sx}}>
            <CircularProgress color='primary' />
        </Stack>
    );
};
