import {useForm} from 'react-hook-form';
import {Paper} from 'components/accessory/Paper';
import {Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import React from 'react';
import PermissionListTable from './components/PermissionListTable';
import {StandardTableHeader} from 'components/accessory/StandardTableHeader';
import {AddButton} from 'components/accessory/AddButton';
import {handleOpenPermissionAccessModal} from './components/AddPermissionAccessModal';

export const PermissionsAccess = () => {
    const form = useForm({});
    return (
        <Paper sx={{p: {xs: 2, sm: 3}, height: '100%'}}>
            <Stack gap={2}>
                <StandardTableHeader
                    form={form}
                    title={'Список доступов'}
                    buttons={
                        <AddButton
                            onClick={() => handleOpenPermissionAccessModal('Создание доступа')}
                            title={'Создать доступ'}
                        />
                    }
                />
                <Form form={form}>
                    <PermissionListTable form={form} />
                </Form>
            </Stack>
        </Paper>
    );
};
