/* eslint-disable camelcase */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    IconButton,
    Paper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import {useEffect, useState} from 'react';

import {PlusIcon} from 'assets/icons';
import {FieldValues, useFieldArray, UseFormReturn} from 'react-hook-form';
import {Theme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {DepartmentsInput} from 'components/custom-inputs/DepartmentsInput';
import {PositionsInputs} from 'components/custom-inputs/PositionsInputs';

export const AccordionSelectPosition = ({
    params,
    form
}: {
    params?: {[key: string]: any};
    form: UseFormReturn<FieldValues, any, undefined>;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {control, watch, setValue, reset} = form;
    const watchDepartment = watch('departmentInput');
    const typeInput = 'position' + 'Input';
    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control,
        name: 'position',
        keyName: '_id'
    });
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        if (!watchDepartment) {
            reset({...watch(), positionInput: undefined});
        }
        //@ts-ignore
    }, [watchDepartment?.id]);
    return (
        <Stack>
            <Paper variant={'outlined'}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                        sx={{
                            pr: 0,
                            '& .MuiAccordionSummary-content': {m: '12px 0 !important'},
                            '& .MuiAccordionSummary-expandIconWrapper': {py: 1, px: 2},
                            '&.Mui-expanded': {minHeight: 'auto !important'}
                        }}
                        onClick={() => setExpanded(!expanded)}>
                        <Stack direction={'row'} gap={2} alignItems={'center'} sx={{minHeight: 38, width: '100%'}}>
                            <Typography variant={'h5'}>{'Должности'}</Typography>
                            <Chip label={fields.length} />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 2, pt: 0}}>
                        <Stack onClick={(e) => e.stopPropagation()} gap={2}>
                            <DepartmentsInput fullWidth name={'departmentInput'} />
                            <Stack direction={'row'} gap={2} width={'100%'}>
                                <PositionsInputs
                                    fullWidth
                                    name={'positionInput'}
                                    disabled={!watchDepartment}
                                    params={{'filter[search_department_id]': watchDepartment?.id}}
                                />
                                <Button
                                    variant={'contained'}
                                    startIcon={isMd ? <PlusIcon /> : undefined}
                                    sx={{
                                        width: isMd ? 125 : 'auto',
                                        flexShrink: 0,
                                        minWidth: {xs: 38, md: 64},
                                        p: isMd ? undefined : '6px'
                                    }}
                                    onClick={() => {
                                        if (watch(typeInput)) {
                                            //@ts-ignore
                                            if (
                                                fields.findIndex((f: any) => f?.position?.id === watch(typeInput).id) >
                                                -1
                                            ) {
                                                console.log('Уже добавлено');
                                            } else {
                                                append({...watch('departmentInput'), position: watch(typeInput)});
                                            }
                                        }
                                        setValue('departmentInput', undefined);
                                    }}>
                                    {isMd ? 'Добавить' : <PlusIcon />}
                                </Button>
                            </Stack>
                        </Stack>

                        {!!fields?.length && (
                            <Stack sx={{p: 2, border: (theme) => '1px solid ' + theme.palette.divider, mt: 2, gap: 1}}>
                                {fields.map((field: any) => (
                                    <Stack
                                        key={field._id}
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}>
                                        <Stack>
                                            <Typography variant={'h6'} sx={{lineHeight: '16px'}}>
                                                {field?.name}
                                            </Typography>
                                            <Typography variant={'body2'}>{field?.position?.name}</Typography>
                                        </Stack>
                                        <IconButton sx={{mr: -1}} onClick={() => remove(field._id)}>
                                            <CloseIcon width={20} height={20} sx={{width: '20px', height: '20px'}} />
                                        </IconButton>
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </Stack>
    );
};
