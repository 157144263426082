import {Button, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';
import {useDeleteApiBandaUploadAvatarRemove} from 'api/файлы';
import {deleteAvatarMessage} from 'utils/constants/messages';
import {useAuthContext} from 'context/authContext';

export const handleOpenDeleteAvatarModal = (title?: string, item?: any) => {
    openModal({
        title: title,
        body: <DeleteAvatarModal />,
        maxWidth: 'sm'
    });
};

export const DeleteAvatarModal = () => {
    const deleteAvatarMutation = useDeleteApiBandaUploadAvatarRemove();
    const {refetchProfile} = useAuthContext();
    const onSubmit = () => {
        deleteAvatarMutation
            .mutateAsync()
            .then((r) => {
                openToast({
                    text: deleteAvatarMessage,
                    type: TOAST_TYPE.SUCCESS
                });
                closeModal();
                refetchProfile();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Stack gap={2}>
            <Typography>Вы уверены что хотите удалить своё фото?</Typography>
            <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                <LoadingButton variant={'contained'} onClick={onSubmit} loading={deleteAvatarMutation.isPending}>
                    Удалить
                </LoadingButton>
                <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                    Отменить
                </Button>
            </Stack>
        </Stack>
    );
};
