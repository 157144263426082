/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemDepartmentsShow} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {FormTextField} from 'components/form/FormTextField';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {CheckIcon, CloseIcon, PencilIcon} from 'assets/icons';
import {FormSelect} from 'components/form/FormSelect';
import {departmentsEditOpenModal} from './CreateUpdateModal';
import {departmentsDeactivateOpenModal} from './DeactivateModal';

export default function DepartmentsTable({form}: {form: any}) {
    const {reset} = form;
    const [params] = useQsParams();
    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            name: params.name,
            source_id: params.source_id,
            search_parent: params.search_parent,
            is_active: params.is_active
        });
    }, []);
    const {data: departments, isLoading} = useGetApiBandaSystemDepartmentsShow(fetchDepartments(params));
    return (
        <TableLayout
            columns={STRUCTURE}
            filterRow
            //@ts-ignore
            rows={departments?.data || []}
            isLoading={isLoading}
            //@ts-ignore
            pagination={departments?.pagination}
            negativeMargin
        />
    );
}
export const fetchDepartments = (params: {[p: string]: any}) => ({
    page: params.page || 1,
    per_page: params.per_page || '25',
    sort: params.sort,
    'filter[name]': params.name || '',
    'filter[source_id]': params.source_id || '',
    'filter[search_parent]': params.search_parent || '',
    is_active: params.is_active ? Boolean(+params.is_active) : undefined
});
const ACTIVE_OPTIONS = [
    {
        id: 1,
        name: 'Активен',
        value: '1'
    },
    {
        id: 2,
        name: 'Не активен',
        value: '0'
    }
];

const STRUCTURE = [
    {
        title: 'Название',
        filter: (
            <FormTextField
                name={'name'}
                size={'small'}
                isSearch
                placeholder={'Название'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item.name || '',
        sx: {width: '30%'}
    },
    {
        title: <SortButton title={'Родительское подразделение'} sortName={'parent_id'} />,
        filter: (
            <FormTextField
                name={'search_parent'}
                size={'small'}
                isSearch
                placeholder={'Поиск по родительскому подразделению'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.parent?.name,
        sx: {width: '25%'}
    },
    {
        title: <SortButton title={'Источник'} sortName={'source_id'} />,
        filter: (
            <FormTextField
                name={'source_id'}
                size={'small'}
                isSearch
                placeholder={'Поиск по источнику'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.source_id,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Активность'} sortName={'is_active'} />,
        filter: (
            <FormSelect
                name={'is_active'}
                options={ACTIVE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'Активность'}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (item?.is_active ? 'Активен' : 'Не активен'),
        sx: {width: '20%'}
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item)} disabled={item.source_id !== 'local'} />,
        align: 'right',
        sx: {width: '5%'}
    }
];
const ITEM_ACTIONS = (item: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        disabled: !item.can_update,
        onClick: () => departmentsEditOpenModal('Редактирование подразделения', item)
    },
    {
        title: item.is_active ? 'Деактивировать' : 'Активировать',
        icon: item.is_active ? <CloseIcon /> : <CheckIcon />,
        disabled: !item.can_update,
        onClick: () =>
            departmentsDeactivateOpenModal(
                item.is_active ? 'Деактивация подразделения' : 'Активация подразделения',
                item
            )
    }
];
