import {alpha, Stack, SxProps, Theme, Typography} from '@mui/material';
import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {MainAvatar} from 'components/accessory/Avatar';

export const UserLoginItem = ({
    user,
    onClick,
    sx,
    dot
}: {
    user: any;
    onClick?: () => void;
    sx?: SxProps<Theme>;
    dot?: string | boolean;
}) => {
    const {
        palette: {mode: thisTheme}
    } = useTheme();
    // @ts-ignore
    return (
        <Stack
            component={'li'}
            key={user.id}
            gap={1}
            sx={{
                justifyContent: 'center',
                minHeight: '60px',
                borderRadius: '6px',
                py: 1,
                px: 2,
                background: (theme) => alpha(theme.palette.grey[500], thisTheme === 'light' ? 0.06 : 0.5),
                '&:hover': {
                    background: (theme) => alpha(theme.palette.grey[500], thisTheme === 'light' ? 0.06 : 0.5)
                },
                ...sx
            }}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                gap={1}
                onClick={onClick}
                sx={{
                    cursor: 'pointer'
                }}>
                <MainAvatar user={user} dot={dot} />
                <Stack alignItems={'flex-start'}>
                    <Typography variant={'h6'}>{user?.first_name + ' ' + user?.last_name}</Typography>
                    <Typography variant={'body2'} sx={{color: (theme) => theme.palette.text.disabled}}>
                        {user?.positions?.map((position: {name: any}) => position.name)?.[0]}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
