import {FormAutocompleteScroll} from 'components/accessory/FormAutocompleteScroll';
import {getApiBandaSystemDirectoryDepartments, getGetApiBandaSystemDirectoryDepartmentsQueryKey} from 'api/система';
import * as React from 'react';

export const DepartmentsInput = (props: {disabled?: boolean; params?: object; [key: string]: any}) => {
    const {disabled, params, ...rest} = props;
    return (
        <FormAutocompleteScroll
            name={rest?.name || 'department_id'}
            queryKey={getGetApiBandaSystemDirectoryDepartmentsQueryKey}
            queryFn={getApiBandaSystemDirectoryDepartments}
            settingsKey={{module: 'system', ...params}}
            placeholder={'Подразделение'}
            label={rest?.label}
            disabled={disabled}
            {...rest}
        />
    );
};
