import {useDeleteApiBandaUploadAvatarRemove, usePostApiBandaUploadAvatar} from 'api/файлы';
import {useAuthContext} from 'context/authContext';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';
import {uploadAvatarMessage} from 'utils/constants/messages';
import {Box, Stack} from '@mui/material';
import emptyAvatar from 'assets/images/empty-avatar.png';
import * as React from 'react';

export const EditAvatar = ({avatarUrl}: {avatarUrl?: string}) => {
    const uploadAvatarMutation = usePostApiBandaUploadAvatar();
    const deleteAvatarMutation = useDeleteApiBandaUploadAvatarRemove();
    const {refetchProfile, me} = useAuthContext();
    const setPhoto = (photo: any) => {
        const formData = new FormData();
        const thenPromise = (text: string) => {
            openToast({
                text,
                type: TOAST_TYPE.SUCCESS
            });
            refetchProfile();
        };
        const catchPromise = (error: any) => {
            openToast({
                text: errorLayoutMessage(error),
                type: TOAST_TYPE.ERROR
            });
        };
        if (photo) {
            formData.append('file', photo);
            uploadAvatarMutation
                .mutateAsync({
                    data: {file: photo}
                })
                .then(() => {
                    thenPromise(uploadAvatarMessage);
                })
                .catch((error) => {
                    catchPromise(error);
                });
        } else {
            deleteAvatarMutation
                .mutateAsync()
                .then(() => {
                    thenPromise(uploadAvatarMessage);
                })
                .catch((error) => {
                    catchPromise(error);
                });
        }
    };
    return (
        <>
            <Stack
                // onClick={() =>
                //     openModal({
                //         title: 'Редактирование фото',
                //         body: <UploadAvatarModal {...{setPhoto, me}} />,
                //         sx: ModalWidth(422)
                //     })
                // }
                sx={{
                    p: 1,
                    borderRadius: '10px',
                    backgroundColor: (theme) => theme.palette.background.paper,
                    cursor: 'pointer',
                    width: 'fit-content'
                }}>
                <Box
                    width={120}
                    height={120}
                    src={avatarUrl || emptyAvatar}
                    component={'img'}
                    sx={{
                        borderRadius: '6px',
                        objectFit: 'contain',
                        maxWidth: 120,
                        border: (theme) => '1px solid ' + theme.palette.divider
                    }}
                />
            </Stack>
        </>
    );
};
