/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemLogsShowAuthLogs} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {FormTextField} from 'components/form/FormTextField';
import {FormDatePicker} from 'components/form/FormDatePicker';
import dayjs from 'dayjs';
import {handleOpenModal} from './ShowLogModal';
import Button from '@mui/material/Button';

export default function Table({form}: {form: any}) {
    const {reset} = form;
    const [params] = useQsParams();
    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            name: params.name,
            source_id: params.source_id,
            search_parent: params.search_parent,
            is_active: params.is_active
        });
    }, []);

    const {data: logs, isLoading} = useGetApiBandaSystemLogsShowAuthLogs(fetchLogs(params), fetchLogs(params));
    return (
        <TableLayout
            columns={STRUCTURE}
            filterRow
            //@ts-ignore
            rows={logs?.data || []}
            isLoading={isLoading}
            //@ts-ignore
            pagination={logs?.pagination}
            negativeMargin
        />
    );
}
export const fetchLogs =  (params: {[p: string]: any}) => ({
    page: params.page || 1,
    per_page: params.per_page || '25',
    sort: params.sort,
    'filter[user_id]': '',
    'filter[source]': params.source || "",
    'filter[message]': '',
    'filter[level]': 'info',
    'filter[tag]': '',
    'filter[created_at]': params.created_at || "",
});


const STRUCTURE = [
    {
        title: 'Сотрудник',
        filter: (
            <FormTextField
                name={'name'}
                size={'small'}
                isSearch
                placeholder={'Название'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => <Button variant="text"   onClick={() => {
            handleOpenModal('Просмотр лога', item)
        }}>{item.userId || ''}</Button>,
        sx: {width: '10%'},

    },
    {
        title: 'URL',
        filter: (
            <FormTextField
                name={'source'}
                size={'small'}
                isSearch
                placeholder={'URL'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item.source || '',
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Дата'} sortName={'created_at'} />,
        filter: <FormDatePicker name={'created_at'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) =>
            dayjs(item?.created_at).isValid() ? dayjs(item?.created_at).format('DD MMMM YYYY h:m:s') : '',
        sx: {width: '10%'}
    },
    {
        title: 'HTTP CODE',

        value: (item: any) => item.httpCode || '',
        sx: {width: '10%'}
    },

];

