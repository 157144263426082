import {ROUTES} from 'utils/constants/index';
import {
    AuditsIcon,
    CheckboxIcon,
    ChecklistIcon,
    CloudIcon,
    ContactsIcon,
    DocsIcon,
    GiftIcon,
    HelpIcon,
    IdeaIcon,
    ListDetailsIcon,
    LockIcon,
    LogsIcon,
    NewsIcon,
    NotificationsIcon,
    PyrusIcon,
    TableIcon,
    TestsIcon,
    UserCheckIcon,
    UserShieldIcon,
    UsersIcon,
    WikiIcon,
    YandexIcon
} from 'assets/icons';

export const MENU_ROUTES = {
    NEWS: {
        title: 'Новости',
        href: ROUTES.NEWS,
        icon: <NewsIcon />
    },
    TABLE_MENU: {
        title: 'Рабочее время',
        icon: <TableIcon />
    },
    TABLE: {
        title: 'Табель',
        href: ROUTES.TABLE
    },
    SUBSTITUTIONS: {
        title: 'Подмены',
        href: ROUTES.SUBSTITUTIONS
    },
    VACATIONS: {
        title: 'Отпуска',
        href: ROUTES.VACATIONS
    },
    COLLEAGUE: {
        title: 'Коллеги',
        icon: <ContactsIcon />
    },
    CONTACTS: {
        title: 'Контакты',
        href: ROUTES.CONTACTS
        // icon: <ContactsIcon />
    },

    BIRTHDAYS: {
        title: 'Дни рождения',
        href: ROUTES.BIRTHDAYS
        // icon: <BirthdaysIcon />
    },
    STRUCTURE: {
        title: 'Оргструктура',
        href: ROUTES.STRUCTURE
        // icon: <SitemapIcon />
    },
    WIKI: {
        title: 'База знаний',
        href: ROUTES.WIKI,
        icon: <WikiIcon />
    },
    REQUESTS: {
        title: 'Заявки',
        href: ROUTES.REQUESTS,
        icon: <ListDetailsIcon />
    },
    POLLS: {
        title: 'Опросы',
        href: ROUTES.POLLS,
        icon: <CheckboxIcon />
    },
    TESTS: {
        title: 'Тесты',
        href: ROUTES.TESTS,
        icon: <TestsIcon />
    },
    CHECK_LISTS: {
        title: 'Чек-листы',
        href: ROUTES.CHECK_LISTS,
        icon: <ChecklistIcon />
    },
    AUDITS: {
        title: 'Аудиты',
        href: ROUTES.AUDITS,
        icon: <AuditsIcon />
    },
    YANDEX: {
        title: 'Яндекс 360',
        icon: <YandexIcon />
    },
    PYRUS: {
        title: 'Pyrus',
        icon: <PyrusIcon />
    },
    STORAGE: {
        title: 'Хранилище',
        icon: <CloudIcon />
    },
    THANK: {
        title: 'Спасибо',
        icon: <GiftIcon />
    },
    USERS: {
        title: 'Сотруднки',
        icon: <UsersIcon />
    },
    USERS_ACCOUNTS: {
        title: 'Аккаунты',
        href: ROUTES.USERS_ACCOUNTS
    },
    USERS_GROUPS: {
        title: 'Группы пользователей',
        href: ROUTES.USERS_GROUPS
    },
    USERS_SERVICE_ACCOUNTS: {
        title: 'Сервисные аккаунты',
        href: ROUTES.USERS_SERVICE_ACCOUNTS
    },
    USERS_PROFILES: {
        title: 'Рабочие профили',
        href: ROUTES.USERS_PROFILES
    },
    USERS_SCHEDULERS: {
        title: 'Штатные расписания',
        href: ROUTES.USERS_SCHEDULERS
    },
    USERS_POSITIONS: {
        title: 'Должности',
        href: ROUTES.USERS_POSITIONS
    },
    USERS_DIVISIONS: {
        title: 'Подразделения',
        href: ROUTES.USERS_DIVISIONS
    },
    PERMISSIONS: {
        title: 'Права и роли',
        icon: <UserShieldIcon />
    },
    PERMISSIONS_ACCESS: {
        title: 'Список доступов',
        href: ROUTES.PERMISSIONS_ACCESS
    },
    PERMISSIONS_LIST: {
        title: 'Список прав',
        href: ROUTES.PERMISSIONS_LIST
    },
    PERMISSIONS_GROUPS: {
        title: 'Список групп',
        href: ROUTES.PERMISSIONS_GROUPS
    },
    PERMISSIONS_ROLES: {
        title: 'Список ролей',
        href: ROUTES.PERMISSIONS_ROLES
    },
    LOGS: {
        title: 'Логи',
        icon: <LogsIcon />
    },
    LOGS_AUTHORIZATIONS: {
        title: 'Авторизации',
        href: ROUTES.LOGS_AUTH
    },
    HELP: {
        title: 'Помощь',
        href: ROUTES.HELP,
        icon: <HelpIcon />
    },
    DOCUMENTATION: {
        title: 'Документация',
        href: ROUTES.DOCUMENTATION,
        icon: <DocsIcon />
    },
    IDEA: {
        title: 'Идея',
        href: ROUTES.IDEA,
        icon: <IdeaIcon />
    }
};

export const FOOTER_LINKS = [
    {title: 'Цех85', href: '#'},
    {title: 'Контакты', href: '#'},
    {
        title: 'База знаний',
        href: '#'
    },
    {title: 'Поддержка', href: '#'}
];
export const ACCOUNT_TAB_ENUM = {
    ACCOUNT: 'account',
    SECURITY: 'security',
    NOTIFICATIONS: 'notifications'
};
export const ACCOUNT_INFO_TABS = [
    {title: 'Аккаунт', icon: <UserCheckIcon />, tab: ACCOUNT_TAB_ENUM.ACCOUNT},
    {
        title: 'Безопасность',
        icon: <LockIcon />,
        tab: ACCOUNT_TAB_ENUM.SECURITY
    },
    {title: 'Уведомления', icon: <NotificationsIcon />, tab: ACCOUNT_TAB_ENUM.NOTIFICATIONS, disabled: true}
];
