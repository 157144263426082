/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemEmployeesPositionShow} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {FormTextField} from 'components/form/FormTextField';
import {FormDatePicker} from 'components/form/FormDatePicker';
import dayjs from 'dayjs';
import {FormSelect} from 'components/form/FormSelect';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {CheckIcon, CloseIcon, PencilIcon} from 'assets/icons';
import {ACTIVE_OPTIONS} from 'utils/constants';
import {positionEditOpenModal} from './CreateUpdateModal';
import {positionsDeactivateOpenModal} from './DeactivateModal';

export const fetchPositions = (params: {[p: string]: any}) => ({
    page: params.page || 1,
    per_page: params.per_page || '25',
    sort: params.sort,
    'filter[search_parent]': params.name || '',
    'filter[source_id]': params.source_id || '',
    'filter[created_at]': dayjs(params.created_at).isValid() ? params.created_at : '' || '',
    is_active: params.is_active ? Boolean(+params.is_active) : undefined
});

export default function PositionsTable({form}: {form: any}) {
    const {reset} = form;
    const [params] = useQsParams();
    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            name: params.name,
            created_at: params.created_at,
            source_id: params.source_id,
            is_active: params.is_active
        });
    }, []);

    const {data: departments, isLoading} = useGetApiBandaSystemEmployeesPositionShow(fetchPositions(params));
    return (
        <TableLayout
            columns={STRUCTURE}
            //@ts-ignore
            rows={departments?.data || []}
            isLoading={isLoading}
            filterRow
            //@ts-ignore
            pagination={departments?.pagination}
            negativeMargin
        />
    );
}

const STRUCTURE = [
    {
        title: 'Название',
        filter: (
            <FormTextField
                name={'name'}
                size={'small'}
                isSearch
                placeholder={'Название'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item.name || '',
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Дата создания'} sortName={'created_at'} />,
        filter: <FormDatePicker name={'created_at'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) => (dayjs(item?.created_at).isValid() ? dayjs(item?.created_at).format('DD MMMM YYYY') : ''),
        sx: {width: '15%'}
    },
    {
        title: <SortButton title={'Источник'} sortName={'source_id'} />,
        filter: (
            <FormTextField
                name={'source_id'}
                size={'small'}
                isSearch
                placeholder={'Поиск по источнику'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.source_id,
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Активность'} sortName={'is_active'} />,
        filter: (
            <FormSelect
                name={'is_active'}
                options={ACTIVE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'Активность'}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (item?.is_active ? 'Активен' : 'Не активен'),
        sx: {width: '15%'}
    },
    {
        title: 'Вложенные должности',
        value: (item: any) => item?.position_link?.map((position: any) => position.name)?.join('\n'),
        sx: {width: '15%'}
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item)} />,
        align: 'right',
        sx: {width: '5%'}
    }
];
const ITEM_ACTIONS = (item: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => positionEditOpenModal('Редактирование должности', item)
    },
    {
        title: item.is_active ? 'Деактивировать' : 'Активировать',
        icon: item.is_active ? <CloseIcon /> : <CheckIcon />,
        disabled: true,
        onClick: () =>
            positionsDeactivateOpenModal(item.is_active ? 'Деактивация должности' : 'Активация должности', item)
    }
];
