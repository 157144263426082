/* eslint-disable camelcase */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    IconButton,
    Paper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import {useState} from 'react';
import {PlusIcon} from 'assets/icons';
import {FieldValues, useFieldArray, UseFormReturn} from 'react-hook-form';
import {Theme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {EmployeesLinkInput} from 'components/custom-inputs/EmployeesLinkInput';
import {DepartmentsInput} from 'components/custom-inputs/DepartmentsInput';

const qParams = {page: 1, per_page: 25};
export const AccordionSelect = ({
    type,
    params,
    form
}: {
    type: 'employees' | 'departments';
    params?: {[key: string]: any};
    form: UseFormReturn<FieldValues, any, undefined>;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {control, watch, setValue} = form;
    const variant = QUERIES[type];
    const typeInput = type + 'Input';
    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control,
        name: type,
        keyName: '_id'
    });
    const [expanded, setExpanded] = useState(false);
    return (
        <Stack>
            <Paper variant={'outlined'}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                        sx={{
                            pr: 0,
                            '& .MuiAccordionSummary-content': {m: '12px 0 !important'},
                            '& .MuiAccordionSummary-expandIconWrapper': {py: 1, px: 2},
                            '&.Mui-expanded': {minHeight: 'auto !important'}
                        }}
                        onClick={() => setExpanded(!expanded)}>
                        <Stack direction={'row'} gap={2} alignItems={'center'} sx={{minHeight: 38, width: '100%'}}>
                            <Typography variant={'h5'}>{variant.title}</Typography>
                            <Chip label={fields.length} />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 2, pt: 0}}>
                        <Stack direction={'row'} gap={2} width={'100%'} onClick={(e) => e.stopPropagation()}>
                            {/*@ts-ignore*/}
                            {variant?.input?.()}

                            <Button
                                variant={'contained'}
                                startIcon={isMd ? <PlusIcon /> : undefined}
                                sx={{
                                    width: isMd ? 125 : 'auto',
                                    flexShrink: 0,
                                    minWidth: {xs: 38, md: 64},
                                    p: isMd ? undefined : '6px'
                                }}
                                onClick={() => {
                                    if (watch(typeInput)) {
                                        //@ts-ignore
                                        if (fields.findIndex((f) => f?.id === watch(typeInput).id) > -1) {
                                            console.log('Уже добавлено');
                                        } else {
                                            append(watch(typeInput));
                                        }
                                        setValue(typeInput, undefined);
                                    }
                                }}>
                                {isMd ? 'Добавить' : <PlusIcon />}
                            </Button>
                        </Stack>
                        {!!fields?.length && (
                            <Stack
                                sx={{
                                    p: 2,
                                    border: (theme) => '1px solid ' + theme.palette.divider,
                                    mt: 2,
                                    gap: 1,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                }}>
                                {fields.map((field: any) => (
                                    <Stack
                                        key={field._id}
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}>
                                        {variant?.item ? (
                                            variant?.item(field)
                                        ) : (
                                            <Typography>{variant.info(field)}</Typography>
                                        )}

                                        <IconButton sx={{mr: -1}} onClick={() => remove(field._id)}>
                                            <CloseIcon width={20} height={20} sx={{width: '20px', height: '20px'}} />
                                        </IconButton>
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </Stack>
    );
};
const EmployeesListItem = ({item}: {item: any}) => {
    return (
        <Stack>
            <Typography variant={'h6'} sx={{lineHeight: '16px'}}>
                {item?.employee?.last_name + ' ' + item?.employee?.first_name}
            </Typography>
            <Typography variant={'body2'}>{item?.position_sl?.name}</Typography>
        </Stack>
    );
};
const QUERIES = {
    employees: {
        title: 'Рабочие профили',
        input: function () {
            return (
                <EmployeesLinkInput
                    fullWidth={true}
                    name={'employeesInput'}
                    optionItem={this.item}
                    optionLabel={this.info}
                />
            );
        },
        info: (field: any) =>
            field?.employee?.first_name + ' ' + field?.employee?.last_name + ' (' + field?.position_sl?.name + ')',
        item: (field: any) => <EmployeesListItem item={field} />
    },
    departments: {
        title: 'Подразделения',
        input: function () {
            return (
                <DepartmentsInput
                    fullWidth={true}
                    name={'departmentsInput'}
                    // optionItem={this.item}
                    // optionLabel={this.info}
                />
            );
        },
        info: (field: any) => field?.name,
        item: undefined
        // info: (field: any) =>
        //     field?.employee?.first_name + ' ' + field?.employee?.last_name + ' (' + field?.position_sl?.name + ')',
        // item: (field: any) => <EmployeesListItem item={field} />
    }
};
