import {Stack} from '@mui/material';
import {CalendarRow} from './CalendarRow';
import React, {useEffect, useState} from 'react';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Loader} from 'components/accessory/Loader';
import {GetApiBandaTableShow200DataItemsItem} from 'api/bandaApi.schemas';

export const CalendarRowsList = ({
    userList,
    isLoading
}: {
    userList?: GetApiBandaTableShow200DataItemsItem[];
    isLoading?: boolean;
}) => {
    const [params, setSearchParams] = useQsParams();
    const department = params?.department;

    const [onDrag, setOnDrag] = useState(false);
    const [firstElement, setFirstElement] = useState(null);
    const [lastElement, setLastElement] = useState(null);

    useEffect(() => {
        const handleMouseUp = (event: MouseEvent) => {
            setOnDrag(false);
        };
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [setOnDrag]);

    return (
        <Stack gap={0.25}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {userList?.map((user) => (
                        <CalendarRow
                            //@ts-ignore
                            key={user.id}
                            user={user}
                            {...{
                                onDrag,
                                setOnDrag,
                                firstElement,
                                setFirstElement,
                                lastElement,
                                setLastElement
                            }}
                        />
                    ))}
                </>
            )}
        </Stack>
    );
};
