/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {
    getGetApiBandaSystemEmployeesEmployeeLinkShowQueryKey,
    usePatchApiBandaSystemEmployeesEmployeeLinkUpdate,
    usePutApiBandaSystemEmployeesEmployeeLinkCreate
} from 'api/система';
import {useQueryClient} from '@tanstack/react-query';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';
import {FormSwitch} from 'components/form/FormSwitch';
import {FormDatePicker} from 'components/form/FormDatePicker';
import {DepartmentsInput} from 'components/custom-inputs/DepartmentsInput';
import {PositionsInputs} from 'components/custom-inputs/PositionsInputs';
import {PositionsSlInputs} from 'components/custom-inputs/PositionsSlInputs';
import dayjs from 'dayjs';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';

export const handleOpenAddWorkProfileModal = (userInfo: any, item?: any) => {
    openModal({
        title: `${item ? 'Редатирование' : 'Добавление'} рабочего профиля`,
        body: <AddWorkProfileModal userInfo={userInfo} workProfile={item} />,
        maxWidth: 'sm'
    });
};
export const AddWorkProfileModal = ({userInfo, workProfile}: {userInfo: any; workProfile?: any}) => {
    const [firstLoad, setFirstLoad] = useState(false);
    const form = useForm(schema);
    const {reset, watch} = form;
    const queryClient = useQueryClient();
    const watchDepartment = watch('department_id');
    const watchPosition = watch('position_link_id');

    const addWorkProfile = usePutApiBandaSystemEmployeesEmployeeLinkCreate();
    const editWorkProfile = usePatchApiBandaSystemEmployeesEmployeeLinkUpdate();
    const mutation = workProfile ? editWorkProfile : addWorkProfile;

    const refetchWorkProfile = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemEmployeesEmployeeLinkShowQueryKey({
                'filter[employee_id]': userInfo?.id
            })
        });
    };
    useEffect(() => {
        if (workProfile) {
            reset({
                department_id: workProfile?.department,
                position_link_id: workProfile?.position_link,
                position_sl_id: workProfile?.position_sl,
                hiring_date: workProfile?.hiring_date,
                is_active: workProfile?.is_active
            });
            setTimeout(() => {
                setFirstLoad(true);
            }, 0);
        }
    }, [workProfile]);

    useEffect(() => {
        if (firstLoad) {
            reset({...watch(), position_link_id: undefined});
        }
        //@ts-ignore
    }, [watchDepartment?.id]);
    useEffect(() => {
        if (firstLoad) {
            reset({...watch(), position_sl_id: undefined});
        }
        //@ts-ignore
    }, [watchPosition?.id]);

    const onSubmit = (data: any) => {
        mutation
            .mutateAsync({
                //@ts-ignore
                data: {
                    id: workProfile ? workProfile.id : undefined,
                    employee_id: userInfo?.id,
                    hiring_date: dayjs(data.hiring_date).format('YYYY-MM-DD'),
                    department_id: data.department_id?.id,
                    position_link_id: data.position_link_id?.id,
                    position_sl_id: data.position_sl_id?.id,
                    is_active: data.is_active
                }
            })
            .then((r) => {
                openToast({
                    text: 'Рабочий профиль успешно создан',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchWorkProfile();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <DepartmentsInput label={'Подразделение'} />
                <PositionsInputs
                    disabled={!watchDepartment}
                    label={'Должность'}
                    // @ts-ignore
                    params={{'filter[search_department_id]': watchDepartment?.id}}
                />
                <PositionsSlInputs
                    disabled={!watchPosition}
                    params={{
                        // @ts-ignore
                        'filter[search_position_id]': watchPosition?.id,
                        // @ts-ignore
                        'filter[search_department_id]': watchDepartment?.id
                    }}
                />
                <FormDatePicker name={'hiring_date'} sx={{width: '100%'}} label={'Дата найма'} />
                <FormSwitch name={'is_active'} label={'Активировать рабочий профиль'} />
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={false}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            hiring_date: yup.string().required(ERROR_MESSAGE.REQUIRED),
            department_id: yup.object().typeError(ERROR_MESSAGE.REQUIRED).required(ERROR_MESSAGE.REQUIRED),
            position_link_id: yup.object().typeError(ERROR_MESSAGE.REQUIRED).required(ERROR_MESSAGE.REQUIRED),
            position_sl_id: yup.object().typeError(ERROR_MESSAGE.REQUIRED).required(ERROR_MESSAGE.REQUIRED),
            is_active: yup.boolean()
        })
    )
};
