import {Button, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {getGetApiBandaSystemDepartmentsShowQueryKey, usePatchApiBandaSystemDepartmentsUpdate} from 'api/система';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {fetchPositions} from './PositionsTable';
import {errorLayoutMessage} from 'utils/functions';

export const positionsDeactivateOpenModal = (title?: string, item?: any) => {
    openModal({
        title: title,
        body: <DeactivateModal row={item} />,
        maxWidth: 'sm'
    });
};

export const DeactivateModal = ({row}: any) => {
    const editDepartmentMutation = usePatchApiBandaSystemDepartmentsUpdate();
    const [params] = useQsParams();
    const queryClient = useQueryClient();
    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemDepartmentsShowQueryKey(fetchPositions(params))
        });
    };
    const onSubmit = () => {
        editDepartmentMutation
            .mutateAsync({
                data: {
                    id: row.id,
                    // eslint-disable-next-line camelcase
                    is_active: row.is_active ? false : true,
                    name: row.name,
                    parentId: ''
                }
            })
            .then((r) => {
                openToast({
                    text: row.is_active ? 'Должность активирована' : 'Должность деактивирована',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Stack gap={2}>
            <Typography>
                Вы уверены что хотите {row.is_active ? 'деактивировать' : 'активировать'} подразделение {row.name}?
            </Typography>
            <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                <LoadingButton variant={'contained'} onClick={onSubmit} loading={editDepartmentMutation.isPending}>
                    Сохранить
                </LoadingButton>
                <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                    Отменить
                </Button>
            </Stack>
        </Stack>
    );
};
