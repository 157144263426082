/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {MutationFunction, UseMutationOptions, UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import type {
    PostApiBandaSsoCheckSession200,
    PostApiBandaSsoCheckSession401,
    PostApiBandaSsoCheckSession403,
    PostApiBandaSsoCheckSession422,
    PostApiBandaSsoCheckSession500,
    PostApiBandaSsoCheckSessionBody,
    PostApiBandaSsoLoginAuthRequest200,
    PostApiBandaSsoLoginAuthRequest401,
    PostApiBandaSsoLoginAuthRequest403,
    PostApiBandaSsoLoginAuthRequest422,
    PostApiBandaSsoLoginAuthRequest500,
    PostApiBandaSsoLoginAuthRequestBody,
    PostApiBandaSsoTokenAuthRequest200,
    PostApiBandaSsoTokenAuthRequest401,
    PostApiBandaSsoTokenAuthRequest403,
    PostApiBandaSsoTokenAuthRequest422,
    PostApiBandaSsoTokenAuthRequest500,
    PostApiBandaSsoTokenAuthRequestBody
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Авторизация через форму
 */
export const postApiBandaSsoLoginAuthRequest = (
    postApiBandaSsoLoginAuthRequestBody: PostApiBandaSsoLoginAuthRequestBody
) => {
    return axiosInstance<PostApiBandaSsoLoginAuthRequest200>({
        url: `/api/banda/sso/loginAuthRequest`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiBandaSsoLoginAuthRequestBody
    });
};

export const getPostApiBandaSsoLoginAuthRequestMutationOptions = <
    TError =
        | PostApiBandaSsoLoginAuthRequest401
        | PostApiBandaSsoLoginAuthRequest403
        | PostApiBandaSsoLoginAuthRequest422
        | PostApiBandaSsoLoginAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSsoLoginAuthRequest>>,
        TError,
        {data: PostApiBandaSsoLoginAuthRequestBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaSsoLoginAuthRequest>>,
    TError,
    {data: PostApiBandaSsoLoginAuthRequestBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaSsoLoginAuthRequest>>,
        {data: PostApiBandaSsoLoginAuthRequestBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaSsoLoginAuthRequest(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaSsoLoginAuthRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaSsoLoginAuthRequest>>
>;
export type PostApiBandaSsoLoginAuthRequestMutationBody = PostApiBandaSsoLoginAuthRequestBody;
export type PostApiBandaSsoLoginAuthRequestMutationError =
    | PostApiBandaSsoLoginAuthRequest401
    | PostApiBandaSsoLoginAuthRequest403
    | PostApiBandaSsoLoginAuthRequest422
    | PostApiBandaSsoLoginAuthRequest500;

/**
 * @summary Авторизация через форму
 */
export const usePostApiBandaSsoLoginAuthRequest = <
    TError =
        | PostApiBandaSsoLoginAuthRequest401
        | PostApiBandaSsoLoginAuthRequest403
        | PostApiBandaSsoLoginAuthRequest422
        | PostApiBandaSsoLoginAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSsoLoginAuthRequest>>,
        TError,
        {data: PostApiBandaSsoLoginAuthRequestBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaSsoLoginAuthRequest>>,
    TError,
    {data: PostApiBandaSsoLoginAuthRequestBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaSsoLoginAuthRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Проверка текущей сессии
 */
export const postApiBandaSsoCheckSession = (postApiBandaSsoCheckSessionBody: PostApiBandaSsoCheckSessionBody) => {
    return axiosInstance<PostApiBandaSsoCheckSession200>({
        url: `/api/banda/sso/checkSession`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiBandaSsoCheckSessionBody
    });
};

export const getPostApiBandaSsoCheckSessionMutationOptions = <
    TError =
        | PostApiBandaSsoCheckSession401
        | PostApiBandaSsoCheckSession403
        | PostApiBandaSsoCheckSession422
        | PostApiBandaSsoCheckSession500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSsoCheckSession>>,
        TError,
        {data: PostApiBandaSsoCheckSessionBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaSsoCheckSession>>,
    TError,
    {data: PostApiBandaSsoCheckSessionBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaSsoCheckSession>>,
        {data: PostApiBandaSsoCheckSessionBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaSsoCheckSession(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaSsoCheckSessionMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaSsoCheckSession>>
>;
export type PostApiBandaSsoCheckSessionMutationBody = PostApiBandaSsoCheckSessionBody;
export type PostApiBandaSsoCheckSessionMutationError =
    | PostApiBandaSsoCheckSession401
    | PostApiBandaSsoCheckSession403
    | PostApiBandaSsoCheckSession422
    | PostApiBandaSsoCheckSession500;

/**
 * @summary Проверка текущей сессии
 */
export const usePostApiBandaSsoCheckSession = <
    TError =
        | PostApiBandaSsoCheckSession401
        | PostApiBandaSsoCheckSession403
        | PostApiBandaSsoCheckSession422
        | PostApiBandaSsoCheckSession500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSsoCheckSession>>,
        TError,
        {data: PostApiBandaSsoCheckSessionBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaSsoCheckSession>>,
    TError,
    {data: PostApiBandaSsoCheckSessionBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaSsoCheckSessionMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Авторизация по токену пользователя
 */
export const postApiBandaSsoTokenAuthRequest = (
    postApiBandaSsoTokenAuthRequestBody: PostApiBandaSsoTokenAuthRequestBody
) => {
    return axiosInstance<PostApiBandaSsoTokenAuthRequest200>({
        url: `/api/banda/sso/tokenAuthRequest`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiBandaSsoTokenAuthRequestBody
    });
};

export const getPostApiBandaSsoTokenAuthRequestMutationOptions = <
    TError =
        | PostApiBandaSsoTokenAuthRequest401
        | PostApiBandaSsoTokenAuthRequest403
        | PostApiBandaSsoTokenAuthRequest422
        | PostApiBandaSsoTokenAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSsoTokenAuthRequest>>,
        TError,
        {data: PostApiBandaSsoTokenAuthRequestBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaSsoTokenAuthRequest>>,
    TError,
    {data: PostApiBandaSsoTokenAuthRequestBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaSsoTokenAuthRequest>>,
        {data: PostApiBandaSsoTokenAuthRequestBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaSsoTokenAuthRequest(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaSsoTokenAuthRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaSsoTokenAuthRequest>>
>;
export type PostApiBandaSsoTokenAuthRequestMutationBody = PostApiBandaSsoTokenAuthRequestBody;
export type PostApiBandaSsoTokenAuthRequestMutationError =
    | PostApiBandaSsoTokenAuthRequest401
    | PostApiBandaSsoTokenAuthRequest403
    | PostApiBandaSsoTokenAuthRequest422
    | PostApiBandaSsoTokenAuthRequest500;

/**
 * @summary Авторизация по токену пользователя
 */
export const usePostApiBandaSsoTokenAuthRequest = <
    TError =
        | PostApiBandaSsoTokenAuthRequest401
        | PostApiBandaSsoTokenAuthRequest403
        | PostApiBandaSsoTokenAuthRequest422
        | PostApiBandaSsoTokenAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSsoTokenAuthRequest>>,
        TError,
        {data: PostApiBandaSsoTokenAuthRequestBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaSsoTokenAuthRequest>>,
    TError,
    {data: PostApiBandaSsoTokenAuthRequestBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaSsoTokenAuthRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
