import {Paper as MuiPaper, PaperProps} from '@mui/material';

export const Paper = (props: PaperProps) => {
    // const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    // return <MuiPaper variant={isMd ? 'elevation' : 'outlined'} {...props} />;
    return (
        <MuiPaper
            {...props}
            sx={{
                // boxShadow: shadow,
                ...props.sx
            }}
        />
    );
};
