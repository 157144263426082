import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import FormRadioGroup from 'components/form/FormRadioGroup';
import {Chip, Stack, Typography} from '@mui/material';
import {isObject} from 'utils/functions';
import {Loader} from 'components/accessory/Loader';
import {UseFormSetValue} from 'react-hook-form';
import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';

export const TransferRole = ({
    form,
    permissions,
    module,
    isLoading,
    allPermissions = [],
    permissionsGroups
}: {
    form: any;
    permissions: any[];
    module?: string;
    isLoading?: boolean;
    allPermissions?: any[];
    permissionsGroups?: any[];
}) => {
    const {watch, setValue} = form;

    const checkedThisModule = watch(`permissions.${module}`) || [];
    const handleToggle = (value: any) => () => {
        const currentIndex = checkedThisModule.findIndex((item: any) => item?.id === value.id);
        const newChecked = [...checkedThisModule];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setValue(`permissions.${module}`, newChecked);
    };
    const customList = (items: readonly number[]) => (
        <Paper sx={{height: 300, overflow: 'auto'}}>
            <List dense component='div' role='list'>
                {items.map((item: any) => {
                    const labelId = `item-${item.slug}`;
                    const options = Object.keys(item?.options)?.map((key) => ({
                        id: key,
                        value: key,
                        label: item.options[key]
                    }));
                    return (
                        <ListItemButton
                            key={item.id}
                            role='listitem'
                            onClick={handleToggle(item)}
                            disabled={!module}
                            disableRipple
                            sx={{gap: 1, flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Stack sx={{flexDirection: 'row'}}>
                                <ListItemIcon sx={{minWidth: 'auto'}}>
                                    <Checkbox
                                        checked={
                                            checkedThisModule?.findIndex((elB: any) => elB.slug === item.slug) > -1
                                        }
                                        tabIndex={-1}
                                        sx={{padding: 0, mr: 1}}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={item.name} />
                            </Stack>
                            {item?.options && isObject(item?.options) && options && (
                                <FormRadioGroup
                                    defaultValue={options?.[0]}
                                    options={options}
                                    sx={{ml: 2}}
                                    name={`slugs.${item.slug}`}
                                />
                            )}
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column', gap: 1.5, order: {xs: 1, sm: 0}}}>
                <Typography variant={'h6'}>Выбранные права</Typography>
                <Paper variant={'outlined'} sx={{flexGrow: 1}}>
                    <Stack gap={1} sx={{height: 352, overflow: 'auto'}} p={1}>
                        {allPermissions?.map((permis: any) => (
                            <React.Fragment key={permis.key}>
                                {permis?.value?.length > 0 ? (
                                    <Stack key={permis.key} gap={0.5}>
                                        <Typography variant={'h6'}>{permis.name}</Typography>
                                        <Stack direction={'row'} gap={0.5} flexWrap={'wrap'}>
                                            {permis.value.map((item: any) => (
                                                <Chip
                                                    key={item?.item?.id}
                                                    label={
                                                        item?.item?.name +
                                                        (item.mainOption ? ' (' + item.mainOption + ')' : '')
                                                    }
                                                    onDelete={() => {
                                                        removeObjectByIdForm(
                                                            setValue,
                                                            watch,
                                                            permis.key,
                                                            item?.item?.id
                                                        );
                                                    }}
                                                />
                                            ))}
                                        </Stack>
                                    </Stack>
                                ) : (
                                    <></>
                                )}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column', gap: 1.5}}>
                <Typography variant={'h6'}>Модуль</Typography>
                <FormAutocompleteAsyncPaginate
                    name={'module'}
                    optionText={'name'}
                    optionValue={'name'}
                    placeholder={'Модуль прав'}
                    //@ts-ignore
                    options={permissionsGroups || []}
                />
                <Paper variant={'outlined'}>
                    {isLoading ? <Loader sx={{height: '300px'}} /> : customList(permissions)}
                </Paper>
            </Grid>
        </Grid>
    );
};

interface Identifiable {
    id: string | number;

    [key: string]: any;
}

function removeObjectByIdForm<T extends Identifiable>(
    setValue: UseFormSetValue<any>,
    watch: any,
    formKey: string,
    idToRemove: string | number
): void {
    const prevState = watch().permissions?.[formKey];
    if (!prevState) {
        return;
    }
    setValue(
        'permissions.' + formKey,
        prevState.filter((obj: any) => obj.id !== idToRemove)
    );
}
