/* eslint-disable camelcase */
import {IMask} from 'react-imask';

export const drawerWidth = 260;

export enum ROUTES {
    BASE = '/',

    AUTH = '/login',
    SSO_AUTH = '/login/sso',

    PROFILE = '/user',
    PROFILE_SETTINGS = '/settings',

    NEWS = '/news',
    NEWS_EDITOR = '/news/editor',

    TABLE = '/table',
    SUBSTITUTIONS = '/substitutions',
    VACATIONS = '/vacations',

    CONTACTS = '/contacts',
    BIRTHDAYS = '/birthdays',
    STRUCTURE = 'structure',

    REQUESTS = '/tasks',
    POLLS = '/polls',
    TESTS = '/tests',
    CHECK_LISTS = '/check-lists',
    AUDITS = '/audits',

    SYSTEM = '/system',
    USERS = '/system/users',
    USERS_ACCOUNTS = '/system/users/accounts',
    USERS_GROUPS = '/system/users/groups',
    USERS_SERVICE_ACCOUNTS = '/system/users/service-accounts',
    USERS_PROFILES = '/system/users/profiles',
    USERS_SCHEDULERS = '/system/users/schedulers',
    USERS_POSITIONS = '/system/users/positions',
    USERS_DIVISIONS = '/system/users/departments',

    PERMISSIONS = '/system/permissions',
    PERMISSIONS_ACCESS = '/system/permissions/access',
    PERMISSIONS_LIST = '/system/permissions/list',
    PERMISSIONS_GROUPS = '/system/permissions/groups',
    PERMISSIONS_ROLES = '/system/permissions/roles',

    LOGS = '/system/logs',
    LOGS_AUTH = '/system/logs/auth',
    LOGS_READ = '/system/logs/read',
    LOGS_WRITE = '/system/logs/write',
    LOGS_INTEGRATIONS = '/system/logs/integrations',
    LOGS_ERRORS = '/system/logs/errors',

    WIKI = '/wiki',
    HELP = '/wiki/help',
    DOCUMENTATION = '/wiki/documentation',
    IDEA = '/idea',
    ERROR = 'error',
    ERROR_403 = '/error-403',
    ERROR_404 = '/error-404'
}

export const ROUTES_ENUM = Object.fromEntries(Object.keys(ROUTES).map((key) => [key, key]));

export enum ERROR_MESSAGE {
    REQUIRED = 'Обязательное поле',
    REQUIRED_EMPTY = ' ',
    EMAIL = 'Неверный формат e-mail',
    INVALID_CREDENTIALS = 'Неверная почта или пароль',
    USER_BLOCKED = 'Пользователь заблокирован. Обратитесь к администратору системы'
}

export const MASKS = {
    NAME: /[ЁёА-яa-zA-Z\s-]$/,
    PHONE: {
        mask: '+{7} (000) 000-00-00',
        prepare: function (appended: any, masked: any) {
            let value = appended;
            if (value.startsWith('8') && masked.value === '') {
                value = value.replace('8', '7');
                return value;
            }
            return appended;
        }
    },
    EMAIL: /^\S*@?\S*$/,
    TIME: {
        mask: 'hh:mm',
        lazy: false,
        autofix: true,
        blocks: {
            hh: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 23, maxLength: 1},
            mm: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 59, maxLength: 1}
        }
    }
};

export const ERROR_PAGES_INFO = {
    403: {
        code: 403,
        title: 'Доступ запрещен ⚠️',
        description: 'К сожалению, у вас нет доступа к данному разделу'
    },
    404: {
        code: 404,
        title: 'Страница не найдена ⚠️',
        description: 'К сожалению, запрашиваемая вами страница не найдена'
    }
};
export const ACTIVE_OPTIONS = [
    {
        id: 1,
        name: 'Активен',
        value: '1'
    },
    {
        id: 2,
        name: 'Не активен',
        value: '0'
    }
];
