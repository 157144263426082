/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemEmployeesEmployeeLinkShow} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import dayjs from 'dayjs';
import {useQsParams} from 'utils/hooks/useQsParams';
import {FormTextField} from 'components/form/FormTextField';
import {FormDatePicker} from 'components/form/FormDatePicker';
import {FormSelect} from 'components/form/FormSelect';
import {Avatar, Stack, Typography} from '@mui/material';
import {scrollBodyToTop, stringAvatar} from 'utils/functions';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {PencilIcon, UserIcon} from 'assets/icons';
import {ROUTES} from 'utils/constants';
import {ACCOUNT_TAB_ENUM} from 'utils/constants/menu';
import {useNavigate} from 'react-router-dom';

const fetchWorkProfiles = (params: any) => ({
    page: params.page || 1,
    per_page: params.per_page || '25',
    sort: params.sort,
    'filter[search_employee]': params.full_name,
    'filter[source_id]': params.source_id,
    'filter[hiring_date]': params.hiring_date,
    'filter[search_employee_birth_date]': dayjs(params.date_of_birth).isValid() ? params.date_of_birth : '',
    'filter[search_department]': params.department,
    'filter[search_position]': params.position,
    is_active: params.is_active ? Boolean(+params.is_active) : undefined
});
export default function Table({form}: {form: any}) {
    const {reset} = form;
    const [params] = useQsParams();
    const navigate = useNavigate();
    useEffect(() => {
        reset({
            page: params.page || 1,
            per_page: params.per_page || 25,
            is_active: params.is_active,
            sort: params.sort,

            full_name: params.full_name,
            date_of_birth: params.date_of_birth,
            hiring_date: params.hiring_date,
            source_id: params.source_id,
            department: params.department,
            position: params.position,

            status: params.status
        });
    }, []);
    const {data: users, isLoading, refetch} = useGetApiBandaSystemEmployeesEmployeeLinkShow(fetchWorkProfiles(params));

    return (
        <TableLayout
            columns={REPORTS_STRUCTURE(navigate)}
            rows={users?.data || []}
            isLoading={isLoading}
            filterRow
            pagination={users?.pagination}
            negativeMargin
            minWidth={1500}
        />
    );
}
const ACTIVE_OPTIONS = [
    {
        id: 1,
        name: 'Активен',
        value: '1'
    },
    {
        id: 2,
        name: 'Не активен',
        value: '0'
    }
];

const REPORTS_STRUCTURE = (navigate: any) => [
    {
        title: <SortButton title={'Сотрудник'} sortName={'last_name'} />,
        filter: (
            <FormTextField
                name={'full_name'}
                size={'small'}
                isSearch
                // hasSearchIcon
                placeholder={'Поиск по ФИО'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Avatar
                    {...stringAvatar(item?.employee?.last_name + ' ' + item?.employee?.first_name)}
                    src={item?.employee?.avatar?.url}
                    sx={{
                        width: 36,
                        height: 36,
                        fontSize: '16px'
                    }}
                />
                <Stack>
                    <Typography variant={'body2'}>
                        {item?.employee?.last_name + ' ' + item?.employee?.first_name + ' '}
                    </Typography>
                    <Typography variant={'body2'}>{item?.employee?.patronymic || ''}</Typography>
                </Stack>
            </Stack>
        ),
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Дата рождения'} sortName={'date_of_birth'} />,
        filter: <FormDatePicker name={'date_of_birth'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) =>
            dayjs(item?.employee?.date_of_birth).isValid()
                ? dayjs(item?.employee?.date_of_birth).format('DD MMMM YYYY')
                : '',
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Подразделение'} sortName={'department'} />,
        filter: (
            <FormTextField
                name={'department'}
                size={'small'}
                isSearch
                placeholder={'Поиск по подразделению'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.department?.name,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Должность'} sortName={'position'} />,
        filter: (
            <FormTextField
                name={'position'}
                size={'small'}
                isSearch
                placeholder={'Поиск по должности'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.position_link?.name,
        sx: {width: '15%'}
    },
    {
        title: <SortButton title={'Дата найма'} sortName={'hiring_date'} />,
        filter: <FormDatePicker name={'hiring_date'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) =>
            dayjs(item?.hiring_date).isValid() ? dayjs(item?.hiring_date).format('DD MMMM YYYY') : '',
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Источник'} sortName={'source_id'} />,
        filter: (
            <FormTextField
                name={'source_id'}
                size={'small'}
                isSearch
                placeholder={'Поиск по источнику'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.source_id,
        sx: {width: '20%'}
    },
    {
        title: <SortButton title={'Активность'} sortName={'is_active'} />,
        filter: (
            <FormSelect
                name={'is_active'}
                options={ACTIVE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'Активность'}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (item?.is_active ? 'Активен' : 'Не активен'),
        sx: {width: '10%'}
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item, navigate)} />,
        align: 'right',
        sx: {width: '5%'}
    }
];
const ITEM_ACTIONS = (item: any, navigate: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => {
            navigate(ROUTES.USERS_ACCOUNTS + '/' + item?.employee?.id + '?tab=' + ACCOUNT_TAB_ENUM.ACCOUNT);
            scrollBodyToTop();
        }
    },
    {
        title: 'Профиль',
        icon: <UserIcon />,
        onClick: () => {
            navigate(ROUTES.PROFILE + '/' + item?.employee?.id);
            scrollBodyToTop();
        }
    }
];
