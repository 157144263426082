import {alpha, Chip} from '@mui/material';
import * as React from 'react';

export const ChipStatus = ({item}: any) => {
    return (
        <Chip
            size={'small'}
            label={item.name}
            sx={{height: 20, color: item.color, backgroundColor: alpha(item.color, 0.1)}}
        />
    );
};
