/* eslint-disable camelcase */
import {Divider, Stack, Typography, useMediaQuery} from '@mui/material';
import {FieldValues, UseFormReturn} from 'react-hook-form';
import {Form} from 'components/form/Form';
import * as React from 'react';
import {ReactNode, useEffect} from 'react';
import {TablePerPage} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Theme} from '@mui/material/styles';
import {ResetButton} from 'components/accessory/ResetButton';

export const StandardTableHeader = ({
    form,
    buttons,
    title,
    resetButton = true
}: {
    form: UseFormReturn<FieldValues, any, undefined>;
    buttons?: ReactNode;
    title?: string | ReactNode;
    resetButton?: boolean;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {reset} = form;
    const [params, setSearchParams] = useQsParams();
    useEffect(() => {
        reset({per_page: params?.per_page});
    }, []);
    const resetInputs = () => {
        reset({});
        setSearchParams(params.tab ? {tab: params.tab} : {});
    };
    return (
        <Form form={form}>
            <Stack gap={2}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    {typeof title === 'string' ? <Typography variant={'h5'}>{title}</Typography> : title}
                </Stack>
                <Divider sx={{mx: {xs: -2, sm: -3}}} />
                {resetButton && (
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'flex-start'}
                        flexWrap={'wrap'}
                        gap={2}>
                        {isMd && <TablePerPage />}
                        <Stack direction={'row'} gap={2}>
                            {buttons}
                            {Object.keys(params).length > (params.tab ? 1 : 0) && (
                                <ResetButton onClick={() => resetInputs()} />
                            )}
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Form>
    );
};
