import React, {ReactNode} from 'react';
import {createEvent, createStore} from 'effector';
import {MenuItem, MenuList, Popover, useMediaQuery} from '@mui/material';
import {useUnit} from 'effector-react';
import {Theme} from '@mui/material/styles';

// Типизация
type handleOpenType = {anchor?: HTMLButtonElement | ReactNode; items?: any[]} & any;

// Создаем эффекты и сторы
const setOpen = createEvent<boolean>();
const setAnchorEl = createEvent<HTMLButtonElement | null>();
const setContent = createEvent<handleOpenType>();

const $open = createStore(false).on(setOpen, (_, open) => open);
const $anchorEl = createStore<HTMLButtonElement | null>(null).on(setAnchorEl, (_, anchorEl) => anchorEl);
const $content = createStore<handleOpenType>([]).on(setContent, (_, content) => content);

// Создаем события
export const handleOpenPopover = createEvent<handleOpenType>();
export const handleClosePopover = createEvent<void>();

// Подписываемся на события и обновляем сторы
$open.on(handleOpenPopover, () => true);
$anchorEl.on(handleOpenPopover, (state, params) => params.anchor || null);
$content.on(handleOpenPopover, (state, params) => params);

$open.on(handleClosePopover, () => false);
$anchorEl.on(handleClosePopover, () => null);

// Провайдер контекста (теперь будет просто оберткой для Popover)
export const PopoverProvider = ({children}: {children: ReactNode}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const open = useUnit($open);
    const anchorEl = useUnit($anchorEl);
    const content = useUnit($content);

    const id = open && anchorEl ? 'popover-layout' : undefined;
    console.log(content);
    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                disableScrollLock
                onClose={() => {
                    handleClosePopover();
                    content?.onClose?.();
                }}
                slotProps={{
                    paper: {
                        sx: {mt: 1, mb: 2, minWidth: 150},
                        className: 'custom-scroll'
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}>
                <MenuList>
                    {content?.items?.map((item: any) => (
                        <MenuItem
                            key={item.title}
                            sx={{gap: 1, '& svg': {width: 20, height: 20}}}
                            disabled={item.disabled}
                            onClick={() => {
                                handleClosePopover();
                                item?.onClick();
                            }}>
                            {item?.icon} {item.title}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
            {children}
        </>
    );
};
