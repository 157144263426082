import {Button, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {getGetApiBandaSystemAccessRoleShowQueryKey, useDeleteApiBandaSystemAccessRoleRemove} from 'api/система';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {errorLayoutMessage} from 'utils/functions';
import {deleteRoleMessage} from 'utils/constants/messages';
import {fetchRoles} from './PermissionRolesTable';

export const deleteRolesOpenModal = (item?: any) => {
    openModal({
        title: 'Удаление роли',
        body: <DeleteRolesModal row={item} />,
        maxWidth: 'sm'
    });
};

export const DeleteRolesModal = ({row}: any) => {
    const deleteRoleMutation = useDeleteApiBandaSystemAccessRoleRemove();
    const [params] = useQsParams();
    const queryClient = useQueryClient();
    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemAccessRoleShowQueryKey(fetchRoles(params))
        });
    };
    const onSubmit = () => {
        deleteRoleMutation
            .mutateAsync({
                data: {
                    id: row.id
                }
            })
            .then((r) => {
                openToast({
                    text: deleteRoleMessage,
                    type: TOAST_TYPE.SUCCESS
                });
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Stack gap={2}>
            <Typography>Вы уверены что хотите удалить роль {row.name}?</Typography>
            <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                <LoadingButton variant={'contained'} onClick={onSubmit} loading={deleteRoleMutation.isPending}>
                    Удалить
                </LoadingButton>
                <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                    Отменить
                </Button>
            </Stack>
        </Stack>
    );
};
