import {Paper} from 'components/accessory/Paper';
import {Divider, Stack, Typography} from '@mui/material';
import {useGetApiBandaSystemEmployeesEmployeeLinkShow} from 'api/система';
import {TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import * as React from 'react';
import dayjs from 'dayjs';
import {useAuthContext} from 'context/authContext';

export const WorkProfiles = ({userInfo}: {userInfo?: any}) => {
    const {me} = useAuthContext();
    const {data, isLoading} = useGetApiBandaSystemEmployeesEmployeeLinkShow(
        {
            page: 1,
            //@ts-ignore
            'filter[search_employee]': me?.full_name
        },
        {
            query: {enabled: !!userInfo}
        }
    );

    return (
        <Paper sx={{p: {xs: 2, sm: 3}}}>
            <Stack sx={{mb: 3}} direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h5'}>Рабочие профили</Typography>
            </Stack>
            <Divider sx={{mx: {xs: -2, sm: -3}}} />
            <TableLayout
                columns={STRUCTURE}
                //@ts-ignore
                rows={data?.data || []}
                isLoading={isLoading}
                //@ts-ignore
                pagination={data?.pagination}
                negativeMargin
            />
        </Paper>
    );
};
const STRUCTURE = [
    {
        title: 'ФИО',
        value: (item: any) =>
            item.employee?.last_name + ' ' + item.employee?.first_name + ' ' + item.employee?.patronymic,
        sx: {width: '25%'}
    },
    {
        title: 'Подразделение',
        value: (item: any) => item?.department?.name,
        sx: {width: '25%'}
    },
    {
        title: 'Должность',
        value: (item: any) => item?.position_link?.name,
        sx: {width: '25%'}
    },
    {
        title: 'Дата найма',
        value: (item: any) => (dayjs(item?.hiring_date).isValid() ? dayjs(item?.hiring_date).format('DD.MM.YYYY') : ''),
        sx: {width: '15%'}
    },
    {
        title: 'Статус',
        value: (item: any) => (item?.is_active ? 'Активен' : 'Не активен'),
        sx: {width: '10%'}
    }
];
