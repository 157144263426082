import {Box, Stack} from '@mui/material';
import {UserInfo} from './UserInfo';
import {CalendarDayItem} from './CalendarDayItem';
import {useUnit} from 'effector-react';
import {$calendarDays, $selectedUser, setSelectedUser} from 'context/schedulerEffector';
import React, {useEffect, useRef} from 'react';
import {PencilIcon, TablePlusIcon, TrashIcon} from 'assets/icons';
import {handleOpenPopover} from 'context/popoverContext';
import {LeftEmptyWhiteColumn} from './LeftEmptyWhiteColumn';
import dayjs, {Dayjs} from 'dayjs';
import _ from 'lodash';
import {MergedItem, TimeSlot} from '../types';

export const CalendarRow = ({
    user,
    onDrag,
    setOnDrag,
    firstElement,
    setFirstElement,
    lastElement,
    setLastElement
}: {
    user: any;
    onDrag: boolean;
    setOnDrag: (b: boolean) => void;
    firstElement: any;
    setFirstElement: (v: any) => void;
    lastElement: any;
    setLastElement: (v: any) => void;
}) => {
    const userIsSelected = useUnit($selectedUser)?.employee_links_id === user?.employee_links_id;
    const monthArray = mergeDaysWithTimeSlots(useUnit($calendarDays), user?.time_slots);

    const rowRef: any = useRef();
    const clean = () => {
        setSelectedUser(null);
        setFirstElement(null);
        setLastElement(null);
    };
    useEffect(() => {
        if (userIsSelected && lastElement && !onDrag) {
            const test = monthArray.findIndex((day: any) => day?.date === lastElement);
            handleOpenPopover({
                anchor: rowRef?.current?.children?.[test],
                items: ITEM_ACTIONS(),
                onClose: () => {
                    clean();
                }
            });
        }
    }, [onDrag]);
    return (
        <Stack direction={'row'} gap={2}>
            <LeftEmptyWhiteColumn />
            <UserInfo user={user} />
            <Stack direction={'row'} gap={0.5} ref={rowRef}>
                {monthArray.map((day: any) => {
                    return (
                        <Box
                            key={day?.date?.$D}
                            sx={{cursor: 'pointer', userSelect: 'none'}}
                            onMouseDown={(e) => {
                                setSelectedUser(null);

                                setSelectedUser(user);
                                setOnDrag(true);
                                setFirstElement(day?.date);
                                setLastElement(day?.date);
                            }}
                            onMouseEnter={() => {
                                if (firstElement && onDrag && userIsSelected) {
                                    setLastElement(day?.date);
                                }
                            }}>
                            {/*<Tooltip title={'tooltip info'}>*/}
                            <CalendarDayItem
                                day={day}
                                isActive={simplifiedCheck(userIsSelected, day?.date, firstElement, lastElement)}
                            />
                            {/*</Tooltip>*/}
                        </Box>
                    );
                })}
            </Stack>
        </Stack>
    );
};
const ITEM_ACTIONS = () => [
    {
        title: 'Добавить ',
        icon: <TablePlusIcon />,
        onClick: () => {}
    },
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => {}
    },
    {
        title: 'Удалить',
        icon: <TrashIcon />,
        onClick: () => {}
    }
];

const mergeDaysWithTimeSlots = (days: Dayjs[], timeSlots: TimeSlot[]): MergedItem[] => {
    return days.map((day: Dayjs) => {
        const matchingTimeSlot = _.find(timeSlots, (timeSlot) => dayjs(timeSlot.started_at).isSame(day, 'day'));
        return {
            date: day,
            timeslot: matchingTimeSlot || null
        };
    });
};
const simplifiedCheck = (
    userIsSelected: boolean,
    day: Dayjs | undefined,
    firstElement: Dayjs,
    lastElement: Dayjs
): boolean => {
    if (!userIsSelected || !day) return false;
    return day.isBetween(firstElement, lastElement, 'day', '[]');
};
