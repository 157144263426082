import * as React from 'react';
import {useEffect, useState} from 'react';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {alpha, Box, IconButton, Link, Stack, useMediaQuery} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {Outlet, useLocation} from 'react-router-dom';
import {drawerWidth, ROUTES} from 'utils/constants';
import {LeftPanelList} from 'components/layouts/MainLayout/components/LeftPanelList';
import {AppBarTop} from 'components/layouts/MainLayout/components/AppBar';
import {CircleDotIcon, CircleIcon, FullLogoIcon, SmallLogoIcon} from 'assets/icons';
import {logoColorMixin, shadow, svgColorMixin, transitionMixin} from 'utils/mixins';
import {ModalEffector} from 'context/modalEffector';
import {Footer} from 'components/layouts/MainLayout/components/Footer';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function MainLayout() {
    const {pathname} = useLocation();
    const leftMenuBlockStorage = Boolean(Number(localStorage.getItem('leftMenuBlock')));
    const [open, setOpen] = React.useState(leftMenuBlockStorage || false);
    const [blockOpen, setBlockOpen] = React.useState(leftMenuBlockStorage || false);
    const isAuth = pathname?.includes(ROUTES.AUTH);
    const isError = pathname?.includes(ROUTES.ERROR);

    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const toggleBlockOpen = (value: boolean) => {
        setBlockOpen(value);
        window.localStorage.setItem('leftMenuBlock', String(Number(value)));
    };
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };
    useEffect(() => {
        if (!isMd) {
            setOpen(false);
        }
        if (isMd && blockOpen) {
            setOpen(true);
        }
    }, [isMd]);
    return (
        <>
            {!isAuth && !isError ? (
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100%',
                        position: 'relative',
                        backgroundColor: theme.palette.background.default,
                        '&:before': blurToolBarStyles(theme.palette.background.default)
                    })}>
                    <CssBaseline />
                    <AppBarTop {...{open, setOpen, blockOpen}} />
                    {isMd ? (
                        <Drawer
                            variant={isMd || open ? 'permanent' : 'temporary'}
                            open={open}
                            onMouseEnter={() => setOpen(true)}
                            onMouseLeave={() => (blockOpen ? {} : setOpen(false))}
                            sx={(theme) => ({
                                '& .MuiDrawer-paper': {
                                    boxShadow: shadow,
                                    borderRight: 'transparent',
                                    ...transitionMixin(theme),
                                    backgroundColor: theme.palette.background.paper
                                },
                                '& .ps__rail-y': {
                                    background: 'none !important'
                                },
                                '& .ps__rail-y:hover': {
                                    '& .ps__thumb-y': {
                                        width: '6px !important'
                                    }
                                },
                                '& .ps__thumb-y': {
                                    background: 'rgba(68, 64, 80, 0.2) !important',
                                    width: '2px !important',
                                    right: '4px !important'
                                },
                                position: 'absolute'
                            })}>
                            <DrawerLeftMenu {...{open, blockOpen, toggleBlockOpen}} />
                        </Drawer>
                    ) : (
                        <MuiDrawer anchor={'left'} onClose={toggleDrawer(false)} open={open}>
                            <Stack onClick={toggleDrawer(false)}>
                                <DrawerLeftMenu {...{open, blockOpen, toggleBlockOpen}} />
                            </Stack>
                        </MuiDrawer>
                    )}

                    <Box
                        component='main'
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.default,
                            width: '100%',
                            height: '100%'
                        }}>
                        <Stack
                            flexGrow={1}
                            sx={{
                                height: '100%',
                                position: 'relative'
                            }}>
                            <Stack
                                sx={(theme) => ({
                                    flexGrow: 1,
                                    ...transitionMixin(theme),
                                    marginLeft: {
                                        md: blockOpen ? `calc(${drawerWidth}px + 1px)` : `calc(${theme.spacing(9)})`
                                    }
                                })}>
                                <Stack sx={{p: {xs: 2, sm: 3}}}>
                                    <Outlet />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                    <Footer blockOpen={blockOpen} />
                </Box>
            ) : (
                <Outlet />
            )}
            <ModalEffector />
        </>
    );
}
const DrawerLeftMenu = ({
    open,
    blockOpen,
    toggleBlockOpen
}: {
    open: boolean;
    blockOpen: boolean;
    toggleBlockOpen: (blockOpen: boolean) => void;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const {
        palette: {mode: thisTheme}
    } = useTheme();
    const shadow =
        thisTheme === 'dark'
            ? `linear-gradient(2f2b3d 41%, rgba(47, 51, 73, 0.11) 95%, rgba(47, 51, 73, 0))`
            : 'linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0))';
    const [scroll, setScroll] = useState(false);
    return (
        <>
            <DrawerHeader
                sx={(theme) => ({
                    justifyContent: 'flex-start',
                    pl: open ? 2.75 : 2.25,
                    ...transitionMixin(theme),
                    pr: 2
                })}>
                <Link
                    sx={{
                        width: '100%',
                        height: '30px',
                        mr: 'auto',
                        ...logoColorMixin(thisTheme)
                    }}>
                    {open ? <FullLogoIcon /> : <SmallLogoIcon />}
                </Link>
                {open && isMd && (
                    <IconButton
                        onClick={() => toggleBlockOpen(!blockOpen)}
                        size={'small'}
                        sx={(theme) => ({...svgColorMixin(theme.palette.text.primary)})}>
                        {blockOpen ? <CircleDotIcon width={20} height={20} /> : <CircleIcon width={20} height={20} />}
                    </IconButton>
                )}
            </DrawerHeader>
            <Box
                sx={{
                    display: scroll ? 'block' : 'none',
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: '52px',
                    height: '48px',
                    width: '100%',
                    zIndex: 2,
                    background: (theme) => shadow
                }}
            />
            {isSm ? (
                <PerfectScrollbar
                    onYReachStart={() => setScroll(false)}
                    onScrollDown={() => setScroll(true)}
                    onScrollUp={(container) => {
                        if (container && container?.scrollTop < 5) {
                            setScroll(false);
                        }
                    }}>
                    <LeftPanelList {...{open}} />
                </PerfectScrollbar>
            ) : (
                <LeftPanelList {...{open}} />
            )}
        </>
    );
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8.75)})`
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));
const blurToolBarStyles = (color: string) => ({
    content: "''",
    width: '100%',
    zIndex: 1,
    right: 16,
    height: {xs: 0, md: '72px'},
    position: 'fixed',
    background: `linear-gradient(180deg, ${alpha(color, 0.7)} 44%, ${alpha(color, 0.43)} 73%, ${alpha(color, 0)})`,
    mask: `linear-gradient(${color}, ${color} 18%, transparent 100%)`,
    backdropFilter: 'saturate(200%) blur(10px)'
});
