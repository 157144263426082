import {ControllerProps, useController} from 'react-hook-form';
import React, {useRef} from 'react';
import {Labeled} from './Labeled';
import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteValue,
    IconButton,
    MenuItem,
    Stack,
    SxProps,
    TextField,
    TextFieldVariants
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Theme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useQsParams} from 'utils/hooks/useQsParams';

export const FormAutocompleteAsyncPaginate = <
    T extends {id: number | string; textFreeSolo?: string; [key: string]: any},
    M extends boolean | undefined
>(
    props: Omit<ControllerProps, 'render'> &
        Omit<AutocompleteProps<T, M, any, any>, 'renderInput'> & {
            label?: string;
            optionText?: keyof T;
            optionValue?: keyof T;
            hasNextPage?: boolean;
            fetchNextPage?: () => void;
            fullWidth?: boolean;
            direction?: 'row' | 'column';
            inputSx?: SxProps<Theme>;
            labelSx?: SxProps<Theme>;
            maskProps?: any;
            variant?: TextFieldVariants;
            placeholder?: string;
            isSearch?: boolean | string;
            disabledColor?: boolean;
            optionItem?: any;
            optionLabel?: any;
        }
) => {
    const {
        name,
        options = [],
        label,
        optionText = 'name',
        optionValue = 'id',
        placeholder,
        multiple,
        rules,
        hasNextPage = false,
        fetchNextPage,
        sx,
        inputSx,
        fullWidth,
        direction,
        labelSx,
        freeSolo,
        maskProps,
        variant,
        isSearch,
        disabledColor,
        optionItem,
        optionLabel,
        ...rest
    } = props;

    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: multiple ? [] : null});
    const textFieldRef = useRef<HTMLInputElement>(null);
    const [, , setParamsSmart] = useQsParams();

    return (
        <Labeled label={label} fullWidth={fullWidth} direction={direction} labelSx={labelSx}>
            <Autocomplete
                {...field}
                options={options}
                fullWidth
                sx={{
                    maxWidth: '100%',
                    ...sx
                }}
                ChipProps={{size: 'small'}}
                clearOnBlur
                freeSolo={freeSolo}
                slotProps={{paper: {elevation: 8}}}
                filterOptions={
                    freeSolo
                        ? (options, params) => {
                              const {inputValue} = params;
                              const filtered = options.filter((option) =>
                                  option?.[optionText as keyof typeof option]
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase())
                              );
                              //Введенное уже существует
                              const isExisting = options.some(
                                  (option) => inputValue === option?.[optionText as keyof typeof option]
                              );
                              if (inputValue !== '' && freeSolo && !isExisting) {
                                  const newOption: any = {};
                                  const containsElement =
                                      multiple && field.value
                                          ? field.value.find(
                                                (el: T) => el[optionText as keyof typeof el] === inputValue
                                            )
                                          : false;
                                  newOption.textFreeSolo = containsElement
                                      ? `Удалить "${inputValue}"`
                                      : `Добавить "${inputValue}"`;
                                  newOption[optionText] = inputValue;
                                  filtered.unshift(newOption);
                              }
                              return filtered;
                          }
                        : undefined
                }
                multiple={multiple}
                loadingText={'Загрузка...'}
                noOptionsText={'Нет данных'}
                value={multiple ? field.value || [] : field.value || null}
                getOptionLabel={(option: any) =>
                    optionLabel ? optionLabel?.(option) : String(option[optionText] || '')
                }
                isOptionEqualToValue={(option, value) => {
                    return (
                        String(freeSolo ? option?.[optionText as keyof typeof option] : option[optionValue]) ===
                        String(freeSolo ? value?.[optionText as keyof typeof option] : value[optionValue])
                    );
                }}
                popupIcon={<ExpandMoreIcon />}
                renderInput={(params) => {
                    return (
                        <TextField
                            error={!!error}
                            type={'text'}
                            helperText={error?.message}
                            inputRef={textFieldRef}
                            variant={variant}
                            placeholder={placeholder || label}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <Stack
                                            direction={'row'}
                                            sx={{
                                                position: 'absolute',
                                                right: '13px',
                                                height: '100%',
                                                minHeight: '36px',
                                                justifyContent: 'flex-end'
                                            }}>
                                            {(multiple ? !!field.value?.length : field.value) && (
                                                <Stack
                                                    sx={{
                                                        width: 28,
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                    <IconButton
                                                        sx={{'& path': {fill: '#9294A0'}, mr: -1, p: '4px'}}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            field.onChange('');
                                                            if (isSearch) {
                                                                setParamsSmart({[name]: ''});
                                                            }
                                                        }}>
                                                        <CloseIcon
                                                            width={20}
                                                            height={20}
                                                            sx={{width: '20px', height: '20px'}}
                                                        />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </>
                                )
                            }}
                        />
                    );
                }}
                onChange={(_, data: AutocompleteValue<T, M, any, any>) => {
                    field.onChange(data);
                    if (isSearch) {
                        setParamsSmart({
                            [name]:
                                multiple && data instanceof Array
                                    ? data?.map((e: any) => ({
                                          [optionValue]: e[optionValue],
                                          [optionText]: e[optionText]
                                      }))
                                    : {
                                          // @ts-ignore
                                          [optionValue]: data?.[optionValue],
                                          // @ts-ignore
                                          [optionText]: data?.[optionText]
                                      } || ''
                        });
                    }
                }}
                renderOption={(props, option, state, ownerState) => {
                    const value = freeSolo ? option?.[optionText as keyof typeof option] : option[optionValue];
                    return (
                        <MenuItem {...props} key={String(value)} value={String(value)} disableRipple>
                            {optionItem ? (
                                optionItem?.(option)
                            ) : (
                                <> {option.textFreeSolo || option?.[optionText as keyof typeof option]}</>
                            )}
                        </MenuItem>
                    );
                }}
                ListboxProps={{
                    onScroll: (event: any) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollHeight - (listboxNode.scrollTop + listboxNode.clientHeight) < 1) {
                            if (hasNextPage) fetchNextPage?.();
                        }
                    },
                    style: {maxHeight: '190px'}
                }}
                {...rest}
            />
        </Labeled>
    );
};
