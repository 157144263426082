import {Stack, styled, Switch, Typography} from '@mui/material';
import {useController} from 'react-hook-form';
import React from 'react';

export const FormSwitch = (props: {name: string; defaultValue?: boolean; label?: string}) => {
    const {name, defaultValue, label, ...rest} = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, defaultValue: defaultValue || false});

    return (
        <Stack direction={'row'} gap={2} alignItems={'center'}>
            <ThemeSwitch {...field} checked={field.value || false} color={'primary'} />
            {label && <Typography>{label}</Typography>}
        </Stack>
    );
};
const ThemeSwitch = styled(Switch, {shouldForwardProp: (prop) => prop !== 'theme'})(({theme}) => ({
    padding: 0,
    cursor: 'pointer',
    height: '22px',
    width: '40px',
    marginRight: -8,
    overflow: 'visible',
    '& .MuiSwitch-switchBase': {
        background: 'none',
        padding: 0,
        marginTop: '1px',
        marginLeft: '1.5px',

        '&.Mui-checked:hover': {background: 'none'},
        '&:hover': {background: 'none'},
        '&.Mui-checked': {
            transform: 'translate(17px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                // background: '#713DFF',
                boxShadow: '0 2px 6px 0 rgba(115, 103, 240, 0.3)'
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: '#FFFFFF'
            }
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: '6px',
        boxShadow: '0 0 0.25rem 0 rgba(0, 0, 0, 0.16) inset',
        opacity: 1,
        background: theme.palette.mode === 'dark' ? '#41445a' : '#eaeaec',
        border: '1px solid transparent',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0.6,
            width: 10,
            height: 10
        },
        '&:after': {
            right: 12,
            backgroundPosition: 'center',
            backgroundSize: 'contain'
        }
    },
    // '& .MuiSwitch-switchBase.Mui-checked': {
    //     '& .MuiSwitch-thumb': {
    //         '&:after': {
    //             display: 'block',
    //             content: '""',
    //             backgroundRepeat: 'no-repeat',
    //             height: 12,
    //             width: 12,
    //             backgroundPosition: 'center',
    //             backgroundSize: 'contain'
    //         }
    //     }
    // },
    '& .MuiSwitch-thumb': {
        boxShadow: '0px 2px 2px 0px rgba(41, 47, 53, 0.15)',
        width: 14,
        height: 14,
        marginLeft: 3,
        marginTop: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: 'white'
    }
}));
