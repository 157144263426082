import {Stack} from '@mui/material';
import {ACCOUNT_INFO_TABS, ACCOUNT_TAB_ENUM} from 'utils/constants/menu';
import {PersonalInfoTabs} from 'components/accessory/PersonalInfoTabs';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Account} from './components/Account';
import {Safety} from './components/Safety';

export const ProfileSettings = () => {
    const [params] = useQsParams();
    const {tab} = params;

    return (
        <Stack gap={{xs: 2, sm: 3}}>
            <PersonalInfoTabs tabsArray={ACCOUNT_INFO_TABS} />
            {TABS_COMPONENTS[tab]}
        </Stack>
    );
};
const TABS_COMPONENTS = {
    [ACCOUNT_TAB_ENUM.ACCOUNT]: <Account />,
    [ACCOUNT_TAB_ENUM.SECURITY]: <Safety />,
    [ACCOUNT_TAB_ENUM.NOTIFICATIONS]: <></>
};
