import {Button, Stack, Typography, useMediaQuery} from '@mui/material';
import bgShadow from 'assets/images/login-background2.png';
import error403 from 'assets/images/t_403.png';
import error404 from 'assets/images/t_404.png';
import {ROUTES} from 'utils/constants';
import {Theme} from '@mui/material/styles';

export const Error = ({error}: any) => {
    const {code, title, description} = error;
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return (
        <Stack
            sx={{
                overflow: 'auto',
                height: '100%',
                backgroundImage: `url(${bgShadow})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                backgroundSize: {xs: '100% 50% 25%', md: '100% 355px'}
            }}>
            <Stack
                gap={1}
                sx={{
                    margin: '40px',
                    marginTop: {md: '60px'},
                    height: '100%',
                    justifyContent: {xs: 'flex-start', md: 'center'},
                    alignItems: 'center'
                }}>
                <Typography variant={'h1'} sx={{fontSize: '96px', lineHeight: '96px', fontWeight: '400'}}>
                    {code}
                </Typography>
                <Typography variant={'h4'} textAlign={'center'}>
                    {title}
                </Typography>
                <Typography textAlign={'center'}>{description}</Typography>
                <Button variant={'contained'} sx={{width: 120, mt: 2}} href={ROUTES.PROFILE}>
                    На главную
                </Button>
                <img
                    alt={'error-image'}
                    src={imageError[(code as keyof typeof imageError) || 403]}
                    style={{width: 455, maxWidth: isMd ? '30%' : '80%', marginTop: '16px'}}
                />
            </Stack>
        </Stack>
    );
};
const imageError = {403: error403, 404: error404};
