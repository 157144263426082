/* eslint-disable camelcase */
import {Button, Divider, Stack, Typography, useMediaQuery} from '@mui/material';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {useEffect} from 'react';
import {TablePerPage} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Theme} from '@mui/material/styles';
import {PlusIcon} from 'assets/icons';
import {positionEditOpenModal} from './CreateUpdateModal';
import {ResetButton} from 'components/accessory/ResetButton';

export const TableHeader = ({allReset}: {allReset: () => void}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const form = useForm();
    const {reset} = form;
    const [params] = useQsParams();
    useEffect(() => {
        reset({per_page: params?.per_page});
    }, []);

    const resetInputs = () => {
        reset({per_page: '25'});
        allReset();
    };
    return (
        <Form form={form}>
            <Stack gap={2}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    <Typography variant={'h5'}>Список должностей</Typography>
                    <Button
                        variant={'contained'}
                        startIcon={<PlusIcon />}
                        onClick={() => positionEditOpenModal('Добавление должности')}>
                        Добавить должность
                    </Button>
                </Stack>
                <Divider sx={{mx: {xs: -2, sm: -3}}} />
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    {isMd && <TablePerPage />}
                    <Stack direction={'row'} gap={2}>
                        {!!Object.keys(params).length && <ResetButton onClick={() => resetInputs()} />}
                    </Stack>
                </Stack>
            </Stack>
        </Form>
    );
};
