/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {useEffect} from 'react';
import {FormTextField} from 'components/form/FormTextField';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {useQueryClient} from '@tanstack/react-query';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE, MASKS} from 'utils/constants';
import {
    getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryKey,
    usePatchApiBandaSystemEmployeesAccountsEdit
} from 'api/система';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';

export const handleOpenEditUserModal = (userInfo: any) => {
    openModal({
        title: 'Редактирование аккаунта',
        body: <EditUserInfoModal userInfo={userInfo} />,
        maxWidth: 'sm'
    });
};
export const EditUserInfoModal = ({userInfo}: {userInfo: any}) => {
    const form = useForm(schema);
    const {reset} = form;
    const queryClient = useQueryClient();
    useEffect(() => {
        if (userInfo) {
            reset({email: userInfo?.email, phone: userInfo?.phone});
        }
    }, [userInfo]);
    const editProfileMutation = usePatchApiBandaSystemEmployeesAccountsEdit();
    const refetchProfile = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryKey(userInfo?.id as string)
        });
    };

    const onSubmit = (data: any) => {
        editProfileMutation
            .mutateAsync({
                data: {
                    id: userInfo?.id,
                    phone: data?.phone,
                    //@ts-ignore
                    email: data?.email
                }
            })
            .then((r) => {
                openToast({
                    text: 'Аккаунт успешно отредактирован',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchProfile();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <FormTextField
                    name={'phone'}
                    placeholder={'Номер телефона'}
                    label={'Номер телефона'}
                    maskProps={MASKS.PHONE}
                    autoComplete='off'
                />
                <FormTextField
                    name={'email'}
                    placeholder={'E-mail'}
                    label={'E-mail'}
                    autoComplete='off'
                    type={'email'}
                />
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={false}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            phone: yup.string().required(ERROR_MESSAGE.REQUIRED),
            email: yup.string().required(ERROR_MESSAGE.REQUIRED)
        })
    )
};
