import {Skeleton, Typography} from '@mui/material';
import React, {ReactNode} from 'react';
import {TypographyProps} from '@mui/material/Typography/Typography';

export const SkTypography = (
    props: {isLoading?: boolean; children?: ReactNode; skeleton?: string; widthSk?: number} & TypographyProps
) => {
    const {isLoading, children, skeleton, widthSk, ...rest} = props;
    const skeletonWidthContent = '_'.repeat(widthSk || 1);
    return (
        <>
            {isLoading ? (
                <Skeleton animation={'wave'} width={!widthSk && !skeleton ? '100%' : 'auto'}>
                    <Typography {...rest} sx={{whiteSpace: 'pre-wrap'}}>
                        {widthSk ? skeletonWidthContent : skeleton}
                    </Typography>
                </Skeleton>
            ) : (
                <Typography {...rest}>{children}</Typography>
            )}
        </>
    );
};
