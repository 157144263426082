/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    GetApiBandaTableDownload401,
    GetApiBandaTableDownload403,
    GetApiBandaTableDownload422,
    GetApiBandaTableDownload500,
    GetApiBandaTableDownloadParams,
    GetApiBandaTablePersonal401,
    GetApiBandaTablePersonal403,
    GetApiBandaTablePersonal422,
    GetApiBandaTablePersonal500,
    GetApiBandaTablePersonalBody,
    GetApiBandaTablePersonalParams,
    GetApiBandaTableShow200,
    GetApiBandaTableShow401,
    GetApiBandaTableShow403,
    GetApiBandaTableShow422,
    GetApiBandaTableShow500,
    GetApiBandaTableShowParams,
    Id401,
    Id403,
    Id422,
    Id500,
    IdBody,
    PatchApiBandaTableUpdate401,
    PatchApiBandaTableUpdate403,
    PatchApiBandaTableUpdate422,
    PatchApiBandaTableUpdate500,
    PatchApiBandaTableUpdateBodyItem,
    PutApiBandaTableCreate401,
    PutApiBandaTableCreate403,
    PutApiBandaTableCreate422,
    PutApiBandaTableCreate500,
    PutApiBandaTableCreateBodyItem
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Получение табеля
 */
export const getApiBandaTableShow = (params: GetApiBandaTableShowParams, signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaTableShow200>({url: `/api/banda/table/show`, method: 'GET', params, signal});
};

export const getGetApiBandaTableShowQueryKey = (params: GetApiBandaTableShowParams) => {
    return [`/api/banda/table/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaTableShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaTableShow>>,
    TError = GetApiBandaTableShow401 | GetApiBandaTableShow403 | GetApiBandaTableShow422 | GetApiBandaTableShow500
>(
    params: GetApiBandaTableShowParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaTableShow>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaTableShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaTableShow>>> = ({signal}) =>
        getApiBandaTableShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaTableShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaTableShowQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaTableShow>>>;
export type GetApiBandaTableShowQueryError =
    | GetApiBandaTableShow401
    | GetApiBandaTableShow403
    | GetApiBandaTableShow422
    | GetApiBandaTableShow500;

/**
 * @summary Получение табеля
 */
export const useGetApiBandaTableShow = <
    TData = Awaited<ReturnType<typeof getApiBandaTableShow>>,
    TError = GetApiBandaTableShow401 | GetApiBandaTableShow403 | GetApiBandaTableShow422 | GetApiBandaTableShow500
>(
    params: GetApiBandaTableShowParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaTableShow>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaTableShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Скачивание табеля
 */
export const getApiBandaTableDownload = (params: GetApiBandaTableDownloadParams, signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/table/download`, method: 'GET', params, signal});
};

export const getGetApiBandaTableDownloadQueryKey = (params: GetApiBandaTableDownloadParams) => {
    return [`/api/banda/table/download`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaTableDownloadQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaTableDownload>>,
    TError =
        | GetApiBandaTableDownload401
        | GetApiBandaTableDownload403
        | GetApiBandaTableDownload422
        | GetApiBandaTableDownload500
>(
    params: GetApiBandaTableDownloadParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaTableDownload>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaTableDownloadQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaTableDownload>>> = ({signal}) =>
        getApiBandaTableDownload(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaTableDownload>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaTableDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaTableDownload>>>;
export type GetApiBandaTableDownloadQueryError =
    | GetApiBandaTableDownload401
    | GetApiBandaTableDownload403
    | GetApiBandaTableDownload422
    | GetApiBandaTableDownload500;

/**
 * @summary Скачивание табеля
 */
export const useGetApiBandaTableDownload = <
    TData = Awaited<ReturnType<typeof getApiBandaTableDownload>>,
    TError =
        | GetApiBandaTableDownload401
        | GetApiBandaTableDownload403
        | GetApiBandaTableDownload422
        | GetApiBandaTableDownload500
>(
    params: GetApiBandaTableDownloadParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaTableDownload>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaTableDownloadQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Получение табеля по сотруднику
 */
export const getApiBandaTablePersonal = (
    getApiBandaTablePersonalBody: GetApiBandaTablePersonalBody,
    params: GetApiBandaTablePersonalParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/table/personal`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaTablePersonalQueryKey = (
    getApiBandaTablePersonalBody: GetApiBandaTablePersonalBody,
    params: GetApiBandaTablePersonalParams
) => {
    return [`/api/banda/table/personal`, ...(params ? [params] : []), getApiBandaTablePersonalBody] as const;
};

export const getGetApiBandaTablePersonalQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaTablePersonal>>,
    TError =
        | GetApiBandaTablePersonal401
        | GetApiBandaTablePersonal403
        | GetApiBandaTablePersonal422
        | GetApiBandaTablePersonal500
>(
    getApiBandaTablePersonalBody: GetApiBandaTablePersonalBody,
    params: GetApiBandaTablePersonalParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaTablePersonal>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetApiBandaTablePersonalQueryKey(getApiBandaTablePersonalBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaTablePersonal>>> = ({signal}) =>
        getApiBandaTablePersonal(getApiBandaTablePersonalBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaTablePersonal>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaTablePersonalQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaTablePersonal>>>;
export type GetApiBandaTablePersonalQueryError =
    | GetApiBandaTablePersonal401
    | GetApiBandaTablePersonal403
    | GetApiBandaTablePersonal422
    | GetApiBandaTablePersonal500;

/**
 * @summary Получение табеля по сотруднику
 */
export const useGetApiBandaTablePersonal = <
    TData = Awaited<ReturnType<typeof getApiBandaTablePersonal>>,
    TError =
        | GetApiBandaTablePersonal401
        | GetApiBandaTablePersonal403
        | GetApiBandaTablePersonal422
        | GetApiBandaTablePersonal500
>(
    getApiBandaTablePersonalBody: GetApiBandaTablePersonalBody,
    params: GetApiBandaTablePersonalParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaTablePersonal>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaTablePersonalQueryOptions(getApiBandaTablePersonalBody, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Добавление табеля
 */
export const putApiBandaTableCreate = (putApiBandaTableCreateBodyItem: PutApiBandaTableCreateBodyItem[]) => {
    return axiosInstance<unknown>({
        url: `/api/banda/table/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaTableCreateBodyItem
    });
};

export const getPutApiBandaTableCreateMutationOptions = <
    TError =
        | PutApiBandaTableCreate401
        | PutApiBandaTableCreate403
        | PutApiBandaTableCreate422
        | PutApiBandaTableCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaTableCreate>>,
        TError,
        {data: PutApiBandaTableCreateBodyItem[]},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaTableCreate>>,
    TError,
    {data: PutApiBandaTableCreateBodyItem[]},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaTableCreate>>,
        {data: PutApiBandaTableCreateBodyItem[]}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaTableCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaTableCreateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiBandaTableCreate>>>;
export type PutApiBandaTableCreateMutationBody = PutApiBandaTableCreateBodyItem[];
export type PutApiBandaTableCreateMutationError =
    | PutApiBandaTableCreate401
    | PutApiBandaTableCreate403
    | PutApiBandaTableCreate422
    | PutApiBandaTableCreate500;

/**
 * @summary Добавление табеля
 */
export const usePutApiBandaTableCreate = <
    TError =
        | PutApiBandaTableCreate401
        | PutApiBandaTableCreate403
        | PutApiBandaTableCreate422
        | PutApiBandaTableCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaTableCreate>>,
        TError,
        {data: PutApiBandaTableCreateBodyItem[]},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaTableCreate>>,
    TError,
    {data: PutApiBandaTableCreateBodyItem[]},
    TContext
> => {
    const mutationOptions = getPutApiBandaTableCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Обновление табеля
 */
export const patchApiBandaTableUpdate = (patchApiBandaTableUpdateBodyItem: PatchApiBandaTableUpdateBodyItem[]) => {
    return axiosInstance<unknown>({
        url: `/api/banda/table/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaTableUpdateBodyItem
    });
};

export const getPatchApiBandaTableUpdateMutationOptions = <
    TError =
        | PatchApiBandaTableUpdate401
        | PatchApiBandaTableUpdate403
        | PatchApiBandaTableUpdate422
        | PatchApiBandaTableUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaTableUpdate>>,
        TError,
        {data: PatchApiBandaTableUpdateBodyItem[]},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaTableUpdate>>,
    TError,
    {data: PatchApiBandaTableUpdateBodyItem[]},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaTableUpdate>>,
        {data: PatchApiBandaTableUpdateBodyItem[]}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaTableUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaTableUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiBandaTableUpdate>>>;
export type PatchApiBandaTableUpdateMutationBody = PatchApiBandaTableUpdateBodyItem[];
export type PatchApiBandaTableUpdateMutationError =
    | PatchApiBandaTableUpdate401
    | PatchApiBandaTableUpdate403
    | PatchApiBandaTableUpdate422
    | PatchApiBandaTableUpdate500;

/**
 * @summary Обновление табеля
 */
export const usePatchApiBandaTableUpdate = <
    TError =
        | PatchApiBandaTableUpdate401
        | PatchApiBandaTableUpdate403
        | PatchApiBandaTableUpdate422
        | PatchApiBandaTableUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaTableUpdate>>,
        TError,
        {data: PatchApiBandaTableUpdateBodyItem[]},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaTableUpdate>>,
    TError,
    {data: PatchApiBandaTableUpdateBodyItem[]},
    TContext
> => {
    const mutationOptions = getPatchApiBandaTableUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Удаление табеля по ID
 */
export const id = (idBody: IdBody) => {
    return axiosInstance<unknown>({
        url: `/api/banda/table/delete`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        data: idBody
    });
};

export const getIdMutationOptions = <TError = Id401 | Id403 | Id422 | Id500, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof id>>, TError, {data: IdBody}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof id>>, TError, {data: IdBody}, TContext> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof id>>, {data: IdBody}> = (props) => {
        const {data} = props ?? {};

        return id(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type IdMutationResult = NonNullable<Awaited<ReturnType<typeof id>>>;
export type IdMutationBody = IdBody;
export type IdMutationError = Id401 | Id403 | Id422 | Id500;

/**
 * @summary Удаление табеля по ID
 */
export const useId = <TError = Id401 | Id403 | Id422 | Id500, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof id>>, TError, {data: IdBody}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof id>>, TError, {data: IdBody}, TContext> => {
    const mutationOptions = getIdMutationOptions(options);

    return useMutation(mutationOptions);
};
