import {useForm} from 'react-hook-form';
import {Paper} from 'components/accessory/Paper';
import {Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import React from 'react';
import {TableHeader} from './components/TableHeader';
import PositionsTable from './components/PositionsTable';
import {useQsParams} from 'utils/hooks/useQsParams';

export const Positions = () => {
    const form = useForm({});

    const {reset} = form;
    const [params, setSearchParams] = useQsParams();
    const allReset = () => {
        reset({});
        setSearchParams({});
        reset({});
    };

    return (
        <Paper sx={{p: {xs: 2, sm: 3}, height: '100%'}}>
            <Stack gap={2}>
                <TableHeader allReset={allReset} />
                <Form form={form}>
                    <PositionsTable form={form} />
                </Form>
            </Stack>
        </Paper>
    );
};
