import {Stack} from '@mui/material';
import React from 'react';

export const LeftEmptyWhiteColumn = () => {
    return (
        <Stack
            sx={{
                position: 'sticky',
                width: {xs: '20px', md: '28px'},
                left: 0,
                mr: '-20px',
                background: (theme) => theme.palette.background.paper,
                zIndex: 2
            }}
        />
    );
};
