import React, {ReactNode} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    SxProps,
    Theme,
    useMediaQuery
} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {iconsShadow} from 'utils/mixins';

export const CustomModal = (
    props: Omit<DialogProps, 'onClose'> & {
        hiddenCloseIcon?: boolean;
        customActions?: ReactNode;
        onClose?: () => void;
        sx?: SxProps<Theme>;
        modalTitle?: string | ReactNode;
        sxDialogTitle?: SxProps<Theme>;
        sxDialogContent?: SxProps<Theme>;
        hasTitle?: boolean;
    }
) => {
    const {
        open,
        onClose,
        children,
        customActions,
        hiddenCloseIcon,
        sx,
        modalTitle,
        sxDialogTitle,
        sxDialogContent,
        hasTitle = true,
        ...rest
    } = props;
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    return (
        <Dialog
            open={open}
            fullWidth
            scroll={'body'}
            disableScrollLock
            onClose={(e, reason) => {
                // eslint-disable-next-line no-constant-condition
                if (reason !== 'backdropClick') onClose?.();
            }}
            PaperProps={{elevation: 8, sx: {overflow: 'visible', backgroundImage: 'none'}}}
            maxWidth={'md'}
            sx={{
                '& .MuiDialog-paper': {
                    margin: {xs: 0, md: 2},
                    my: {xs: 1, md: 2},
                    width: {xs: 'calc(100vw - 16px)', md: 'calc(100vw - 32px)'},
                    maxWidth: !isSm ? 'calc(100vw - 16px) !important' : undefined
                },
                ...sx
            }}
            transitionDuration={{exit: 0, enter: 400, appear: 400}}
            {...rest}>
            {!hiddenCloseIcon && (
                <Stack
                    sx={{
                        position: 'sticky',
                        transition: 'all 0.23s ease 0.1s',
                        top: {xs: 8, sm: 14},
                        '&>button:hover': {
                            transform: 'translateY(4px) translateX(-4px)'
                        },
                        transform: {xs: 'translateY(-4px)', sm: 'translateY(-10px)'},
                        height: 0,
                        marginLeft: {xs: 'calc(100% - 26px)', sm: 'calc(100% - 20px)'},
                        zIndex: 1000
                    }}>
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{
                            borderRadius: '4px',
                            transition: 'all 0.23s ease 0.1s',
                            boxShadow: (theme) => ({
                                boxShadow: iconsShadow[theme.palette.mode]
                            }),
                            background: (theme) => theme.palette.background.paper + '!important',
                            width: 30,
                            height: 30,

                            color: (theme) => theme.palette.text.primary
                        }}>
                        <CloseIcon sx={{opacity: 0.35, width: 18, height: 18}} />
                    </IconButton>
                </Stack>
            )}
            <Stack sx={{overflow: 'auto'}}>
                {hasTitle && (
                    <DialogTitle id='alert-dialog-title' typography={'h5'} sx={{py: 3, ...sxDialogTitle}}>
                        {modalTitle}
                    </DialogTitle>
                )}

                <DialogContent sx={{padding: {xs: '16px', md: '20px 24px'}, ...sxDialogContent}}>
                    {children}
                </DialogContent>
                {customActions && <DialogActions sx={{p: 0}}>{customActions}</DialogActions>}
            </Stack>
        </Dialog>
    );
};
