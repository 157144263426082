/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {useGetApiBandaSystemAccessRoleShow} from 'api/система';
import {SortButton, TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {PencilIcon, TrashIcon} from 'assets/icons';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {FormTextField} from 'components/form/FormTextField';
import dayjs from 'dayjs';
import {handleOpenRolesModal} from './AddRolesModal';
import {deleteRolesOpenModal} from './DeleteRolesModal';

export const fetchRoles = (params: {[p: string]: any}) => ({
    page: params.page || 1,
    per_page: params.per_page || '25',
    sort: params.sort,
    'filter[search]': params.name || ''
});
export default function PermissionRolesTable({form}: {form: any}) {
    const {reset} = form;
    const [params] = useQsParams();
    const {data: roles, isLoading} = useGetApiBandaSystemAccessRoleShow(fetchRoles(params));
    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            name: params.name
        });
    }, []);
    return (
        <TableLayout
            columns={ROLES_STRUCTURE}
            rows={roles?.data || []}
            isLoading={isLoading}
            pagination={roles?.pagination}
            filterRow
            // onClick={handleOpenModal}
            negativeMargin
        />
    );
}

const ROLES_STRUCTURE = [
    {
        title: <SortButton title={'Название'} sortName={'name'} />,
        filter: (
            <FormTextField
                name={'name'}
                isSearch
                placeholder={'Поиск по названию'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.name || '',
        sx: {width: '25%'}
    },
    {
        title: 'Дата создания',
        value: (item: any) => (dayjs(item.created_at).isValid() ? dayjs(item.created_at).format('DD.MM.YYYY') : ''),
        sx: {width: '20%'}
    },
    {
        title: 'Описание',
        filter: (
            <FormTextField
                name={'description'}
                isSearch
                placeholder={'Поиск по описанию'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.description || '',
        sx: {width: '25%'}
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item)} />,
        align: 'right',
        sx: {width: '5%'}
    }
];
const ITEM_ACTIONS = (item: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        // disabled: true,
        onClick: () => handleOpenRolesModal(item, 'Добавление роли')
    },
    {
        title: 'Удалить',
        icon: <TrashIcon />,
        onClick: () => deleteRolesOpenModal(item)
    }
];
