/* eslint-disable camelcase */
import {NewsCard} from './components/NewsCard';
import {Box, Button, Stack, useMediaQuery} from '@mui/material';
import {getApiBandaNews, getGetApiBandaNewsQueryKey} from 'api/новости';
import {useInfiniteQuery} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo} from 'react';
import {flatInfinityQuery} from 'utils/functions';
import {Loader} from 'components/accessory/Loader';
import {handleOpenNewsModal} from './components/ViewNews';
import {useNavigate, useParams} from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import {PlusIcon} from 'assets/icons';
import {Theme} from '@mui/material/styles';
import {closeModal} from 'context/modalEffector';
import {ROUTES} from 'utils/constants';

export const News = () => {
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const breakpoints: number[] = [0, 599, 1199, 1599];
    const getActiveBreakpoint = useCallback(() => {
        let currentBreakpoint: number | null = null;
        for (const breakpoint of breakpoints) {
            const isUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(breakpoint));
            if (isUp) {
                currentBreakpoint = breakpoint;
            }
        }
        return currentBreakpoint;
    }, [breakpoints]);

    const activeBreakpoint = getActiveBreakpoint();

    const {newsId} = useParams();
    const navigate = useNavigate();
    const {
        data: news,
        isLoading,
        fetchNextPage,
        isFetchingNextPage
    } = useInfiniteQuery({
        queryKey: getGetApiBandaNewsQueryKey(),
        queryFn: ({pageParam = 1}) => getApiBandaNews({page: pageParam, per_page: 25}),
        initialPageParam: 1,

        getNextPageParam: (data) => {
            //@ts-ignore
            if (data?.pagination?.current_page >= data?.pagination?.last_page) {
                return undefined;
            }
            //@ts-ignore
            return data.pagination.current_page + 1;
        }
    });
    const newsData = useMemo(() => flatInfinityQuery(news), [news]);
    useEffect(() => {
        const handleScroll = () => {
            if (!news) return;
            const scrollHeight = document.body.scrollHeight;
            const scrollTop = document.body.scrollTop;
            const clientHeight = window.innerHeight;
            const remainingElements = scrollHeight - scrollTop - clientHeight;
            const threshold = 800;
            if (remainingElements < threshold && !isFetchingNextPage) {
                fetchNextPage();
            }
        };
        document.body.addEventListener('scroll', handleScroll);
        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, [fetchNextPage, news, isFetchingNextPage]);
    const loading = isLoading || isFetchingNextPage;
    useEffect(() => {
        if (newsId) {
            handleOpenNewsModal(navigate);
        } else {
            closeModal();
        }
    }, [newsId]);
    const renderedNewsCards = useMemo(() => {
        return newsData?.map((item: any) => (
            <Stack key={item.id}>
                <NewsCard item={item} />
            </Stack>
        ));
    }, [newsData]);
    return (
        <Box>
            <Stack alignItems={'flex-end'} sx={{mb: {xs: 2, sm: 3}}}>
                <Button variant={'contained'} startIcon={<PlusIcon />} onClick={() => navigate(ROUTES.NEWS_EDITOR)}>
                    Добавить новость
                </Button>
            </Stack>
            <ResponsiveMasonry columnsCountBreakPoints={{100: 1, 600: 2, 1200: 3, 1600: 4}} key={activeBreakpoint}>
                <Masonry gutter={isSm ? '24px' : '16px'}>{renderedNewsCards}</Masonry>
            </ResponsiveMasonry>
            {loading && <Loader />}
        </Box>
    );
};
