/* eslint-disable camelcase */
import {Divider, Stack, Typography, useMediaQuery} from '@mui/material';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {TablePerPage} from 'components/layouts/MainLayout/components/TableLayout';
import {useEffect} from 'react';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Theme} from '@mui/material/styles';
import {ResetButton} from 'components/accessory/ResetButton';

export const TableHeader = ({allReset}: {allReset: () => void}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const form = useForm();
    const {reset} = form;
    const [params] = useQsParams();

    useEffect(() => {
        reset({per_page: params.per_page || '25'});
    }, []);
    const resetInputs = () => {
        reset({per_page: '25'});
        allReset();
    };
    return (
        <Form form={form}>
            <Stack gap={2}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    <Typography variant={'h5'}>Штатные расписания</Typography>
                </Stack>
                <Divider sx={{mx: {xs: -2, sm: -3}}} />
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    {isMd && <TablePerPage />}
                    <Stack direction={'row'} gap={2}>
                        {!!Object.keys(params).length && <ResetButton onClick={() => resetInputs()} />}
                    </Stack>
                </Stack>
            </Stack>
        </Form>
    );
};
