/* eslint-disable camelcase */
import * as React from 'react';
import {useGetApiBandaSystemAccessGroupShow} from 'api/система';
import {TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {PencilIcon} from 'assets/icons';
import {handleOpenEditGroupModal} from './AddEditGroupModal';

export const PermissionGroupsParams = (params: {[p: string]: any}) => ({
    page: params.page || 1,
    per_page: params.per_page || '25'
});
export default function PermissionGroupsTable({form}: {form: any}) {
    const [params] = useQsParams();
    const {data: groups, isLoading} = useGetApiBandaSystemAccessGroupShow(PermissionGroupsParams(params));
    return (
        <TableLayout
            columns={PERMISSION_STRUCTURE}
            rows={groups?.data || []}
            isLoading={isLoading}
            pagination={groups?.pagination}
            negativeMargin
        />
    );
}

const PERMISSION_STRUCTURE = [
    {
        title: 'Название',
        value: (item: any) => item.name
    },
    {
        title: 'Действия',
        value: (item: any) => <ActionsTableButton actions={ITEM_ACTIONS(item)} />,
        align: 'right'
    }
];
const ITEM_ACTIONS = (item: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => handleOpenEditGroupModal('Редактирование группы', item)
    }
];
