/* eslint-disable camelcase */
import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';
import {useInfiniteQuery} from '@tanstack/react-query';
import React, {useMemo, useState} from 'react';
import {AutocompleteInputChangeReason, AutocompleteProps, SxProps, TextFieldVariants} from '@mui/material';
import {flatInfinityQuery} from 'utils/functions';
import {debounce} from 'lodash';
import {ControllerProps} from 'react-hook-form';
import {Theme} from '@mui/material/styles';

export type FormAutocompleteScrollProps<
    T extends {id: number | string; textFreeSolo?: string; [key: string]: any},
    M extends boolean | undefined
> = Omit<ControllerProps, 'render'> &
    Omit<Omit<AutocompleteProps<T, M, any, any>, 'renderInput'>, 'options'> & {
        label?: string;
        optionText?: keyof T;
        optionValue?: keyof T;
        hasNextPage?: boolean;
        fetchNextPage?: () => void;
        fullWidth?: boolean;
        direction?: 'row' | 'column';
        inputSx?: SxProps<Theme>;
        labelSx?: SxProps<Theme>;
        maskProps?: any;
        variant?: TextFieldVariants;
        placeholder?: string;
        isSearch?: boolean;
        disabledColor?: boolean;
        queryKey: any;
        queryFn: any;
        settingsKey?: object;
        multiple?: boolean;
        searchName?: string;
    };

export const FormAutocompleteScroll = (props: FormAutocompleteScrollProps<any, any>) => {
    const {queryKey, queryFn, multiple, settingsKey, ...rest} = props;
    const [search, setSearch] = useState('');
    //@ts-ignore
    const {data, fetchNextPage, hasNextPage} = useInfiniteQuery({
        queryKey: queryKey({[rest?.searchName || 'filter[search]']: search, ...settingsKey}),
        queryFn: ({pageParam = 1}: {pageParam: number}) => {
            return queryFn({
                [rest?.searchName || 'filter[search]']: search,
                //@ts-ignore
                per_page: 25,
                page: pageParam || 1,
                ...settingsKey
            });
        },

        getNextPageParam: (
            lastPage //@ts-ignore
        ) =>
            lastPage?.pagination.last_page > lastPage?.pagination.current_page
                ? //@ts-ignore
                  +lastPage?.pagination.current_page + 1
                : undefined
    });
    const options = useMemo(() => flatInfinityQuery(data), [data]);
    const onInputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'reset') {
            setSearch('');
        } else {
            setSearch(`${value || ''}`);
        }
    };
    const debounceSetSearchString = debounce(onInputChange, 500);
    return (
        <FormAutocompleteAsyncPaginate
            {...rest}
            multiple={multiple}
            options={options}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onInputChange={debounceSetSearchString}
        />
    );
};
