/* eslint-disable camelcase */
import {Grid, Stack, Typography} from '@mui/material';
import {Form} from 'components/form/Form';
import {useForm} from 'react-hook-form';
import {FormTextField} from 'components/form/FormTextField';
import * as React from 'react';
import {useEffect} from 'react';
import {useAuthContext} from 'context/authContext';
import dayjs from 'dayjs';

export const PersonalInfo = () => {
    const form = useForm();
    const {reset} = form;
    const {me} = useAuthContext();
    useEffect(() => {
        reset({
            first_name: me?.first_name,
            last_name: me?.last_name,
            patronymic: me?.patronymic,
            date_of_birth: dayjs(me?.date_of_birth).isValid() ? dayjs(me?.date_of_birth).format('DD.MM.YYYY') : '',
            email: me?.email,
            phone: me?.phone
        });
    }, []);
    return (
        <Form form={form}>
            <Stack gap={4}>
                <Stack gap={2}>
                    <Typography variant={'h5'}>Личные данные</Typography>
                    <Grid container spacing={3}>
                        {PERSONAL_INPUTS.map((el, index) => (
                            <Grid item md={6} xs={12} key={index}>
                                <FormTextField
                                    name={el.name}
                                    placeholder={el.title}
                                    label={el.title}
                                    disabled
                                    autoComplete='off'
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
                <Stack gap={2}>
                    <Typography variant={'h5'}>Контактные данные</Typography>
                    <Grid container spacing={3}>
                        {CONTACT_INPUTS.map((el, index) => (
                            <Grid item md={6} xs={12} key={index}>
                                <FormTextField
                                    name={el.name}
                                    placeholder={el.title}
                                    label={el.title}
                                    disabled
                                    autoComplete='off'
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Stack>
        </Form>
    );
};
const PERSONAL_INPUTS = [
    {title: 'Имя', name: 'first_name'},
    {title: 'Фамилия', name: 'last_name'},
    {
        title: 'Отчество',
        name: 'patronymic'
    },
    {title: 'Дата рождения', name: 'date_of_birth'}
];
const CONTACT_INPUTS = [
    {title: 'E-mail', name: 'email'},
    {title: 'Телефон', name: 'phone'}
];
