/* eslint-disable camelcase */
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {Paper} from 'components/accessory/Paper';
import {Grid, Stack, Typography} from '@mui/material';
import {FormTextField} from 'components/form/FormTextField';
import * as React from 'react';
import {LoadingButton} from '@mui/lab';
import {usePatchApiBandaProfileChangePassword} from 'api/профиль';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {updatePasswordMessage} from 'utils/constants/messages';
import {closeModal} from 'context/modalEffector';
import {errorLayoutMessage} from 'utils/functions';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';

export const Safety = () => {
    const form = useForm(schema);
    const changePasswordMutation = usePatchApiBandaProfileChangePassword();
    const onSubmit = (data: {current_password: string; password: string; password_confirmation: string}) => {
        changePasswordMutation
            .mutateAsync({
                data
            })
            .then((r) => {
                openToast({
                    text: updatePasswordMessage,
                    type: TOAST_TYPE.SUCCESS
                });
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Paper sx={{p: {xs: 2, sm: 3}}}>
            <Form form={form} onSubmit={onSubmit}>
                <Stack gap={{xs: 2, sm: 3}}>
                    <Typography variant={'h5'}>Смена пароля</Typography>

                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <FormTextField
                                name={'current_password'}
                                placeholder={'············'}
                                password
                                label={'Текущий пароль'}
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item md={6} xs={12} />
                        <Grid item md={6} xs={12}>
                            <FormTextField
                                name={'password'}
                                placeholder={'············'}
                                password
                                label={'Новый пароль'}
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormTextField
                                name={'password_confirmation'}
                                placeholder={'············'}
                                password
                                label={'Подтверждение пароля'}
                                autoComplete='off'
                            />
                        </Grid>
                    </Grid>
                    <Stack gap={1}>
                        <Typography>Требования для пароля:</Typography>
                        <Typography>• Минимум 8 символов</Typography>
                        <Typography>• Хотя бы одна строчная буква</Typography>
                        <Typography>• Хотя бы одна цифра, символ или пробел</Typography>
                    </Stack>
                    <LoadingButton
                        variant={'contained'}
                        sx={{width: 'fit-content'}}
                        type={'submit'}
                        loading={changePasswordMutation.isPending}>
                        Сохранить
                    </LoadingButton>
                </Stack>
            </Form>
        </Paper>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            current_password: yup.string().required(ERROR_MESSAGE.REQUIRED),
            password: yup.string().required(ERROR_MESSAGE.REQUIRED),
            password_confirmation: yup.string().required(ERROR_MESSAGE.REQUIRED)
        })
    )
};
