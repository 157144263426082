/* eslint-disable camelcase */
import {Paper} from 'components/accessory/Paper';
import {Avatar, Stack, SxProps, Typography} from '@mui/material';
import {errorLayoutMessage, errorMessage, getCurrentDate, stringAvatar, successMessage} from 'utils/functions';
import * as React from 'react';
import {useRef, useState} from 'react';
import {ActionsTableButton} from 'components/accessory/ActionsTableButton';
import {CheckIcon, EyeIcon, HeartContainedIcon, HeartIcon, PencilIcon, TrashIcon} from 'assets/icons';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'utils/constants';
import {CountInfo} from './CountInfo';
import {Theme, useTheme} from '@mui/material/styles';
import {getGetApiBandaNewsQueryKey, useDeleteApiBandaNewsDestroy, usePutApiBandaNewsLikesCreate} from 'api/новости';
import {useQueryClient} from '@tanstack/react-query';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {closeModal} from 'context/modalEffector';

export const NewsCard = ({item}: {item: any}) => {
    return (
        <Paper>
            <CardHeader item={item} actions={ITEM_ACTIONS} />
            <CardInfo item={item} />
        </Paper>
    );
};

export const CardHeader = ({item, actions, sx}: {item?: any; actions?: any; sx?: SxProps<Theme>}) => {
    const navigate = useNavigate();
    const deleteMutation = useDeleteApiBandaNewsDestroy();
    const queryClient = useQueryClient();
    const deleteNews = () => {
        deleteMutation
            .mutateAsync({
                data: {id: item.id}
            })
            .then((r) => {
                successMessage('Новость удалена');
                handleDeleteItem(item.id, queryClient);
                closeModal();
            })
            .catch((error) => {
                errorMessage(error);
            });
    };
    return (
        <Stack p={{xs: 1.5, sm: 2}} direction={'row'} gap={1} alignItems={'top'} sx={sx}>
            {!!item?.author && (
                <Stack
                    direction={'row'}
                    gap={1}
                    onClick={() => {
                        closeModal();
                        navigate(ROUTES.PROFILE + '/' + item?.author?.id);
                    }}
                    sx={{cursor: 'pointer'}}>
                    <Avatar
                        {...stringAvatar(item?.author?.last_name + ' ' + item?.author?.first_name)}
                        src={item?.author?.avatar?.url}
                        sx={{
                            width: 42,
                            height: 42,
                            fontSize: '16px'
                        }}
                    />
                    <Stack justifyContent={'center'}>
                        <Typography fontWeight={500} sx={{color: (theme) => theme.palette.text.heading}}>
                            {item?.author?.last_name + ' ' + item?.author?.first_name + ' '}
                        </Typography>
                        <Typography variant={'subtitle2'} color={'text.disabled'}>
                            {getCurrentDate(item?.created_at)}
                        </Typography>
                    </Stack>
                </Stack>
            )}
            {actions && (
                <ActionsTableButton
                    actions={actions?.(item, navigate, deleteNews)}
                    color={'default'}
                    sx={{marginLeft: 'auto'}}
                />
            )}
        </Stack>
    );
};
const ITEM_ACTIONS = (item: any, navigate: any, deleteNews: any) => [
    {
        title: 'Редактировать',
        icon: <PencilIcon />,
        onClick: () => navigate(ROUTES.NEWS_EDITOR + '/' + item.id)
    },
    {
        title: 'Удалить',
        icon: <TrashIcon />,
        onClick: () => deleteNews()
    }
];

const CardInfo = ({item}: {item: any}) => {
    const [info, setInfo] = useState(item);
    const navigate = useNavigate();
    const ref = useRef<any>();
    const theme = useTheme();
    const likeMutate = usePutApiBandaNewsLikesCreate();
    const queryClient = useQueryClient();
    const likeClick = () => {
        setInfo({
            ...item,
            is_liked: !item.is_liked,
            count_likes: item?.count_likes + (item.is_liked ? -1 : 1)
        });

        handleUpdateLikeItem(
            item?.id,
            {
                ...item,
                is_liked: !item.is_liked,
                count_likes: item?.count_likes + (item.is_liked ? -1 : 1)
            },
            queryClient
        );
        likeMutate
            .mutateAsync({
                data: {news_id: item?.id as string}
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    const clickFunctions = {
        like: likeClick
    };

    return (
        <Stack
            gap={{xs: 2, sm: 3}}
            onClick={() => navigate(ROUTES.NEWS + '/' + info.id)}
            ref={ref}
            sx={{cursor: 'pointer'}}>
            {info?.thumbnail?.url && (
                <img
                    // loading='lazy'
                    alt={'image'}
                    style={{aspectRatio: info?.thumbnail?.dimensions?.width / info?.thumbnail?.dimensions?.height}}
                    // width={info?.thumbnail?.dimensions?.width}
                    // height={info?.thumbnail?.dimensions?.height}
                    // src={'https://funik.ru/wp-content/uploads/2018/10/17478da42271207e1d86.jpg'}
                    src={item?.thumbnail?.url}
                />
            )}
            <Stack sx={{mx: {xs: 1.5, sm: 2}, mb: {xs: 1.5, sm: 2}}}>
                <Stack gap={2}>
                    <Stack gap={1}>
                        <Typography variant={'h5'} color={'heading'}>
                            {info?.title}
                        </Typography>
                        <Typography>{info?.intro_text}</Typography>
                    </Stack>
                    <CountInfo newItem={info} arrayCount={COUNT_ITEMS(info, theme, clickFunctions)} />
                </Stack>
            </Stack>
        </Stack>
    );
};

const iconStyle = {width: 20, height: 20};
const COUNT_ITEMS = (infoNew: any, theme: Theme, clickFunctions?: any) => [
    {
        value: 'count_likes',
        icon: infoNew.is_liked ? (
            <HeartContainedIcon style={{...iconStyle, color: theme.palette.secondary.main}} />
        ) : (
            <HeartIcon style={iconStyle} />
        ),
        onClick: () => clickFunctions?.like()
    },
    {
        value: 'count_views',
        icon: <EyeIcon style={{...iconStyle}} />
    },
    ...(infoNew?.is_must_read
        ? [
              {
                  value: 'is_must_read',
                  icon: (
                      <CheckIcon
                          style={{
                              ...iconStyle,
                              color: infoNew?.is_read ? theme.palette.success.main : 'inherit'
                          }}
                      />
                  )
              }
          ]
        : [])
];
export const handleUpdateLikeItem = (id: string, newCardInfo: any, queryClient: any) => {
    queryClient.setQueryData(getGetApiBandaNewsQueryKey(), (old: any) => {
        if (!old) return old;
        const newPages = old.pages.map((page: any) => {
            const newData = page.data.map((item: any) => {
                if (item.id === id) {
                    return newCardInfo;
                }
                return item;
            });
            return {...page, data: newData};
        });
        return {...old, pages: newPages};
    });
};
export const handleDeleteItem = (id: string, queryClient: any) => {
    queryClient.setQueryData(getGetApiBandaNewsQueryKey(), (old: any) => {
        if (!old) return old;
        const newPages = old.pages.map((page: any) => {
            const copyData = [...page.data];
            const findIndex = copyData.findIndex((el) => el.id === id);
            copyData.splice(findIndex, 1);
            return {...page, data: copyData};
        });
        return {...old, pages: newPages};
    });
};
