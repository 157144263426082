import React, {ReactNode, useState} from 'react';
import {IconButton, Stack, SxProps, TextField, TextFieldProps} from '@mui/material';
import {ControllerProps, useController} from 'react-hook-form';
import {IMaskInput} from 'react-imask';
import {Theme} from '@mui/material/styles';
import {useQsParams} from 'utils/hooks/useQsParams';
import {debounce} from 'lodash';
import {Labeled} from 'components/form/Labeled';
import SearchIcon from '@mui/icons-material/Search';
import {EyeIcon, EyeOffIcon} from 'assets/icons';
import CloseIcon from '@mui/icons-material/Close';

const MaskCustom = React.forwardRef(function TextMaskCustom(props: any, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            onAccept={(value) => {
                onChange({target: {name: props?.name, value}});
            }}
        />
    );
});
export const FormTextField = (
    props: Omit<ControllerProps, 'render'> &
        Omit<TextFieldProps, 'label'> & {
            label?: string;
            isFilter?: boolean;
            maxLength?: number;
            name: string;
            maskProps?: any;
            fullWidth?: boolean;
            direction?: 'row' | 'column';
            labelSx?: SxProps<Theme>;
            password?: boolean;
            helperText?: string;
            isSearch?: boolean;
            hasSearchIcon?: boolean;
            labelInInput?: boolean;
            closeIcon?: boolean;
            customEnd?: ReactNode;
        }
) => {
    const {
        name,
        rules,
        placeholder,
        label,
        maxLength,
        maskProps,
        sx,
        fullWidth,
        direction,
        labelSx,
        password = false,
        helperText,
        isSearch,
        hasSearchIcon,
        labelInInput,
        closeIcon = true,
        customEnd,
        ...rest
    } = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: ''});
    const [showPassword, setShowPassword] = useState(false);
    const [, , setParamsSmart] = useQsParams();
    const debounceSetSearchString = isSearch
        ? debounce((e) => setParamsSmart({[name]: e.target?.value || ''}), 500)
        : () => {};

    return (
        <Labeled
            label={!labelInInput ? label : undefined}
            fullWidth={fullWidth}
            direction={direction}
            labelSx={labelSx}>
            <TextField
                {...field}
                fullWidth
                value={field.value || ''}
                sx={{maxWidth: '100%', ...sx}}
                helperText={error?.message || helperText}
                placeholder={placeholder || label}
                label={labelInInput ? label : undefined}
                type={!showPassword && password ? 'password' : 'text'}
                inputProps={{maxLength: maxLength || undefined}}
                onInput={debounceSetSearchString}
                error={!!error}
                InputProps={{
                    inputComponent: maskProps?.mask ? MaskCustom : undefined,
                    inputProps: {
                        ...maskProps,
                        maxLength: maxLength || undefined,
                        id: name
                    },
                    sx: {
                        letterSpacing: password && field.value ? '1px' : undefined
                    },
                    startAdornment: hasSearchIcon ? (
                        <SearchIcon sx={{mr: 1, color: (theme) => theme.palette.text.secondary}} />
                    ) : (
                        <></>
                    ),
                    endAdornment: (
                        <>
                            <Stack direction={'row'}>
                                {customEnd ? (
                                    customEnd
                                ) : (
                                    <>
                                        {field.value && !props.disabled && closeIcon && (
                                            <IconButton
                                                sx={{'& path': {fill: 'currentColor'}, p: '4px'}}
                                                size={'small'}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    field.onChange('');
                                                    if (isSearch) debounceSetSearchString?.('');
                                                }}>
                                                <CloseIcon
                                                    width={20}
                                                    height={20}
                                                    sx={{width: '20px', height: '20px'}}
                                                />
                                            </IconButton>
                                        )}
                                        {!props.multiline && (
                                            <>
                                                {}
                                                {password && (
                                                    <Stack
                                                        sx={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            minWidth: '32px',
                                                            px: 1
                                                        }}>
                                                        <IconButton
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            edge='end'
                                                            sx={{color: (theme) => theme.palette.text.disabled}}>
                                                            {showPassword ? (
                                                                <EyeIcon height={20} width={20} />
                                                            ) : (
                                                                <EyeOffIcon height={20} width={20} />
                                                            )}
                                                        </IconButton>
                                                    </Stack>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        </>
                    )
                }}
                {...rest}
            />
        </Labeled>
    );
};
