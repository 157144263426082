import {createEvent, createStore, restore, sample} from 'effector';
import React, {ReactNode} from 'react';
import {useUnit} from 'effector-react';
import {Alert, Snackbar} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Определение событий для открытия и закрытия модального окна
export const openToast = createEvent<handleOpenType>();
export const closeToast = createEvent();

// Создание хранилища для состояния открытия/закрытия модального окна
export const $isToastOpen = createStore(false)
    .on(openToast, () => true)
    .on(closeToast, () => false);

// Создание хранилища для содержимого модального окна
export const $contentToast = restore(openToast, {
    text: '',
    type: ''
});

sample({
    source: openToast,
    fn: (payload: handleOpenType) => {
        const {text, type} = payload;
        if (!text) {
            switch (type) {
                case TOAST_TYPE.SUCCESS: {
                    return {text: 'Успешно сохранено', type};
                    break;
                }
                case TOAST_TYPE.ERROR: {
                    return {text: 'Кажется что-то пошло не так', type};
                    break;
                }
                case TOAST_TYPE.WARNING: {
                    return {text: 'Происходит обработка данных', type};
                    break;
                }
            }
        } else {
            return payload;
        }
        return payload;
    },
    target: $contentToast
});

type handleOpenType = {text?: string | ReactNode; type: TOAST_TYPE_VALUES};

export const ToastEffector = ({children}: {children: ReactNode}) => {
    const {text, type} = useUnit($contentToast);
    return (
        <>
            {children}
            <Snackbar
                open={useUnit($isToastOpen)}
                autoHideDuration={5000}
                onClose={() => closeToast()}
                sx={{
                    marginTop: 10,
                    width: '240px',
                    minHeight: '80px',
                    '& .MuiAlert-root': {borderRadius: '8px', borderLeftWidth: '6px'}
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert
                    variant='outlined'
                    iconMapping={{error: <HighlightOffIcon />}}
                    severity={type as SeverityType}
                    onClose={() => closeToast()}
                    sx={{height: 'auto', whiteSpace: 'pre-line'}}>
                    {text}
                </Alert>
            </Snackbar>
        </>
    );
};

export const TOAST_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning'
};
type TOAST_TYPE_VALUES = (typeof TOAST_TYPE)[keyof typeof TOAST_TYPE];
type SeverityType = 'success' | 'error' | 'info' | 'warning' | undefined;
