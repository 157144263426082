import {alpha, Box, Stack, Tooltip} from '@mui/material';
import {$calendarDays} from 'context/schedulerEffector';
import {useUnit} from 'effector-react';
import {capitalizeFLetter} from 'utils/functions';
import dayjs from 'dayjs';

export const CalendarHeader = () => {
    const monthArray = useUnit($calendarDays);
    return (
        <Stack gap={0.5}>
            <Stack direction={'row'} gap={0.5}>
                {monthArray.map((day: any) => (
                    <CalendarHeaderCell title={day.format('DD')} key={day.$D} day={day} />
                ))}
            </Stack>
            <Stack direction={'row'} gap={0.5}>
                {monthArray.map((day: any) => (
                    <Tooltip title={'tooltip info'} key={day.$D}>
                        <Box>
                            <CalendarHeaderCell title={capitalizeFLetter(day.format('dd'))} day={day} />
                        </Box>
                    </Tooltip>
                ))}
            </Stack>
        </Stack>
    );
};

const CalendarHeaderCell = ({title, day}: {title: string; day: dayjs.Dayjs}) => {
    const dayOfTheWeek = capitalizeFLetter(day.format('dd'));
    return (
        <Stack
            sx={{
                minWidth: 36,
                height: 36,
                border: (theme) => '1px solid ' + theme.palette.divider,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: (theme) =>
                    dayOfTheWeek === 'Сб' || dayOfTheWeek === 'Вс'
                        ? alpha(theme.palette.secondary.main, 0.1)
                        : undefined
            }}>
            {title}
        </Stack>
    );
};
