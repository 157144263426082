import {Avatar, Stack, Typography} from '@mui/material';
import {stringAvatar} from 'utils/functions';
import * as React from 'react';
import {leftColumnWidth} from '../utils';

export const UserInfo = ({user}: {user: any}) => {
    return (
        <Stack
            sx={{
                width: leftColumnWidth,
                minHeight: 55,
                minWidth: leftColumnWidth,
                border: (theme) => '1px solid ' + theme.palette.divider,
                borderRadius: '6px',
                alignItems: 'center',
                padding: 1,
                gap: 2,
                position: 'sticky',
                left: {xs: '16px', md: '24px'},
                zIndex: 2,
                backgroundColor: (theme) => theme.palette.background.paper
            }}
            direction={'row'}>
            <Avatar
                {...stringAvatar(user.name)}
                sx={{
                    width: 32,
                    height: 32,
                    fontSize: '16px'
                }}
            />
            <Stack gap={0.25}>
                <Typography variant={'h6'} sx={{lineHeight: '14px', fontSize: '13px'}}>
                    {user.name}
                </Typography>
                <Typography
                    variant={'body2'}
                    color={'text.secondary'}
                    sx={{fontSize: '10px', lineHeight: '12px', letterSpacing: '0.75px'}}>
                    {user?.position_sl_name}
                </Typography>
            </Stack>
        </Stack>
    );
};
const name = 'Test Test';
