/* eslint-disable camelcase */
import {FormTextField} from 'components/form/FormTextField';
import * as React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE, MASKS} from 'utils/constants';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {closeModal, openModal} from 'context/modalEffector';
import {LoadingButton} from '@mui/lab';
import {
    getGetApiBandaSystemEmployeesPositionShowQueryKey,
    usePutApiBandaSystemEmployeesAccountsCreate
} from 'api/система';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {fetchPositions} from '../../Positions/components/PositionsTable';
import {FormDatePicker} from 'components/form/FormDatePicker';
import dayjs from 'dayjs';
import {errorLayoutMessage} from 'utils/functions';
import {DepartmentsInput} from 'components/custom-inputs/DepartmentsInput';
import {PositionsInputs} from 'components/custom-inputs/PositionsInputs';
import {PositionsSlInputs} from 'components/custom-inputs/PositionsSlInputs';

export const userEditOpenModal = (title?: string) => {
    openModal({
        title: title,
        body: <CreateUpdateModal />,
        maxWidth: 'md'
    });
};

export const CreateUpdateModal = () => {
    const form = useForm(schema);
    const {reset, watch} = form;
    const newMutation = usePutApiBandaSystemEmployeesAccountsCreate();
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemEmployeesPositionShowQueryKey(fetchPositions(params))
        });
    };
    const onSubmit = (data: any) => {
        newMutation
            .mutateAsync({
                data: {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    patronymic: data.patronymic,
                    date_of_birth: dayjs(data.date_of_birth).format('YYYY-MM-DD'),
                    phone: data.phone,
                    username: data.username,
                    password: data.password,
                    hiring_date: dayjs(data.hiring_date).format('YYYY-MM-DD'),
                    department_id: data.department_id?.id,
                    position_link_id: data.position_link_id?.id,
                    position_sl_id: data.position_sl_id?.id
                }
            })
            .then((r) => {
                openToast({
                    text: 'Аккаунт успешно создан',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={4}>
                <Stack gap={{xs: 2, sm: 3}} direction={'row'} sx={{flexWrap: {xs: 'wrap', md: 'nowrap'}}}>
                    <Stack gap={2} flexGrow={1}>
                        <Typography variant={'h6'}>Персональные данные</Typography>
                        <FormTextField name={'first_name'} placeholder={'Имя'} label={'Имя'} autoComplete='off' />
                        <FormTextField
                            name={'last_name'}
                            placeholder={'Фамилия'}
                            label={'Фамилия'}
                            autoComplete='off'
                        />
                        <FormTextField
                            name={'patronymic'}
                            placeholder={'Отчество'}
                            label={'Отчество'}
                            autoComplete='off'
                        />
                        <FormDatePicker
                            name={'date_of_birth'}
                            label={'Дата рождения'}
                            sx={{width: '100%'}}
                            autoComplete='off'
                        />
                        <FormTextField
                            name={'phone'}
                            placeholder={'Номер телефона'}
                            label={'Номер телефона'}
                            maskProps={{mask: MASKS.PHONE}}
                            autoComplete='off'
                        />
                    </Stack>
                    <Stack gap={{xs: 2, sm: 3}} flexGrow={1}>
                        <Stack gap={2}>
                            <Typography variant={'h6'}>Данные аккаунта</Typography>
                            <FormTextField name={'username'} placeholder={'Логин'} label={'Логин'} autoComplete='off' />
                            <FormTextField
                                name={'password'}
                                placeholder={'Пароль'}
                                label={'Пароль'}
                                autoComplete='off'
                            />
                        </Stack>
                        <Stack gap={2}>
                            <Typography variant={'h6'}>Внутренние данные</Typography>
                            <FormDatePicker
                                name={'hiring_date'}
                                label={'Дата найма'}
                                sx={{width: '100%'}}
                                autoComplete='off'
                            />
                            <DepartmentsInput label={'Подразделение'} />
                            <PositionsInputs label={'Должность'} />
                            <PositionsSlInputs />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={newMutation.isPending}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            first_name: yup.string().required(ERROR_MESSAGE.REQUIRED),
            last_name: yup.string().required(ERROR_MESSAGE.REQUIRED),
            // patronymic: yup.string().required(ERROR_MESSAGE.REQUIRED),
            date_of_birth: yup.string().required(ERROR_MESSAGE.REQUIRED),
            phone: yup.string().required(ERROR_MESSAGE.REQUIRED),
            username: yup.string().required(ERROR_MESSAGE.REQUIRED),
            password: yup.string().required(ERROR_MESSAGE.REQUIRED),
            hiring_date: yup.string().required(ERROR_MESSAGE.REQUIRED),
            department_id: yup.object().required(ERROR_MESSAGE.REQUIRED),
            position_link_id: yup.object().required(ERROR_MESSAGE.REQUIRED),
            position_sl_id: yup.object().required(ERROR_MESSAGE.REQUIRED)
        })
    )
};
