import {FormAutocompleteScroll} from 'components/accessory/FormAutocompleteScroll';
import {
    getApiBandaSystemEmployeesPositionSlShow,
    getGetApiBandaSystemEmployeesPositionSlShowQueryKey
} from 'api/система';
import * as React from 'react';

export const PositionsSlInputs = (props: {disabled?: boolean; params?: object}) => {
    const {disabled, params, ...rest} = props;
    return (
        <FormAutocompleteScroll
            name={'position_sl_id'}
            queryKey={getGetApiBandaSystemEmployeesPositionSlShowQueryKey}
            queryFn={getApiBandaSystemEmployeesPositionSlShow}
            placeholder={'Штатное расписание'}
            settingsKey={{module: 'system', ...params}}
            label={'Штатное расписание'}
            disabled={disabled}
            {...rest}
        />
    );
};
