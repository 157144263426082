/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    DeleteApiBandaSystemAccessGroupRemove200,
    DeleteApiBandaSystemAccessGroupRemove401,
    DeleteApiBandaSystemAccessGroupRemove403,
    DeleteApiBandaSystemAccessGroupRemove422,
    DeleteApiBandaSystemAccessGroupRemove500,
    DeleteApiBandaSystemAccessGroupRemoveBody,
    DeleteApiBandaSystemAccessRoleRemove401,
    DeleteApiBandaSystemAccessRoleRemove403,
    DeleteApiBandaSystemAccessRoleRemove422,
    DeleteApiBandaSystemAccessRoleRemove500,
    DeleteApiBandaSystemAccessRoleRemoveBody,
    GetApiBandaStructureGetManagers401,
    GetApiBandaStructureGetManagers403,
    GetApiBandaStructureGetManagers422,
    GetApiBandaStructureGetManagers500,
    GetApiBandaStructureGetSubordinates401,
    GetApiBandaStructureGetSubordinates403,
    GetApiBandaStructureGetSubordinates422,
    GetApiBandaStructureGetSubordinates500,
    GetApiBandaStructureGetSubordinatesBody,
    GetApiBandaStructureGetSubordinatesParams,
    GetApiBandaStructureIndex401,
    GetApiBandaStructureIndex403,
    GetApiBandaStructureIndex422,
    GetApiBandaStructureIndex500,
    GetApiBandaStructureIndexParams,
    GetApiBandaSystemAccessAccessShow200,
    GetApiBandaSystemAccessAccessShow401,
    GetApiBandaSystemAccessAccessShow403,
    GetApiBandaSystemAccessAccessShow422,
    GetApiBandaSystemAccessAccessShow500,
    GetApiBandaSystemAccessAccessShowParams,
    GetApiBandaSystemAccessContentGroupGroups200,
    GetApiBandaSystemAccessContentGroupGroups401,
    GetApiBandaSystemAccessContentGroupGroups403,
    GetApiBandaSystemAccessContentGroupGroups422,
    GetApiBandaSystemAccessGroupId200,
    GetApiBandaSystemAccessGroupId401,
    GetApiBandaSystemAccessGroupId403,
    GetApiBandaSystemAccessGroupId422,
    GetApiBandaSystemAccessGroupId500,
    GetApiBandaSystemAccessGroupShow200,
    GetApiBandaSystemAccessGroupShow401,
    GetApiBandaSystemAccessGroupShow403,
    GetApiBandaSystemAccessGroupShow422,
    GetApiBandaSystemAccessGroupShow500,
    GetApiBandaSystemAccessGroupShowParams,
    GetApiBandaSystemAccessPermissionGroupsShow401,
    GetApiBandaSystemAccessPermissionGroupsShow403,
    GetApiBandaSystemAccessPermissionGroupsShow422,
    GetApiBandaSystemAccessPermissionGroupsShow500,
    GetApiBandaSystemAccessPermissionGroupsShowNested401,
    GetApiBandaSystemAccessPermissionGroupsShowNested403,
    GetApiBandaSystemAccessPermissionGroupsShowNested422,
    GetApiBandaSystemAccessPermissionGroupsShowNested500,
    GetApiBandaSystemAccessPermissionShow200,
    GetApiBandaSystemAccessPermissionShow401,
    GetApiBandaSystemAccessPermissionShow403,
    GetApiBandaSystemAccessPermissionShow422,
    GetApiBandaSystemAccessPermissionShow500,
    GetApiBandaSystemAccessPermissionShowParams,
    GetApiBandaSystemAccessRoleDetailRolesId401,
    GetApiBandaSystemAccessRoleDetailRolesId403,
    GetApiBandaSystemAccessRoleDetailRolesId422,
    GetApiBandaSystemAccessRoleDetailRolesId500,
    GetApiBandaSystemAccessRoleShow200,
    GetApiBandaSystemAccessRoleShow401,
    GetApiBandaSystemAccessRoleShow403,
    GetApiBandaSystemAccessRoleShow422,
    GetApiBandaSystemAccessRoleShow500,
    GetApiBandaSystemAccessRoleShowParams,
    GetApiBandaSystemDepartmentsShow401,
    GetApiBandaSystemDepartmentsShow403,
    GetApiBandaSystemDepartmentsShow422,
    GetApiBandaSystemDepartmentsShow500,
    GetApiBandaSystemDepartmentsShowParams,
    GetApiBandaSystemDepartmentsTree401,
    GetApiBandaSystemDepartmentsTree403,
    GetApiBandaSystemDepartmentsTree422,
    GetApiBandaSystemDepartmentsTree500,
    GetApiBandaSystemDirectoryDepartments200,
    GetApiBandaSystemDirectoryDepartments401,
    GetApiBandaSystemDirectoryDepartments403,
    GetApiBandaSystemDirectoryDepartmentsParams,
    GetApiBandaSystemDirectoryEmployeeLink200,
    GetApiBandaSystemDirectoryEmployeeLink401,
    GetApiBandaSystemDirectoryEmployeeLink403,
    GetApiBandaSystemDirectoryEmployeeLinkParams,
    GetApiBandaSystemDirectoryEmployees200,
    GetApiBandaSystemDirectoryEmployees401,
    GetApiBandaSystemDirectoryEmployees403,
    GetApiBandaSystemDirectoryEmployeesParams,
    GetApiBandaSystemDirectoryGetSource200,
    GetApiBandaSystemDirectoryGetSource401,
    GetApiBandaSystemDirectoryGetSource403,
    GetApiBandaSystemDirectoryGetSourceParams,
    GetApiBandaSystemDirectoryPositions200,
    GetApiBandaSystemDirectoryPositions401,
    GetApiBandaSystemDirectoryPositions403,
    GetApiBandaSystemDirectoryPositionSl401,
    GetApiBandaSystemDirectoryPositionSl403,
    GetApiBandaSystemDirectoryPositionSlParams,
    GetApiBandaSystemDirectoryPositionsParams,
    GetApiBandaSystemEmployeesAccountsCreateCheckUsername401,
    GetApiBandaSystemEmployeesAccountsCreateCheckUsername403,
    GetApiBandaSystemEmployeesAccountsCreateCheckUsername422,
    GetApiBandaSystemEmployeesAccountsCreateCheckUsername500,
    GetApiBandaSystemEmployeesAccountsCreateCheckUsernameParams,
    GetApiBandaSystemEmployeesAccountsDetailEmployeesId401,
    GetApiBandaSystemEmployeesAccountsDetailEmployeesId403,
    GetApiBandaSystemEmployeesAccountsDetailEmployeesId422,
    GetApiBandaSystemEmployeesAccountsDetailEmployeesId500,
    GetApiBandaSystemEmployeesAccountsDirectoryStatuses401,
    GetApiBandaSystemEmployeesAccountsDirectoryStatuses403,
    GetApiBandaSystemEmployeesAccountsDirectoryStatuses422,
    GetApiBandaSystemEmployeesAccountsDirectoryStatuses500,
    GetApiBandaSystemEmployeesAccountsShow200,
    GetApiBandaSystemEmployeesAccountsShow401,
    GetApiBandaSystemEmployeesAccountsShow403,
    GetApiBandaSystemEmployeesAccountsShow422,
    GetApiBandaSystemEmployeesAccountsShow500,
    GetApiBandaSystemEmployeesAccountsShowParams,
    GetApiBandaSystemEmployeesControlShow200,
    GetApiBandaSystemEmployeesControlShow401,
    GetApiBandaSystemEmployeesControlShow403,
    GetApiBandaSystemEmployeesControlShow422,
    GetApiBandaSystemEmployeesControlShow500,
    GetApiBandaSystemEmployeesControlShowBody,
    GetApiBandaSystemEmployeesControlShowParams,
    GetApiBandaSystemEmployeesEmployeeLinkShow200,
    GetApiBandaSystemEmployeesEmployeeLinkShow401,
    GetApiBandaSystemEmployeesEmployeeLinkShow403,
    GetApiBandaSystemEmployeesEmployeeLinkShow422,
    GetApiBandaSystemEmployeesEmployeeLinkShow500,
    GetApiBandaSystemEmployeesEmployeeLinkShowParams,
    GetApiBandaSystemEmployeesPositionShow200,
    GetApiBandaSystemEmployeesPositionShow401,
    GetApiBandaSystemEmployeesPositionShow403,
    GetApiBandaSystemEmployeesPositionShow422,
    GetApiBandaSystemEmployeesPositionShow500,
    GetApiBandaSystemEmployeesPositionShowParams,
    GetApiBandaSystemEmployeesPositionSlShow200,
    GetApiBandaSystemEmployeesPositionSlShow401,
    GetApiBandaSystemEmployeesPositionSlShow403,
    GetApiBandaSystemEmployeesPositionSlShow422,
    GetApiBandaSystemEmployeesPositionSlShow500,
    GetApiBandaSystemEmployeesPositionSlShowParams,
    GetApiBandaSystemEmployeesServiceAccountsShow200,
    GetApiBandaSystemEmployeesServiceAccountsShow401,
    GetApiBandaSystemEmployeesServiceAccountsShow403,
    GetApiBandaSystemEmployeesServiceAccountsShow422,
    GetApiBandaSystemEmployeesServiceAccountsShow500,
    GetApiBandaSystemEmployeesServiceAccountsShowParams,
    GetApiBandaSystemLogsShowAuthLogs401,
    GetApiBandaSystemLogsShowAuthLogs403,
    GetApiBandaSystemLogsShowAuthLogs422,
    GetApiBandaSystemLogsShowAuthLogs500,
    GetApiBandaSystemLogsShowAuthLogsBody,
    GetApiBandaSystemLogsShowAuthLogsParams,
    GetApiBandaSystemLogsShowErrorLogs401,
    GetApiBandaSystemLogsShowErrorLogs403,
    GetApiBandaSystemLogsShowErrorLogs422,
    GetApiBandaSystemLogsShowErrorLogs500,
    GetApiBandaSystemLogsShowErrorLogsBody,
    GetApiBandaSystemLogsShowErrorLogsParams,
    GetApiBandaSystemLogsShowIntegrationLogs401,
    GetApiBandaSystemLogsShowIntegrationLogs403,
    GetApiBandaSystemLogsShowIntegrationLogs422,
    GetApiBandaSystemLogsShowIntegrationLogs500,
    GetApiBandaSystemLogsShowIntegrationLogsBody,
    GetApiBandaSystemLogsShowIntegrationLogsParams,
    GetApiBandaSystemLogsShowLogs401,
    GetApiBandaSystemLogsShowLogs403,
    GetApiBandaSystemLogsShowLogs422,
    GetApiBandaSystemLogsShowLogs500,
    GetApiBandaSystemLogsShowLogsBody,
    GetApiBandaSystemLogsShowLogsParams,
    GetApiBandaSystemRevisionList200,
    GetApiBandaSystemRevisionList401,
    GetApiBandaSystemRevisionList403,
    GetApiBandaSystemRevisionShow200,
    GetApiBandaSystemRevisionShow401,
    GetApiBandaSystemRevisionShow403,
    GetApiBandaSystemRevisionShowParams,
    PatchApiBandaStructureUpdate401,
    PatchApiBandaStructureUpdate403,
    PatchApiBandaStructureUpdate422,
    PatchApiBandaStructureUpdate500,
    PatchApiBandaStructureUpdateBody,
    PatchApiBandaSystemAccessAccessUpdate200,
    PatchApiBandaSystemAccessAccessUpdate401,
    PatchApiBandaSystemAccessAccessUpdate403,
    PatchApiBandaSystemAccessAccessUpdate422,
    PatchApiBandaSystemAccessAccessUpdate500,
    PatchApiBandaSystemAccessAccessUpdateBody,
    PatchApiBandaSystemAccessContentGroupEdit200,
    PatchApiBandaSystemAccessContentGroupEdit401,
    PatchApiBandaSystemAccessContentGroupEdit403,
    PatchApiBandaSystemAccessContentGroupEdit422,
    PatchApiBandaSystemAccessContentGroupEditBody,
    PatchApiBandaSystemAccessGroupUpdate200,
    PatchApiBandaSystemAccessGroupUpdate401,
    PatchApiBandaSystemAccessGroupUpdate403,
    PatchApiBandaSystemAccessGroupUpdate422,
    PatchApiBandaSystemAccessGroupUpdate500,
    PatchApiBandaSystemAccessGroupUpdateBody,
    PatchApiBandaSystemAccessRoleUpdate200,
    PatchApiBandaSystemAccessRoleUpdate401,
    PatchApiBandaSystemAccessRoleUpdate403,
    PatchApiBandaSystemAccessRoleUpdate422,
    PatchApiBandaSystemAccessRoleUpdate500,
    PatchApiBandaSystemAccessRoleUpdateBody,
    PatchApiBandaSystemDepartmentsUpdate200,
    PatchApiBandaSystemDepartmentsUpdate401,
    PatchApiBandaSystemDepartmentsUpdate403,
    PatchApiBandaSystemDepartmentsUpdate422,
    PatchApiBandaSystemDepartmentsUpdate500,
    PatchApiBandaSystemDepartmentsUpdateBody,
    PatchApiBandaSystemEmployeesAccountsEdit200,
    PatchApiBandaSystemEmployeesAccountsEdit401,
    PatchApiBandaSystemEmployeesAccountsEdit403,
    PatchApiBandaSystemEmployeesAccountsEdit422,
    PatchApiBandaSystemEmployeesAccountsEdit500,
    PatchApiBandaSystemEmployeesAccountsEditBody,
    PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager401,
    PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager403,
    PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager422,
    PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager500,
    PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody,
    PatchApiBandaSystemEmployeesControlBlock200,
    PatchApiBandaSystemEmployeesControlBlock401,
    PatchApiBandaSystemEmployeesControlBlock403,
    PatchApiBandaSystemEmployeesControlBlock422,
    PatchApiBandaSystemEmployeesControlBlock500,
    PatchApiBandaSystemEmployeesControlBlockBody,
    PatchApiBandaSystemEmployeesControlUnblock200,
    PatchApiBandaSystemEmployeesControlUnblock401,
    PatchApiBandaSystemEmployeesControlUnblock403,
    PatchApiBandaSystemEmployeesControlUnblock422,
    PatchApiBandaSystemEmployeesControlUnblock500,
    PatchApiBandaSystemEmployeesControlUnblockBody,
    PatchApiBandaSystemEmployeesEmployeeLinkUpdate200,
    PatchApiBandaSystemEmployeesEmployeeLinkUpdate401,
    PatchApiBandaSystemEmployeesEmployeeLinkUpdate403,
    PatchApiBandaSystemEmployeesEmployeeLinkUpdate422,
    PatchApiBandaSystemEmployeesEmployeeLinkUpdate500,
    PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody,
    PatchApiBandaSystemEmployeesPositionUpdate401,
    PatchApiBandaSystemEmployeesPositionUpdate403,
    PatchApiBandaSystemEmployeesPositionUpdate422,
    PatchApiBandaSystemEmployeesPositionUpdate500,
    PatchApiBandaSystemEmployeesPositionUpdateBody,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate200,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate401,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate403,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate422,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate500,
    PatchApiBandaSystemEmployeesServiceAccountsUpdateBody,
    PostApiBandaSystemEmployeesAccountsCreateGenerateUsername401,
    PostApiBandaSystemEmployeesAccountsCreateGenerateUsername403,
    PostApiBandaSystemEmployeesAccountsCreateGenerateUsername422,
    PostApiBandaSystemEmployeesAccountsCreateGenerateUsername500,
    PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody,
    PostApiBandaSystemEmployeesAccountsPasswordGenerate401,
    PostApiBandaSystemEmployeesAccountsPasswordGenerate403,
    PostApiBandaSystemEmployeesAccountsPasswordGenerate422,
    PostApiBandaSystemEmployeesAccountsPasswordGenerate500,
    PutApiBandaStructureCreate401,
    PutApiBandaStructureCreate403,
    PutApiBandaStructureCreate422,
    PutApiBandaStructureCreate500,
    PutApiBandaStructureCreateBody,
    PutApiBandaSystemAccessAccessCreate200,
    PutApiBandaSystemAccessAccessCreate401,
    PutApiBandaSystemAccessAccessCreate403,
    PutApiBandaSystemAccessAccessCreate422,
    PutApiBandaSystemAccessAccessCreate500,
    PutApiBandaSystemAccessAccessCreateBody,
    PutApiBandaSystemAccessContentGroupCreate200,
    PutApiBandaSystemAccessContentGroupCreate401,
    PutApiBandaSystemAccessContentGroupCreate403,
    PutApiBandaSystemAccessContentGroupCreate422,
    PutApiBandaSystemAccessContentGroupCreateBody,
    PutApiBandaSystemAccessGroupCreate200,
    PutApiBandaSystemAccessGroupCreate401,
    PutApiBandaSystemAccessGroupCreate403,
    PutApiBandaSystemAccessGroupCreate422,
    PutApiBandaSystemAccessGroupCreate500,
    PutApiBandaSystemAccessGroupCreateBody,
    PutApiBandaSystemAccessRoleCreate200,
    PutApiBandaSystemAccessRoleCreate401,
    PutApiBandaSystemAccessRoleCreate403,
    PutApiBandaSystemAccessRoleCreate422,
    PutApiBandaSystemAccessRoleCreate500,
    PutApiBandaSystemAccessRoleCreateBody,
    PutApiBandaSystemDepartmentsCreate200,
    PutApiBandaSystemDepartmentsCreate401,
    PutApiBandaSystemDepartmentsCreate403,
    PutApiBandaSystemDepartmentsCreate422,
    PutApiBandaSystemDepartmentsCreate500,
    PutApiBandaSystemDepartmentsCreateBody,
    PutApiBandaSystemEmployeesAccountsCreate200,
    PutApiBandaSystemEmployeesAccountsCreate401,
    PutApiBandaSystemEmployeesAccountsCreate403,
    PutApiBandaSystemEmployeesAccountsCreate422,
    PutApiBandaSystemEmployeesAccountsCreate500,
    PutApiBandaSystemEmployeesAccountsCreateBody,
    PutApiBandaSystemEmployeesEmployeeLinkCreate200,
    PutApiBandaSystemEmployeesEmployeeLinkCreate401,
    PutApiBandaSystemEmployeesEmployeeLinkCreate403,
    PutApiBandaSystemEmployeesEmployeeLinkCreate422,
    PutApiBandaSystemEmployeesEmployeeLinkCreate500,
    PutApiBandaSystemEmployeesEmployeeLinkCreateBody,
    PutApiBandaSystemEmployeesPositionCreate401,
    PutApiBandaSystemEmployeesPositionCreate403,
    PutApiBandaSystemEmployeesPositionCreate422,
    PutApiBandaSystemEmployeesPositionCreate500,
    PutApiBandaSystemEmployeesPositionCreateBody,
    PutApiBandaSystemEmployeesPositionSlCreate200,
    PutApiBandaSystemEmployeesPositionSlCreate401,
    PutApiBandaSystemEmployeesPositionSlCreate403,
    PutApiBandaSystemEmployeesPositionSlCreate422,
    PutApiBandaSystemEmployeesPositionSlCreate500,
    PutApiBandaSystemEmployeesPositionSlCreateBody,
    PutApiBandaSystemEmployeesServiceAccountsCreate200,
    PutApiBandaSystemEmployeesServiceAccountsCreate401,
    PutApiBandaSystemEmployeesServiceAccountsCreate403,
    PutApiBandaSystemEmployeesServiceAccountsCreate422,
    PutApiBandaSystemEmployeesServiceAccountsCreate500,
    PutApiBandaSystemEmployeesServiceAccountsCreateBody
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Получение логов
 */
export const getApiBandaSystemLogsShowLogs = (
    getApiBandaSystemLogsShowLogsBody?: GetApiBandaSystemLogsShowLogsBody,
    params?: GetApiBandaSystemLogsShowLogsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/logs/showLogs`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemLogsShowLogsQueryKey = (
    getApiBandaSystemLogsShowLogsBody?: GetApiBandaSystemLogsShowLogsBody,
    params?: GetApiBandaSystemLogsShowLogsParams
) => {
    return [`/api/banda/system/logs/showLogs`, ...(params ? [params] : []), getApiBandaSystemLogsShowLogsBody] as const;
};

export const getGetApiBandaSystemLogsShowLogsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>,
    TError =
        | GetApiBandaSystemLogsShowLogs401
        | GetApiBandaSystemLogsShowLogs403
        | GetApiBandaSystemLogsShowLogs422
        | GetApiBandaSystemLogsShowLogs500
>(
    getApiBandaSystemLogsShowLogsBody?: GetApiBandaSystemLogsShowLogsBody,
    params?: GetApiBandaSystemLogsShowLogsParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetApiBandaSystemLogsShowLogsQueryKey(getApiBandaSystemLogsShowLogsBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>> = ({signal}) =>
        getApiBandaSystemLogsShowLogs(getApiBandaSystemLogsShowLogsBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemLogsShowLogsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>
>;
export type GetApiBandaSystemLogsShowLogsQueryError =
    | GetApiBandaSystemLogsShowLogs401
    | GetApiBandaSystemLogsShowLogs403
    | GetApiBandaSystemLogsShowLogs422
    | GetApiBandaSystemLogsShowLogs500;

/**
 * @summary Получение логов
 */
export const useGetApiBandaSystemLogsShowLogs = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>,
    TError =
        | GetApiBandaSystemLogsShowLogs401
        | GetApiBandaSystemLogsShowLogs403
        | GetApiBandaSystemLogsShowLogs422
        | GetApiBandaSystemLogsShowLogs500
>(
    getApiBandaSystemLogsShowLogsBody?: GetApiBandaSystemLogsShowLogsBody,
    params?: GetApiBandaSystemLogsShowLogsParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowLogs>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemLogsShowLogsQueryOptions(
        getApiBandaSystemLogsShowLogsBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Получение логов авторизаций
 */
export const getApiBandaSystemLogsShowAuthLogs = (
    getApiBandaSystemLogsShowAuthLogsBody?: GetApiBandaSystemLogsShowAuthLogsBody,
    params?: GetApiBandaSystemLogsShowAuthLogsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/logs/showAuthLogs`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemLogsShowAuthLogsQueryKey = (
    getApiBandaSystemLogsShowAuthLogsBody?: GetApiBandaSystemLogsShowAuthLogsBody,
    params?: GetApiBandaSystemLogsShowAuthLogsParams
) => {
    return [
        `/api/banda/system/logs/showAuthLogs`,
        ...(params ? [params] : []),
        getApiBandaSystemLogsShowAuthLogsBody
    ] as const;
};

export const getGetApiBandaSystemLogsShowAuthLogsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>,
    TError =
        | GetApiBandaSystemLogsShowAuthLogs401
        | GetApiBandaSystemLogsShowAuthLogs403
        | GetApiBandaSystemLogsShowAuthLogs422
        | GetApiBandaSystemLogsShowAuthLogs500
>(
    getApiBandaSystemLogsShowAuthLogsBody?: GetApiBandaSystemLogsShowAuthLogsBody,
    params?: GetApiBandaSystemLogsShowAuthLogsParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemLogsShowAuthLogsQueryKey(getApiBandaSystemLogsShowAuthLogsBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>> = ({signal}) =>
        getApiBandaSystemLogsShowAuthLogs(getApiBandaSystemLogsShowAuthLogsBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemLogsShowAuthLogsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>
>;
export type GetApiBandaSystemLogsShowAuthLogsQueryError =
    | GetApiBandaSystemLogsShowAuthLogs401
    | GetApiBandaSystemLogsShowAuthLogs403
    | GetApiBandaSystemLogsShowAuthLogs422
    | GetApiBandaSystemLogsShowAuthLogs500;

/**
 * @summary Получение логов авторизаций
 */
export const useGetApiBandaSystemLogsShowAuthLogs = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>,
    TError =
        | GetApiBandaSystemLogsShowAuthLogs401
        | GetApiBandaSystemLogsShowAuthLogs403
        | GetApiBandaSystemLogsShowAuthLogs422
        | GetApiBandaSystemLogsShowAuthLogs500
>(
    getApiBandaSystemLogsShowAuthLogsBody?: GetApiBandaSystemLogsShowAuthLogsBody,
    params?: GetApiBandaSystemLogsShowAuthLogsParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowAuthLogs>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemLogsShowAuthLogsQueryOptions(
        getApiBandaSystemLogsShowAuthLogsBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Получение логов интеграций
 */
export const getApiBandaSystemLogsShowIntegrationLogs = (
    getApiBandaSystemLogsShowIntegrationLogsBody?: GetApiBandaSystemLogsShowIntegrationLogsBody,
    params?: GetApiBandaSystemLogsShowIntegrationLogsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/logs/showIntegrationLogs`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemLogsShowIntegrationLogsQueryKey = (
    getApiBandaSystemLogsShowIntegrationLogsBody?: GetApiBandaSystemLogsShowIntegrationLogsBody,
    params?: GetApiBandaSystemLogsShowIntegrationLogsParams
) => {
    return [
        `/api/banda/system/logs/showIntegrationLogs`,
        ...(params ? [params] : []),
        getApiBandaSystemLogsShowIntegrationLogsBody
    ] as const;
};

export const getGetApiBandaSystemLogsShowIntegrationLogsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>,
    TError =
        | GetApiBandaSystemLogsShowIntegrationLogs401
        | GetApiBandaSystemLogsShowIntegrationLogs403
        | GetApiBandaSystemLogsShowIntegrationLogs422
        | GetApiBandaSystemLogsShowIntegrationLogs500
>(
    getApiBandaSystemLogsShowIntegrationLogsBody?: GetApiBandaSystemLogsShowIntegrationLogsBody,
    params?: GetApiBandaSystemLogsShowIntegrationLogsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemLogsShowIntegrationLogsQueryKey(getApiBandaSystemLogsShowIntegrationLogsBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>> = ({signal}) =>
        getApiBandaSystemLogsShowIntegrationLogs(getApiBandaSystemLogsShowIntegrationLogsBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemLogsShowIntegrationLogsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>
>;
export type GetApiBandaSystemLogsShowIntegrationLogsQueryError =
    | GetApiBandaSystemLogsShowIntegrationLogs401
    | GetApiBandaSystemLogsShowIntegrationLogs403
    | GetApiBandaSystemLogsShowIntegrationLogs422
    | GetApiBandaSystemLogsShowIntegrationLogs500;

/**
 * @summary Получение логов интеграций
 */
export const useGetApiBandaSystemLogsShowIntegrationLogs = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>,
    TError =
        | GetApiBandaSystemLogsShowIntegrationLogs401
        | GetApiBandaSystemLogsShowIntegrationLogs403
        | GetApiBandaSystemLogsShowIntegrationLogs422
        | GetApiBandaSystemLogsShowIntegrationLogs500
>(
    getApiBandaSystemLogsShowIntegrationLogsBody?: GetApiBandaSystemLogsShowIntegrationLogsBody,
    params?: GetApiBandaSystemLogsShowIntegrationLogsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowIntegrationLogs>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemLogsShowIntegrationLogsQueryOptions(
        getApiBandaSystemLogsShowIntegrationLogsBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const getApiBandaSystemLogsShowErrorLogs = (
    getApiBandaSystemLogsShowErrorLogsBody?: GetApiBandaSystemLogsShowErrorLogsBody,
    params?: GetApiBandaSystemLogsShowErrorLogsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/logs/showErrorLogs`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemLogsShowErrorLogsQueryKey = (
    getApiBandaSystemLogsShowErrorLogsBody?: GetApiBandaSystemLogsShowErrorLogsBody,
    params?: GetApiBandaSystemLogsShowErrorLogsParams
) => {
    return [
        `/api/banda/system/logs/showErrorLogs`,
        ...(params ? [params] : []),
        getApiBandaSystemLogsShowErrorLogsBody
    ] as const;
};

export const getGetApiBandaSystemLogsShowErrorLogsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>,
    TError =
        | GetApiBandaSystemLogsShowErrorLogs401
        | GetApiBandaSystemLogsShowErrorLogs403
        | GetApiBandaSystemLogsShowErrorLogs422
        | GetApiBandaSystemLogsShowErrorLogs500
>(
    getApiBandaSystemLogsShowErrorLogsBody?: GetApiBandaSystemLogsShowErrorLogsBody,
    params?: GetApiBandaSystemLogsShowErrorLogsParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemLogsShowErrorLogsQueryKey(getApiBandaSystemLogsShowErrorLogsBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>> = ({signal}) =>
        getApiBandaSystemLogsShowErrorLogs(getApiBandaSystemLogsShowErrorLogsBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemLogsShowErrorLogsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>
>;
export type GetApiBandaSystemLogsShowErrorLogsQueryError =
    | GetApiBandaSystemLogsShowErrorLogs401
    | GetApiBandaSystemLogsShowErrorLogs403
    | GetApiBandaSystemLogsShowErrorLogs422
    | GetApiBandaSystemLogsShowErrorLogs500;

export const useGetApiBandaSystemLogsShowErrorLogs = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>,
    TError =
        | GetApiBandaSystemLogsShowErrorLogs401
        | GetApiBandaSystemLogsShowErrorLogs403
        | GetApiBandaSystemLogsShowErrorLogs422
        | GetApiBandaSystemLogsShowErrorLogs500
>(
    getApiBandaSystemLogsShowErrorLogsBody?: GetApiBandaSystemLogsShowErrorLogsBody,
    params?: GetApiBandaSystemLogsShowErrorLogsParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemLogsShowErrorLogs>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemLogsShowErrorLogsQueryOptions(
        getApiBandaSystemLogsShowErrorLogsBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник групп
 */
export const getApiBandaSystemAccessContentGroupGroups = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemAccessContentGroupGroups200>({
        url: `/api/banda/system/access_content_group/groups`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemAccessContentGroupGroupsQueryKey = () => {
    return [`/api/banda/system/access_content_group/groups`] as const;
};

export const getGetApiBandaSystemAccessContentGroupGroupsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>,
    TError =
        | GetApiBandaSystemAccessContentGroupGroups401
        | GetApiBandaSystemAccessContentGroupGroups403
        | GetApiBandaSystemAccessContentGroupGroups422
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>, TError, TData>
    >;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessContentGroupGroupsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>> = ({signal}) =>
        getApiBandaSystemAccessContentGroupGroups(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessContentGroupGroupsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>
>;
export type GetApiBandaSystemAccessContentGroupGroupsQueryError =
    | GetApiBandaSystemAccessContentGroupGroups401
    | GetApiBandaSystemAccessContentGroupGroups403
    | GetApiBandaSystemAccessContentGroupGroups422;

/**
 * @summary Справочник групп
 */
export const useGetApiBandaSystemAccessContentGroupGroups = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>,
    TError =
        | GetApiBandaSystemAccessContentGroupGroups401
        | GetApiBandaSystemAccessContentGroupGroups403
        | GetApiBandaSystemAccessContentGroupGroups422
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessContentGroupGroupsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание группы
 */
export const putApiBandaSystemAccessContentGroupCreate = (
    putApiBandaSystemAccessContentGroupCreateBody: PutApiBandaSystemAccessContentGroupCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessContentGroupCreate200>({
        url: `/api/banda/system/access_content_group/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessContentGroupCreateBody
    });
};

export const getPutApiBandaSystemAccessContentGroupCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessContentGroupCreate401
        | PutApiBandaSystemAccessContentGroupCreate403
        | PutApiBandaSystemAccessContentGroupCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
        TError,
        {data: PutApiBandaSystemAccessContentGroupCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
    TError,
    {data: PutApiBandaSystemAccessContentGroupCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
        {data: PutApiBandaSystemAccessContentGroupCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessContentGroupCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessContentGroupCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>
>;
export type PutApiBandaSystemAccessContentGroupCreateMutationBody = PutApiBandaSystemAccessContentGroupCreateBody;
export type PutApiBandaSystemAccessContentGroupCreateMutationError =
    | PutApiBandaSystemAccessContentGroupCreate401
    | PutApiBandaSystemAccessContentGroupCreate403
    | PutApiBandaSystemAccessContentGroupCreate422;

/**
 * @summary Создание группы
 */
export const usePutApiBandaSystemAccessContentGroupCreate = <
    TError =
        | PutApiBandaSystemAccessContentGroupCreate401
        | PutApiBandaSystemAccessContentGroupCreate403
        | PutApiBandaSystemAccessContentGroupCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
        TError,
        {data: PutApiBandaSystemAccessContentGroupCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
    TError,
    {data: PutApiBandaSystemAccessContentGroupCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessContentGroupCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование группы
 */
export const patchApiBandaSystemAccessContentGroupEdit = (
    patchApiBandaSystemAccessContentGroupEditBody: PatchApiBandaSystemAccessContentGroupEditBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessContentGroupEdit200>({
        url: `/api/banda/system/access_content_group/edit`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessContentGroupEditBody
    });
};

export const getPatchApiBandaSystemAccessContentGroupEditMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessContentGroupEdit401
        | PatchApiBandaSystemAccessContentGroupEdit403
        | PatchApiBandaSystemAccessContentGroupEdit422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
        TError,
        {data: PatchApiBandaSystemAccessContentGroupEditBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
    TError,
    {data: PatchApiBandaSystemAccessContentGroupEditBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
        {data: PatchApiBandaSystemAccessContentGroupEditBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessContentGroupEdit(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessContentGroupEditMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>
>;
export type PatchApiBandaSystemAccessContentGroupEditMutationBody = PatchApiBandaSystemAccessContentGroupEditBody;
export type PatchApiBandaSystemAccessContentGroupEditMutationError =
    | PatchApiBandaSystemAccessContentGroupEdit401
    | PatchApiBandaSystemAccessContentGroupEdit403
    | PatchApiBandaSystemAccessContentGroupEdit422;

/**
 * @summary Редактирование группы
 */
export const usePatchApiBandaSystemAccessContentGroupEdit = <
    TError =
        | PatchApiBandaSystemAccessContentGroupEdit401
        | PatchApiBandaSystemAccessContentGroupEdit403
        | PatchApiBandaSystemAccessContentGroupEdit422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
        TError,
        {data: PatchApiBandaSystemAccessContentGroupEditBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
    TError,
    {data: PatchApiBandaSystemAccessContentGroupEditBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessContentGroupEditMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список аккаунтов
 */
export const getApiBandaSystemEmployeesAccountsShow = (
    params?: GetApiBandaSystemEmployeesAccountsShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesAccountsShow200>({
        url: `/api/banda/system/employees/accounts/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesAccountsShowQueryKey = (
    params?: GetApiBandaSystemEmployeesAccountsShowParams
) => {
    return [`/api/banda/system/employees/accounts/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesAccountsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsShow401
        | GetApiBandaSystemEmployeesAccountsShow403
        | GetApiBandaSystemEmployeesAccountsShow422
        | GetApiBandaSystemEmployeesAccountsShow500
>(
    params?: GetApiBandaSystemEmployeesAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesAccountsShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>> = ({signal}) =>
        getApiBandaSystemEmployeesAccountsShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesAccountsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>
>;
export type GetApiBandaSystemEmployeesAccountsShowQueryError =
    | GetApiBandaSystemEmployeesAccountsShow401
    | GetApiBandaSystemEmployeesAccountsShow403
    | GetApiBandaSystemEmployeesAccountsShow422
    | GetApiBandaSystemEmployeesAccountsShow500;

/**
 * @summary Список аккаунтов
 */
export const useGetApiBandaSystemEmployeesAccountsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsShow401
        | GetApiBandaSystemEmployeesAccountsShow403
        | GetApiBandaSystemEmployeesAccountsShow422
        | GetApiBandaSystemEmployeesAccountsShow500
>(
    params?: GetApiBandaSystemEmployeesAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesAccountsShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Передаем юлид сотрудника
 * @summary Информация об аккаунте
 */
export const getApiBandaSystemEmployeesAccountsDetailEmployeesId = (employeesId: string, signal?: AbortSignal) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/accounts/detail/${employeesId}`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryKey = (employeesId: string) => {
    return [`/api/banda/system/employees/accounts/detail/${employeesId}`] as const;
};

export const getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId401
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId403
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId422
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId500
>(
    employeesId: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>,
                TError,
                TData
            >
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryKey(employeesId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>> = ({
        signal
    }) => getApiBandaSystemEmployeesAccountsDetailEmployeesId(employeesId, signal);

    return {queryKey, queryFn, enabled: !!employeesId, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>
>;
export type GetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryError =
    | GetApiBandaSystemEmployeesAccountsDetailEmployeesId401
    | GetApiBandaSystemEmployeesAccountsDetailEmployeesId403
    | GetApiBandaSystemEmployeesAccountsDetailEmployeesId422
    | GetApiBandaSystemEmployeesAccountsDetailEmployeesId500;

/**
 * @summary Информация об аккаунте
 */
export const useGetApiBandaSystemEmployeesAccountsDetailEmployeesId = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId401
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId403
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId422
        | GetApiBandaSystemEmployeesAccountsDetailEmployeesId500
>(
    employeesId: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDetailEmployeesId>>,
                TError,
                TData
            >
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryOptions(employeesId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание аккаунта
 */
export const putApiBandaSystemEmployeesAccountsCreate = (
    putApiBandaSystemEmployeesAccountsCreateBody: PutApiBandaSystemEmployeesAccountsCreateBody
) => {
    return axiosInstance<PutApiBandaSystemEmployeesAccountsCreate200>({
        url: `/api/banda/system/employees/accounts/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemEmployeesAccountsCreateBody
    });
};

export const getPutApiBandaSystemEmployeesAccountsCreateMutationOptions = <
    TError =
        | PutApiBandaSystemEmployeesAccountsCreate401
        | PutApiBandaSystemEmployeesAccountsCreate403
        | PutApiBandaSystemEmployeesAccountsCreate422
        | PutApiBandaSystemEmployeesAccountsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesAccountsCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesAccountsCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesAccountsCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesAccountsCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesAccountsCreate>>,
        {data: PutApiBandaSystemEmployeesAccountsCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemEmployeesAccountsCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemEmployeesAccountsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesAccountsCreate>>
>;
export type PutApiBandaSystemEmployeesAccountsCreateMutationBody = PutApiBandaSystemEmployeesAccountsCreateBody;
export type PutApiBandaSystemEmployeesAccountsCreateMutationError =
    | PutApiBandaSystemEmployeesAccountsCreate401
    | PutApiBandaSystemEmployeesAccountsCreate403
    | PutApiBandaSystemEmployeesAccountsCreate422
    | PutApiBandaSystemEmployeesAccountsCreate500;

/**
 * @summary Создание аккаунта
 */
export const usePutApiBandaSystemEmployeesAccountsCreate = <
    TError =
        | PutApiBandaSystemEmployeesAccountsCreate401
        | PutApiBandaSystemEmployeesAccountsCreate403
        | PutApiBandaSystemEmployeesAccountsCreate422
        | PutApiBandaSystemEmployeesAccountsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesAccountsCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesAccountsCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesAccountsCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesAccountsCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemEmployeesAccountsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Проверка на уникальность юзернайм
 */
export const getApiBandaSystemEmployeesAccountsCreateCheckUsername = (
    params: GetApiBandaSystemEmployeesAccountsCreateCheckUsernameParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/accounts/create/checkUsername`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesAccountsCreateCheckUsernameQueryKey = (
    params: GetApiBandaSystemEmployeesAccountsCreateCheckUsernameParams
) => {
    return [`/api/banda/system/employees/accounts/create/checkUsername`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesAccountsCreateCheckUsernameQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername401
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername403
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername422
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername500
>(
    params: GetApiBandaSystemEmployeesAccountsCreateCheckUsernameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>,
                TError,
                TData
            >
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesAccountsCreateCheckUsernameQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>> = ({
        signal
    }) => getApiBandaSystemEmployeesAccountsCreateCheckUsername(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesAccountsCreateCheckUsernameQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>
>;
export type GetApiBandaSystemEmployeesAccountsCreateCheckUsernameQueryError =
    | GetApiBandaSystemEmployeesAccountsCreateCheckUsername401
    | GetApiBandaSystemEmployeesAccountsCreateCheckUsername403
    | GetApiBandaSystemEmployeesAccountsCreateCheckUsername422
    | GetApiBandaSystemEmployeesAccountsCreateCheckUsername500;

/**
 * @summary Проверка на уникальность юзернайм
 */
export const useGetApiBandaSystemEmployeesAccountsCreateCheckUsername = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername401
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername403
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername422
        | GetApiBandaSystemEmployeesAccountsCreateCheckUsername500
>(
    params: GetApiBandaSystemEmployeesAccountsCreateCheckUsernameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsCreateCheckUsername>>,
                TError,
                TData
            >
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesAccountsCreateCheckUsernameQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Генерация юзернайм
 */
export const postApiBandaSystemEmployeesAccountsCreateGenerateUsername = (
    postApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody: PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/accounts/create/generateUsername`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody
    });
};

export const getPostApiBandaSystemEmployeesAccountsCreateGenerateUsernameMutationOptions = <
    TError =
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername401
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername403
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername422
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsCreateGenerateUsername>>,
        TError,
        {data: PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsCreateGenerateUsername>>,
    TError,
    {data: PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsCreateGenerateUsername>>,
        {data: PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaSystemEmployeesAccountsCreateGenerateUsername(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsCreateGenerateUsername>>
>;
export type PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameMutationBody =
    PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody;
export type PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameMutationError =
    | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername401
    | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername403
    | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername422
    | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername500;

/**
 * @summary Генерация юзернайм
 */
export const usePostApiBandaSystemEmployeesAccountsCreateGenerateUsername = <
    TError =
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername401
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername403
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername422
        | PostApiBandaSystemEmployeesAccountsCreateGenerateUsername500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsCreateGenerateUsername>>,
        TError,
        {data: PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsCreateGenerateUsername>>,
    TError,
    {data: PostApiBandaSystemEmployeesAccountsCreateGenerateUsernameBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaSystemEmployeesAccountsCreateGenerateUsernameMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование аккаунта
 */
export const patchApiBandaSystemEmployeesAccountsEdit = (
    patchApiBandaSystemEmployeesAccountsEditBody: PatchApiBandaSystemEmployeesAccountsEditBody
) => {
    return axiosInstance<PatchApiBandaSystemEmployeesAccountsEdit200>({
        url: `/api/banda/system/employees/accounts/edit`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesAccountsEditBody
    });
};

export const getPatchApiBandaSystemEmployeesAccountsEditMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesAccountsEdit401
        | PatchApiBandaSystemEmployeesAccountsEdit403
        | PatchApiBandaSystemEmployeesAccountsEdit422
        | PatchApiBandaSystemEmployeesAccountsEdit500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsEdit>>,
        TError,
        {data: PatchApiBandaSystemEmployeesAccountsEditBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsEdit>>,
    TError,
    {data: PatchApiBandaSystemEmployeesAccountsEditBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsEdit>>,
        {data: PatchApiBandaSystemEmployeesAccountsEditBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesAccountsEdit(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesAccountsEditMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsEdit>>
>;
export type PatchApiBandaSystemEmployeesAccountsEditMutationBody = PatchApiBandaSystemEmployeesAccountsEditBody;
export type PatchApiBandaSystemEmployeesAccountsEditMutationError =
    | PatchApiBandaSystemEmployeesAccountsEdit401
    | PatchApiBandaSystemEmployeesAccountsEdit403
    | PatchApiBandaSystemEmployeesAccountsEdit422
    | PatchApiBandaSystemEmployeesAccountsEdit500;

/**
 * @summary Редактирование аккаунта
 */
export const usePatchApiBandaSystemEmployeesAccountsEdit = <
    TError =
        | PatchApiBandaSystemEmployeesAccountsEdit401
        | PatchApiBandaSystemEmployeesAccountsEdit403
        | PatchApiBandaSystemEmployeesAccountsEdit422
        | PatchApiBandaSystemEmployeesAccountsEdit500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsEdit>>,
        TError,
        {data: PatchApiBandaSystemEmployeesAccountsEditBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsEdit>>,
    TError,
    {data: PatchApiBandaSystemEmployeesAccountsEditBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesAccountsEditMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Справочник статусов
 */
export const getApiBandaSystemEmployeesAccountsDirectoryStatuses = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/accounts/directory/statuses`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemEmployeesAccountsDirectoryStatusesQueryKey = () => {
    return [`/api/banda/system/employees/accounts/directory/statuses`] as const;
};

export const getGetApiBandaSystemEmployeesAccountsDirectoryStatusesQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses401
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses403
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses422
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses500
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>, TError, TData>
    >;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesAccountsDirectoryStatusesQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>> = ({
        signal
    }) => getApiBandaSystemEmployeesAccountsDirectoryStatuses(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesAccountsDirectoryStatusesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>
>;
export type GetApiBandaSystemEmployeesAccountsDirectoryStatusesQueryError =
    | GetApiBandaSystemEmployeesAccountsDirectoryStatuses401
    | GetApiBandaSystemEmployeesAccountsDirectoryStatuses403
    | GetApiBandaSystemEmployeesAccountsDirectoryStatuses422
    | GetApiBandaSystemEmployeesAccountsDirectoryStatuses500;

/**
 * @summary Справочник статусов
 */
export const useGetApiBandaSystemEmployeesAccountsDirectoryStatuses = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses401
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses403
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses422
        | GetApiBandaSystemEmployeesAccountsDirectoryStatuses500
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsDirectoryStatuses>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesAccountsDirectoryStatusesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Генерация пароля
 */
export const postApiBandaSystemEmployeesAccountsPasswordGenerate = () => {
    return axiosInstance<unknown>({url: `/api/banda/system/employees/accounts/password/generate`, method: 'POST'});
};

export const getPostApiBandaSystemEmployeesAccountsPasswordGenerateMutationOptions = <
    TError =
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate401
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate403
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate422
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsPasswordGenerate>>,
        TError,
        void,
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsPasswordGenerate>>,
    TError,
    void,
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsPasswordGenerate>>,
        void
    > = () => {
        return postApiBandaSystemEmployeesAccountsPasswordGenerate();
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaSystemEmployeesAccountsPasswordGenerateMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsPasswordGenerate>>
>;

export type PostApiBandaSystemEmployeesAccountsPasswordGenerateMutationError =
    | PostApiBandaSystemEmployeesAccountsPasswordGenerate401
    | PostApiBandaSystemEmployeesAccountsPasswordGenerate403
    | PostApiBandaSystemEmployeesAccountsPasswordGenerate422
    | PostApiBandaSystemEmployeesAccountsPasswordGenerate500;

/**
 * @summary Генерация пароля
 */
export const usePostApiBandaSystemEmployeesAccountsPasswordGenerate = <
    TError =
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate401
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate403
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate422
        | PostApiBandaSystemEmployeesAccountsPasswordGenerate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsPasswordGenerate>>,
        TError,
        void,
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaSystemEmployeesAccountsPasswordGenerate>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = getPostApiBandaSystemEmployeesAccountsPasswordGenerateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Смена пароля менеджером
 */
export const patchApiBandaSystemEmployeesAccountsPasswordChangeByManager = (
    patchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody: PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/accounts/password/changeByManager`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody
    });
};

export const getPatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager401
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager403
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager422
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsPasswordChangeByManager>>,
        TError,
        {data: PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsPasswordChangeByManager>>,
    TError,
    {data: PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsPasswordChangeByManager>>,
        {data: PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesAccountsPasswordChangeByManager(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsPasswordChangeByManager>>
>;
export type PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerMutationBody =
    PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody;
export type PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerMutationError =
    | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager401
    | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager403
    | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager422
    | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager500;

/**
 * @summary Смена пароля менеджером
 */
export const usePatchApiBandaSystemEmployeesAccountsPasswordChangeByManager = <
    TError =
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager401
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager403
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager422
        | PatchApiBandaSystemEmployeesAccountsPasswordChangeByManager500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsPasswordChangeByManager>>,
        TError,
        {data: PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesAccountsPasswordChangeByManager>>,
    TError,
    {data: PatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesAccountsPasswordChangeByManagerMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Информация о блокировках
 */
export const getApiBandaSystemEmployeesControlShow = (
    getApiBandaSystemEmployeesControlShowBody: GetApiBandaSystemEmployeesControlShowBody,
    params: GetApiBandaSystemEmployeesControlShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesControlShow200>({
        url: `/api/banda/system/employees/control/show`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesControlShowQueryKey = (
    getApiBandaSystemEmployeesControlShowBody: GetApiBandaSystemEmployeesControlShowBody,
    params: GetApiBandaSystemEmployeesControlShowParams
) => {
    return [
        `/api/banda/system/employees/control/show`,
        ...(params ? [params] : []),
        getApiBandaSystemEmployeesControlShowBody
    ] as const;
};

export const getGetApiBandaSystemEmployeesControlShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>,
    TError =
        | GetApiBandaSystemEmployeesControlShow401
        | GetApiBandaSystemEmployeesControlShow403
        | GetApiBandaSystemEmployeesControlShow422
        | GetApiBandaSystemEmployeesControlShow500
>(
    getApiBandaSystemEmployeesControlShowBody: GetApiBandaSystemEmployeesControlShowBody,
    params: GetApiBandaSystemEmployeesControlShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemEmployeesControlShowQueryKey(getApiBandaSystemEmployeesControlShowBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>> = ({signal}) =>
        getApiBandaSystemEmployeesControlShow(getApiBandaSystemEmployeesControlShowBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesControlShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>
>;
export type GetApiBandaSystemEmployeesControlShowQueryError =
    | GetApiBandaSystemEmployeesControlShow401
    | GetApiBandaSystemEmployeesControlShow403
    | GetApiBandaSystemEmployeesControlShow422
    | GetApiBandaSystemEmployeesControlShow500;

/**
 * @summary Информация о блокировках
 */
export const useGetApiBandaSystemEmployeesControlShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>,
    TError =
        | GetApiBandaSystemEmployeesControlShow401
        | GetApiBandaSystemEmployeesControlShow403
        | GetApiBandaSystemEmployeesControlShow422
        | GetApiBandaSystemEmployeesControlShow500
>(
    getApiBandaSystemEmployeesControlShowBody: GetApiBandaSystemEmployeesControlShowBody,
    params: GetApiBandaSystemEmployeesControlShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesControlShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesControlShowQueryOptions(
        getApiBandaSystemEmployeesControlShowBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Блокировка аккаунта
 */
export const patchApiBandaSystemEmployeesControlBlock = (
    patchApiBandaSystemEmployeesControlBlockBody: PatchApiBandaSystemEmployeesControlBlockBody
) => {
    return axiosInstance<PatchApiBandaSystemEmployeesControlBlock200>({
        url: `/api/banda/system/employees/control/block`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesControlBlockBody
    });
};

export const getPatchApiBandaSystemEmployeesControlBlockMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesControlBlock401
        | PatchApiBandaSystemEmployeesControlBlock403
        | PatchApiBandaSystemEmployeesControlBlock422
        | PatchApiBandaSystemEmployeesControlBlock500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlBlock>>,
        TError,
        {data: PatchApiBandaSystemEmployeesControlBlockBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlBlock>>,
    TError,
    {data: PatchApiBandaSystemEmployeesControlBlockBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlBlock>>,
        {data: PatchApiBandaSystemEmployeesControlBlockBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesControlBlock(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesControlBlockMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlBlock>>
>;
export type PatchApiBandaSystemEmployeesControlBlockMutationBody = PatchApiBandaSystemEmployeesControlBlockBody;
export type PatchApiBandaSystemEmployeesControlBlockMutationError =
    | PatchApiBandaSystemEmployeesControlBlock401
    | PatchApiBandaSystemEmployeesControlBlock403
    | PatchApiBandaSystemEmployeesControlBlock422
    | PatchApiBandaSystemEmployeesControlBlock500;

/**
 * @summary Блокировка аккаунта
 */
export const usePatchApiBandaSystemEmployeesControlBlock = <
    TError =
        | PatchApiBandaSystemEmployeesControlBlock401
        | PatchApiBandaSystemEmployeesControlBlock403
        | PatchApiBandaSystemEmployeesControlBlock422
        | PatchApiBandaSystemEmployeesControlBlock500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlBlock>>,
        TError,
        {data: PatchApiBandaSystemEmployeesControlBlockBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlBlock>>,
    TError,
    {data: PatchApiBandaSystemEmployeesControlBlockBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesControlBlockMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Разблокировка аккаунта
 */
export const patchApiBandaSystemEmployeesControlUnblock = (
    patchApiBandaSystemEmployeesControlUnblockBody: PatchApiBandaSystemEmployeesControlUnblockBody
) => {
    return axiosInstance<PatchApiBandaSystemEmployeesControlUnblock200>({
        url: `/api/banda/system/employees/control/unblock`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesControlUnblockBody
    });
};

export const getPatchApiBandaSystemEmployeesControlUnblockMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesControlUnblock401
        | PatchApiBandaSystemEmployeesControlUnblock403
        | PatchApiBandaSystemEmployeesControlUnblock422
        | PatchApiBandaSystemEmployeesControlUnblock500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlUnblock>>,
        TError,
        {data: PatchApiBandaSystemEmployeesControlUnblockBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlUnblock>>,
    TError,
    {data: PatchApiBandaSystemEmployeesControlUnblockBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlUnblock>>,
        {data: PatchApiBandaSystemEmployeesControlUnblockBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesControlUnblock(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesControlUnblockMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlUnblock>>
>;
export type PatchApiBandaSystemEmployeesControlUnblockMutationBody = PatchApiBandaSystemEmployeesControlUnblockBody;
export type PatchApiBandaSystemEmployeesControlUnblockMutationError =
    | PatchApiBandaSystemEmployeesControlUnblock401
    | PatchApiBandaSystemEmployeesControlUnblock403
    | PatchApiBandaSystemEmployeesControlUnblock422
    | PatchApiBandaSystemEmployeesControlUnblock500;

/**
 * @summary Разблокировка аккаунта
 */
export const usePatchApiBandaSystemEmployeesControlUnblock = <
    TError =
        | PatchApiBandaSystemEmployeesControlUnblock401
        | PatchApiBandaSystemEmployeesControlUnblock403
        | PatchApiBandaSystemEmployeesControlUnblock422
        | PatchApiBandaSystemEmployeesControlUnblock500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlUnblock>>,
        TError,
        {data: PatchApiBandaSystemEmployeesControlUnblockBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesControlUnblock>>,
    TError,
    {data: PatchApiBandaSystemEmployeesControlUnblockBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesControlUnblockMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список штатных расписаний
 */
export const getApiBandaSystemEmployeesPositionSlShow = (
    params?: GetApiBandaSystemEmployeesPositionSlShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesPositionSlShow200>({
        url: `/api/banda/system/employees/position_sl/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesPositionSlShowQueryKey = (
    params?: GetApiBandaSystemEmployeesPositionSlShowParams
) => {
    return [`/api/banda/system/employees/position_sl/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesPositionSlShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>,
    TError =
        | GetApiBandaSystemEmployeesPositionSlShow401
        | GetApiBandaSystemEmployeesPositionSlShow403
        | GetApiBandaSystemEmployeesPositionSlShow422
        | GetApiBandaSystemEmployeesPositionSlShow500
>(
    params?: GetApiBandaSystemEmployeesPositionSlShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesPositionSlShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>> = ({signal}) =>
        getApiBandaSystemEmployeesPositionSlShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesPositionSlShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>
>;
export type GetApiBandaSystemEmployeesPositionSlShowQueryError =
    | GetApiBandaSystemEmployeesPositionSlShow401
    | GetApiBandaSystemEmployeesPositionSlShow403
    | GetApiBandaSystemEmployeesPositionSlShow422
    | GetApiBandaSystemEmployeesPositionSlShow500;

/**
 * @summary Список штатных расписаний
 */
export const useGetApiBandaSystemEmployeesPositionSlShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>,
    TError =
        | GetApiBandaSystemEmployeesPositionSlShow401
        | GetApiBandaSystemEmployeesPositionSlShow403
        | GetApiBandaSystemEmployeesPositionSlShow422
        | GetApiBandaSystemEmployeesPositionSlShow500
>(
    params?: GetApiBandaSystemEmployeesPositionSlShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionSlShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesPositionSlShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание штатного расписания
 */
export const putApiBandaSystemEmployeesPositionSlCreate = (
    putApiBandaSystemEmployeesPositionSlCreateBody: PutApiBandaSystemEmployeesPositionSlCreateBody
) => {
    return axiosInstance<PutApiBandaSystemEmployeesPositionSlCreate200>({
        url: `/api/banda/system/employees/position_sl/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemEmployeesPositionSlCreateBody
    });
};

export const getPutApiBandaSystemEmployeesPositionSlCreateMutationOptions = <
    TError =
        | PutApiBandaSystemEmployeesPositionSlCreate401
        | PutApiBandaSystemEmployeesPositionSlCreate403
        | PutApiBandaSystemEmployeesPositionSlCreate422
        | PutApiBandaSystemEmployeesPositionSlCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionSlCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesPositionSlCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionSlCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesPositionSlCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionSlCreate>>,
        {data: PutApiBandaSystemEmployeesPositionSlCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemEmployeesPositionSlCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemEmployeesPositionSlCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionSlCreate>>
>;
export type PutApiBandaSystemEmployeesPositionSlCreateMutationBody = PutApiBandaSystemEmployeesPositionSlCreateBody;
export type PutApiBandaSystemEmployeesPositionSlCreateMutationError =
    | PutApiBandaSystemEmployeesPositionSlCreate401
    | PutApiBandaSystemEmployeesPositionSlCreate403
    | PutApiBandaSystemEmployeesPositionSlCreate422
    | PutApiBandaSystemEmployeesPositionSlCreate500;

/**
 * @summary Создание штатного расписания
 */
export const usePutApiBandaSystemEmployeesPositionSlCreate = <
    TError =
        | PutApiBandaSystemEmployeesPositionSlCreate401
        | PutApiBandaSystemEmployeesPositionSlCreate403
        | PutApiBandaSystemEmployeesPositionSlCreate422
        | PutApiBandaSystemEmployeesPositionSlCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionSlCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesPositionSlCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionSlCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesPositionSlCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemEmployeesPositionSlCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список должностей
 */
export const getApiBandaSystemEmployeesPositionShow = (
    params?: GetApiBandaSystemEmployeesPositionShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesPositionShow200>({
        url: `/api/banda/system/employees/position/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesPositionShowQueryKey = (
    params?: GetApiBandaSystemEmployeesPositionShowParams
) => {
    return [`/api/banda/system/employees/position/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesPositionShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>,
    TError =
        | GetApiBandaSystemEmployeesPositionShow401
        | GetApiBandaSystemEmployeesPositionShow403
        | GetApiBandaSystemEmployeesPositionShow422
        | GetApiBandaSystemEmployeesPositionShow500
>(
    params?: GetApiBandaSystemEmployeesPositionShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesPositionShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>> = ({signal}) =>
        getApiBandaSystemEmployeesPositionShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesPositionShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>
>;
export type GetApiBandaSystemEmployeesPositionShowQueryError =
    | GetApiBandaSystemEmployeesPositionShow401
    | GetApiBandaSystemEmployeesPositionShow403
    | GetApiBandaSystemEmployeesPositionShow422
    | GetApiBandaSystemEmployeesPositionShow500;

/**
 * @summary Список должностей
 */
export const useGetApiBandaSystemEmployeesPositionShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>,
    TError =
        | GetApiBandaSystemEmployeesPositionShow401
        | GetApiBandaSystemEmployeesPositionShow403
        | GetApiBandaSystemEmployeesPositionShow422
        | GetApiBandaSystemEmployeesPositionShow500
>(
    params?: GetApiBandaSystemEmployeesPositionShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesPositionShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesPositionShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание должности
 */
export const putApiBandaSystemEmployeesPositionCreate = (
    putApiBandaSystemEmployeesPositionCreateBody: PutApiBandaSystemEmployeesPositionCreateBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/position/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemEmployeesPositionCreateBody
    });
};

export const getPutApiBandaSystemEmployeesPositionCreateMutationOptions = <
    TError =
        | PutApiBandaSystemEmployeesPositionCreate401
        | PutApiBandaSystemEmployeesPositionCreate403
        | PutApiBandaSystemEmployeesPositionCreate422
        | PutApiBandaSystemEmployeesPositionCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesPositionCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesPositionCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionCreate>>,
        {data: PutApiBandaSystemEmployeesPositionCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemEmployeesPositionCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemEmployeesPositionCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionCreate>>
>;
export type PutApiBandaSystemEmployeesPositionCreateMutationBody = PutApiBandaSystemEmployeesPositionCreateBody;
export type PutApiBandaSystemEmployeesPositionCreateMutationError =
    | PutApiBandaSystemEmployeesPositionCreate401
    | PutApiBandaSystemEmployeesPositionCreate403
    | PutApiBandaSystemEmployeesPositionCreate422
    | PutApiBandaSystemEmployeesPositionCreate500;

/**
 * @summary Создание должности
 */
export const usePutApiBandaSystemEmployeesPositionCreate = <
    TError =
        | PutApiBandaSystemEmployeesPositionCreate401
        | PutApiBandaSystemEmployeesPositionCreate403
        | PutApiBandaSystemEmployeesPositionCreate422
        | PutApiBandaSystemEmployeesPositionCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesPositionCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesPositionCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesPositionCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemEmployeesPositionCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование должности
 */
export const patchApiBandaSystemEmployeesPositionUpdate = (
    patchApiBandaSystemEmployeesPositionUpdateBody: PatchApiBandaSystemEmployeesPositionUpdateBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/position/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesPositionUpdateBody
    });
};

export const getPatchApiBandaSystemEmployeesPositionUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesPositionUpdate401
        | PatchApiBandaSystemEmployeesPositionUpdate403
        | PatchApiBandaSystemEmployeesPositionUpdate422
        | PatchApiBandaSystemEmployeesPositionUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesPositionUpdate>>,
        TError,
        {data: PatchApiBandaSystemEmployeesPositionUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesPositionUpdate>>,
    TError,
    {data: PatchApiBandaSystemEmployeesPositionUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesPositionUpdate>>,
        {data: PatchApiBandaSystemEmployeesPositionUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesPositionUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesPositionUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesPositionUpdate>>
>;
export type PatchApiBandaSystemEmployeesPositionUpdateMutationBody = PatchApiBandaSystemEmployeesPositionUpdateBody;
export type PatchApiBandaSystemEmployeesPositionUpdateMutationError =
    | PatchApiBandaSystemEmployeesPositionUpdate401
    | PatchApiBandaSystemEmployeesPositionUpdate403
    | PatchApiBandaSystemEmployeesPositionUpdate422
    | PatchApiBandaSystemEmployeesPositionUpdate500;

/**
 * @summary Редактирование должности
 */
export const usePatchApiBandaSystemEmployeesPositionUpdate = <
    TError =
        | PatchApiBandaSystemEmployeesPositionUpdate401
        | PatchApiBandaSystemEmployeesPositionUpdate403
        | PatchApiBandaSystemEmployeesPositionUpdate422
        | PatchApiBandaSystemEmployeesPositionUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesPositionUpdate>>,
        TError,
        {data: PatchApiBandaSystemEmployeesPositionUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesPositionUpdate>>,
    TError,
    {data: PatchApiBandaSystemEmployeesPositionUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesPositionUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список рабочих профилей
 */
export const getApiBandaSystemEmployeesEmployeeLinkShow = (
    params?: GetApiBandaSystemEmployeesEmployeeLinkShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesEmployeeLinkShow200>({
        url: `/api/banda/system/employees/employee_link/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesEmployeeLinkShowQueryKey = (
    params?: GetApiBandaSystemEmployeesEmployeeLinkShowParams
) => {
    return [`/api/banda/system/employees/employee_link/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesEmployeeLinkShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>,
    TError =
        | GetApiBandaSystemEmployeesEmployeeLinkShow401
        | GetApiBandaSystemEmployeesEmployeeLinkShow403
        | GetApiBandaSystemEmployeesEmployeeLinkShow422
        | GetApiBandaSystemEmployeesEmployeeLinkShow500
>(
    params?: GetApiBandaSystemEmployeesEmployeeLinkShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesEmployeeLinkShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>> = ({signal}) =>
        getApiBandaSystemEmployeesEmployeeLinkShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesEmployeeLinkShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>
>;
export type GetApiBandaSystemEmployeesEmployeeLinkShowQueryError =
    | GetApiBandaSystemEmployeesEmployeeLinkShow401
    | GetApiBandaSystemEmployeesEmployeeLinkShow403
    | GetApiBandaSystemEmployeesEmployeeLinkShow422
    | GetApiBandaSystemEmployeesEmployeeLinkShow500;

/**
 * @summary Список рабочих профилей
 */
export const useGetApiBandaSystemEmployeesEmployeeLinkShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>,
    TError =
        | GetApiBandaSystemEmployeesEmployeeLinkShow401
        | GetApiBandaSystemEmployeesEmployeeLinkShow403
        | GetApiBandaSystemEmployeesEmployeeLinkShow422
        | GetApiBandaSystemEmployeesEmployeeLinkShow500
>(
    params?: GetApiBandaSystemEmployeesEmployeeLinkShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesEmployeeLinkShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesEmployeeLinkShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание рабочего профиля
 */
export const putApiBandaSystemEmployeesEmployeeLinkCreate = (
    putApiBandaSystemEmployeesEmployeeLinkCreateBody: PutApiBandaSystemEmployeesEmployeeLinkCreateBody
) => {
    return axiosInstance<PutApiBandaSystemEmployeesEmployeeLinkCreate200>({
        url: `/api/banda/system/employees/employee_link/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemEmployeesEmployeeLinkCreateBody
    });
};

export const getPutApiBandaSystemEmployeesEmployeeLinkCreateMutationOptions = <
    TError =
        | PutApiBandaSystemEmployeesEmployeeLinkCreate401
        | PutApiBandaSystemEmployeesEmployeeLinkCreate403
        | PutApiBandaSystemEmployeesEmployeeLinkCreate422
        | PutApiBandaSystemEmployeesEmployeeLinkCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesEmployeeLinkCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesEmployeeLinkCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesEmployeeLinkCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesEmployeeLinkCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesEmployeeLinkCreate>>,
        {data: PutApiBandaSystemEmployeesEmployeeLinkCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemEmployeesEmployeeLinkCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemEmployeesEmployeeLinkCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesEmployeeLinkCreate>>
>;
export type PutApiBandaSystemEmployeesEmployeeLinkCreateMutationBody = PutApiBandaSystemEmployeesEmployeeLinkCreateBody;
export type PutApiBandaSystemEmployeesEmployeeLinkCreateMutationError =
    | PutApiBandaSystemEmployeesEmployeeLinkCreate401
    | PutApiBandaSystemEmployeesEmployeeLinkCreate403
    | PutApiBandaSystemEmployeesEmployeeLinkCreate422
    | PutApiBandaSystemEmployeesEmployeeLinkCreate500;

/**
 * @summary Создание рабочего профиля
 */
export const usePutApiBandaSystemEmployeesEmployeeLinkCreate = <
    TError =
        | PutApiBandaSystemEmployeesEmployeeLinkCreate401
        | PutApiBandaSystemEmployeesEmployeeLinkCreate403
        | PutApiBandaSystemEmployeesEmployeeLinkCreate422
        | PutApiBandaSystemEmployeesEmployeeLinkCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesEmployeeLinkCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesEmployeeLinkCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesEmployeeLinkCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesEmployeeLinkCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemEmployeesEmployeeLinkCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование рабочего профиля
 */
export const patchApiBandaSystemEmployeesEmployeeLinkUpdate = (
    patchApiBandaSystemEmployeesEmployeeLinkUpdateBody: PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemEmployeesEmployeeLinkUpdate200>({
        url: `/api/banda/system/employees/employee_link/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesEmployeeLinkUpdateBody
    });
};

export const getPatchApiBandaSystemEmployeesEmployeeLinkUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate401
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate403
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate422
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesEmployeeLinkUpdate>>,
        TError,
        {data: PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesEmployeeLinkUpdate>>,
    TError,
    {data: PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesEmployeeLinkUpdate>>,
        {data: PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesEmployeeLinkUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesEmployeeLinkUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesEmployeeLinkUpdate>>
>;
export type PatchApiBandaSystemEmployeesEmployeeLinkUpdateMutationBody =
    PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody;
export type PatchApiBandaSystemEmployeesEmployeeLinkUpdateMutationError =
    | PatchApiBandaSystemEmployeesEmployeeLinkUpdate401
    | PatchApiBandaSystemEmployeesEmployeeLinkUpdate403
    | PatchApiBandaSystemEmployeesEmployeeLinkUpdate422
    | PatchApiBandaSystemEmployeesEmployeeLinkUpdate500;

/**
 * @summary Редактирование рабочего профиля
 */
export const usePatchApiBandaSystemEmployeesEmployeeLinkUpdate = <
    TError =
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate401
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate403
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate422
        | PatchApiBandaSystemEmployeesEmployeeLinkUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesEmployeeLinkUpdate>>,
        TError,
        {data: PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesEmployeeLinkUpdate>>,
    TError,
    {data: PatchApiBandaSystemEmployeesEmployeeLinkUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesEmployeeLinkUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список сервисных аккаунты
 */
export const getApiBandaSystemEmployeesServiceAccountsShow = (
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesServiceAccountsShow200>({
        url: `/api/banda/system/employees/service_accounts/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesServiceAccountsShowQueryKey = (
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams
) => {
    return [`/api/banda/system/employees/service_accounts/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesServiceAccountsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesServiceAccountsShow401
        | GetApiBandaSystemEmployeesServiceAccountsShow403
        | GetApiBandaSystemEmployeesServiceAccountsShow422
        | GetApiBandaSystemEmployeesServiceAccountsShow500
>(
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesServiceAccountsShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>> = ({
        signal
    }) => getApiBandaSystemEmployeesServiceAccountsShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemEmployeesServiceAccountsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>
>;
export type GetApiBandaSystemEmployeesServiceAccountsShowQueryError =
    | GetApiBandaSystemEmployeesServiceAccountsShow401
    | GetApiBandaSystemEmployeesServiceAccountsShow403
    | GetApiBandaSystemEmployeesServiceAccountsShow422
    | GetApiBandaSystemEmployeesServiceAccountsShow500;

/**
 * @summary Список сервисных аккаунты
 */
export const useGetApiBandaSystemEmployeesServiceAccountsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesServiceAccountsShow401
        | GetApiBandaSystemEmployeesServiceAccountsShow403
        | GetApiBandaSystemEmployeesServiceAccountsShow422
        | GetApiBandaSystemEmployeesServiceAccountsShow500
>(
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesServiceAccountsShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создать сервисный аккаунт
 */
export const putApiBandaSystemEmployeesServiceAccountsCreate = (
    putApiBandaSystemEmployeesServiceAccountsCreateBody: PutApiBandaSystemEmployeesServiceAccountsCreateBody
) => {
    return axiosInstance<PutApiBandaSystemEmployeesServiceAccountsCreate200>({
        url: `/api/banda/system/employees/service_accounts/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemEmployeesServiceAccountsCreateBody
    });
};

export const getPutApiBandaSystemEmployeesServiceAccountsCreateMutationOptions = <
    TError =
        | PutApiBandaSystemEmployeesServiceAccountsCreate401
        | PutApiBandaSystemEmployeesServiceAccountsCreate403
        | PutApiBandaSystemEmployeesServiceAccountsCreate422
        | PutApiBandaSystemEmployeesServiceAccountsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesServiceAccountsCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesServiceAccountsCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
        {data: PutApiBandaSystemEmployeesServiceAccountsCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemEmployeesServiceAccountsCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemEmployeesServiceAccountsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>
>;
export type PutApiBandaSystemEmployeesServiceAccountsCreateMutationBody =
    PutApiBandaSystemEmployeesServiceAccountsCreateBody;
export type PutApiBandaSystemEmployeesServiceAccountsCreateMutationError =
    | PutApiBandaSystemEmployeesServiceAccountsCreate401
    | PutApiBandaSystemEmployeesServiceAccountsCreate403
    | PutApiBandaSystemEmployeesServiceAccountsCreate422
    | PutApiBandaSystemEmployeesServiceAccountsCreate500;

/**
 * @summary Создать сервисный аккаунт
 */
export const usePutApiBandaSystemEmployeesServiceAccountsCreate = <
    TError =
        | PutApiBandaSystemEmployeesServiceAccountsCreate401
        | PutApiBandaSystemEmployeesServiceAccountsCreate403
        | PutApiBandaSystemEmployeesServiceAccountsCreate422
        | PutApiBandaSystemEmployeesServiceAccountsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
        TError,
        {data: PutApiBandaSystemEmployeesServiceAccountsCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
    TError,
    {data: PutApiBandaSystemEmployeesServiceAccountsCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemEmployeesServiceAccountsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование сервисного аккаунта
 */
export const patchApiBandaSystemEmployeesServiceAccountsUpdate = (
    patchApiBandaSystemEmployeesServiceAccountsUpdateBody: PatchApiBandaSystemEmployeesServiceAccountsUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemEmployeesServiceAccountsUpdate200>({
        url: `/api/banda/system/employees/service_accounts/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemEmployeesServiceAccountsUpdateBody
    });
};

export const getPatchApiBandaSystemEmployeesServiceAccountsUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate401
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate403
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate422
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
        TError,
        {data: PatchApiBandaSystemEmployeesServiceAccountsUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
    TError,
    {data: PatchApiBandaSystemEmployeesServiceAccountsUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
        {data: PatchApiBandaSystemEmployeesServiceAccountsUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemEmployeesServiceAccountsUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesServiceAccountsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>
>;
export type PatchApiBandaSystemEmployeesServiceAccountsUpdateMutationBody =
    PatchApiBandaSystemEmployeesServiceAccountsUpdateBody;
export type PatchApiBandaSystemEmployeesServiceAccountsUpdateMutationError =
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate401
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate403
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate422
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate500;

/**
 * @summary Редактирование сервисного аккаунта
 */
export const usePatchApiBandaSystemEmployeesServiceAccountsUpdate = <
    TError =
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate401
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate403
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate422
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
        TError,
        {data: PatchApiBandaSystemEmployeesServiceAccountsUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
    TError,
    {data: PatchApiBandaSystemEmployeesServiceAccountsUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesServiceAccountsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список подразделений
 */
export const getApiBandaSystemDepartmentsShow = (
    params?: GetApiBandaSystemDepartmentsShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({url: `/api/banda/system/departments/show`, method: 'GET', params, signal});
};

export const getGetApiBandaSystemDepartmentsShowQueryKey = (params?: GetApiBandaSystemDepartmentsShowParams) => {
    return [`/api/banda/system/departments/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDepartmentsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>,
    TError =
        | GetApiBandaSystemDepartmentsShow401
        | GetApiBandaSystemDepartmentsShow403
        | GetApiBandaSystemDepartmentsShow422
        | GetApiBandaSystemDepartmentsShow500
>(
    params?: GetApiBandaSystemDepartmentsShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDepartmentsShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>> = ({signal}) =>
        getApiBandaSystemDepartmentsShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDepartmentsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>
>;
export type GetApiBandaSystemDepartmentsShowQueryError =
    | GetApiBandaSystemDepartmentsShow401
    | GetApiBandaSystemDepartmentsShow403
    | GetApiBandaSystemDepartmentsShow422
    | GetApiBandaSystemDepartmentsShow500;

/**
 * @summary Список подразделений
 */
export const useGetApiBandaSystemDepartmentsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>,
    TError =
        | GetApiBandaSystemDepartmentsShow401
        | GetApiBandaSystemDepartmentsShow403
        | GetApiBandaSystemDepartmentsShow422
        | GetApiBandaSystemDepartmentsShow500
>(
    params?: GetApiBandaSystemDepartmentsShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDepartmentsShow>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDepartmentsShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание подразделения
 */
export const putApiBandaSystemDepartmentsCreate = (
    putApiBandaSystemDepartmentsCreateBody: PutApiBandaSystemDepartmentsCreateBody
) => {
    return axiosInstance<PutApiBandaSystemDepartmentsCreate200>({
        url: `/api/banda/system/departments/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemDepartmentsCreateBody
    });
};

export const getPutApiBandaSystemDepartmentsCreateMutationOptions = <
    TError =
        | PutApiBandaSystemDepartmentsCreate401
        | PutApiBandaSystemDepartmentsCreate403
        | PutApiBandaSystemDepartmentsCreate422
        | PutApiBandaSystemDepartmentsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemDepartmentsCreate>>,
        TError,
        {data: PutApiBandaSystemDepartmentsCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemDepartmentsCreate>>,
    TError,
    {data: PutApiBandaSystemDepartmentsCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemDepartmentsCreate>>,
        {data: PutApiBandaSystemDepartmentsCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemDepartmentsCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemDepartmentsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemDepartmentsCreate>>
>;
export type PutApiBandaSystemDepartmentsCreateMutationBody = PutApiBandaSystemDepartmentsCreateBody;
export type PutApiBandaSystemDepartmentsCreateMutationError =
    | PutApiBandaSystemDepartmentsCreate401
    | PutApiBandaSystemDepartmentsCreate403
    | PutApiBandaSystemDepartmentsCreate422
    | PutApiBandaSystemDepartmentsCreate500;

/**
 * @summary Создание подразделения
 */
export const usePutApiBandaSystemDepartmentsCreate = <
    TError =
        | PutApiBandaSystemDepartmentsCreate401
        | PutApiBandaSystemDepartmentsCreate403
        | PutApiBandaSystemDepartmentsCreate422
        | PutApiBandaSystemDepartmentsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemDepartmentsCreate>>,
        TError,
        {data: PutApiBandaSystemDepartmentsCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemDepartmentsCreate>>,
    TError,
    {data: PutApiBandaSystemDepartmentsCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemDepartmentsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование подразделения
 */
export const patchApiBandaSystemDepartmentsUpdate = (
    patchApiBandaSystemDepartmentsUpdateBody: PatchApiBandaSystemDepartmentsUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemDepartmentsUpdate200>({
        url: `/api/banda/system/departments/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemDepartmentsUpdateBody
    });
};

export const getPatchApiBandaSystemDepartmentsUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemDepartmentsUpdate401
        | PatchApiBandaSystemDepartmentsUpdate403
        | PatchApiBandaSystemDepartmentsUpdate422
        | PatchApiBandaSystemDepartmentsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemDepartmentsUpdate>>,
        TError,
        {data: PatchApiBandaSystemDepartmentsUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemDepartmentsUpdate>>,
    TError,
    {data: PatchApiBandaSystemDepartmentsUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemDepartmentsUpdate>>,
        {data: PatchApiBandaSystemDepartmentsUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemDepartmentsUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemDepartmentsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemDepartmentsUpdate>>
>;
export type PatchApiBandaSystemDepartmentsUpdateMutationBody = PatchApiBandaSystemDepartmentsUpdateBody;
export type PatchApiBandaSystemDepartmentsUpdateMutationError =
    | PatchApiBandaSystemDepartmentsUpdate401
    | PatchApiBandaSystemDepartmentsUpdate403
    | PatchApiBandaSystemDepartmentsUpdate422
    | PatchApiBandaSystemDepartmentsUpdate500;

/**
 * @summary Редактирование подразделения
 */
export const usePatchApiBandaSystemDepartmentsUpdate = <
    TError =
        | PatchApiBandaSystemDepartmentsUpdate401
        | PatchApiBandaSystemDepartmentsUpdate403
        | PatchApiBandaSystemDepartmentsUpdate422
        | PatchApiBandaSystemDepartmentsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemDepartmentsUpdate>>,
        TError,
        {data: PatchApiBandaSystemDepartmentsUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemDepartmentsUpdate>>,
    TError,
    {data: PatchApiBandaSystemDepartmentsUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemDepartmentsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Древовидная структура
 */
export const getApiBandaSystemDepartmentsTree = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/system/departments/tree`, method: 'GET', signal});
};

export const getGetApiBandaSystemDepartmentsTreeQueryKey = () => {
    return [`/api/banda/system/departments/tree`] as const;
};

export const getGetApiBandaSystemDepartmentsTreeQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>,
    TError =
        | GetApiBandaSystemDepartmentsTree401
        | GetApiBandaSystemDepartmentsTree403
        | GetApiBandaSystemDepartmentsTree422
        | GetApiBandaSystemDepartmentsTree500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDepartmentsTreeQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>> = ({signal}) =>
        getApiBandaSystemDepartmentsTree(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDepartmentsTreeQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>
>;
export type GetApiBandaSystemDepartmentsTreeQueryError =
    | GetApiBandaSystemDepartmentsTree401
    | GetApiBandaSystemDepartmentsTree403
    | GetApiBandaSystemDepartmentsTree422
    | GetApiBandaSystemDepartmentsTree500;

/**
 * @summary Древовидная структура
 */
export const useGetApiBandaSystemDepartmentsTree = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>,
    TError =
        | GetApiBandaSystemDepartmentsTree401
        | GetApiBandaSystemDepartmentsTree403
        | GetApiBandaSystemDepartmentsTree422
        | GetApiBandaSystemDepartmentsTree500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDepartmentsTree>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDepartmentsTreeQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Список доступов
 */
export const getApiBandaSystemAccessAccessShow = (
    params?: GetApiBandaSystemAccessAccessShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessAccessShow200>({
        url: `/api/banda/system/access/access/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessAccessShowQueryKey = (params?: GetApiBandaSystemAccessAccessShowParams) => {
    return [`/api/banda/system/access/access/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemAccessAccessShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>,
    TError =
        | GetApiBandaSystemAccessAccessShow401
        | GetApiBandaSystemAccessAccessShow403
        | GetApiBandaSystemAccessAccessShow422
        | GetApiBandaSystemAccessAccessShow500
>(
    params?: GetApiBandaSystemAccessAccessShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessAccessShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>> = ({signal}) =>
        getApiBandaSystemAccessAccessShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessAccessShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>
>;
export type GetApiBandaSystemAccessAccessShowQueryError =
    | GetApiBandaSystemAccessAccessShow401
    | GetApiBandaSystemAccessAccessShow403
    | GetApiBandaSystemAccessAccessShow422
    | GetApiBandaSystemAccessAccessShow500;

/**
 * @summary Список доступов
 */
export const useGetApiBandaSystemAccessAccessShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>,
    TError =
        | GetApiBandaSystemAccessAccessShow401
        | GetApiBandaSystemAccessAccessShow403
        | GetApiBandaSystemAccessAccessShow422
        | GetApiBandaSystemAccessAccessShow500
>(
    params?: GetApiBandaSystemAccessAccessShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessAccessShow>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessAccessShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание доступа
 */
export const putApiBandaSystemAccessAccessCreate = (
    putApiBandaSystemAccessAccessCreateBody: PutApiBandaSystemAccessAccessCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessAccessCreate200>({
        url: `/api/banda/system/access/access/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessAccessCreateBody
    });
};

export const getPutApiBandaSystemAccessAccessCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessAccessCreate401
        | PutApiBandaSystemAccessAccessCreate403
        | PutApiBandaSystemAccessAccessCreate422
        | PutApiBandaSystemAccessAccessCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessAccessCreate>>,
        TError,
        {data: PutApiBandaSystemAccessAccessCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessAccessCreate>>,
    TError,
    {data: PutApiBandaSystemAccessAccessCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessAccessCreate>>,
        {data: PutApiBandaSystemAccessAccessCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessAccessCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessAccessCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessAccessCreate>>
>;
export type PutApiBandaSystemAccessAccessCreateMutationBody = PutApiBandaSystemAccessAccessCreateBody;
export type PutApiBandaSystemAccessAccessCreateMutationError =
    | PutApiBandaSystemAccessAccessCreate401
    | PutApiBandaSystemAccessAccessCreate403
    | PutApiBandaSystemAccessAccessCreate422
    | PutApiBandaSystemAccessAccessCreate500;

/**
 * @summary Создание доступа
 */
export const usePutApiBandaSystemAccessAccessCreate = <
    TError =
        | PutApiBandaSystemAccessAccessCreate401
        | PutApiBandaSystemAccessAccessCreate403
        | PutApiBandaSystemAccessAccessCreate422
        | PutApiBandaSystemAccessAccessCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessAccessCreate>>,
        TError,
        {data: PutApiBandaSystemAccessAccessCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessAccessCreate>>,
    TError,
    {data: PutApiBandaSystemAccessAccessCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessAccessCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование доступа
 */
export const patchApiBandaSystemAccessAccessUpdate = (
    patchApiBandaSystemAccessAccessUpdateBody: PatchApiBandaSystemAccessAccessUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessAccessUpdate200>({
        url: `/api/banda/system/access/access/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessAccessUpdateBody
    });
};

export const getPatchApiBandaSystemAccessAccessUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessAccessUpdate401
        | PatchApiBandaSystemAccessAccessUpdate403
        | PatchApiBandaSystemAccessAccessUpdate422
        | PatchApiBandaSystemAccessAccessUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessAccessUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessAccessUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessAccessUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessAccessUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessAccessUpdate>>,
        {data: PatchApiBandaSystemAccessAccessUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessAccessUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessAccessUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessAccessUpdate>>
>;
export type PatchApiBandaSystemAccessAccessUpdateMutationBody = PatchApiBandaSystemAccessAccessUpdateBody;
export type PatchApiBandaSystemAccessAccessUpdateMutationError =
    | PatchApiBandaSystemAccessAccessUpdate401
    | PatchApiBandaSystemAccessAccessUpdate403
    | PatchApiBandaSystemAccessAccessUpdate422
    | PatchApiBandaSystemAccessAccessUpdate500;

/**
 * @summary Редактирование доступа
 */
export const usePatchApiBandaSystemAccessAccessUpdate = <
    TError =
        | PatchApiBandaSystemAccessAccessUpdate401
        | PatchApiBandaSystemAccessAccessUpdate403
        | PatchApiBandaSystemAccessAccessUpdate422
        | PatchApiBandaSystemAccessAccessUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessAccessUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessAccessUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessAccessUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessAccessUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessAccessUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список групп
 */
export const getApiBandaSystemAccessGroupShow = (
    params?: GetApiBandaSystemAccessGroupShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessGroupShow200>({
        url: `/api/banda/system/access/group/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessGroupShowQueryKey = (params?: GetApiBandaSystemAccessGroupShowParams) => {
    return [`/api/banda/system/access/group/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemAccessGroupShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>,
    TError =
        | GetApiBandaSystemAccessGroupShow401
        | GetApiBandaSystemAccessGroupShow403
        | GetApiBandaSystemAccessGroupShow422
        | GetApiBandaSystemAccessGroupShow500
>(
    params?: GetApiBandaSystemAccessGroupShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessGroupShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>> = ({signal}) =>
        getApiBandaSystemAccessGroupShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessGroupShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>
>;
export type GetApiBandaSystemAccessGroupShowQueryError =
    | GetApiBandaSystemAccessGroupShow401
    | GetApiBandaSystemAccessGroupShow403
    | GetApiBandaSystemAccessGroupShow422
    | GetApiBandaSystemAccessGroupShow500;

/**
 * @summary Список групп
 */
export const useGetApiBandaSystemAccessGroupShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>,
    TError =
        | GetApiBandaSystemAccessGroupShow401
        | GetApiBandaSystemAccessGroupShow403
        | GetApiBandaSystemAccessGroupShow422
        | GetApiBandaSystemAccessGroupShow500
>(
    params?: GetApiBandaSystemAccessGroupShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessGroupShow>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessGroupShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Детальная информация
 */
export const getApiBandaSystemAccessGroupId = (id: string, signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemAccessGroupId200>({
        url: `/api/banda/system/access/group/${id}`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemAccessGroupIdQueryKey = (id: string) => {
    return [`/api/banda/system/access/group/${id}`] as const;
};

export const getGetApiBandaSystemAccessGroupIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>,
    TError =
        | GetApiBandaSystemAccessGroupId401
        | GetApiBandaSystemAccessGroupId403
        | GetApiBandaSystemAccessGroupId422
        | GetApiBandaSystemAccessGroupId500
>(
    id: string,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessGroupIdQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>> = ({signal}) =>
        getApiBandaSystemAccessGroupId(id, signal);

    return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessGroupIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>
>;
export type GetApiBandaSystemAccessGroupIdQueryError =
    | GetApiBandaSystemAccessGroupId401
    | GetApiBandaSystemAccessGroupId403
    | GetApiBandaSystemAccessGroupId422
    | GetApiBandaSystemAccessGroupId500;

/**
 * @summary Детальная информация
 */
export const useGetApiBandaSystemAccessGroupId = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>,
    TError =
        | GetApiBandaSystemAccessGroupId401
        | GetApiBandaSystemAccessGroupId403
        | GetApiBandaSystemAccessGroupId422
        | GetApiBandaSystemAccessGroupId500
>(
    id: string,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessGroupId>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessGroupIdQueryOptions(id, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание группы
 */
export const putApiBandaSystemAccessGroupCreate = (
    putApiBandaSystemAccessGroupCreateBody: PutApiBandaSystemAccessGroupCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessGroupCreate200>({
        url: `/api/banda/system/access/group/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessGroupCreateBody
    });
};

export const getPutApiBandaSystemAccessGroupCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessGroupCreate401
        | PutApiBandaSystemAccessGroupCreate403
        | PutApiBandaSystemAccessGroupCreate422
        | PutApiBandaSystemAccessGroupCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessGroupCreate>>,
        TError,
        {data: PutApiBandaSystemAccessGroupCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessGroupCreate>>,
    TError,
    {data: PutApiBandaSystemAccessGroupCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessGroupCreate>>,
        {data: PutApiBandaSystemAccessGroupCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessGroupCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessGroupCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessGroupCreate>>
>;
export type PutApiBandaSystemAccessGroupCreateMutationBody = PutApiBandaSystemAccessGroupCreateBody;
export type PutApiBandaSystemAccessGroupCreateMutationError =
    | PutApiBandaSystemAccessGroupCreate401
    | PutApiBandaSystemAccessGroupCreate403
    | PutApiBandaSystemAccessGroupCreate422
    | PutApiBandaSystemAccessGroupCreate500;

/**
 * @summary Создание группы
 */
export const usePutApiBandaSystemAccessGroupCreate = <
    TError =
        | PutApiBandaSystemAccessGroupCreate401
        | PutApiBandaSystemAccessGroupCreate403
        | PutApiBandaSystemAccessGroupCreate422
        | PutApiBandaSystemAccessGroupCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessGroupCreate>>,
        TError,
        {data: PutApiBandaSystemAccessGroupCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessGroupCreate>>,
    TError,
    {data: PutApiBandaSystemAccessGroupCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessGroupCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование группы
 */
export const patchApiBandaSystemAccessGroupUpdate = (
    patchApiBandaSystemAccessGroupUpdateBody: PatchApiBandaSystemAccessGroupUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessGroupUpdate200>({
        url: `/api/banda/system/access/group/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessGroupUpdateBody
    });
};

export const getPatchApiBandaSystemAccessGroupUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessGroupUpdate401
        | PatchApiBandaSystemAccessGroupUpdate403
        | PatchApiBandaSystemAccessGroupUpdate422
        | PatchApiBandaSystemAccessGroupUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessGroupUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessGroupUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessGroupUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessGroupUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessGroupUpdate>>,
        {data: PatchApiBandaSystemAccessGroupUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessGroupUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessGroupUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessGroupUpdate>>
>;
export type PatchApiBandaSystemAccessGroupUpdateMutationBody = PatchApiBandaSystemAccessGroupUpdateBody;
export type PatchApiBandaSystemAccessGroupUpdateMutationError =
    | PatchApiBandaSystemAccessGroupUpdate401
    | PatchApiBandaSystemAccessGroupUpdate403
    | PatchApiBandaSystemAccessGroupUpdate422
    | PatchApiBandaSystemAccessGroupUpdate500;

/**
 * @summary Редактирование группы
 */
export const usePatchApiBandaSystemAccessGroupUpdate = <
    TError =
        | PatchApiBandaSystemAccessGroupUpdate401
        | PatchApiBandaSystemAccessGroupUpdate403
        | PatchApiBandaSystemAccessGroupUpdate422
        | PatchApiBandaSystemAccessGroupUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessGroupUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessGroupUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessGroupUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessGroupUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessGroupUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Удаление группы
 */
export const deleteApiBandaSystemAccessGroupRemove = (
    deleteApiBandaSystemAccessGroupRemoveBody: DeleteApiBandaSystemAccessGroupRemoveBody
) => {
    return axiosInstance<DeleteApiBandaSystemAccessGroupRemove200>({
        url: `/api/banda/system/access/group/remove`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        data: deleteApiBandaSystemAccessGroupRemoveBody
    });
};

export const getDeleteApiBandaSystemAccessGroupRemoveMutationOptions = <
    TError =
        | DeleteApiBandaSystemAccessGroupRemove401
        | DeleteApiBandaSystemAccessGroupRemove403
        | DeleteApiBandaSystemAccessGroupRemove422
        | DeleteApiBandaSystemAccessGroupRemove500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteApiBandaSystemAccessGroupRemove>>,
        TError,
        {data: DeleteApiBandaSystemAccessGroupRemoveBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiBandaSystemAccessGroupRemove>>,
    TError,
    {data: DeleteApiBandaSystemAccessGroupRemoveBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteApiBandaSystemAccessGroupRemove>>,
        {data: DeleteApiBandaSystemAccessGroupRemoveBody}
    > = (props) => {
        const {data} = props ?? {};

        return deleteApiBandaSystemAccessGroupRemove(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type DeleteApiBandaSystemAccessGroupRemoveMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteApiBandaSystemAccessGroupRemove>>
>;
export type DeleteApiBandaSystemAccessGroupRemoveMutationBody = DeleteApiBandaSystemAccessGroupRemoveBody;
export type DeleteApiBandaSystemAccessGroupRemoveMutationError =
    | DeleteApiBandaSystemAccessGroupRemove401
    | DeleteApiBandaSystemAccessGroupRemove403
    | DeleteApiBandaSystemAccessGroupRemove422
    | DeleteApiBandaSystemAccessGroupRemove500;

/**
 * @summary Удаление группы
 */
export const useDeleteApiBandaSystemAccessGroupRemove = <
    TError =
        | DeleteApiBandaSystemAccessGroupRemove401
        | DeleteApiBandaSystemAccessGroupRemove403
        | DeleteApiBandaSystemAccessGroupRemove422
        | DeleteApiBandaSystemAccessGroupRemove500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteApiBandaSystemAccessGroupRemove>>,
        TError,
        {data: DeleteApiBandaSystemAccessGroupRemoveBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteApiBandaSystemAccessGroupRemove>>,
    TError,
    {data: DeleteApiBandaSystemAccessGroupRemoveBody},
    TContext
> => {
    const mutationOptions = getDeleteApiBandaSystemAccessGroupRemoveMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список ролей
 */
export const getApiBandaSystemAccessRoleShow = (
    params?: GetApiBandaSystemAccessRoleShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessRoleShow200>({
        url: `/api/banda/system/access/role/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessRoleShowQueryKey = (params?: GetApiBandaSystemAccessRoleShowParams) => {
    return [`/api/banda/system/access/role/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemAccessRoleShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>,
    TError =
        | GetApiBandaSystemAccessRoleShow401
        | GetApiBandaSystemAccessRoleShow403
        | GetApiBandaSystemAccessRoleShow422
        | GetApiBandaSystemAccessRoleShow500
>(
    params?: GetApiBandaSystemAccessRoleShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessRoleShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>> = ({signal}) =>
        getApiBandaSystemAccessRoleShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessRoleShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>
>;
export type GetApiBandaSystemAccessRoleShowQueryError =
    | GetApiBandaSystemAccessRoleShow401
    | GetApiBandaSystemAccessRoleShow403
    | GetApiBandaSystemAccessRoleShow422
    | GetApiBandaSystemAccessRoleShow500;

/**
 * @summary Список ролей
 */
export const useGetApiBandaSystemAccessRoleShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>,
    TError =
        | GetApiBandaSystemAccessRoleShow401
        | GetApiBandaSystemAccessRoleShow403
        | GetApiBandaSystemAccessRoleShow422
        | GetApiBandaSystemAccessRoleShow500
>(
    params?: GetApiBandaSystemAccessRoleShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessRoleShow>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessRoleShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Детали роли
 */
export const getApiBandaSystemAccessRoleDetailRolesId = (rolesId: string, signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/system/access/role/detail/${rolesId}`, method: 'GET', signal});
};

export const getGetApiBandaSystemAccessRoleDetailRolesIdQueryKey = (rolesId: string) => {
    return [`/api/banda/system/access/role/detail/${rolesId}`] as const;
};

export const getGetApiBandaSystemAccessRoleDetailRolesIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>,
    TError =
        | GetApiBandaSystemAccessRoleDetailRolesId401
        | GetApiBandaSystemAccessRoleDetailRolesId403
        | GetApiBandaSystemAccessRoleDetailRolesId422
        | GetApiBandaSystemAccessRoleDetailRolesId500
>(
    rolesId: string,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessRoleDetailRolesIdQueryKey(rolesId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>> = ({signal}) =>
        getApiBandaSystemAccessRoleDetailRolesId(rolesId, signal);

    return {queryKey, queryFn, enabled: !!rolesId, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessRoleDetailRolesIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>
>;
export type GetApiBandaSystemAccessRoleDetailRolesIdQueryError =
    | GetApiBandaSystemAccessRoleDetailRolesId401
    | GetApiBandaSystemAccessRoleDetailRolesId403
    | GetApiBandaSystemAccessRoleDetailRolesId422
    | GetApiBandaSystemAccessRoleDetailRolesId500;

/**
 * @summary Детали роли
 */
export const useGetApiBandaSystemAccessRoleDetailRolesId = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>,
    TError =
        | GetApiBandaSystemAccessRoleDetailRolesId401
        | GetApiBandaSystemAccessRoleDetailRolesId403
        | GetApiBandaSystemAccessRoleDetailRolesId422
        | GetApiBandaSystemAccessRoleDetailRolesId500
>(
    rolesId: string,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessRoleDetailRolesId>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessRoleDetailRolesIdQueryOptions(rolesId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * В поле permissions добавляем слаг права, а если право имеет опции, то передаем опцию так {слаг}:{ключ опции}, пример news.create:all
 * @summary Создание роли
 */
export const putApiBandaSystemAccessRoleCreate = (
    putApiBandaSystemAccessRoleCreateBody: PutApiBandaSystemAccessRoleCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessRoleCreate200>({
        url: `/api/banda/system/access/role/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessRoleCreateBody
    });
};

export const getPutApiBandaSystemAccessRoleCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessRoleCreate401
        | PutApiBandaSystemAccessRoleCreate403
        | PutApiBandaSystemAccessRoleCreate422
        | PutApiBandaSystemAccessRoleCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessRoleCreate>>,
        TError,
        {data: PutApiBandaSystemAccessRoleCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessRoleCreate>>,
    TError,
    {data: PutApiBandaSystemAccessRoleCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessRoleCreate>>,
        {data: PutApiBandaSystemAccessRoleCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessRoleCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessRoleCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessRoleCreate>>
>;
export type PutApiBandaSystemAccessRoleCreateMutationBody = PutApiBandaSystemAccessRoleCreateBody;
export type PutApiBandaSystemAccessRoleCreateMutationError =
    | PutApiBandaSystemAccessRoleCreate401
    | PutApiBandaSystemAccessRoleCreate403
    | PutApiBandaSystemAccessRoleCreate422
    | PutApiBandaSystemAccessRoleCreate500;

/**
 * @summary Создание роли
 */
export const usePutApiBandaSystemAccessRoleCreate = <
    TError =
        | PutApiBandaSystemAccessRoleCreate401
        | PutApiBandaSystemAccessRoleCreate403
        | PutApiBandaSystemAccessRoleCreate422
        | PutApiBandaSystemAccessRoleCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessRoleCreate>>,
        TError,
        {data: PutApiBandaSystemAccessRoleCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessRoleCreate>>,
    TError,
    {data: PutApiBandaSystemAccessRoleCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessRoleCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * В поле permissions добавляем слаг права, а если право имеет опции, то передаем опцию так {слаг}:{ключ опции}, пример news.create:all
 * @summary Редактирование роли
 */
export const patchApiBandaSystemAccessRoleUpdate = (
    patchApiBandaSystemAccessRoleUpdateBody: PatchApiBandaSystemAccessRoleUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessRoleUpdate200>({
        url: `/api/banda/system/access/role/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessRoleUpdateBody
    });
};

export const getPatchApiBandaSystemAccessRoleUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessRoleUpdate401
        | PatchApiBandaSystemAccessRoleUpdate403
        | PatchApiBandaSystemAccessRoleUpdate422
        | PatchApiBandaSystemAccessRoleUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessRoleUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessRoleUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessRoleUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessRoleUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessRoleUpdate>>,
        {data: PatchApiBandaSystemAccessRoleUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessRoleUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessRoleUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessRoleUpdate>>
>;
export type PatchApiBandaSystemAccessRoleUpdateMutationBody = PatchApiBandaSystemAccessRoleUpdateBody;
export type PatchApiBandaSystemAccessRoleUpdateMutationError =
    | PatchApiBandaSystemAccessRoleUpdate401
    | PatchApiBandaSystemAccessRoleUpdate403
    | PatchApiBandaSystemAccessRoleUpdate422
    | PatchApiBandaSystemAccessRoleUpdate500;

/**
 * @summary Редактирование роли
 */
export const usePatchApiBandaSystemAccessRoleUpdate = <
    TError =
        | PatchApiBandaSystemAccessRoleUpdate401
        | PatchApiBandaSystemAccessRoleUpdate403
        | PatchApiBandaSystemAccessRoleUpdate422
        | PatchApiBandaSystemAccessRoleUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessRoleUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessRoleUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessRoleUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessRoleUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessRoleUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Удаление роли
 */
export const deleteApiBandaSystemAccessRoleRemove = (
    deleteApiBandaSystemAccessRoleRemoveBody: DeleteApiBandaSystemAccessRoleRemoveBody
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/access/role/remove`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        data: deleteApiBandaSystemAccessRoleRemoveBody
    });
};

export const getDeleteApiBandaSystemAccessRoleRemoveMutationOptions = <
    TError =
        | DeleteApiBandaSystemAccessRoleRemove401
        | DeleteApiBandaSystemAccessRoleRemove403
        | DeleteApiBandaSystemAccessRoleRemove422
        | DeleteApiBandaSystemAccessRoleRemove500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteApiBandaSystemAccessRoleRemove>>,
        TError,
        {data: DeleteApiBandaSystemAccessRoleRemoveBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiBandaSystemAccessRoleRemove>>,
    TError,
    {data: DeleteApiBandaSystemAccessRoleRemoveBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteApiBandaSystemAccessRoleRemove>>,
        {data: DeleteApiBandaSystemAccessRoleRemoveBody}
    > = (props) => {
        const {data} = props ?? {};

        return deleteApiBandaSystemAccessRoleRemove(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type DeleteApiBandaSystemAccessRoleRemoveMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteApiBandaSystemAccessRoleRemove>>
>;
export type DeleteApiBandaSystemAccessRoleRemoveMutationBody = DeleteApiBandaSystemAccessRoleRemoveBody;
export type DeleteApiBandaSystemAccessRoleRemoveMutationError =
    | DeleteApiBandaSystemAccessRoleRemove401
    | DeleteApiBandaSystemAccessRoleRemove403
    | DeleteApiBandaSystemAccessRoleRemove422
    | DeleteApiBandaSystemAccessRoleRemove500;

/**
 * @summary Удаление роли
 */
export const useDeleteApiBandaSystemAccessRoleRemove = <
    TError =
        | DeleteApiBandaSystemAccessRoleRemove401
        | DeleteApiBandaSystemAccessRoleRemove403
        | DeleteApiBandaSystemAccessRoleRemove422
        | DeleteApiBandaSystemAccessRoleRemove500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteApiBandaSystemAccessRoleRemove>>,
        TError,
        {data: DeleteApiBandaSystemAccessRoleRemoveBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteApiBandaSystemAccessRoleRemove>>,
    TError,
    {data: DeleteApiBandaSystemAccessRoleRemoveBody},
    TContext
> => {
    const mutationOptions = getDeleteApiBandaSystemAccessRoleRemoveMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список прав
 */
export const getApiBandaSystemAccessPermissionShow = (
    params?: GetApiBandaSystemAccessPermissionShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessPermissionShow200>({
        url: `/api/banda/system/access/permission/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessPermissionShowQueryKey = (
    params?: GetApiBandaSystemAccessPermissionShowParams
) => {
    return [`/api/banda/system/access/permission/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemAccessPermissionShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>,
    TError =
        | GetApiBandaSystemAccessPermissionShow401
        | GetApiBandaSystemAccessPermissionShow403
        | GetApiBandaSystemAccessPermissionShow422
        | GetApiBandaSystemAccessPermissionShow500
>(
    params?: GetApiBandaSystemAccessPermissionShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessPermissionShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>> = ({signal}) =>
        getApiBandaSystemAccessPermissionShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessPermissionShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>
>;
export type GetApiBandaSystemAccessPermissionShowQueryError =
    | GetApiBandaSystemAccessPermissionShow401
    | GetApiBandaSystemAccessPermissionShow403
    | GetApiBandaSystemAccessPermissionShow422
    | GetApiBandaSystemAccessPermissionShow500;

/**
 * @summary Список прав
 */
export const useGetApiBandaSystemAccessPermissionShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>,
    TError =
        | GetApiBandaSystemAccessPermissionShow401
        | GetApiBandaSystemAccessPermissionShow403
        | GetApiBandaSystemAccessPermissionShow422
        | GetApiBandaSystemAccessPermissionShow500
>(
    params?: GetApiBandaSystemAccessPermissionShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessPermissionShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Список групп прав
 */
export const getApiBandaSystemAccessPermissionGroupsShow = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/system/access/permission/groups/show`, method: 'GET', signal});
};

export const getGetApiBandaSystemAccessPermissionGroupsShowQueryKey = () => {
    return [`/api/banda/system/access/permission/groups/show`] as const;
};

export const getGetApiBandaSystemAccessPermissionGroupsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>,
    TError =
        | GetApiBandaSystemAccessPermissionGroupsShow401
        | GetApiBandaSystemAccessPermissionGroupsShow403
        | GetApiBandaSystemAccessPermissionGroupsShow422
        | GetApiBandaSystemAccessPermissionGroupsShow500
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>, TError, TData>
    >;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessPermissionGroupsShowQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>> = ({
        signal
    }) => getApiBandaSystemAccessPermissionGroupsShow(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessPermissionGroupsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>
>;
export type GetApiBandaSystemAccessPermissionGroupsShowQueryError =
    | GetApiBandaSystemAccessPermissionGroupsShow401
    | GetApiBandaSystemAccessPermissionGroupsShow403
    | GetApiBandaSystemAccessPermissionGroupsShow422
    | GetApiBandaSystemAccessPermissionGroupsShow500;

/**
 * @summary Список групп прав
 */
export const useGetApiBandaSystemAccessPermissionGroupsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>,
    TError =
        | GetApiBandaSystemAccessPermissionGroupsShow401
        | GetApiBandaSystemAccessPermissionGroupsShow403
        | GetApiBandaSystemAccessPermissionGroupsShow422
        | GetApiBandaSystemAccessPermissionGroupsShow500
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShow>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessPermissionGroupsShowQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Список групп прав (вложенные)
 */
export const getApiBandaSystemAccessPermissionGroupsShowNested = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/access/permission/groups/show/nested`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemAccessPermissionGroupsShowNestedQueryKey = () => {
    return [`/api/banda/system/access/permission/groups/show/nested`] as const;
};

export const getGetApiBandaSystemAccessPermissionGroupsShowNestedQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>,
    TError =
        | GetApiBandaSystemAccessPermissionGroupsShowNested401
        | GetApiBandaSystemAccessPermissionGroupsShowNested403
        | GetApiBandaSystemAccessPermissionGroupsShowNested422
        | GetApiBandaSystemAccessPermissionGroupsShowNested500
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>, TError, TData>
    >;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessPermissionGroupsShowNestedQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>> = ({
        signal
    }) => getApiBandaSystemAccessPermissionGroupsShowNested(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemAccessPermissionGroupsShowNestedQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>
>;
export type GetApiBandaSystemAccessPermissionGroupsShowNestedQueryError =
    | GetApiBandaSystemAccessPermissionGroupsShowNested401
    | GetApiBandaSystemAccessPermissionGroupsShowNested403
    | GetApiBandaSystemAccessPermissionGroupsShowNested422
    | GetApiBandaSystemAccessPermissionGroupsShowNested500;

/**
 * @summary Список групп прав (вложенные)
 */
export const useGetApiBandaSystemAccessPermissionGroupsShowNested = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>,
    TError =
        | GetApiBandaSystemAccessPermissionGroupsShowNested401
        | GetApiBandaSystemAccessPermissionGroupsShowNested403
        | GetApiBandaSystemAccessPermissionGroupsShowNested422
        | GetApiBandaSystemAccessPermissionGroupsShowNested500
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessPermissionGroupsShowNested>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessPermissionGroupsShowNestedQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник сотрудников
 */
export const getApiBandaSystemDirectoryEmployees = (
    params: GetApiBandaSystemDirectoryEmployeesParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemDirectoryEmployees200>({
        url: `/api/banda/system/directory/employees`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemDirectoryEmployeesQueryKey = (params: GetApiBandaSystemDirectoryEmployeesParams) => {
    return [`/api/banda/system/directory/employees`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDirectoryEmployeesQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>,
    TError = GetApiBandaSystemDirectoryEmployees401 | GetApiBandaSystemDirectoryEmployees403
>(
    params: GetApiBandaSystemDirectoryEmployeesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryEmployeesQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>> = ({signal}) =>
        getApiBandaSystemDirectoryEmployees(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDirectoryEmployeesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>
>;
export type GetApiBandaSystemDirectoryEmployeesQueryError =
    | GetApiBandaSystemDirectoryEmployees401
    | GetApiBandaSystemDirectoryEmployees403;

/**
 * @summary Справочник сотрудников
 */
export const useGetApiBandaSystemDirectoryEmployees = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>,
    TError = GetApiBandaSystemDirectoryEmployees401 | GetApiBandaSystemDirectoryEmployees403
>(
    params: GetApiBandaSystemDirectoryEmployeesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryEmployeesQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник подразделений
 */
export const getApiBandaSystemDirectoryDepartments = (
    params: GetApiBandaSystemDirectoryDepartmentsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemDirectoryDepartments200>({
        url: `/api/banda/system/directory/departments`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemDirectoryDepartmentsQueryKey = (
    params: GetApiBandaSystemDirectoryDepartmentsParams
) => {
    return [`/api/banda/system/directory/departments`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDirectoryDepartmentsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>,
    TError = GetApiBandaSystemDirectoryDepartments401 | GetApiBandaSystemDirectoryDepartments403
>(
    params: GetApiBandaSystemDirectoryDepartmentsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryDepartmentsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>> = ({signal}) =>
        getApiBandaSystemDirectoryDepartments(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDirectoryDepartmentsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>
>;
export type GetApiBandaSystemDirectoryDepartmentsQueryError =
    | GetApiBandaSystemDirectoryDepartments401
    | GetApiBandaSystemDirectoryDepartments403;

/**
 * @summary Справочник подразделений
 */
export const useGetApiBandaSystemDirectoryDepartments = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>,
    TError = GetApiBandaSystemDirectoryDepartments401 | GetApiBandaSystemDirectoryDepartments403
>(
    params: GetApiBandaSystemDirectoryDepartmentsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryDepartmentsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник должностей
 */
export const getApiBandaSystemDirectoryPositions = (
    params: GetApiBandaSystemDirectoryPositionsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemDirectoryPositions200>({
        url: `/api/banda/system/directory/positions`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemDirectoryPositionsQueryKey = (params: GetApiBandaSystemDirectoryPositionsParams) => {
    return [`/api/banda/system/directory/positions`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDirectoryPositionsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>,
    TError = GetApiBandaSystemDirectoryPositions401 | GetApiBandaSystemDirectoryPositions403
>(
    params: GetApiBandaSystemDirectoryPositionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryPositionsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>> = ({signal}) =>
        getApiBandaSystemDirectoryPositions(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDirectoryPositionsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>
>;
export type GetApiBandaSystemDirectoryPositionsQueryError =
    | GetApiBandaSystemDirectoryPositions401
    | GetApiBandaSystemDirectoryPositions403;

/**
 * @summary Справочник должностей
 */
export const useGetApiBandaSystemDirectoryPositions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>,
    TError = GetApiBandaSystemDirectoryPositions401 | GetApiBandaSystemDirectoryPositions403
>(
    params: GetApiBandaSystemDirectoryPositionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryPositionsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник источников
 */
export const getApiBandaSystemDirectoryGetSource = (
    params: GetApiBandaSystemDirectoryGetSourceParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemDirectoryGetSource200>({
        url: `/api/banda/system/directory/getSource`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemDirectoryGetSourceQueryKey = (params: GetApiBandaSystemDirectoryGetSourceParams) => {
    return [`/api/banda/system/directory/getSource`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDirectoryGetSourceQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>,
    TError = GetApiBandaSystemDirectoryGetSource401 | GetApiBandaSystemDirectoryGetSource403
>(
    params: GetApiBandaSystemDirectoryGetSourceParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryGetSourceQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>> = ({signal}) =>
        getApiBandaSystemDirectoryGetSource(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDirectoryGetSourceQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>
>;
export type GetApiBandaSystemDirectoryGetSourceQueryError =
    | GetApiBandaSystemDirectoryGetSource401
    | GetApiBandaSystemDirectoryGetSource403;

/**
 * @summary Справочник источников
 */
export const useGetApiBandaSystemDirectoryGetSource = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>,
    TError = GetApiBandaSystemDirectoryGetSource401 | GetApiBandaSystemDirectoryGetSource403
>(
    params: GetApiBandaSystemDirectoryGetSourceParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryGetSource>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryGetSourceQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник рабочих профилей
 */
export const getApiBandaSystemDirectoryEmployeeLink = (
    params: GetApiBandaSystemDirectoryEmployeeLinkParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemDirectoryEmployeeLink200>({
        url: `/api/banda/system/directory/employee_link`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemDirectoryEmployeeLinkQueryKey = (
    params: GetApiBandaSystemDirectoryEmployeeLinkParams
) => {
    return [`/api/banda/system/directory/employee_link`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDirectoryEmployeeLinkQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>,
    TError = GetApiBandaSystemDirectoryEmployeeLink401 | GetApiBandaSystemDirectoryEmployeeLink403
>(
    params: GetApiBandaSystemDirectoryEmployeeLinkParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryEmployeeLinkQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>> = ({signal}) =>
        getApiBandaSystemDirectoryEmployeeLink(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDirectoryEmployeeLinkQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>
>;
export type GetApiBandaSystemDirectoryEmployeeLinkQueryError =
    | GetApiBandaSystemDirectoryEmployeeLink401
    | GetApiBandaSystemDirectoryEmployeeLink403;

/**
 * @summary Справочник рабочих профилей
 */
export const useGetApiBandaSystemDirectoryEmployeeLink = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>,
    TError = GetApiBandaSystemDirectoryEmployeeLink401 | GetApiBandaSystemDirectoryEmployeeLink403
>(
    params: GetApiBandaSystemDirectoryEmployeeLinkParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployeeLink>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryEmployeeLinkQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник Штатное распиание
 */
export const getApiBandaSystemDirectoryPositionSl = (
    params: GetApiBandaSystemDirectoryPositionSlParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({url: `/api/banda/system/directory/position_sl`, method: 'GET', params, signal});
};

export const getGetApiBandaSystemDirectoryPositionSlQueryKey = (params: GetApiBandaSystemDirectoryPositionSlParams) => {
    return [`/api/banda/system/directory/position_sl`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemDirectoryPositionSlQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>,
    TError = GetApiBandaSystemDirectoryPositionSl401 | GetApiBandaSystemDirectoryPositionSl403
>(
    params: GetApiBandaSystemDirectoryPositionSlParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryPositionSlQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>> = ({signal}) =>
        getApiBandaSystemDirectoryPositionSl(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemDirectoryPositionSlQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>
>;
export type GetApiBandaSystemDirectoryPositionSlQueryError =
    | GetApiBandaSystemDirectoryPositionSl401
    | GetApiBandaSystemDirectoryPositionSl403;

/**
 * @summary Справочник Штатное распиание
 */
export const useGetApiBandaSystemDirectoryPositionSl = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>,
    TError = GetApiBandaSystemDirectoryPositionSl401 | GetApiBandaSystemDirectoryPositionSl403
>(
    params: GetApiBandaSystemDirectoryPositionSlParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositionSl>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryPositionSlQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник сущностей
 */
export const getApiBandaSystemRevisionList = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemRevisionList200>({
        url: `/api/banda/system/revision/list`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemRevisionListQueryKey = () => {
    return [`/api/banda/system/revision/list`] as const;
};

export const getGetApiBandaSystemRevisionListQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>,
    TError = GetApiBandaSystemRevisionList401 | GetApiBandaSystemRevisionList403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemRevisionListQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>> = ({signal}) =>
        getApiBandaSystemRevisionList(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemRevisionListQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>
>;
export type GetApiBandaSystemRevisionListQueryError =
    | GetApiBandaSystemRevisionList401
    | GetApiBandaSystemRevisionList403;

/**
 * @summary Справочник сущностей
 */
export const useGetApiBandaSystemRevisionList = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>,
    TError = GetApiBandaSystemRevisionList401 | GetApiBandaSystemRevisionList403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemRevisionList>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemRevisionListQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Поиск изменений
 */
export const getApiBandaSystemRevisionShow = (params?: GetApiBandaSystemRevisionShowParams, signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemRevisionShow200>({
        url: `/api/banda/system/revision/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemRevisionShowQueryKey = (params?: GetApiBandaSystemRevisionShowParams) => {
    return [`/api/banda/system/revision/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemRevisionShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>,
    TError = GetApiBandaSystemRevisionShow401 | GetApiBandaSystemRevisionShow403
>(
    params?: GetApiBandaSystemRevisionShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemRevisionShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>> = ({signal}) =>
        getApiBandaSystemRevisionShow(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaSystemRevisionShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>
>;
export type GetApiBandaSystemRevisionShowQueryError =
    | GetApiBandaSystemRevisionShow401
    | GetApiBandaSystemRevisionShow403;

/**
 * @summary Поиск изменений
 */
export const useGetApiBandaSystemRevisionShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>,
    TError = GetApiBandaSystemRevisionShow401 | GetApiBandaSystemRevisionShow403
>(
    params?: GetApiBandaSystemRevisionShowParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemRevisionShow>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemRevisionShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Оргструктура
 */
export const getApiBandaStructureIndex = (params?: GetApiBandaStructureIndexParams, signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/structure/index`, method: 'GET', params, signal});
};

export const getGetApiBandaStructureIndexQueryKey = (params?: GetApiBandaStructureIndexParams) => {
    return [`/api/banda/structure/index`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaStructureIndexQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaStructureIndex>>,
    TError =
        | GetApiBandaStructureIndex401
        | GetApiBandaStructureIndex403
        | GetApiBandaStructureIndex422
        | GetApiBandaStructureIndex500
>(
    params?: GetApiBandaStructureIndexParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaStructureIndex>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaStructureIndexQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaStructureIndex>>> = ({signal}) =>
        getApiBandaStructureIndex(params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaStructureIndex>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaStructureIndexQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaStructureIndex>>>;
export type GetApiBandaStructureIndexQueryError =
    | GetApiBandaStructureIndex401
    | GetApiBandaStructureIndex403
    | GetApiBandaStructureIndex422
    | GetApiBandaStructureIndex500;

/**
 * @summary Оргструктура
 */
export const useGetApiBandaStructureIndex = <
    TData = Awaited<ReturnType<typeof getApiBandaStructureIndex>>,
    TError =
        | GetApiBandaStructureIndex401
        | GetApiBandaStructureIndex403
        | GetApiBandaStructureIndex422
        | GetApiBandaStructureIndex500
>(
    params?: GetApiBandaStructureIndexParams,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaStructureIndex>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaStructureIndexQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Добавление сотрудника
 */
export const putApiBandaStructureCreate = (putApiBandaStructureCreateBody: PutApiBandaStructureCreateBody) => {
    return axiosInstance<unknown>({
        url: `/api/banda/structure/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaStructureCreateBody
    });
};

export const getPutApiBandaStructureCreateMutationOptions = <
    TError =
        | PutApiBandaStructureCreate401
        | PutApiBandaStructureCreate403
        | PutApiBandaStructureCreate422
        | PutApiBandaStructureCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaStructureCreate>>,
        TError,
        {data: PutApiBandaStructureCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaStructureCreate>>,
    TError,
    {data: PutApiBandaStructureCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaStructureCreate>>,
        {data: PutApiBandaStructureCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaStructureCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaStructureCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaStructureCreate>>
>;
export type PutApiBandaStructureCreateMutationBody = PutApiBandaStructureCreateBody;
export type PutApiBandaStructureCreateMutationError =
    | PutApiBandaStructureCreate401
    | PutApiBandaStructureCreate403
    | PutApiBandaStructureCreate422
    | PutApiBandaStructureCreate500;

/**
 * @summary Добавление сотрудника
 */
export const usePutApiBandaStructureCreate = <
    TError =
        | PutApiBandaStructureCreate401
        | PutApiBandaStructureCreate403
        | PutApiBandaStructureCreate422
        | PutApiBandaStructureCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaStructureCreate>>,
        TError,
        {data: PutApiBandaStructureCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaStructureCreate>>,
    TError,
    {data: PutApiBandaStructureCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaStructureCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Обновление оргструктуры
 */
export const patchApiBandaStructureUpdate = (patchApiBandaStructureUpdateBody: PatchApiBandaStructureUpdateBody) => {
    return axiosInstance<unknown>({
        url: `/api/banda/structure/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaStructureUpdateBody
    });
};

export const getPatchApiBandaStructureUpdateMutationOptions = <
    TError =
        | PatchApiBandaStructureUpdate401
        | PatchApiBandaStructureUpdate403
        | PatchApiBandaStructureUpdate422
        | PatchApiBandaStructureUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaStructureUpdate>>,
        TError,
        {data: PatchApiBandaStructureUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaStructureUpdate>>,
    TError,
    {data: PatchApiBandaStructureUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaStructureUpdate>>,
        {data: PatchApiBandaStructureUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaStructureUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaStructureUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaStructureUpdate>>
>;
export type PatchApiBandaStructureUpdateMutationBody = PatchApiBandaStructureUpdateBody;
export type PatchApiBandaStructureUpdateMutationError =
    | PatchApiBandaStructureUpdate401
    | PatchApiBandaStructureUpdate403
    | PatchApiBandaStructureUpdate422
    | PatchApiBandaStructureUpdate500;

/**
 * @summary Обновление оргструктуры
 */
export const usePatchApiBandaStructureUpdate = <
    TError =
        | PatchApiBandaStructureUpdate401
        | PatchApiBandaStructureUpdate403
        | PatchApiBandaStructureUpdate422
        | PatchApiBandaStructureUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaStructureUpdate>>,
        TError,
        {data: PatchApiBandaStructureUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaStructureUpdate>>,
    TError,
    {data: PatchApiBandaStructureUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaStructureUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Получение головы
 */
export const getApiBandaStructureGetManagers = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/structure/getManagers`, method: 'GET', signal});
};

export const getGetApiBandaStructureGetManagersQueryKey = () => {
    return [`/api/banda/structure/getManagers`] as const;
};

export const getGetApiBandaStructureGetManagersQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>,
    TError =
        | GetApiBandaStructureGetManagers401
        | GetApiBandaStructureGetManagers403
        | GetApiBandaStructureGetManagers422
        | GetApiBandaStructureGetManagers500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaStructureGetManagersQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>> = ({signal}) =>
        getApiBandaStructureGetManagers(signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaStructureGetManagersQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>
>;
export type GetApiBandaStructureGetManagersQueryError =
    | GetApiBandaStructureGetManagers401
    | GetApiBandaStructureGetManagers403
    | GetApiBandaStructureGetManagers422
    | GetApiBandaStructureGetManagers500;

/**
 * @summary Получение головы
 */
export const useGetApiBandaStructureGetManagers = <
    TData = Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>,
    TError =
        | GetApiBandaStructureGetManagers401
        | GetApiBandaStructureGetManagers403
        | GetApiBandaStructureGetManagers422
        | GetApiBandaStructureGetManagers500
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaStructureGetManagers>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaStructureGetManagersQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Получение подчиненных
 */
export const getApiBandaStructureGetSubordinates = (
    getApiBandaStructureGetSubordinatesBody: GetApiBandaStructureGetSubordinatesBody,
    params?: GetApiBandaStructureGetSubordinatesParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/structure/getSubordinates`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaStructureGetSubordinatesQueryKey = (
    getApiBandaStructureGetSubordinatesBody: GetApiBandaStructureGetSubordinatesBody,
    params?: GetApiBandaStructureGetSubordinatesParams
) => {
    return [
        `/api/banda/structure/getSubordinates`,
        ...(params ? [params] : []),
        getApiBandaStructureGetSubordinatesBody
    ] as const;
};

export const getGetApiBandaStructureGetSubordinatesQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>,
    TError =
        | GetApiBandaStructureGetSubordinates401
        | GetApiBandaStructureGetSubordinates403
        | GetApiBandaStructureGetSubordinates422
        | GetApiBandaStructureGetSubordinates500
>(
    getApiBandaStructureGetSubordinatesBody: GetApiBandaStructureGetSubordinatesBody,
    params?: GetApiBandaStructureGetSubordinatesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaStructureGetSubordinatesQueryKey(getApiBandaStructureGetSubordinatesBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>> = ({signal}) =>
        getApiBandaStructureGetSubordinates(getApiBandaStructureGetSubordinatesBody, params, signal);

    return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
        Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>,
        TError,
        TData
    > & {queryKey: QueryKey};
};

export type GetApiBandaStructureGetSubordinatesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>
>;
export type GetApiBandaStructureGetSubordinatesQueryError =
    | GetApiBandaStructureGetSubordinates401
    | GetApiBandaStructureGetSubordinates403
    | GetApiBandaStructureGetSubordinates422
    | GetApiBandaStructureGetSubordinates500;

/**
 * @summary Получение подчиненных
 */
export const useGetApiBandaStructureGetSubordinates = <
    TData = Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>,
    TError =
        | GetApiBandaStructureGetSubordinates401
        | GetApiBandaStructureGetSubordinates403
        | GetApiBandaStructureGetSubordinates422
        | GetApiBandaStructureGetSubordinates500
>(
    getApiBandaStructureGetSubordinatesBody: GetApiBandaStructureGetSubordinatesBody,
    params?: GetApiBandaStructureGetSubordinatesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaStructureGetSubordinates>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaStructureGetSubordinatesQueryOptions(
        getApiBandaStructureGetSubordinatesBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};
