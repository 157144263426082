import {FormTextField} from 'components/form/FormTextField';
import * as React from 'react';
import {useEffect} from 'react';
import {Button, Stack} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {closeModal, openModal} from 'context/modalEffector';
import {LoadingButton} from '@mui/lab';
import {
    getGetApiBandaSystemEmployeesPositionShowQueryKey,
    usePatchApiBandaSystemEmployeesPositionUpdate,
    usePutApiBandaSystemEmployeesPositionCreate
} from 'api/система';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {useQueryClient} from '@tanstack/react-query';
import {useQsParams} from 'utils/hooks/useQsParams';
import {fetchPositions} from './PositionsTable';
import {errorLayoutMessage} from 'utils/functions';

export const positionEditOpenModal = (title?: string, item?: any) => {
    openModal({
        title: title,
        body: <CreateUpdateModal row={item} />,
        maxWidth: 'sm'
    });
};

export const CreateUpdateModal = ({row}: {row: any}) => {
    const form = useForm(schema);
    const {reset} = form;
    const newMutation = usePutApiBandaSystemEmployeesPositionCreate();
    const editMutation = usePatchApiBandaSystemEmployeesPositionUpdate();
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    useEffect(() => {
        if (row) {
            reset({name: row.name});
        }
    }, [row]);
    const refetchTable = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemEmployeesPositionShowQueryKey(fetchPositions(params))
        });
    };
    const query = row ? editMutation : newMutation;
    const onSubmit = (data: any) => {
        query
            .mutateAsync({
                data: {
                    positionId: row ? row.id : undefined,
                    name: data.name
                }
            })
            .then((r) => {
                openToast({
                    text: row ? 'Должность успешно отредактирована' : 'Должность успешно создана',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchTable();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <FormTextField name={'name'} placeholder={'Название'} label={'Название'} />
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={query.isPending}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            name: yup.string().required(ERROR_MESSAGE.REQUIRED)
        })
    )
};
