import {FormAutocompleteScroll} from 'components/accessory/FormAutocompleteScroll';
import {
    getApiBandaSystemAccessContentGroupGroups,
    getGetApiBandaSystemAccessContentGroupGroupsQueryKey
} from 'api/система';
import * as React from 'react';

export const GroupsInput = (props: {disabled?: boolean; params?: object}) => {
    const {disabled, params, ...rest} = props;
    return (
        <FormAutocompleteScroll
            name={'group_id'}
            queryKey={getGetApiBandaSystemAccessContentGroupGroupsQueryKey}
            queryFn={getApiBandaSystemAccessContentGroupGroups}
            // settingsKey={{module: 'system', ...params}}
            placeholder={'Группы'}
            label={'Группы'}
            disabled={disabled}
            {...rest}
        />
    );
};
