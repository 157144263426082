/* eslint-disable camelcase */
import {Button, Stack} from '@mui/material';
import {closeModal, openModal} from 'context/modalEffector';
import * as React from 'react';
import {FormTextField} from 'components/form/FormTextField';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {
    getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryKey,
    usePatchApiBandaSystemEmployeesControlBlock,
    usePatchApiBandaSystemEmployeesControlUnblock
} from 'api/система';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {errorLayoutMessage} from 'utils/functions';
import {useQueryClient} from '@tanstack/react-query';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils/constants';

export const handleOpenBlockModal = (userInfo: any) => {
    openModal({
        title: userInfo?.is_blocked ? 'Разблокирование аккаунта' : 'Блокирование аккаунта',
        body: <BlockUnblockModal userInfo={userInfo} />,
        maxWidth: 'sm'
    });
};
export const BlockUnblockModal = ({userInfo}: {userInfo: any}) => {
    const form = useForm(schema);
    const queryClient = useQueryClient();

    const isBlocked = !!userInfo?.is_blocked;
    const blockMutation = usePatchApiBandaSystemEmployeesControlBlock();
    const unblockMutation = usePatchApiBandaSystemEmployeesControlUnblock();
    const mutation = isBlocked ? unblockMutation : blockMutation;

    const refetchProfile = () => {
        queryClient.refetchQueries({
            queryKey: getGetApiBandaSystemEmployeesAccountsDetailEmployeesIdQueryKey(userInfo?.id as string)
        });
    };

    const onSubmit = (data: any) => {
        mutation
            .mutateAsync({
                data: {
                    block_reason: !isBlocked ? data.description : undefined,
                    employee_id: userInfo?.id,
                    unblock_note: isBlocked ? data.description : undefined
                }
            })
            .then((r) => {
                openToast({
                    text: isBlocked ? 'Аккаунт разблокирован' : 'Аккаунт заблокирован',
                    type: TOAST_TYPE.SUCCESS
                });
                refetchProfile();
                closeModal();
            })
            .catch((error) => {
                openToast({
                    text: errorLayoutMessage(error),
                    type: TOAST_TYPE.ERROR
                });
            });
    };
    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack gap={2}>
                <FormTextField
                    name={'description'}
                    placeholder={isBlocked ? 'Примечание' : 'Причина блокировки'}
                    multiline
                    rows={4}
                />
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                    <LoadingButton variant={'contained'} type={'submit'} loading={false}>
                        Сохранить
                    </LoadingButton>
                    <Button variant={'contained'} color={'info'} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
const schema = {
    resolver: yupResolver(
        yup.object({
            description: yup.string().required(ERROR_MESSAGE.REQUIRED)
        })
    )
};
